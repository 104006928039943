<template>
  <div>
    <ion-list :class="`list-tutor ${disabled && 'list-tutor-disabled'}`  " lines="none" id="edit-student-legal-tutors">
      <ion-list-header class="header-tutor" >
        Tuteurs
      </ion-list-header>
      <ion-item
          v-for="(tutor, kTutor) in student.legal_tutors"
          :key="kTutor"
          :value="student.uuid"
      >
        <ion-label>{{ tutor.user.first_name }} {{ tutor.user.last_name }} - {{ tutor.user.email }}</ion-label>
        <ion-button v-if="!disabled" @click.prevent="removeTutor(tutor)" slot="end" class="btn-cancel" color="white" fill="solid" size="small">
          <ion-icon src="assets/icon/trash-solid.svg"></ion-icon>
        </ion-button>
      </ion-item>
    </ion-list>

    <ion-buttons v-if="!disabled" class="btn-tuteur">
      <modal-add-tutor @addTutor="addTutor" :currentStudent="currentStudent" ></modal-add-tutor>
      <modal-create-tutor @addTutor="addTutor" ></modal-create-tutor>
    </ion-buttons>
  </div>
</template>
<script>
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
} from '@ionic/vue';
import {defineComponent} from 'vue';
import ModalCreateTutor from "@/views/components/students/EditStudentForm/ModalCreateTutor";
import ModalAddTutor from "@/views/components/students/EditStudentForm/ModalAddTutor";
import {sortingName, basicAlertMsg} from '@/utils';
import {getAPI} from "@/axios-api";

export default defineComponent({
  name: 'PartTutorsList',
  components: {
    IonLabel, IonItem, IonList, IonButton,
    IonIcon, IonListHeader, IonButtons,
    ModalCreateTutor, ModalAddTutor
  },
  props: ["currentStudent", "disabled"],
  data(){
    return{
      tutorsToUpdate: [],
      searchTutorInput: "",
      student: this.currentStudent,
      isOpenRef: false
    }
  },
  setup(){
    return {
      sortingName, basicAlertMsg
    }
  },
  methods: {
    addTutor(tutor) {
      this.student.legal_tutors[tutor.uuid] = tutor
      this.tutorsToUpdate.push({'action' : 'add', 'email' : tutor.user.email, 'uuid' : tutor.uuid});
    },
    removeTutor(deletedTutor) {
      delete this.student.legal_tutors[deletedTutor.uuid]
      // Dans le cas ou on ajoute un tuteur puis le supprime, pour éviter de remove un tuteur qui n'existe pas dans la db
      const nonDeletedTutor = this.tutorsToUpdate.filter(tutor=>tutor.uuid !== deletedTutor.uuid)
      if(this.tutorsToUpdate.length !== nonDeletedTutor.length){
        this.tutorsToUpdate = nonDeletedTutor
      }else{
        this.tutorsToUpdate.push({'action' : 'remove', 'email' : deletedTutor.email, 'uuid' : deletedTutor.uuid})
      }
    },
    async doLinkTutorToStudent(tutorUuid) {
      await getAPI.post('/schools/' + this.$route.params.schoolUuid + '/students/' + this.student.uuid + '/legal_tutors/' + tutorUuid).catch(() => {
        this.basicAlertMsg('Une erreur s\'est produite lors de la liaison entre un tuteur et l\'élève, veuillez réessayer plus tard.');
      });
    },
    async doCreateNewTutor(tutor) {
      await getAPI.post('schools/' + this.$route.params.schoolUuid + '/legal_tutors/create', tutor).then((res) => {
        this.$store.dispatch('school/addLegalTutor', res.data.data)
        this.doLinkTutorToStudent(res.data.data.uuid);
      }).catch(() => {
        this.basicAlertMsg('Une erreur s\'est produite lors de la création d\'un tuteur, veuillez réessayer plus tard.');
      });
    },
    async doUnlinkTutor(tutorUuid) {
      await getAPI.delete('/schools/' + this.$route.params.schoolUuid + '/students/' + this.$route.params.studentUuid + '/legal_tutors/' + tutorUuid + '/delete').catch(() => {
        this.basicAlertMsg('Une erreur s\'est produite lors de la suppression d\'un tuteur, veuillez réessayer plus tard.');
      });
    },
    async doUpdateTutor(studentUuid) {
      if (studentUuid) {
        this.student.uuid = studentUuid
      }
      // Cette methods est call depuis le parent lors de l'enregistrement

      // Les méthodes addTutor et RemoveTutor mettent à jour l'objet student mais ne font pas directement la MaJ dans la db.
      // La MaJ dans la db ne se fait que lors de l'enregistrement, c'est cette méthode qui est appellée.
      for (const tutor of this.tutorsToUpdate) {
        if (tutor.action === 'add') {
          if (tutor.uuid) {
            await this.doLinkTutorToStudent(tutor.uuid);
          } else {
            const createdTutor = {first_name: "", last_name: "", email: tutor.email};
            await this.doCreateNewTutor(createdTutor)
          }
        } else {
          await this.doUnlinkTutor(tutor.uuid);
        }
      }
    }
  }
})
</script>
<style scoped>
  .header-tutor{
    max-width: var(--item-max-width);
    padding: 0;
    color: var(--ion-color-white-contrast);
  }
  .list-tutor{
    border-bottom: 1px solid var(--ion-color-white-contrast);
  }
  .list-tutor ion-item {
    --min-height: 0px;
  }
  .list-tutor-disabled {
    opacity: 0.3;
  }
  .btn-cancel{
    width: 1.5rem;
    --ion-color-base: var(--ion-color-white);
    --ion-color-contrast: var(--ion-color-white-contrast);
    --box-shadow: 0;
  }
  .btn-tuteur{
    border: none;
    flex-direction: column;
  }
  .btn-tuteur ion-icon{
    margin-left: 1rem;
  }
  ion-list-header{
    margin: 1rem auto;
    font-size: 16px;
  }
</style>