<template>
  <ion-page >
    <ion-header>
      <page-header></page-header>
    </ion-header>
    <ion-content v-if="item !== undefined">
      <ion-card v-if="!edit" color="light">
        <ion-card-header>
          <ion-card-subtitle v-if="item.item_type">{{ item.item_type.name }}</ion-card-subtitle>
          <ion-card-title>{{ item.name.substring(0, 15) }}...</ion-card-title>
          <ion-card-subtitle>{{ format_date(item.start_at) }} <span v-if="item.end_at">- {{ format_date(item.end_at) }}</span></ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
          <p>{{item.name}}</p>
          <hr>
          <ion-button fill="solid" size="small" @click.prevent="edit=true">
            <ion-icon
                style="font-size: 13px;"
                slot="start"
                src="assets/icon/pen-solid.svg"
            ></ion-icon>
            Editer
          </ion-button>
          <ion-button fill="solid" size="small">

            <ion-icon
                style="font-size: 13px;"
                slot="start"
                :icon="trash"
            ></ion-icon>
            Supprimer
          </ion-button>
        </ion-card-content>
      </ion-card>
      <div v-else>
        <ion-card color="light">
          <ion-card-content>
            <item-form :item_uuid="item.uuid"></item-form>
          </ion-card-content>
          <ion-button @click.prevent="$router.back()" fill="solid" color="medium">
            Annuler
          </ion-button>
          <ion-button @click.prevent="saveItem" fill="solid">
            Enregistrer
          </ion-button>
        </ion-card>

      </div>
    </ion-content>
    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonButton,
  IonContent,
  IonCard,
  IonCardSubtitle,
  IonCardTitle,
  IonCardHeader,
  IonFooter,
  IonPage,
  IonIcon,
  IonHeader,
  IonCardContent,
} from '@ionic/vue';
import pageHeader from "@/views/components/PageHeader";
import pageFooter from "@/views/components/PageFooter";
import itemForm from "@/views/components/items/ItemForm";
import moment from "moment";
import {trash} from "ionicons/icons";

export default {
  name: "ItemPage",
  components: {
    IonButton,
    IonContent,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonFooter,
    IonPage, IonIcon,
    pageHeader,
    pageFooter,
    IonHeader,
    IonCardContent,
    itemForm,
  },
  setup() {
    return {
      trash,
    }
  },
  data: () => ({
    showDetail: false,
    edited_item: {
      name: null,
      description: null,
      price_as_float: null,
      maximum_quantity: null,
      student_maximum_quantity: null,
      burned_quantity: null,
      burned_at: null,
      sell_start_at: null,
      sell_end_at: null,
      start_at: null,
      end_at: null,
      created_at: null,
      updated_at: null,
      deleted_at: null,
      item_type_id: null,
      image: null,
      price: null,
    },
    edit:false,
  }),
  computed: {
    item() {
      return this.$store.getters["item/getItem"](this.$route.params.itemUuid);
    }
  },
  methods: {
    saveItem() {
      this.$store.dispatch("item/saveItem", this.edited_item);
      this.edit = false;
    },
    editItem() {
      this.$store.dispatch("item/editItem", this.edited_item);
    },
    format_date(date) {
      return moment(date).format('DD MMMM YYYY HH:mm:ss')
    }
  },
  mounted() {
    this.$store.dispatch("item/fetchItem", this.$route.params.itemUuid).then(() => {
      this.$store.dispatch("item/fetchItemTags", this.$route.params.itemUuid).then(() => {
        this.$store.dispatch("item/getAvailableTags", this.$route.params.itemUuid);
      });
    });
  }
}
</script>

<style scoped>
ion-select {
  --placeholder-color: black;
  --color: black;
}
ion-select-option {
  color: #9d9fa6;
}
</style>