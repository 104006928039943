<template>

  <ion-refresher slot="fixed" @ionRefresh="refreshData($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <ion-list class="listPadding">

    <ion-list-header color="white" >
      Dernières transactions
    </ion-list-header>

    <ion-item v-if="transactions && Object.keys(transactions).length <= 0">
      <ion-label>Il n'y a pas encore eu de transaction</ion-label>
    </ion-item>

    <ion-item-group v-for="(transaction, key) in transactions" :key="key" lines="full" color="white">
      <ion-item-divider v-if="transaction[1] === 2" color="white">
        <strong>
          Solde du compte du {{ moment(transaction[0]).format("DD/MM/YYYY") }} :
          {{ transaction[2] }}&nbsp;&euro;
        </strong>
      </ion-item-divider>
      <ion-item lines="none" class="no-padding" color="white" v-if="transaction[1] === 1">
        <span class="meal-desc">
          {{ moment(transaction[0]).format("DD/MM/YYYY") }} -
          Paiement en {{ transaction[2] === true ? 'liquide' : 'ligne' }}
          <span v-if="transaction[3]">
            <span v-if="transaction[4] > 0">de</span><span v-else>pour</span> {{transaction[3]}}
          </span>
        </span>
        <span v-if="transaction[4] >= 0" class="meal-desc transaction-amount" slot="end">+ {{ transaction[4] }}&nbsp;&euro;</span>
        <span v-else class="meal-desc minus transaction-amount" slot="end">{{ transaction[4] }}&nbsp;&euro;</span>
      </ion-item>
      <ion-item lines="none" class="no-padding" color="white" v-if="transaction[1] === 0">
        <span class="meal-desc consumed-desc">
          {{ moment(transaction[0]).format("DD/MM/YYYY") }}  {{ transaction[5] && moment(transaction[5]).format('HH:mm') }} -
          {{ transaction[3] }} - {{ transaction[4] }}
        </span>
        <span class="meal-desc consumed-amount" slot="end">- {{ transaction[2] }}&nbsp;&euro;</span>
      </ion-item>
    </ion-item-group>
  </ion-list>

  <ion-infinite-scroll v-if="!ionInfiniteDisabled" @ionInfinite="loadTransactions($event,1)" id="infinite-scroll">
    <ion-infinite-scroll-content loading-spinner="bubbles"></ion-infinite-scroll-content>
  </ion-infinite-scroll>

</template>

<script>
import moment from 'moment'
import {
  IonList,
  IonItem,
  IonLabel,
  IonItemGroup,
  IonListHeader,
  IonItemDivider,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher, IonRefresherContent,
} from '@ionic/vue';
import {copyOutline} from 'ionicons/icons';
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex'

export default defineComponent({
  name: 'TransactionsList',
  components: {
    IonList, IonItem, IonLabel, IonItemGroup, IonInfiniteScroll, IonInfiniteScrollContent,
    IonItemDivider, IonListHeader, IonRefresher, IonRefresherContent,
  },
  created: function () {
    this.moment = moment;
  },
  data() {
    return {
      amount: null,
      offset: 1,
      ionInfiniteDisabled: false
    }
  },
  props: [
    'isTeacher'
  ],
  mounted() {
    this.loadTransactions();
  },
  computed:{
    ...mapGetters('students', {
      transactions:'studentTransactions'
    })
  },
  methods: {
    loadTransactions(ev=undefined, newOffset=0) {
      this.offset += newOffset;
      this.$store.dispatch('students/syncTransactions', this.offset).then((data)=>{
        if (ev) {
          this.ionInfiniteDisabled = Object.keys(this.transactions || {}).length === Object.keys(data).length;
          ev.target.complete();
        }
      })
    },
    refreshData(event){
      this.offset = 1
      this.$store.dispatch('students/reloadTransactions').then(()=>{
        this.ionInfiniteDisabled = false
        event.target.complete()
      })
    }
  },
  setup() {
    return {
      copyOutline
    }
  },
});
</script>

<style scoped>

.meal-desc {
  font-size: 0.8rem;
}

.consumed-desc {
  position: relative;
  margin-left: 25px;
}

.consumed-amount {
  position: relative;
}
.transaction-amount{
  color: var(--ion-color-success);
  font-weight: 700;
  font-size: 14px;
}
.transaction-amount.minus{
  color: var(--ion-color-danger);
  font-weight: 700;
  font-size: 14px;
}
ion-item-divider{
  padding: 1rem;
}

ion-list-header {
  font-size: 24px;
  font-weight: 600;
  padding: 1rem;
}

</style>