<template>
  <ion-page>
    <page-header>
      <ion-title v-if="township" class="header-link" >
        <router-link enterkeyhint="go" to="/home" replace>Mes communes</router-link> /
        <router-link enterkeyhint="go" :to="`/townships/${township.uuid}`" replace>{{township.name}}</router-link> /
        <router-link enterkeyhint="go" :to="`/townships/${township.uuid}/manage_menus`" replace>Gérer</router-link> /
        Editer menu
      </ion-title>
    </page-header>
    <ion-content :fullscreen="true">

      <ion-refresher slot="fixed" @ionRefresh="reloadDatas($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div class="container" >
        <ion-card color="light" >
          <ion-card-header>
            <ion-card-title>{{ school && school.name }}</ion-card-title>
            <ion-card-subtitle>Modifier un menu</ion-card-subtitle>
          </ion-card-header>
        </ion-card>
        <div v-if="!menuToEdit || loading">
          <p style="text-align: center">
            Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
          </p>
        </div>
        <form v-else class="form" onSubmit={this.processForm}>
          <ion-item lines="none" >
            <ion-label position="floating">Description <ion-text color="danger">*</ion-text></ion-label>
            <ion-textarea required type="text" v-model="menu.description" :rows="5"></ion-textarea>
          </ion-item>
          <ion-item lines="none" v-if="tags.length">
            <ion-buttons class="options-list" >
              <ion-button
                v-for="(tag, ktag) in tags"
                :key="ktag"
                class="option-el" 
                :class="menuHasTag(tag) ? 'option-selected' : ''"
                @click.prevent="toggleTag(tag)"
                color="secondary"
                :id="tag.name" 
              >{{tag.name}}  

                <!-- first char 'i' = PNG and '/' = jpg -->
                <ion-icon 
                  v-if="tag.b64_icon && tag.b64_icon.charAt(0) != 'i' && tag.b64_icon.charAt(0) != '/'" 
                  :src="'data:image/png;base64,' + tag.b64_icon"></ion-icon>
                
                <ion-img 
                  v-else
                  class='image'
                  :src="'data:image/png;base64,' + tag.b64_icon" ></ion-img>
              </ion-button>
            </ion-buttons>
          </ion-item>
          <ion-radio-group v-model="update_batch">
            <ion-item lines="none" :class="!update_batch && 'no-padding'">
              <ion-label>Mettre à jour toutes les occurences</ion-label>
                <ion-radio slot="start" :value="false" ></ion-radio>
            </ion-item>
            <ion-text v-if="!update_batch" color="danger" class="small-text">Attention, en modifiant toutes les occurrences de ce repas, vous allez modifier le repas sur toutes les classes où celui-ci est présent.</ion-text>
            <ion-item lines="none">
              <ion-label>Ne mettre à jour que ce menu</ion-label>
              <ion-radio slot="start" :value="true" ></ion-radio>
            </ion-item>
          </ion-radio-group>
          <ion-button shape="round" expand="full" color="primary" @click.prevent="saveMenu" id="save-menu">Enregistrer le menu</ion-button>
        </form>
      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>

</template>

<script>
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonFooter,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  IonText,
  IonTextarea,
  IonTitle,
  toastController
} from '@ionic/vue';
import {copyOutline} from 'ionicons/icons';
import {defineComponent} from 'vue';
import {getAPI} from "@/axios-api";
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {sortingName} from "@/utils"

export default defineComponent({

  name: "EditMenu",
  components: {
    IonPage,
    IonContent,
    PageHeader, PageFooter, IonTextarea, IonItem, IonLabel, IonButton, IonFooter,
    IonText, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonTitle, IonButtons, 
    IonSpinner, IonRefresher, IonRefresherContent, IonImg, IonRadio, IonRadioGroup
  },
  data() {
    return  {
      update_batch: true,
      loading: true,
      menu: {
        'description': null,
        'date': null,
        'classrooms': null,
        'contain_fish': false,
        'contain_gluten': false,
        'contain_pork': false,
        'is_vegetarian': false,
        'themed_menu': false,
      }
    }
  },
  computed: {
    menuToEdit () {
      return this.$store.getters["township/getMenuToEdit"]
    },
    township (){
      return this.$store.getters["township/getTownship"]
    },
    school(){
      return this.$store.getters["township/getCurrentSchool"]
    },
    tags(){
      return this.$store.getters['township/getMenuTags']
    }
  },
  mounted() {
    this.loading = true
    this.$store.dispatch('township/navigateToTownship', this.$route.params.townshipUuid).then(()=>{
      this.$store.dispatch('township/loadTownship',this.$route.params.townshipUuid)
      this.$store.dispatch('township/navigateToSchool', this.$route.params.schoolUuid).then(()=>{
        this.$store.dispatch('township/loadMenuToEdit',this.$route.params.menuUuid).then(()=>{
          this.menu = {...this.menuToEdit.menu}
          this.$store.dispatch('township/loadMenuTagList').then(()=>{
            this.loading = false
          })
        })
      })
    })
  },
  methods: {
    saveMenu() {
      const data = this.menu
      data.update_batch = this.update_batch
      getAPI.put('/meals/schools/' + this.$route.params.schoolUuid + '/caterers/menu/' + data.uuid + '/update', data).then(async (response) => {
        this.menu = response.data.data
        this.$store.dispatch('township/updateMenu', this.menu).then(()=>{
          this.$router.push("/townships/" + this.$route.params.townshipUuid + "/manage_menus");
        })
        const toast = await toastController
          .create({
            message: 'Menu enregistré avec succès.',
            duration: 2000
          })
        return toast.present();
      }).catch(async (error) => {
        alert(error.message)
      })
    },
    reloadDatas(event){
      this.$store.dispatch('township/reloadMenuToEdit', this.$route.params.menuUuid).then(()=>{
        event.target.complete();
      })
    },
    toggleTag(toggledTag){
      const index = this.menu.tags.findIndex(tag => tag.uuid === toggledTag.uuid)
      if (index > -1) {
        this.menu.tags.splice(index, 1)
      } else {
        this.menu.tags.push(toggledTag)
      }
    },
    menuHasTag(tag){
      return this.menu.tags.find(selectedTag => selectedTag.uuid === tag.uuid);
    }
  },
  setup () {
    const customPickerOptions = {
      cssClass: 'custom-options'
    }
    return {
      copyOutline, customPickerOptions, sortingName
    }
  },
});
</script>

<style scoped>
  .form-addMenu{
    box-shadow: none;
    height: calc(100vh - 7rem);
    padding: 1rem;
    margin-top: 0;

    background: #fff;
    width: 100%;
    margin: 0;
    border-radius: 0;

  }
  .small-text{
    font-size: 11px;
  }
  .no-padding{
    padding-bottom: 0;
  }
  ion-item{
    --ion-item-background: none;
    --ion-item-border-color: rgba(0,0,0,1);
    --padding-start: 0;
    --inner-padding-end: 0;
    color: var(--ion-color-white-contrast)
  }

  ion-input{
    border-bottom : 1px solid #fff;
    box-sizing: border-box;
    --padding-start: 0.5rem;
    --background: var(--ion-color-light);
    --color: var(--ion-color-white-contrast);
    margin-left: 0.5rem;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  }

  ion-button{
    width: 100%;
    margin: 0;
    --box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  }

  ion-button ion-img, ion-button ion-icon{
    max-width: 15px;
    max-height: 15px;
    margin-left: 0.3rem;
    position: relative;
  }

  .options-list{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .option-el{
    width: auto;
    margin: 0.5rem 0.5rem 0.5rem 0;
    --border-radius: 5px;
  }
  .option-selected::part(native){
    color: var(--ion-color-contrast);
    background: rgba(var(--ion-color-base-rgb), 1)
  }

  @media (min-width: 600px) {
    .form-addMenu{

      --background: var(--ion-color-white);
      --color: var(--ion-color-white-contrast);

      height: inherit;
      margin-inline: 0;
      padding: 1rem 1rem 2rem 1rem;
      overflow: visible;
      border-radius: 10px;

      --item-max-width: 500px;
    }

    ion-item{
      --ion-item-color: var(--ion-color-white-contrast);
      max-width: var(--item-max-width);
      margin: 0 auto;
      padding-bottom: 1rem;
    }

    ion-input{
      border-bottom: 1px solid var(--ion-color-white-contrast);
    }

    ion-button{
      --ion-color-base: var(--ion-background-color);
      --ion-color-contrast: var(--ion-color-white);
      max-width: var(--item-max-width);
      margin: 0 auto;
      
    }
  }
</style>