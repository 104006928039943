<template>
  <ion-page>

    <ion-header :translucent="true">
      <page-header>
        <ion-title v-if="school" class="header-link" id="add-teacher-ariane">
          <router-link enterkeyhint="go" to="/home" replace id="add-teacher-home-link">Mes écoles</router-link> / 
          <router-link enterkeyhint="go" :to="`/school/${school.uuid}`" replace id="add-teacher-school-link">{{schoolName}}</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${school.uuid}/teachers`" replace id="add-teacher-school-teachers-link">Professeurs</router-link> /
          Nouveau professeur
        </ion-title>
      </page-header>
    </ion-header>
    
    <ion-content :fullscreen="true">

      <div class="container" >
        <ion-card color="white" >
          <ion-card-header>
            <ion-card-title id="add-teacher-title">{{ schoolName}}</ion-card-title>
            <ion-card-subtitle id="add-teacher-subtitle">Ajout d'un utilisateur</ion-card-subtitle>
          </ion-card-header>
        </ion-card>
        <form class="form" @keyup.enter.prevent="createTeacher" @submit.prevent="createTeacher">
          <ion-list lines="none" class="ion-no-margin ion-no-padding">
            <ion-item lines="none" id="add-teacher-email">
              <ion-label position="floating">Email <ion-text color="danger">*</ion-text></ion-label>
              <ion-input required type="email" v-model="teacher.email"></ion-input>
            </ion-item>
            <ion-item lines="none" id="add-teacher-first-name">
              <ion-label position="floating">Prénom</ion-label>
              <ion-input required type="text" v-model="teacher.first_name"></ion-input>
            </ion-item>
            <ion-item lines="none" id="add-teacher-last-name">
              <ion-label position="floating">Nom</ion-label>
              <ion-input required type="text" v-model="teacher.last_name"></ion-input>
            </ion-item>
          </ion-list>
          <br>
          <section style="padding: 0 10px">
            <ion-button @click.prevent="createTeacher" shape="round" expand="block" id="add-teacher-button">Ajouter le professeur</ion-button>
          </section>
        </form>
      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import { IonContent, IonFooter, IonPage, IonInput, IonLabel, toastController,
IonButton, IonHeader, IonList, IonItem, IonText, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonTitle, alertController } from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { getAPI } from "@/axios-api";
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";

export default defineComponent({
  name: 'AddTeacher',
  components: {
    IonPage,
    IonContent, IonButton, IonList, IonItem, IonText, IonCard, 
    IonCardHeader, IonCardSubtitle, IonCardTitle, IonTitle,
    PageHeader, PageFooter, IonFooter, IonInput, IonLabel, IonHeader
  },
  data() {
    return  {
      teacher: {
        email: '',
        first_name: '',
        last_name: '',
        schoolUuid: null
      },
    }
  },
  mounted() {
    this.schoolUuid = this.$route.params.schoolUuid
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid)
    this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid})
  },
  setup () {
    return {
      copyOutline
    }
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    }
  },
  methods: {

    validateEmail() {
      const validation = this.teacher.email.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i
      );
      return validation
    },
    async createTeacher() {

      if(this.validateEmail()){
        getAPI.post('/schools/' + this.$route.params.schoolUuid + '/teachers/create', this.teacher).then(async (response) => {
          let message ='Le professeur a bien été ajouté.'
          if (response.status === 202) {
            message ='Le professeur a bien été lié à l\'école.'
          }
          else if(response.status === 200 && response.data.data){
            this.$store.dispatch('school/addTeacher', response.data.data)
          }
          await this.$router.back()
          const toast = await toastController
              .create({
                message: message,
                duration: 2000
              })
          return toast.present();
        }).catch(async () => {
          const alertItem = await alertController
            .create({
              message: 'Une erreur serveur est survenue. Veuillez réessayer plus tard.',
              buttons: ['OK']
            })
          return alertItem.present();
        })
      }else{
        const alertItem = await alertController
          .create({
            message: "L'adresse e-mail entrée n'est pas valide.",
            buttons: ['OK']
          })
        return alertItem.present();
      }
    }
  }
});
</script>

<style scoped>

</style>
