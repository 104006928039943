<template>
  <ion-page>
    <ion-header :translucent="true">
      <page-header>
        <ion-title v-if="schoolName && student" class="header-link" id="edit-student-ariane">
          <router-link enterkeyhint="go" to="/home" replace id="edit-student-home-link">Mes écoles</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}`" replace id="edit-student-school-link">{{schoolName}}</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}/students`" replace id="edit-student-school-students-link">Élèves</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}/students/${student.uuid}`" replace id="edit-student-school-student-link'">{{student.first_name}} {{student.last_name}}</router-link> /
          Éditer
        </ion-title>
      </page-header>
    </ion-header>

    <ion-content>
      <div class="container" v-if="loaded" >
        <page-student-form
            v-if="student && school"
            :student="student"
            :school="school"
        >
       </page-student-form>
      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer> 
    </ion-footer>
  </ion-page>
</template>

<script>
import {IonPage, IonContent, IonFooter, IonHeader, IonTitle} from '@ionic/vue';
import {defineComponent} from 'vue';
import PageStudentForm from "@/views/components/students/EditStudentForm/PageStudentForm";
import PageHeader from "@/views/components/PageHeader";
import PageFooter from "@/views/components/PageFooter";

export default defineComponent({ 
  name: 'AdminEditStudent',
  components: {
    IonPage, IonContent, IonFooter, IonHeader, IonTitle, PageStudentForm, PageFooter, PageHeader
  },
  data() {
    return {
      schoolUuid: null,
      eType: this.editType,
      loaded: false
    }
  },
  mounted() {
    this.refreshDatas()
  },
  methods: {
    refreshDatas() {
      this.schoolUuid = this.$route.params.schoolUuid
      this.$store.dispatch('school/navigateToSchool', this.schoolUuid)
      this.$store.dispatch('students/setCurrentStudent', {student_uuid: this.$route.params.studentUuid})
      this.$store.dispatch('students/syncCompleteStudent', {student_uuid: this.$route.params.studentUuid}).then(() => {
        this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid}).then(() => {
          this.$store.dispatch('school/loadClassrooms').then(()=>{
            this.$store.dispatch('school/loadLegalTutors').then(()=>{
              this.loaded = true
            })
          })
        })
      })
    },
  },
  computed: {
    student() {
      return this.$store.getters['students/currentStudent']
    },
    schoolName() {
      return this.$store.getters['school/getSchoolName']
    },
    school(){
      return this.$store.getters['school/getSchool']
    }
  },
  ionViewDidEnter() {
    this.refreshDatas()
  },
  setup() {
    return {}
  },
});
</script>

<style scoped>

</style>
