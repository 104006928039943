import Vuex from 'vuex'
import school from "./modules/school"
import meal from "./modules/meal"
import home from "@/store/modules/home"
import students from "@/store/modules/students"
import township from "@/store/modules/township"
import module from "@/store/modules/module"
import legalTutor from "@/store/modules/legalTutor"
import classroom from "@/store/modules/classroom"
import teacher from "@/store/modules/teacher"
import greeter from "@/store/modules/greeter"
import itemType from "@/store/modules/itemType";
import item from "@/store/modules/item";
import tag from "@/store/modules/tag";
import itemTypeClassroomPrice  from "@/store/modules/itemTypeClassroomPrice";
import manager from "@/store/modules/manager";
import moduleSubscription from './modules/moduleSubscription'
import reservation from './modules/reservation'

export default new Vuex.Store({
  modules: {
    school,
    meal,
    home,
    students,
    township,
    module,
    legalTutor,
    classroom,
    teacher,
    greeter,
    itemType,
    item,
    tag,
    itemTypeClassroomPrice,
    manager,
    moduleSubscription,
    reservation
  }
})