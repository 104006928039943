const state = () => ({
    meal: {}
})
const mutations = {
    changeMeal(state: any, meal: any){
        state.meal = meal
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
