<template>
  <ion-page>

    <ion-header>
      <page-header>
      </page-header>
    </ion-header>

    <part-form-edit v-if='user' :userProps="user" :userType="'self'" @saveUser="saveUser" ></part-form-edit>

  </ion-page>
</template>

<script>
import {
  IonPage, toastController, IonHeader
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {getAPI} from "@/axios-api";
import PartFormEdit from '@/views/components/PartFormEdit.vue';
import PageHeader from '@/views/components/PageHeader.vue';

export default defineComponent({
  name: 'UserEdit',
  components: {
    IonPage, PartFormEdit, IonHeader, PageHeader
  },
  data() {
    return {
      user: null,
      fieldMap: {
        'email': 'email',
        'last_name': 'nom',
        'first_name': 'prénom'
      },
      baseEmail: null
    }
  },
  methods: {
    loadUser() {
      let user = JSON.parse(localStorage.getItem('user'))
      getAPI.get('api/user/director/' + user.userprofile.uuid).then((response) => {
        const data = response.data.data
        localStorage.setItem('user', JSON.stringify(data));
        user = data;
        user.email = user.email || user.username;
        this.user = user;
        this.baseEmail = user.email
      }).catch(() => {
        alert('Une erreur serveur est survenue. Veuillez réessayer plus tard.')
      })
    },
    checkRequired(fields) {
      const invalidFields = Object.keys(fields).map((field) => {
        if (!this.user[field]) {
          return fields[field];
        }
      })
      return invalidFields.filter(n => n);
    },
    saveUser() {
      const invalidFieldsExist = this.checkRequired(this.fieldMap);
      if (invalidFieldsExist.length) {
        const msg = invalidFieldsExist.length > 1 ? 'Les champs ' + invalidFieldsExist.join(', ') + ' sont obligatoires.' : 'Le champ ' + invalidFieldsExist.join(', ') + ' est obligatoire.'
        alert(msg);
        return;
      }
      getAPI.post('/api/user/' + this.user.userprofile.uuid + '/update', this.user).then(async () => {
        localStorage.setItem('user', JSON.stringify(this.user))
        this.$router.back();
        if (this.baseEmail != this.user.email){
          const toast = await toastController
            .create({
              message: "Un email a été envoyé à l'adresse " + this.user.email + " pour confirmer le changement d'email",
              duration: 3000
            })
          return toast.present();
        }
        const toast = await toastController
            .create({
              message: 'Informations modifiées avec succès.',
              duration: 2000
            })
        return toast.present();
      })
    },
  },
  mounted() {
    this.loadUser()
  }
});
</script>

<style scoped>

</style>
