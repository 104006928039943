<template>
  <ion-page>
    <page-header>
      <ion-title class="header-link" >
        <router-link enterkeyhint="go" to="/home" replace>Mes communes</router-link> / 
        <router-link v-if="township" enterkeyhint="go" :to="`/townships/${township.uuid}`" replace>{{township.name}}</router-link> /
        Importer menu
      </ion-title>
    </page-header>

    <ion-content :fullscreen="true">

      <ion-refresher slot="fixed" @ionRefresh="reloadDatas($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="scrollToForm()" >
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>

      <div class="container" >

        <ion-card color="light" >
          <ion-card-header>
            <ion-card-title>{{ township && township.name }}</ion-card-title>
            <h3>Ecoles</h3>
            <p>Sélectionnez les écoles dans lesquelles importer les menus</p>
          </ion-card-header>
        </ion-card>
        
        <div v-if="!schools">
          <p style="text-align: center">
            Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
          </p>
        </div>
        <div v-if="schools" >
          <ion-item>
            <ion-label>Tout sélectionner</ion-label>
            <ion-checkbox 
              class="listHeader-checkbox"
                @update:modelValue="checkAll($event)"
              :modelValue="schools.selected"
            ></ion-checkbox>
          </ion-item>
          <ion-list v-for="(school, sKey) in schools.sort((a,b)=>sortingName(a,b,['name']))" :key="sKey" >
            <ion-item>
              <ion-label>
                <ion-list-header class="ion-justify-content-between" >
                  {{school.name}}
                </ion-list-header>
              </ion-label>
              <ion-checkbox 
                class="listHeader-checkbox"
                :value="school.uuid"
                  @update:modelValue="checkSchool(school, $event)"
                :modelValue="school.selected"
              ></ion-checkbox>
            </ion-item>
  
            <div v-if="school && school.classrooms" >
              <ion-item class="list-item" color="white" v-for="(classroom, clKey) in Object.values(school.classrooms).sort((a,b)=>sortingName(a,b,['name']))" :key="clKey" :v-bind="'classroom-'+classroom.id">
                <ion-label>{{classroom.name}}</ion-label>
                <ion-checkbox
                    slot="end"
                    :value="classroom.uuid"
                    @update:modelValue="checkClassroom(school, classroom, $event)"
                    :modelValue="classroom.selected"
                ></ion-checkbox>

              </ion-item>
            </div>
          </ion-list>
        </div>

        <form ref="form" class="form" >
          <ion-list-header class="form-header" >
            <h3>Exporter les menus existants</h3>
          </ion-list-header>
          <ion-button :disabled="exporting" shape="round" expand="block" @click.prevent="sendExport" id="export-button">
            Exporter <ion-spinner name="lines-sharp-small" v-if="exporting"></ion-spinner>
          </ion-button>
          <h3 class="form-divider" >PUIS</h3>        
          <ion-list-header class="form-header" >
            <h3>Importer des nouveaux menus</h3>
          </ion-list-header>
          <ion-item lines="none" >
            <input ref="inputFile" class="input-file" id="file" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
            <!-- accept=".csv" -->
          </ion-item>
          <ion-button shape="round" expand="block" @click.prevent="sendImport" id="import-button">Importer</ion-button>
          <h3 class="form-divider" >OU</h3>
          <ion-list lines="none" >
            <ion-list-header class="form-header" >
              <h3>Encoder un menu</h3>
            </ion-list-header>
            <ion-item class="hiddenForDesktop" >
              <ion-label position="floating">Date <ion-text color="danger">*</ion-text></ion-label>
              <ion-datetime :picker-options="customPickerOptions"  min="1990-02" max="2100" display-format="DD MMM YYYY" required v-model="menu.date"></ion-datetime>
            </ion-item>
              <DatePicker :attributes="calendarAttrs" is-expanded class="hiddenForMobile" :masks="masks" v-model="menu.date" mode="date">
                <template v-slot="{ inputValue, inputEvents }">
                  <ion-label position="floating">Date <ion-text color="danger">*</ion-text></ion-label>
                  <ion-input
                    :value="inputValue"
                    v-on="inputEvents"
                    readonly
                    id="add-date"
                  ></ion-input>
                </template>
              </DatePicker >

            <ion-item lines="none" >
              <ion-label position="floating">Type de repas <ion-text color="danger">*</ion-text></ion-label>
              <ion-select required type="text" v-model="menu.item_type" :rows="5" id="add-description" v-if="selectableItemTypes">
                <ion-select-option v-for="(itemType, kitemType) in selectableItemTypes" :key="kitemType" :value="itemType.name">{{itemType.name}}</ion-select-option>              
              </ion-select>
            </ion-item>
            <ion-item lines="none" >
              <ion-label position="floating">Description <ion-text color="danger">*</ion-text></ion-label>
              <ion-textarea required type="text" v-model="menu.description" :rows="5" id="add-description"></ion-textarea>
            </ion-item>
            <ion-item lines="none" >
              <ion-label position="floating">Prix (si ce n'est pas le prix par défaut)</ion-label>
              <ion-input type="number" v-model="menu.price" id="add-price"></ion-input>
            </ion-item>
            <ion-item>
              <ion-buttons v-if="tags.length" class="options-list" >
              <ion-button
                v-for="(tag, ktag) in tags"
                :key="ktag"
                class="option-el" 
                :class="menu.tags.filter(selectedTag=>selectedTag.uuid==tag.uuid).length ? 'option-selected' : ''" 
                @click.prevent="toggleTag(tag)"
                color="secondary"
                :id="tag.name"
              >{{tag.name}}  

                <!-- first char 'i' = PNG and '/' = jpg -->
                <ion-icon 
                  v-if="tag.b64_icon && tag.b64_icon.charAt(0) != 'i' && tag.b64_icon.charAt(0) != '/'" 
                  :src="'data:image/png;base64,' + tag.b64_icon"></ion-icon>
                
                <ion-img
                  v-else
                  class='image'
                  :src="'data:image/png;base64,' + tag.b64_icon" ></ion-img>
              </ion-button>
            </ion-buttons>
            </ion-item>
          </ion-list>

          <ion-button @click.prevent='addMenu' shape="round" expand="block" id="encode-menu">Encoder un menu</ion-button>
        </form>
        
      </div>

    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton, IonButtons, IonCheckbox, IonFooter, IonList, IonItem, loadingController,
IonLabel, IonListHeader, IonCard, IonCardHeader, IonCardTitle, IonDatetime, IonInput, IonRefresher, IonRefresherContent,
IonTextarea, IonText, IonFab, IonFabButton, IonIcon, IonSelect, IonSelectOption, toastController, alertController, IonTitle, IonSpinner, IonImg  } from '@ionic/vue';
import { copyOutline, add } from 'ionicons/icons';
import { defineComponent} from 'vue';
import { getAPI } from "@/axios-api";
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {sortingName} from "@/utils"
import {DatePicker } from 'v-calendar';
import moment from 'moment'

export default defineComponent({

  name: "ImportMenus",
  components: {
    PageHeader, PageFooter, IonFooter, IonRefresher, IonRefresherContent,
    IonPage, IonList, IonItem, IonLabel, IonButtons,
    IonListHeader, IonCard, IonCardHeader, IonCardTitle,
    IonContent, IonButton, IonCheckbox, IonDatetime, IonInput, IonImg,
    IonTextarea, IonText, IonFab, IonFabButton, IonIcon, IonSelect, IonSelectOption, DatePicker, IonTitle, IonSpinner
  },
  data() {
    return  {
      townshipUuid: null,
      exporting: false,
      menu: {
        'description': null,
        'date': null,
        'classrooms': null,
        'tags': [],
        'price': null
      },
      masks: {
        input: 'DD MMM YYYY',
      },
      calendarAttrs: [
        {
          key: 'today',
          bar: {
            color: 'gray',
          },
          dates: new Date(),
        },
      ],
    }
  },
  computed:{
    township(){
      return this.$store.getters["township/getTownship"]
    },
    schools(){
      return this.$store.getters["township/getTownshipSchools"]
    },
    tags(){
      return this.$store.getters['township/getMenuTags']
    },
    selectableItemTypes() {
      return this.$store.getters["township/getSelectableItemTypes"]
    }
  },
  mounted() {
    this.townshipUuid = this.$route.params.townshipUuid
    this.$store.dispatch('township/navigateToTownship', this.townshipUuid)
    this.$store.dispatch('township/loadTownship').then(() => {
      this.$store.dispatch('township/loadTownshipSchools')
      this.$store.dispatch('township/loadMenuTagList')
      this.$store.dispatch('township/loadItemTypeList', {townshipUuid: this.$route.params.townshipUuid})
    })
  },
  methods: {
    reloadDatas(event){
      this.$store.dispatch('township/reloadTownship').then(() => {
        this.$store.dispatch('township/loadTownshipSchools').then(()=>{
          event.target.complete();
        })
      })
    },
    async erreurhandler(msg){
      const alertMsg = {
        header: 'Une erreur est survenue',
        message: msg,
        buttons: ['OK'],
      }
      const alertItem = await alertController.create(alertMsg);
      return alertItem.present();
    },
    async sendImport() {
      const loading = await loadingController.create()
      loading.present()
      const formData = new FormData();
      const xlsxFile = this.$refs.inputFile.files[0]
      const classroomUuids = []
      this.schools.forEach((item) => {
        Object.keys(item.classrooms).forEach((uuid) => {
          if (item.classrooms[uuid].selected) {
            classroomUuids.push(uuid)
          }
        })
      })
      if(classroomUuids.length === 0){
         this.erreurhandler("Veuillez sélectionner au moins une classe")
         return;
      }
      formData.append("township_uuid", this.township.uuid);
      formData.append("xlsx", xlsxFile);
      formData.append("classroomUuids", JSON.stringify(classroomUuids));

      getAPI.post('/meals/imports', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(async () => {
        loading.dismiss()
        const alertMsg = {
          message: 'Les menus sont en cours d\'importation. Vous recevrez un email lorsque ceux-ci seront importés.',
          buttons: ['OK'],
        }
        this.$router.back()
        const alertItem = await alertController.create(alertMsg);
        return alertItem.present();
      }).catch(async () => {
        loading.dismiss()
        const alertMsg = {
          message: 'Vos repas n\'ont pas été importés. Vous recevrez un email vous en détaillant les raisons.',
          buttons: ['OK'],
        }
        this.$router.back()
        const alertItem = await alertController.create(alertMsg);
        return alertItem.present();
      })
    },
    sendExport(){
      const selectedSchools = this.$store.getters['township/getSelectedSchools']
      if (selectedSchools.length === 0) {
        this.erreurhandler("Veuillez sélectionner au moins une école")
        return
      }
      if (selectedSchools.length > 1) {
        this.erreurhandler("Pour exporter les menus, veuillez ne sélectionner qu'une seule école")
        return
      }
      const schoolUuid = selectedSchools[0].uuid
      this.exporting = true
      getAPI.get('meals/exports/school/'+schoolUuid).then(async () => {
        const alertMsg = {
          message: 'Les menus sont en cours d\'exportation. Vous recevrez un email lorsque ceux-ci seront exportés.',
          buttons: ['OK'],
        }
        this.$router.back()
        const alertItem = await alertController.create(alertMsg);
        return alertItem.present();
      }).finally(() => {
        this.exporting = false
      })
    },
    addMenu() {
      const required_fields = ['date', 'description', 'item_type']

      for (let index = 0; index < required_fields.length; index++) {
        const field = required_fields[index];
        if (!this.menu[field]){
          this.erreurhandler(`Le champ ${field} ne peut pas être vide`)
          return;
        }
      }
      this.menu.date = moment(this.menu.date).add(12, 'hours')

      const selectedSchool = this.schools.filter((school)=>{
        let keepSchool = false
        Object.values(school.classrooms).forEach((classr) => {
          if (classr.selected) {
            keepSchool = true
          }
        })
        if(keepSchool){
          return school
        }
      })

      if(selectedSchool.length === 0){
        this.erreurhandler("Veuillez sélectionner au moins une classe")
        return;
      }

      selectedSchool.forEach((school)=>{
        const menu_data = {...this.menu}
        menu_data.classrooms = Object.values(school.classrooms).filter(classroom => classroom.selected);
        menu_data.classrooms = menu_data.classrooms.map(classroom => classroom.uuid)

        getAPI.post('/meals/schools/' + school.uuid + '/caterers/menu/create', menu_data).then(async () => {
          this.$store.dispatch("township/addMealsToTownship", {meals:menu_data, school})
          this.menu.date = null
          this.menu.description = null
          this.menu.tags = []
          const toast = await toastController
              .create({
                message: 'Menu(s) ajouté(s) avec succès.',
                duration: 2000
              })
          return toast.present();
        }).catch(async (error) => {
          const alertMsg = {
            header: 'Une erreur s\'est produite.',
            message: error.message,
            buttons: ['OK'],
          }
          const alertItem = await alertController.create(alertMsg);
          return alertItem.present();
        })

      })
      this.menu.price = null;
    },
    checkAll(event) {
      this.schools.forEach(school=>{
        school.selected = event;
        this.checkSchool(school, event)
      })
    },
    checkSchool(school, event){
      this.$store.commit('township/selectSchool', {townshipUuid: this.township.uuid, schoolUuid: school.uuid, selected: event})
      this.$store.dispatch('township/loadItemTypeList', {townshipUuid: this.township.uuid})
      Object.values(school.classrooms).forEach((classroom)=>{
        this.$store.commit('township/selectClassroom', {townshipUuid: this.township.uuid, schoolUuid: school.uuid, classroomUuid: classroom.uuid, selected: event})
        this.$store.dispatch('township/loadItemTypeList', {townshipUuid: this.township.uuid})
      })
    },
    checkClassroom(school, classroom, event){
      this.$store.commit('township/selectClassroom', {townshipUuid: this.township.uuid, schoolUuid: school.uuid, classroomUuid: classroom.uuid, selected: event})
      this.$store.dispatch('township/loadItemTypeList', {townshipUuid: this.township.uuid})
    },
    toggleTag(tag){
      const tagExist = this.menu.tags.find(selectedTag=>selectedTag.uuid==tag.uuid)
      if(tagExist){
        this.menu.tags = this.menu.tags.filter(selectedTag=>selectedTag.uuid!=tag.uuid)
      }else{
        this.menu.tags.push(tag)
      }
    },
    scrollToForm(){
      this.$refs.form.scrollIntoView({behavior: "smooth", block: 'start'})
    }
  },
  setup () {
    const customPickerOptions = {
      cssClass: 'custom-options'
    }
    return {
      copyOutline, add, sortingName, customPickerOptions
    }
  },
});
</script>

<style scoped>

  .container{
    padding-bottom: 1rem;
  }

  .form{
    padding: 3rem 1rem;
  }

  .form ion-list{
    display: initial;
  }
  .form ion-input{
    box-shadow: none;
  }

  ion-list-header{
    font-weight: 600;
    font-size: 18px;
    color: var(--ion-color-white);
    padding: 1rem 0;
    align-items: center;
  }

  .form-header h3{
    color: var(--ion-color-white-contrast);
    margin: 0 auto;
  }

  .form-divider{
    color: var(--ion-color-white-contrast);
    text-align: center;
    font-weight: 700;
    margin-top: 3rem;
  }

  .listHeader-checkbox{
    --border-color: var(--ion-color-white);
  }

  ion-card{
    margin: 0;
    border-radius: 0;
  }


  .options-list{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .option-el{
    width: 100px;
    margin: 0.5rem 0.5rem 0.5rem 0;
    --border-radius: 5px;
  }
  .option-selected::part(native){
    color: var(--ion-color-contrast);
    background: rgba(var(--ion-color-base-rgb), 1)
  }

  @media (min-width: 600px) {
    ion-list-header{
      color: var(--ion-color-white-contrast);
    }

    .listHeader-checkbox{
      --border-color: var(--ion-color-white-contrast);
    }
  }

</style>