const state = () => ({
    modules: {}
})

const mutations = {
    createManager(state: any) {
        state.homeDataLoaded = true
    },
    setModule(state: any, data: any) {
        state.modules[data.uuid] = data
    }
}

const getters = {
    getModule(state: any) {
        return (moduleUuid: string) => {
            return state.modules[moduleUuid]
        }
    },
    getModuleBySlug: (state: any) => (slug:string) => {
        return Object.values(state.modules).find((module:any)=>{
            return module.slug_name == slug
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}
