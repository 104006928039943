import { RouteRecordRaw } from 'vue-router';

import AddTeacher from "@/views/pages/school/teacher/AddTeacher.vue";
import AddStudent from "@/views/pages/school/student/AddStudent.vue";
import AddClassroom from "@/views/pages/school/classroom/AddClassroom.vue";
import AddLegalTutor from "@/views/pages/school/legalTutor/AddLegalTutor.vue";
import EditLegalTutor from "@/views/pages/school/legalTutor/EditLegalTutor.vue";
import EditTeacher from "@/views/pages/school/teacher/EditTeacher.vue";
import EditGreeter from "@/views/pages/school/greeter/EditGreeter.vue";
import EditClassroom from "@/views/pages/school/classroom/EditClassroom.vue";
import AdminEditStudent from "@/views/pages/school/student/EditStudent.vue";
import SchoolClassrooms from "@/views/pages/school/classroom/SchoolClassrooms.vue";
import SchoolClassroom from "@/views/pages/school/classroom/SchoolClassroom.vue";
import SchoolStudents from "@/views/pages/school/student/SchoolStudents.vue";
import SchoolTeachers from "@/views/pages/school/teacher/SchoolTeachers.vue";
import SchoolTutors from "@/views/pages/school/legalTutor/SchoolTutors.vue";
import LegalTutorPage from "@/views/pages/school/legalTutor/LegalTutorPage.vue";
import TeacherPage from "@/views/pages/school/teacher/TeacherPage.vue"
import GreeterPage from "@/views/pages/school/greeter/GreeterPage.vue"
import AdminStudent from "@/views/pages/school/student/AdminStudent.vue";
import SchoolGreeters from "@/views/pages/school/greeter/SchoolGreeters.vue";
import AddGreeter from "@/views/pages/school/greeter/AddGreeter.vue";
import PaymentRecap from '@/views/pages/school/PaymentRecap.vue'
import SchoolPage from "@/views/pages/school/SchoolPage.vue";
import ClassroomPage from "@/views/pages/teacher/ClassroomPage.vue";

const routesSchoolDirector: Array<RouteRecordRaw> = [
    {
        path: '/school/:schoolUuid',
        name: 'SchoolPage',
        component: SchoolPage
    },
    {
        path: '/school/:schoolUuid/students',
        name: 'SchoolStudents',
        component: SchoolStudents
    },
    {
        path: '/school/:schoolUuid/students/:studentUuid/edit',
        name: 'AdminEditStudent',
        component: AdminEditStudent
    },
    {
        path: '/school/:schoolUuid/students/:studentUuid',
        name: 'AdminSchoolStudents',
        component: AdminStudent
    },
    {
        path: '/school/:schoolUuid/teachers',
        name: 'SchoolTeachers',
        component: SchoolTeachers
    },
    {
        path: '/school/:schoolUuid/tutors',
        name: 'SchoolTutors',
        component: SchoolTutors
    },
    {
        path: '/school/:schoolUuid/tutors/:tutorUuid',
        name: 'LegalTutorPage',
        component: LegalTutorPage
    },
    {
        path: '/school/:schoolUuid/greeters/:greeterUuid',
        name: 'GreeterPage',
        component: GreeterPage
    },
    {
        path: '/school/:schoolUuid/teachers/:teacherUuid',
        name: 'TeacherPage',
        component: TeacherPage
    },
    {
        path: '/school/:schoolUuid/tutors/:tutorUuid/edit',
        name: 'EditLegalTutor',
        component: EditLegalTutor
    },
    {
        path: '/school/:schoolUuid/teachers/:teacherUuid/edit',
        name: 'EditTeacher',
        component: EditTeacher
    },
    {
        path: '/school/:schoolUuid/greeters/:greeterUuid/edit',
        name: 'EditGreeter',
        component: EditGreeter
    },
    {
        path: '/school/:schoolUuid/classrooms/:classroomUuid/edit',
        name: 'EditClassroom',
        component: EditClassroom
    },
    {
        path: '/school/:schoolUuid/greeters',
        name: 'SchoolGreeters',
        component: SchoolGreeters
    },
    {
        path: '/school/:schoolUuid/add_greeter',
        name: 'AddGreeter',
        component: AddGreeter
    },
    {
        path: '/school/:schoolUuid/add_teacher',
        name: 'AddUser',
        component: AddTeacher
    },
    {
        path: '/school/:schoolUuid/add_student',
        name: 'AddStudent',
        component: AddStudent
    },
    {
        path: '/school/:schoolUuid/add_classroom',
        name: 'AddClassroom',
        component: AddClassroom
    },
    {
        path: '/school/:schoolUuid/add_legal_tutor',
        name: 'AddLegalTutor',
        component: AddLegalTutor
    },
    {
        path: '/school/:schoolUuid/classroom/:classroomUuid',
        name: 'ClassroomPage',
        component: ClassroomPage
    },
    {
        path: '/school/:schoolUuid/classrooms',
        name: 'SchoolClassrooms',
        component: SchoolClassrooms
    },
    {
        path: '/school/:schoolUuid/classrooms/:classroomUuid',
        name: 'SchoolClassroom',
        component: SchoolClassroom
    },
    {
        path: '/payment/recap',
        name: 'CreatePaymentRecap',
        component: PaymentRecap,
    },
]

export default routesSchoolDirector