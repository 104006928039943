import { createRouter, createWebHashHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '@/views/pages/HomePage.vue'
import LoginPage from '@/views/pages/LoginPage.vue'
import ResetPassword from '@/views/pages/user/ResetPassword.vue'
import UserAdmin from "@/views/pages/user/UserEdit.vue";

import routesNursery from './nursery';
import routesTownship from './township';
import routesStudent from './student';
import routesAdminModule from './adminModule';
import routesTeacher from './teacher';
import routesAttendance from './greeter';
import routesSchoolDirector from './schoolDirector';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/home',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/reset_password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/user/:userUuid',
    name: 'UserAdmin',
    component: UserAdmin
  },

  ...routesNursery,
  ...routesTownship,
  ...routesStudent,
  ...routesAdminModule,
  ...routesTeacher,
  ...routesAttendance,
  ...routesSchoolDirector
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

export default router
