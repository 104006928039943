<template>

  <form v-if="!loading" class="form" @keyup.enter.prevent="saveStudent" @submit.prevent="saveStudent">
    <ion-list lines="none">
      <ion-list-header color="white" id="edit-student-header">
        Editer les informations de {{student.first_name}} {{student.last_name}}
      </ion-list-header>

      <part-personal-info :studentProps="currentStudent" :disabled="disabled" ></part-personal-info>

      <part-tutors-list :currentStudent="currentStudent" :disabled="disabled" ref="tutorsList" ></part-tutors-list>

      <part-address :studentProps="currentStudent" ></part-address>
    </ion-list>

    <ion-button shape="round" expand="full" @click.prevent="saveStudent">Enregistrer</ion-button>
  </form>
</template>

<script>
import {
  IonButton,
  IonList,
  IonListHeader,
  toastController
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {getAPI} from "@/axios-api";
import {basicAlertMsg} from '@/utils'
import PartTutorsList from "@/views/components/students/EditStudentForm/PartTutorsList";
import PartPersonalInfo from "@/views/components/students/EditStudentForm/PartPersonalInfo";
import PartAddress from "@/views/components/students/EditStudentForm/PartAddress";


export default defineComponent({
  name: 'PageStudentForm',
  components: {
    IonList, IonButton, IonListHeader, PartTutorsList, PartPersonalInfo, PartAddress
  },
  props: ['student', 'school', 'disabled'],
  data() {
    return {
      tutorToUpdate: {},
      searchTutorInput: "",
      isOpenRef: false,
      isOpenCreateTutor: false,
      legalTutors: [],
      currentStudent: null,
      currentSchool: null,
      fieldMap: {
        'first_name': 'prénom',
        'last_name': 'nom',
        'national_number': 'numéro national',
        'classroom': 'classe'
      },
      newTutorEmail: '',
      errorMessage: '',
      loading: true
    }
  },
  setup(){
    return {basicAlertMsg}
  },
  computed:{
    classrooms(){
      return this.$store.getters['school/getSchoolClassrooms']({schoolUuid: this.$route.params.schoolUuid})
    }
  },
  mounted(){
    this.currentStudent = this.student
    if (this.classrooms.length){
      this.currentStudent.classroom = this.classrooms.find(classroom=>classroom.uuid==this.currentStudent.classroom.uuid)
    }
    if(this.school){
      this.currentSchool = this.school
    }else{
      this.classroom = this.student.classroom
    }
    
    this.loading = false
  },
  methods: {
    checkRequired(fields) {
      const invalidFields = Object.keys(fields).map((field) => {
        if (!this.currentStudent[field]) {
          return fields[field];
        }
      })
      return invalidFields.filter(n => n);
    },
    saveStudent() {
      const student = {
        'address_box': this.currentStudent.address_box || null,
        'address_city': this.currentStudent.address_city || null,
        'address_country': this.currentStudent.address_country || null,
        'address_number': this.currentStudent.address_number || null,
        'address_letter': this.currentStudent.address_letter || null,
        'address_street': this.currentStudent.address_street || null,
        'address_zip': this.currentStudent.address_zip || null,
        'classroom': this.currentStudent.classroom.uuid || null,
        'first_name': this.currentStudent.first_name || null,
        'last_name': this.currentStudent.last_name || null,
        'national_number': this.currentStudent.national_number || null,
        'legal_tutors': this.currentStudent.legal_tutors || [],
        'cpas': this.currentStudent.cpas || false,
      }
      const invalidFieldsExist = this.checkRequired(this.fieldMap);
      if (invalidFieldsExist.length) {
        const msg = invalidFieldsExist.length > 1 ? 'Les champs ' + invalidFieldsExist.join(', ') + ' sont obligatoires.' : 'Le champ ' + invalidFieldsExist.join(', ') + ' est obligatoire.'
        this.basicAlertMsg(msg);
        return;
      }

      const nationalNumberPattern = new RegExp(/([0-9]{6}-[0-9]{3}-[0-9]{2})/g)
      if (!nationalNumberPattern.test(this.currentStudent.national_number)) {
        const msg = "Le champ Numéro national n'est pas correctement complété (000000-000-00)"
        this.basicAlertMsg(msg);
        return;
      }
      getAPI.put('/students/schools/' + this.currentStudent.classroom.school_uuid + '/students/' + this.currentStudent.uuid + '/update', student).then(async () => {
        await this.$refs.tutorsList.doUpdateTutor().then(async()=>{
          await this.$store.dispatch('students/resetStudent', {student_uuid: this.currentStudent.uuid})
          await this.$store.dispatch('students/syncCompleteStudent', {student_uuid: this.currentStudent.uuid})
          this.currentStudent.classroom_uuid = this.currentStudent.classroom.uuid
          await this.$store.dispatch('school/updateStudent', {data:this.currentStudent})
          this.currentStudent = {}
        })
        this.$router.back();
        const toast = await toastController
            .create({
              message: 'Élève modifié avec succès.',
              duration: 2000
            })
        return toast.present();
      }).catch(async (err) => {
        if (err.response !== undefined) {
          const response = err.response;
          if (response.data.status === 'fail' || response.data.status === 'error') {
            if (response.data.data === 'unauthorized') {
              const header = 'Modification impossible'
              const msg = "Vous n'êtes pas autorisé à effectuer la modification."
              this.basicAlertMsg(msg, header)
            }
          }
        } else {
          const header = 'Modification impossible'
          const msg = "Une erreur est survenue lors de la modification de l'élève."
          this.basicAlertMsg(msg, header)
        }
      })
    },
  },
});
</script>

<style scoped>
  ion-list-header{
    margin: 1rem auto;
    font-size: 16px;
  }
</style>
