<template>
  <ion-page>
    <page-header>
      <ion-title class="header-link" v-if="student">
        <router-link enterkeyhint="go" to="/home" replace>
          <span v-if="isLegalTutor">Mes enfants</span>
          <span v-else-if="isTeacher">Mes classes</span>
        </router-link> / 
        <router-link enterkeyhint="go" :to="`/student/${student.uuid}`" replace>
          {{student.first_name}} {{student.last_name}}
        </router-link> / {{module && module.name}}
      </ion-title>
    </page-header>
    <ion-content :fullscreen="true">
      <div class="container" v-if="module && module.slug_name" >
        <student-header 
            v-if="student"
            :currentStudent="student" 
            :currentClassroom="currentClassroom" 
            :currentWallet="currentWallet"></student-header>
        <page-meals v-if="module.slug_name == 'meals-module' && student" :is-teacher="false" :student="student"></page-meals>
        <page-nursery v-if="module.slug_name == 'nursery-module' && student" :is-teacher="false" :student="student"></page-nursery>
      </div>
    </ion-content>
    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>
  </ion-page>
</template>

<script>
import {IonPage, IonContent, IonFooter} from "@ionic/vue";
import PageHeader from "@/views/components/PageHeader";
import pageFooter from "@/views/components/PageFooter";
import PageMeals from "@/views/Modules/meals/PageMeals";
import PageNursery from "@/views/Modules/nursery/PageNursery";
import StudentHeader from "@/views/components/students/StudentHeader";
import {mapActions} from 'vuex'
export default {
  name: "IndexModuleWrapper",
  components: {
    PageHeader,
    PageMeals,
    PageNursery,
    IonPage,
    pageFooter,
    IonContent,
    IonFooter,
    StudentHeader
  },
  mounted(){
    this.setCurrentStudent({student_uuid: this.$route.params.studentUuid}).then(() => {
      this.syncCompleteStudent({student_uuid:  this.$route.params.studentUuid}).then(()=>{
        this.setCurrentModule({student_uuid:  this.$route.params.studentUuid, module_uuid: this.$route.params.moduleUuid })
      })
    });
  },
  methods: {
    ...mapActions('students', [
      'syncCompleteStudent',
      'setCurrentStudent',
      'setCurrentModule'
    ]),
  },
  computed:{
    module(){
      return this.$store.getters['module/getModule'](this.$route.params.moduleUuid)
    },
    student(){
      return this.$store.getters['students/getStudent'](this.$route.params.studentUuid)
    },
    currentClassroom(){
      return this.$store.getters['students/currentClassroom']
    },
    currentWallet(){
      return this.$store.getters['students/currentWallet']
    },
    isLegalTutor(){
      return this.$store.getters['home/isLegalTutor'](this.$route.params.studentUuid)
    },
    isTeacher(){
      return this.$store.getters['home/isTeacher'](this.$store.getters['students/classroom'](this.$route.params.studentUuid))
    }
  }
}
</script>

<style scoped>

</style>