<template>
  <ion-page>
    <page-header>
      <ion-title class="header-link" >
        <router-link enterkeyhint="go" to="/home" replace>Mes écoles</router-link> / 
        {{ $store.getters['school/getSchoolNameByUuid'](this.$route.params.schoolUuid) }}
      </ion-title>
    </page-header>

    <ion-content :fullscreen="true">

      <ion-refresher slot="fixed" @ionRefresh="refreshData($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div class="container" >
        <ion-card>
          <ion-card-header color="light" >
            <ion-card-title id="attendances-of-the-day">Présences du jour et repas</ion-card-title>
          </ion-card-header>
        </ion-card>
        <div v-if="reservations">
          <!-- <ion-list v-for="(reservation, rKey) in reservations.sort((a,b)=>sortingName(a,b,['item.name']))" :key="rKey" :v-bind="'items-list-'+reservation.item.uuid"> -->
          <ion-list v-for="(item, rKey) in itemsList.sort((a,b)=>sortingName(a,b,['name']))" :key="rKey" :v-bind="'items-list-'+item.uuid">
            <ion-list-header color="light">
              <ion-title>
                {{item.name}}
              </ion-title>
            </ion-list-header>
            <ion-item-sliding>
              <ion-item color="white" v-for="(reservation, sKey) in reservations.filter((res)=>res.item.uuid == item.uuid).sort((a,b)=>sortingName(a,b,['classroom', 'student.first_name', 'student.last_name']))" :key="sKey" :v-bind="'student-'+reservation.student.id">
                <ion-label>{{reservation.student.first_name}} {{reservation.student.last_name}}</ion-label>
                <ion-label>{{ reservation.classroom}}</ion-label>
                <ion-checkbox
                    slot="end"
                    :value="reservation.uuid"
                    @ionChange="updateReservation($event, reservation.classroom.uuid)"
                    @update:modelValue="reservation.consumed = $event"
                    :modelValue="reservation.consumed"
                ></ion-checkbox>
              </ion-item>
            </ion-item-sliding>
          </ion-list> 
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  toastController, IonContent, IonPage, IonItem, IonCheckbox, 
  IonList, IonItemSliding, IonListHeader, IonLabel, IonCard, IonCardHeader, 
  IonCardTitle, IonTitle, IonRefresher, IonRefresherContent
} from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import PageHeader from "@/views/components/PageHeader.vue";
import {getAPI} from "@/axios-api";
import {sortingName} from "@/utils";
import moment from 'moment'

export default defineComponent({
  name: 'AttendanceManagement',
  components: {
    IonPage, IonRefresher, IonRefresherContent,
    IonContent,
    PageHeader, IonItem, IonCheckbox,
    IonList, IonItemSliding, IonListHeader, 
    IonLabel, IonCard, IonCardHeader, 
    IonCardTitle, IonTitle
  },
  data() {
    return {
      school: {}
    }
  },
  mounted() {
    this.loadReservations();
  },
  computed:{
    schoolName(){
      // Get current school name from store
      return this.$store.getters['school/getSchoolNameByUuid'](this.$route.params.schoolUuid)
    },
    reservations(){
      // Get reservations from store for one day
      return this.$store.getters['reservation/getMealReservations'](moment().format("YYYY-MM-DD"))
    },
    itemsList(){
      // Return a new array that only contains the items
      const ensembleItems = new Set();
      this.reservations.forEach(reservation => {
        const objetStr = JSON.stringify(reservation.item);
        ensembleItems.add(objetStr);
      });
      return Array.from(ensembleItems).map(objetStr => JSON.parse(objetStr))
    }
  },
  methods: {
    refreshData(event){
      this.loadReservations().then(()=>{
        event.target.complete()
      })
    },
    loadReservations() {
      // Fetch reservation from the store at page load
      this.$store.dispatch('reservation/fetchMealReservationsByDay', {
        schoolUuid: this.$route.params.schoolUuid, 
        date: moment().format("YYYY-MM-DD"),
      })
    },
    updateReservation(event) {
      // Update the consumed checkbox for one reservation
      getAPI.put('schools/' + this.$route.params.schoolUuid + '/reservations/' + event.detail.value + '/update',
          {
            'consumed': event.detail.checked
          }
      ).then(async () => {
        const toast = await toastController
            .create({
              message: 'Présence mise à jour.',
              duration: 2000
            })
        return toast.present();
      })
    },
  },
  setup () {
    return {
      copyOutline, sortingName
    }
  },
});
</script>

<style scoped>
  ion-item:hover, ion-item:focus{
    --ion-color-base: var(--ion-color-light);
    cursor: pointer;
  }
</style>
