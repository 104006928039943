<template>
  <div v-if="!item_types.length">
    <p style="text-align: center">
        Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
    </p>
  </div>

  <ion-list v-else>
    <ion-button expand="block" v-for="(item_type, kit) in item_types.sort((a,b)=>sortingName(a,b,['name']))" :key="kit"
      :router-link="`/nursery/${$route.params.schoolUuid}/types/${item_type.uuid}`" router-direction="forward" >
        <ion-label>
        <h2>{{item_type && item_type.name}}</h2>
        </ion-label>
    </ion-button>
  </ion-list>
</template>
  
<script>
  import {
    IonButton,
    IonList, IonLabel,
  } from '@ionic/vue';
  import {sortingName} from '@/utils';
  
  export default {
    name: "ItemTypeListButtons",
    components: {
      IonList, IonButton, IonLabel
    },
    setup() {
      return {
        sortingName
      }
    },
    computed:{
      item_types(){
        return this.$store.getters["school/getModuleItemTypes"]
      }
    }
  }
  </script>
  
  <style scoped>
  </style>