<template>
  <ion-page>
    <page-header>
      <ion-title v-if="currentStudent" class="header-link" >
        <router-link enterkeyhint="go" to="/home" replace>Mes écoles</router-link> / 
        <router-link enterkeyhint="go" :to="`/school/${schoolUuid}`" replace>{{schoolName}}</router-link> /
        <router-link enterkeyhint="go" :to="`/school/${schoolUuid}/students`" replace>Élèves</router-link> /
        {{currentStudent.first_name}} {{currentStudent.last_name}}
      </ion-title>
    </page-header>
    <ion-content :fullscreen="true">

      <ion-refresher slot="fixed" @ionRefresh="refreshDatas($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div class="container" v-if="!loading">
        <student-header
          :currentStudent="$store.getters['school/currentStudent']"
          :currentClassroom="$store.getters['school/currentStudentClassroom']"
          :currentWallet="$store.getters['school/currentStudentWallet']"
        ></student-header>
        <student-modules
            v-if="$store.getters['school/currentStudentModules']"
          :modules="$store.getters['school/currentStudentModules']"
        ></student-modules>
      </div>
      <ion-spinner v-else></ion-spinner>

    </ion-content>

    <ion-footer>
      <page-footer @goToCalendar="goToCalendar" @goToMenu="goToMenu" :buttonMenu="module" ></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import moment from 'moment'
import {
  IonContent,
  IonFooter,
  IonPage,
  IonTitle,
  IonRefresher, 
  IonRefresherContent,
  IonSpinner,
} from '@ionic/vue';
import {defineComponent} from 'vue';
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {add, card, chevronBackOutline, chevronForwardOutline, create, people} from "ionicons/icons";
import {sortingName} from "@/utils";
import {mask} from 'vue-the-mask'
import StudentModules from "@/views/components/students/StudentModules";
import StudentHeader from "@/views/components/students/StudentHeader";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "AdminStudent",
  components: {
    IonContent, IonPage, IonFooter, IonRefresher, IonRefresherContent,
    PageHeader, PageFooter, IonTitle, StudentModules, StudentHeader, IonSpinner
  },
  created: function () {
    this.moment = moment;
  },
  directives: {mask},
  data() {
    return {
      module: 'menu',
      isOpenRef: false,
      meals: [],
      reservatins: [],
      data: { content: 'New Content' },
      schoolUuid: null,
      student: {
        student: {
          id: null
        }
      },
      tutors: [],
      searchTutorsInput: "",
      isFromControls: false,
      deleteConfirmed: 0,
      loading: true,
    }
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    ...mapGetters('school', [
      'currentStudentWallet',
      'currentStudent',
      'currentStudentClassroom',
      'currentStudentModules',
    ])

  },
  methods: {
    goToStudentEdit(){
      this.$router.push('/school/'+ this.$route.params.schoolUuid  +'/students/' + this.$route.params.studentUuid + '/edit' )
    },
    segmentChanged(event) {
      this.module = event.detail.value
    },
    async loadHome(){
      if (!this.$store.getters['home/isHomeDataLoaded']) {
        return await this.$store.dispatch('home/loadHome')
      }
      return
    },
    refreshDatas(event) {
      this.loading = true
      this.loadHome().then(()=>{
        this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid).then(() => {
          this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid}).then(() => {
            this.$store.dispatch('school/navigateToStudent', this.$route.params.studentUuid).then(() => {
              this.$store.dispatch('school/loadStudents').then(() => {
                this.$store.dispatch('school/loadStudent', this.$route.params.studentUuid).then(() => {
                  this.$store.dispatch('school/loadStudentWallet', this.$route.params.studentUuid).then(() => {
                    this.$store.dispatch('school/loadStudentModules', this.$route.params.studentUuid).then(() => {
                      this.loading = false
                      event.target.complete()
                    })
                  })
                })
              })
            })
          })
        })
      })
    },
    formatDate(oldDate){
      return new Date(Date.parse(oldDate)).toLocaleDateString('fr-FR')
    },
  },
  setup () {
    const options = {
      cssClass: 'custom-options'
    }

    return {
      add, create, people, card, chevronBackOutline, chevronForwardOutline, options, sortingName
    }
  },
  mounted() {
    this.schoolUuid = this.$route.params.schoolUuid
    this.mealWeek = this.moment(new Date()).isoWeek();
    this.year = this.moment(new Date()).year();
    this.loading = true
    this.loadHome().then(()=>{
      this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid).then(() => {
        this.$store.dispatch('school/loadSchool', this.$route.params.schoolUuid).then(() => {
          this.$store.dispatch('school/navigateToStudent', this.$route.params.studentUuid).then(() => {
            this.$store.dispatch('school/loadStudents').then(() => {
              this.$store.dispatch('school/loadStudent', this.$route.params.studentUuid).then(() => {
                this.$store.dispatch('school/loadStudentWallet', this.$route.params.studentUuid).then(() => {
                  this.$store.dispatch('school/loadStudentModules', this.$route.params.studentUuid).then(() => {
                    this.loading = false
                  })
                })
              })
            })
          })
        })
      })
    })
  },
});
</script>

<style scoped>

  .meal-desc{
    font-size: 14px;
  }

  .editable-0 {
    --ion-color-base: var(--ion-color-light);
  }
  .editable-0 .meal-desc, .editable-0 .meal-checkbox {
    opacity: 0.5;
  }
  .btn-modifyProfil{
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    --box-shadow: 0;
  }

  ion-list-header{
    font-weight: 600;
    font-size: 18px;
    color: var(--ion-color-white-contrast);
  }

  ion-item-divider{
    font-weight: 600;
    font-size: 14px;
    padding: 0.5rem 20px;
    border: none;
  }

  ion-item-group{
      border-bottom: 1px solid rgba(var(--ion-color-white-contrast-rgb), 0.2)
  }

  ion-segment{
    background: var(--ion-color-primary);
    border-radius: 0;
  }

  ion-segment-button{
    --ion-background-color: none;
    color: rgba(255,255,255,0.7);
    --indicator-box-shadow: none;
    border-radius: 0;
    font-size: 14px;
    padding: 0.5rem 0;
    font-weight: 700;
    text-transform: uppercase;
    --ion-color-base: #fff;

  }
  ion-segment-button::part(indicator){
    color: #fff;
    border-bottom: 3px solid #fff;
  }

  .switch{
    display: flex;
    box-shadow: 0 3px 6px rgba(0,0,0,0.3);
    border-radius: 20px;
    width: 100px;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
    background: var(--ion-color-light-shade);
    position: relative;
  }
  .switch-btn{
    --box-shadow: none;
    --ion-color-base: none;
    margin: 0;
    opacity: 0.5;
    border-radius: 20px;
    z-index: 1;
    transition: all .3s ease-out;
  }
  .switch-focus-bg::before{
    content: "";
    transition: all .3s ease-out;
    display: block;
    width: 50px;
    height: 36px;
    background: var(--ion-color-white);
    position: absolute;
    top: 0;
    border-radius: 20px;
    transform: translateX(0%);
  }
  .switch-focus-bg-right::before{
    transform: translateX(100%);
  }
  .switch-focus{
    opacity: 1;
  }

  @media (min-width: 600px){
    ion-segment{
      background: none;

    }
    ion-segment-button{
      --ion-color-base: var(--ion-color-primary);
      color: var(--ion-color-white-contrast);
    }

    .btn-add{
      --padding-top: 1rem;
      --padding-bottom: 1rem;
      --padding-start: 2rem;
      --padding-end: 2rem;
    }

    .btn-calendar{
      --box-shadow: none;
    }
  }

</style>
