<template>
  <ion-page>
    <page-header>
      <ion-title class="header-link" >
        <router-link enterkeyhint="go" to="/home" replace>Mes communes</router-link> / 
        <router-link v-if="township" enterkeyhint="go" :to="`/townships/${township.uuid}`" replace>{{township.name}}</router-link> /
        Gérer
      </ion-title>
    </page-header>

    <ion-content :fullscreen="true" v-if="township !== null">

      <ion-refresher slot="fixed" @ionRefresh="reloadDatas($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div class="container" >
        <div v-if="current_school === null">
          <ion-card color="light">
            <ion-card-header>
              <ion-card-title>Veuillez sélectionner une école ci-dessous.</ion-card-title>
            </ion-card-header>
            <ion-item color="light" id="school-selector">
              <ion-label><span v-if="loading">Chargement des écoles...</span><span v-else>École</span></ion-label>
              <ion-select mode="ios" v-if="!loading" interface="popover" :interface-options="options" v-model="current_school" @ionChange="reloadMeals">
                <ion-select-option :value="school" v-for="(school, kSchool) in schools" :key="kSchool" :v-bind="'school-'+school.id">{{school.name}}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-card>
        </div>
        <div v-else>
          <ion-card color="light">
            <ion-card-header>
              <ion-card-title>{{current_school.name}}</ion-card-title>
            </ion-card-header>
            <ion-item color="light" id="school-selector">
              <ion-label><span v-if="loading">Chargement des écoles...</span><span v-else>École</span></ion-label>
              <ion-select mode="ios"  v-if="!loading" interface="popover" :interface-options="options" v-model="current_school" @ionChange="reloadMeals">
                <ion-select-option :value="school" v-for="(school, kSchool) in schools" :key="kSchool">{{school.name}}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-card>
          <div v-if="!meals">
            <p style="text-align: center">
              Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
            </p>
          </div>
          <div v-else>
            <ion-toolbar color="light" >
              <ion-buttons slot="start">
                <ion-button @click.prevent="goPreviousWeek">
                  <ion-icon :icon="chevronBackOutline"></ion-icon>
                </ion-button>
              </ion-buttons>
              <ion-buttons slot="end">
                <ion-button @click.prevent="goNextWeek">
                  <ion-icon :icon="chevronForwardOutline"></ion-icon>
                </ion-button>
              </ion-buttons>
              <ion-title class="ion-text-center hiddenForMobile" >Semaine {{ mealWeek }}  
                <br/><span>
                  {{currentFirstDay.format("DD/MM/YYYY")}} - {{currentLastDay.format("DD/MM/YYYY")}}
                </span>
              </ion-title>
              <ion-title class="ion-text-center hiddenForDesktop text-small" >Semaine {{ mealWeek }}  
                <br/><span>
                  {{currentFirstDay.format("DD/MM/YYYY")}} - {{currentLastDay.format("DD/MM/YYYY")}}
                </span>
              </ion-title>
            </ion-toolbar>
            <ion-list color="white" >
              <div v-for="(collection, kclass) in meals.sort((a,b)=>sortingName(a,b,['classroom.name']))" :key="kclass">
                <div v-if="typeof collection.deleted === 'undefined'">
                  <ion-list-header>
                    {{ collection.classroom.name }}
                  </ion-list-header>
                  <ion-item class="meal-item ion-align-items-start" color="white" v-for="(meal, kmeal) in collection.meals" :key="kmeal" :v-bind="'meal-'+meal.id">
                    <label >
                      <span class="meal-date" >{{ moment(meal.start_at).format("dddd DD MMM YYYY")  }}</span>
                      <div v-if="meal.tags">
                        <ion-badge :style="tag.color && '--background:' + tag.color + ';'" v-for="(tag, ktag) in meal.tags" :key="ktag" >
                          {{tag.name}}
                          <!-- ion-icon n'accepte que les svg, first char 'i' = PNG et '/' = jpg -->
                          <ion-icon
                              v-if="tag.b64_icon && tag.b64_icon.charAt(0) != 'i' && tag.b64_icon.charAt(0) != '/'"
                              :src="'data:image/png;base64,' + tag.b64_icon"></ion-icon>

                          <ion-img
                              v-else
                              class='image'
                              :src="'data:image/png;base64,' + tag.b64_icon" ></ion-img>
                        </ion-badge>
                      </div>

                      <span class="meal-desc" v-html="meal.description.replace(/(?:\r\n|\r|\n)/g, '<br />')">
                      </span>
                    </label>
                    <ion-button class="btn-icon" title="Modifier menu" @click.prevent="goToEditMeal(meal)" slot="end" shape="round" color="white" size="small">
                      <ion-icon src="/assets/icon/pen-solid.svg"></ion-icon>
                    </ion-button>
                    <ion-button class="btn-icon" title="Supprimer menu" @click.prevent="confirmDeleteMeal(meal, kmeal);" slot="end" shape="round" color="white" size="small">
                      <ion-icon src="assets/icon/trash-solid.svg"></ion-icon>
                    </ion-button> 
                  </ion-item>
                </div>
              </div>
            </ion-list>
          </div>
        </div>
      </div>
    </ion-content>
    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>
  </ion-page>
</template>

<script>
import moment from 'moment';
import 'moment/locale/fr';
import {
  IonPage, IonContent, IonButton, IonFooter, IonItem, IonLabel, IonCard, IonCardTitle,
  IonSelect, IonSelectOption, IonSpinner, IonButtons, alertController, toastController, IonBadge, IonListHeader,
  IonToolbar, IonTitle, IonIcon, IonCardHeader, IonList, IonRefresher, IonRefresherContent,
} from '@ionic/vue';
import { copyOutline, chevronBackOutline, chevronForwardOutline, trashOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { getAPI } from "@/axios-api";
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {sortingName} from '@/utils';

export default defineComponent({
  name: "ManageMenus",
  components: {
    PageHeader, PageFooter, IonPage, IonFooter, IonContent,
    IonItem, IonLabel, IonSelect, IonSelectOption, IonCard, IonCardTitle,
    IonSpinner, IonButtons, IonButton, IonBadge, IonListHeader,
    IonToolbar, IonTitle, IonIcon, IonCardHeader, IonList, IonRefresher, IonRefresherContent,
  },
  data() {
    return  {
      current_school: null,
      loading: false,
      loadingMeals: false,
      mealWeek: null,
      firstDay: null,
      lastDay: null,
      year: null,
    }
  },
  mounted() {
    this.loading = true
    this.townshipUuid = this.$route.params.townshipUuid
    this.$store.dispatch('township/navigateToTownship', this.townshipUuid)
    this.$store.dispatch('township/loadTownship').then(() => {
      this.loadSchools().then(() => {
        this.loading = false
      })
    })
  },
  methods: {
    reloadDatas(event){
      this.$store.dispatch('township/reloadTownship').then(() => {
        this.loadSchools().then(() => {
          if (this.current_school){
            this.reloadMeals().then(() => {
              event.target.complete();
            })
          }else{
            event.target.complete();
          }
        })
      })    
    },
    loadSchools() {
      return this.$store.dispatch('township/loadTownshipSchools')
    },
    reloadMeals() {
      this.$store.dispatch('township/navigateToSchool', this.current_school.uuid)
      this.year = this.moment().year();
      this.mealWeek = moment().week()
      if (this.mealWeek >= 53) {
        this.mealWeek = this.mealWeek - 52
        this.year = this.year + 1
      }
      return this.$store.dispatch('township/changeWeek',{
          year: this.year,
          week: this.mealWeek,
      })
    },
    goNextWeek() {
      this.mealWeek = this.mealWeek + 1;
      if (this.mealWeek >= 53) {
        this.mealWeek = this.mealWeek - 52
        this.year = this.year + 1
      }
      this.$store.dispatch('township/changeWeek',{
          year: this.year,
          week: this.mealWeek,
      })
    },
    goPreviousWeek() {
      this.mealWeek = this.mealWeek - 1;
      if (this.mealWeek <= 0) {
        this.mealWeek = this.mealWeek + 52
        this.year = this.year - 1
      }
      this.$store.dispatch('township/changeWeek',{
          year: this.year,
          week: this.mealWeek,
      })
    },
    goToEditMeal(meal){
      this.$store.dispatch('township/setMenuToEdit', meal)
      this.$router.push('/townships/'+ this.$route.params.townshipUuid +'/school/' + this.current_school.uuid + '/menu/'+ meal.uuid +'/edit_menus')
    },

    async confirmDeleteMeal(meal){
      const alertMsg = {
        message: `Voulez vous vraiment supprimer le repas du ${moment(meal.start_at).format("DD/MM/YYYY")} ? \n\n
        Toutes les réservations liées à ce repas seront également annulées.
        `,
        buttons: [
          {
            text: "Retour",
          },
          {
            text: "Continuer",
            cssClass: "btn-danger",
            handler: ()=> {
              this.deleteMeal(meal)
            }
          }
        ]
      }
      const alertItem = await alertController.create(alertMsg);
      return alertItem.present();
    },

    deleteMeal(meal) {
      return getAPI.delete('/meals/schools/' + this.current_school.uuid + '/meals/' + meal.uuid + "/delete").then(async () => {
        this.$store.dispatch("township/removeMeal",meal.uuid)
        this.loadingMeals = false
        const toast = await toastController
            .create({
              message: 'Repas supprimé avec succès.',
              duration: 2000
            })
          return toast.present();
      }).catch(async () => {
        const alertItem = await alertController
            .create({
              header: 'Erreur lors de la suppression',
              message: 'Vous ne pouvez pas supprimer ce repas. Des réservations sont reliées à celui-ci.',
              buttons: ['OK']
            })
        return alertItem.present();

      })
    },
  },
  computed: {
    currentFirstDay () {
      return this.moment().year(this.year).week(this.mealWeek).day(1);
    },
    currentLastDay () {
      return this.moment().year(this.year).week(this.mealWeek).day(5);
    },
    township(){
      return this.$store.getters["township/getTownship"]
    },
    schools(){
      return this.$store.getters["township/getTownshipSchools"]
    },
    meals(){
      return this.$store.getters["township/getSchoolWeekMeals"]
    }
  },
  created: function () {
    this.moment = moment;
  },
  setup () {
    const options = {
      cssClass: "select-school-custom-options",
    }
    return {
      copyOutline, chevronBackOutline, chevronForwardOutline, trashOutline, options, sortingName
    }
  },
});
</script>

<style scoped>

  .meal-desc {
    display: block;
    font-size: 0.8rem;
    line-height: 1.8;
    margin-bottom: 1rem;
  }

  .meal-date{
    text-transform: capitalize;
    display: block;
    margin-top: 1rem;
  }
  .text-small{
    font-size: 14px;
  }
  .btn-cancel{
    margin-left: 1rem;
    position: absolute;
    top: 0.5rem;
    right: 0rem;
    --box-shadow: none;
  }

  .btn-icon{
    margin-top: 1rem;
    --box-shadow: none;
  }

  ion-list{
    padding-top: 0;
  }

  ion-list-header{
    font-size: 21px;
    font-weight: 600;
    align-items: center;
    --color: var(--ion-color-white);
    padding: 1rem;
    background: var(--ion-color-primary)
  }
  ion-badge ion-icon, ion-badge ion-img{
    max-width: 13px;
    max-height: 13px;
    margin-left: 0.3rem;
    position: relative;
    top: 2px;
    display: inline-block;
  }
  @media (min-width: 600px){
    ion-list-header{
      --color: var(--ion-color-white-contrast);
      background: none;
      border-bottom: 1px solid rgba(0,0,0,0.5);
    }
  }

</style>