<template>

  <ion-refresher slot="fixed" @ionRefresh="refreshData($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button @click.prevent="goPreviousWeek">
        <ion-icon :icon="chevronBackOutline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button @click.prevent="goNextWeek">
        <ion-icon :icon="chevronForwardOutline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title v-if="itemWeek" class="ion-text-center hiddenForMobile" >Semaine {{ itemWeek }}<br/><span>{{moment().week(itemWeek).year(year).weekday(0).format("DD/MM/YYYY")}} - {{moment().week(itemWeek).year(year).weekday(4).format("DD/MM/YYYY")}}</span> </ion-title>
    <ion-title v-if="itemWeek" class="ion-text-center hiddenForDesktop text-small" >Semaine {{ itemWeek }}<br/><span>{{moment().week(itemWeek).year(year).weekday(0).format("DD/MM/YYYY")}} - {{moment().week(itemWeek).year(year).weekday(4).format("DD/MM/YYYY")}}</span> </ion-title>
  </ion-toolbar>

  <div v-if="!itemsIsLoaded">
    <p style="text-align: center">
      Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
    </p>
  </div>
  <ion-item v-if="itemsIsLoaded && days.length" class="text-small" >
    <p v-if="module.slug_name !== nursery_module">Veuillez cocher pour commander.</p>
  </ion-item>
  <ion-item v-else-if="itemsIsLoaded && !days.length">
    <ion-label class="ion-text-wrap" >Il n'y a pas d'élément disponible cette semaine.</ion-label>
  </ion-item>

  <ion-list  v-if="itemsIsLoaded && days.length">

    <ion-item-group v-for="(day, dkey) in days" :key="dkey">
      <ion-item-divider class="ion-text-capitalize" color="white">
        {{ moment(day[0].start_at).format("dddd DD/MM/YYYY") }}
      </ion-item-divider>
      <ion-item 
        v-for="(item, mKey) in sortByStartTime(day)"
        :class="not_reservable ? 'editable-0':'editable-'+item.is_editable+' item-'+item.uuid" 
        :key="mKey"
        color="white"
        >
        <ion-label>
          <ion-badge :style="tag.color && '--background:' + tag.color + ';'" v-for="(tag, ktag) in item.tags" :key="ktag" >
            {{tag.name}}
            <!-- ion-icon n'accepte que les svg, first char 'i' = PNG et '/' = jpg -->
            <ion-icon 
              v-if="tag.b64_icon && tag.b64_icon.charAt(0) != 'i' && tag.b64_icon.charAt(0) != '/'" 
              :src="'data:image/png;base64,' + tag.b64_icon"></ion-icon>
            
            <ion-img 
              v-else
              class='image'
              :src="'data:image/png;base64,' + tag.b64_icon" ></ion-img>
          </ion-badge>
          <span v-if="item.description !== null" class="item-desc" v-html="item.description == null ? '' :  item.description.replace(/(?:\r\n|\r|\n)/g, '<br />')">
          </span><strong class="item-desc">{{item.price}}&euro;</strong>
        </ion-label>
        <ion-checkbox
            :disabled="not_reservable ? not_reservable : !item.is_editable" 
            slot="end"
            v-model="item.is_reserved"
            @ionChange="processReservation(item, $event)"
        ></ion-checkbox>
      </ion-item>
    </ion-item-group>

  </ion-list>
</template>

<script>
import {
  IonButtons, IonButton, IonCheckbox, IonTitle, IonToolbar, alertController, toastController,
  IonIcon, IonList, IonItem, IonItemGroup, IonItemDivider, IonBadge, IonLabel, IonImg, IonSpinner,
  IonRefresher, IonRefresherContent
} from '@ionic/vue';
import {chevronBackOutline, chevronForwardOutline} from 'ionicons/icons';
import {defineComponent} from 'vue';
import {doProcessReservation} from '@/utils'
import {NURSERY_MODULE_SLUG} from "@/utils"
import moment from 'moment'

export default defineComponent({
  name: 'PartWeekItemsList',
  components: {
    IonTitle,
    IonToolbar, IonButtons, IonButton, IonCheckbox, IonIcon, IonList, 
    IonItem, IonItemGroup, IonItemDivider, IonBadge, IonLabel, IonImg, IonSpinner,
    IonRefresher, IonRefresherContent
  },
  props: [
    'isTeacher',
    'student',
    'not_reservable'
  ],
  emits: ['updated'],
  created: function () {
    this.moment = moment;
  },
  data() {
    return {
      itemsIsLoaded: false,
      itemWeek: null,
      year: null,
      isFromControls: false,
      deleteConfirmed: 0,
      nursery_module : NURSERY_MODULE_SLUG,
    }
  },
  computed:{
    module(){
      return this.$store.getters['module/getModule'](this.$route.params.moduleUuid)
    },
    items(){
      return this.$store.getters['students/studentWeekItems']({year: this.year, weekNumber: this.itemWeek, module_uuid: this.$route.params.moduleUuid})
    },
    days(){
      if (this.items.length){
        const groupedData = {}
        this.items.forEach(item => {
          const day = this.moment(item.start_at).day()
          if (!groupedData[day]) {
            groupedData[day] = [];
          }
          groupedData[day].push(item);
        });
        return Object.values(groupedData)
      }
      return []
    }
  },
  mounted() {
    this.itemWeek = this.moment(new Date()).isoWeek();
    this.year = this.moment(new Date()).year();

    this.loadWeekitems()
  },
  methods: {
    async loadWeekitems() {
      this.itemsIsLoaded = false
      await this.$store.dispatch("students/loadStudentItemsWeek", {
        year: this.year,
        weekNumber: this.itemWeek,
        force: true,
        student_uuid: this.student.uuid,
        module_uuid: this.$route.params.moduleUuid
      }).then(()=>{
        this.itemsIsLoaded = true
      })
    },
    sortByStartTime(day) {
      return day.sort((a, b) => {
        const startTimeA = moment(a.start_at);
        const startTimeB = moment(b.start_at);
        return startTimeA - startTimeB;
      });
    },
    goNextWeek() {
      this.itemWeek = this.itemWeek + 1;
      if (this.itemWeek >= 53) {
        this.itemWeek = this.itemWeek - 52
        this.year = this.year + 1
      }
      this.loadWeekitems()
    },
    goPreviousWeek() {
      this.itemWeek = this.itemWeek - 1;
      if (this.itemWeek <= 0) {
        this.itemWeek = this.itemWeek + 52
        this.year = this.year - 1
      }
      this.loadWeekitems()
    },
    async processReservation(item, e) {
      if (!this.isFromControls) {
        doProcessReservation(this, item, e)
            .then(async () => {
              this.deleteConfirmed = 0;
              this.$emit("updated", e);
              await this.$store.dispatch("students/syncWallet", {student_uuid: this.student.uuid, force: true})
              if(e.detail.checked){
                const toast = await toastController
                  .create({
                    message: 'Repas commandé avec succès.',
                    duration: 2000
                  })
                return toast.present();
              }else{
                const toast = await toastController
                  .create({
                    message: 'Repas décommandé avec succès.',
                    duration: 2000
                  })
                return toast.present();
              }

            })
            .catch(async alertMsg => {
              alertMsg['buttons'] = alertMsg['buttons'].map(btn => ({
                text: btn[0],
                handler: () => {
                  if (btn[1] === 'confirmReservationProcess') {
                    this.confirmReservationProcess(item, e);
                  } else {
                    this.cancelReservationProcess(item, e);
                  }
                },
                cssClass: btn[2] || '',
              }));
              const alertItem = await alertController.create(alertMsg);
              return alertItem.present();
            })
      }
      this.isFromControls = false;
    },
    cancelReservationProcess(item, e) {
      this.isFromControls = true;
      item['is_reserved'] = !e.target.checked;
    },
    confirmReservationProcess(item, e) {
      this.deleteConfirmed = 1;
      this.processReservation(item, e);
      item["is_editable"] = 0
    },
    refreshData(event){
      this.$store.dispatch("students/reloadStudentitems").then(()=>{
        this.loadWeekitems().then(()=>{
          event.target.complete()
        })
      })
    }
  },
  setup() {
    return {
      chevronForwardOutline,
      chevronBackOutline,
      NURSERY_MODULE_SLUG
    }
  },
});
</script>

<style scoped>

.editable-0 ion-checkbox{
  --border-color: var(--ion-color-light-shade);
  --checkbox-background: var(--ion-color-white-contrast);
  opacity: 0.5;
}

.editable-1 {
  opacity: 1;
}

.item-desc {
  display: block;
  font-size: 0.8rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}
.text-small{
  font-size: 14px;
}

ion-list {
  background: var(--ion-color-white);
}

ion-item-divider {
  font-weight: 600;
  font-size: 11px;
  padding: 0.5rem 20px;
  border: none;
}

ion-item-group {
  border-bottom: 1px solid rgba(var(--ion-color-white-contrast-rgb), 0.2)
}

ion-badge ion-icon, ion-badge ion-img{
  max-width: 13px;
  max-height: 13px;
  margin-left: 0.3rem;
  position: relative;
  top: 2px;
  display: inline-block;
}

</style>
