<template>
  <ion-page>

    <ion-header :translucent="true">
      <page-header>
        <ion-title v-if="legalTutorUser" class="header-link"  id="edit-legal-tutor-ariane">
          <router-link enterkeyhint="go" to="/home" replace id="edit-legal-tutor-home-link">Mes écoles</router-link> / 
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}`" replace id="edit-legal-tutor-school-link">{{schoolName}}</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}/tutors`" replace id="edit-legal-tutor-school-legal-tutors-link">Tuteurs</router-link> /
          {{legalTutorUser.first_name}} {{legalTutorUser.last_name}} / Éditer
        </ion-title>
      </page-header>
    </ion-header>

    <part-form-edit 
      v-if='legalTutorForm' 
      :userProps="legalTutorForm" 
      :userType="'legal-tutor'" 
      @saveUser="saveUser" 
      @reLoadUserDatas="reLoadUserDatas" 
    ></part-form-edit>

  </ion-page>
</template>

<script>
import {
  IonPage, toastController, IonHeader, IonTitle
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {getAPI} from "@/axios-api";
import PartFormEdit from '@/views/components/PartFormEdit.vue';
import PageHeader from "@/views/components/PageHeader.vue";

export default defineComponent({
  name: 'EditLegalTutor',
  components: {
    IonPage, PartFormEdit, IonHeader, IonTitle, PageHeader
  },
  data() {
    return {
      schoolUuid: null,
      legalTutorForm: null,
      fieldMap: {
        'email': 'email',
      },
      baseEmail: null
    }
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    legalTutorUser(){
      return this.$store.getters["legalTutor/getLegalTutorUser"]
    },
  },
  mounted() {
    this.schoolUuid = this.$route.params.schoolUuid
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid)
    this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid})
    this.$store.dispatch('legalTutor/navigateToLegalTutor', this.$route.params.tutorUuid)
    this.$store.dispatch('legalTutor/loadLegalTutor').then(()=>{
        // Clone le store legalTutor pour qu'il ne soit pas réactif
        this.legalTutorForm = {...this.legalTutorUser}
      })
  },
  methods: {
    checkRequired(fields) {
      const invalidFields = Object.keys(fields).map((field) => {
        if (!this.legalTutorForm[field]) {
          return fields[field];
        }
      })
      return invalidFields.filter(n => n);
    },
    saveUser() {
      const invalidFieldsExist = this.checkRequired(this.fieldMap);
      if (invalidFieldsExist.length) {
        const msg = invalidFieldsExist.length > 1 ? 'Les champs ' + invalidFieldsExist.join(', ') + ' sont obligatoires.' : 'Le champ ' + invalidFieldsExist.join(', ') + ' est obligatoire.'
        alert(msg);
        return;
      }
      //Changer l'url
      getAPI.post('/api/user/' + this.legalTutorForm.userprofile.uuid + '/update', this.legalTutorForm).then(async () => {
        const baseEmail = this.legalTutorUser.email
        this.$store.dispatch('legalTutor/updateLegalTutorUser', this.legalTutorForm)
        this.$store.dispatch('school/updateLegalTutorUser', this.legalTutorForm)
        this.$router.back();
        if (baseEmail != this.legalTutorForm.email){
          const toast = await toastController
            .create({
              message: "Un email a été envoyé à l'adresse " + this.legalTutorForm.email + " pour confirmer le changement d'email.",
              duration: 3000
            })
          return toast.present();
        }
        const toast = await toastController
            .create({
              message: 'Informations modifiées avec succès.',
              duration: 2000
            })
        return toast.present();
      })
    },
    reLoadUserDatas(event){
      this.$store.dispatch('legalTutor/reloadLegalTutor').then(()=>{
        this.legalTutorForm = {...this.legalTutorUser}
        event.target.complete()
      })
    }
  }
});
</script>

<style scoped>

</style>
