<template>
  <ion-page>
    
    <ion-header :translucent="true">
      <page-header>
        <ion-title class="header-link" id="add-greeter-ariane">
          <router-link enterkeyhint="go" to="/home" replace id="add-greeter-home-link">Mes écoles</router-link> / 
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}`" replace id="add-greeter-school-link">{{schoolName}}</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}/greeters`" replace id="add-greeter-school-greeters-link">Accueillants</router-link> /
          Nouvel accueillant
        </ion-title>
      </page-header>
    </ion-header>

    <ion-content :fullscreen="true">

      <div class="container" >
        <ion-card color="white" >
          <ion-card-header>
            <ion-card-title id="add-greeter-title">{{ schoolName }}</ion-card-title>
            <ion-card-subtitle id="add-greeter-subtitle">Ajout d'un accueillant</ion-card-subtitle>
          </ion-card-header>
        </ion-card>
        <form class="form" @keyup.enter.prevent="createGreeter" @submit.prevent="createGreeter">
          <ion-list lines="none" class="ion-no-margin ion-no-padding">
            <ion-item lines="none" id="add-greeter-email">
              <ion-label position="floating">Email <ion-text color="danger">*</ion-text></ion-label>
              <ion-input required type="email" v-model="greeter.email"></ion-input>
            </ion-item>
            <ion-item lines="none" id="add-greeter-first-name">
              <ion-label position="floating">Prénom</ion-label>
              <ion-input type="text" v-model="greeter.first_name"></ion-input>
            </ion-item>
            <ion-item lines="none" id="add-greeter-last-name">
              <ion-label position="floating">Nom</ion-label>
              <ion-input type="text" v-model="greeter.last_name"></ion-input>
            </ion-item>
          </ion-list>
          <br>
          <section>
            <ion-button @click.prevent="createGreeter" shape="round" expand="block" id="add-greeter-button">Ajouter l'accueillant</ion-button>
          </section>
        </form>
      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import { 
  IonContent, IonFooter, IonPage,  IonInput, IonLabel, toastController, IonButton, IonHeader, 
  IonList, IonItem, IonText, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonTitle, alertController
} from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { getAPI } from "@/axios-api";
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";

export default defineComponent({
  name: "AddGreeter",
  components: {
    IonPage,
    IonContent,
    PageHeader, PageFooter, IonFooter, IonInput, IonLabel, IonButton, IonHeader, IonTitle,
    IonList, IonItem, IonText, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle,
  },
  data() {
    return  {
      greeter: {
        email: '',
        first_name: '',
        last_name: ''
      },
      schoolUuid: null
    }
  },
  mounted() {
    this.schoolUuid = this.$route.params.schoolUuid
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid)
    this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid})
  },
  setup () {
    return {
      copyOutline
    }
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    }
  },
  methods: {
    async createGreeter() {

      if(!this.greeter.email){
        const alertItem = await alertController
          .create({
            message: 'L\'adresse Email ne peut pas être vide.',
            buttons: ['OK']
          })
        return alertItem.present();
      }

      getAPI.post('/schools/' + this.schoolUuid + '/greeters/create', this.greeter).then(async (response) => {
        let message ='L\'accueillant(e) a bien été ajouté(e).'
        if (response.status === 202) {
          message ='L\'accueillant(e) a bien été lié(e) à l\'école.'
        }else if(response.status === 200 && response.data.data){
          this.$store.dispatch('school/addGreeter', response.data.data)
        }
        await this.$router.back()
        const toast = await toastController
            .create({
              message: message,
              duration: 2000
            })
        return toast.present();
      }).catch(async(error) => {
        let message = 'Une erreur serveur est survenue. Veuillez réessayer plus tard.'
        if (error.response && error.response.status === 422) {
          message = error.response.data.data
        }
        const alertItem = await alertController
          .create({
            message: message,
            buttons: ['OK']
          })
        return alertItem.present();
      })
    }
  }
});
</script>

<style scoped>

</style>
