<template>
  <ion-page>
    <ion-header :translucent="true">
      <page-header>
        <ion-title class="header-link" v-if="!loading">
          <router-link enterkeyhint="go" to="/home" replace>Mes écoles</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}`" replace>{{schoolName}}</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}/teachers`" replace>Professeurs</router-link> /
          {{userTeacher.first_name + " " + userTeacher.last_name}}
        </ion-title>
      </page-header>
    </ion-header>

    <ion-content :fullscreen="true">

      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div v-if="loading">
        <p style="text-align: center">
          Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
        </p>
      </div>
      <div class="container" v-else >

        <ion-card color="light" > 
          <ion-card-header>
            <ion-card-title>{{userTeacher.first_name + " " + userTeacher.last_name}}</ion-card-title> 
          </ion-card-header>
          <ion-card-content>
            <p>{{userTeacher.username}}</p>
          </ion-card-content>
          <ion-button @click.prevent='goToTeacherEdit()' class="btn-modifyProfil" shape="round"  size="small" color="light" >
            <ion-icon src="/assets/icon/pen-solid.svg" ></ion-icon>
          </ion-button>
        </ion-card>

        <ion-list class="listPadding" >
          <ion-list-header>
            <h2>Classes :</h2>
          </ion-list-header>
          <ion-item 
            color="white" 
            v-for="(classroom, kClassroom) in classrooms.sort((a,b)=>sortingName(a,b,['name']))" 
            :key="kClassroom" 
            :v-bind="'classroom-'+classroom.id">
            <ion-label>
              <h2>{{ classroom.name }}</h2>
            </ion-label>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import {
  IonPage, IonContent, IonFooter, IonCard, IonCardTitle, IonCardHeader,
  IonHeader, IonTitle, IonButton, IonIcon, IonRefresherContent, IonRefresher, IonSpinner,
  IonCardContent
} from '@ionic/vue';
import {defineComponent} from 'vue';
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {sortingName} from '@/utils';

export default defineComponent({
  name: "TeacherPage",
  components: {
    IonContent,
    IonPage, IonButton,
    IonFooter, IonHeader,IonTitle,
    PageHeader, PageFooter, IonCard, IonCardTitle,
    IonCardHeader, IonIcon, IonRefresherContent, IonRefresher, 
    IonSpinner, IonCardContent
  },
  data() {
    return {
      schoolUuid: null,
      search: ""
    }
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    userTeacher(){
      return this.$store.getters["teacher/getTeacherUser"]
    },
    classrooms(){
      return this.$store.getters["teacher/getTeacherClassrooms"]
    },
    loading(){
      if (this.userTeacher && this.schoolName && this.classrooms){
        return false
      }
      return true
    }
  },
  mounted(){
    this.schoolUuid = this.$route.params.schoolUuid
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid)
    this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid})
    this.$store.dispatch('teacher/navigateToTeacher', this.$route.params.teacherUuid)
    this.$store.dispatch('teacher/loadUserTeacher')
  },
  methods: {
    goToTeacherEdit(){
      this.$router.push('/school/'+ this.schoolUuid + '/teachers/' + this.$route.params.teacherUuid + '/edit' )
    },
    doRefresh(event){
      this.$store.dispatch('teacher/reloadTeacher').then(()=>{
        event.target.complete()
      })
    }
  },
  setup() {
    return {
      sortingName
    }
  },
});
</script>

<style scoped>

  .btn-delete{
    --box-shadow: none;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
  .btn-modifyProfil{
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    --box-shadow: 0;
  }
  ion-list-header{
      color: var(--ion-color-white)
  }
  @media (min-width:600px){
    ion-list-header{
      color: var(--ion-color-white-contrast)
    }
  }
 

</style>
