const state = () => ({
    modulesSubscriptions: {}
})

const mutations = {
    setModuleSubscription(state: any, data: any) {
        state.modulesSubscriptions[data.uuid] = data
    }
}

const getters = {
    getModuleSubscriptions(state: any) {
        return (moduleUuid: string) => {
            return state.modulesSubscriptions[moduleUuid]
        }
    },
}
const actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
