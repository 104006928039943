<template>
  <ion-modal :is-open="isOpenCreateTutor">
    <ion-page>
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>
            Créer un tuteur et l'ajouter
          </ion-title>
          <ion-buttons slot="end">
              <ion-button @click.prevent="closeCreateTutorModal" >Fermer</ion-button>
            </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content :fullscreen="true">
        <ion-card color="white" >
          <ion-card-header>
            <ion-card-subtitle>Saisissez l'adresse email du tuteur</ion-card-subtitle>
          </ion-card-header>
        </ion-card>
        <form class="form" @submit.prevent="doCreateTutor">
          <ion-list lines="none" class="ion-no-margin ion-no-padding">
            <ion-item lines="none" >
              <ion-label position="floating">E-mail <ion-text color="danger">*</ion-text></ion-label>
              <ion-input v-model="newTutorEmail" required type="text"></ion-input>
            </ion-item>
            <ion-item v-if="errorMessage">
              <ion-label color="danger" > {{errorMessage}} </ion-label>
            </ion-item>
          </ion-list>
          <br>
          <section style="padding: 0 10px">
            <ion-button shape="round" expand="block" @click.prevent="doCreateTutor">Créer et ajouter le tuteur</ion-button>
          </section>
        </form>
      </ion-content>
    </ion-page>
  </ion-modal>

  <ion-button color="primary" slot="end" @click.prevent="openCreateTutorModal">
    Créer un nouveau tuteur
    <ion-icon :icon="personAddSharp"></ion-icon>
  </ion-button>
</template>

<script>
import {
  IonCardHeader,
  IonCardSubtitle,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonCard,
  IonText,
  IonIcon,
} from '@ionic/vue';
import { defineComponent } from 'vue'
import {personAddSharp} from 'ionicons/icons';

export default defineComponent({
  name: "ModalCreateTutor",
  components:{
    IonCardHeader,
    IonCardSubtitle,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonCard,
    IonText,
    IonIcon,
  },
  emits:['addTutor'],
  data() {
    return {
      isOpenCreateTutor: false,
      newTutorEmail: '',
      errorMessage: ''
    }
  },
  setup() {
    return {personAddSharp}
  },
  methods:{
    validateEmail() {
      return this.newTutorEmail.match(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i
      )
    },
    openCreateTutorModal() {
      this.newTutorEmail = '';
      this.isOpenCreateTutor = true;
    },
    closeCreateTutorModal() {
      this.isOpenCreateTutor = false;
    },
    doCreateTutor() {
      if(this.validateEmail()){
        const userMail = this.newTutorEmail;
        
        this.$emit('addTutor', {
          uuid: null,
          user: {uuid: null, email:userMail, username:userMail, first_name: '', last_name: ''}
        })
        
        this.isOpenCreateTutor = false;
      } else{
        this.errorMessage = "L'adresse e-mail entrée n'est pas valide."
      }
    },
  }
})
</script>
<style scoped>
  .btn-tuteur ion-icon{
    margin-left: 1rem;
  }
</style>
