<template>

  <ion-list class="listPadding" >

    <ion-list-header color="white" >
      Futur transactions
    </ion-list-header>

    <ion-item v-if="nextReservations && Object.keys(nextReservations).length <= 0">
      <ion-label>Il n'y a pas de transaction futur</ion-label>
    </ion-item>

    <div  v-for="(week, rKey) in weeks" :key="rKey" >
      <ion-list-header color="light">
        Semaine {{week.week}} :&nbsp;
        <span class="text-week" >
          {{moment().day(1).year(week.year).isoWeek(week.week).format("DD/MM/YYYY")}} - {{moment().day(7).year(week.year).isoWeek(week.week).format("DD/MM/YYYY")}} 
        </span> 
      </ion-list-header>

      <ion-item 
        lines="none" 
        class="transaction no-padding"
        v-for="(reservation, rKey) in nextReservations.filter((payment)=>payment.week === week.week).sort((a,b)=>sortDate(a,b))" 
        :key="rKey" 
        :class="{cancelAnimation: reservation.item.isCancel}"
        color="white" 
      >
        <div class="transaction-item">
          <div class="transaction-comment" >
            <p>{{ moment(reservation.item.start_at).format("dddd DD/MM/YYYY") }} <span class="transaction-title" >{{ reservation.item.name }}</span></p>
            <span class="price">- {{reservation.item.price}}&nbsp;&euro;</span>
            <ion-checkbox 
              class="btn-cancel" 
              title="Annuler réservation" 
              slot="end"  
              v-model="reservation.isReserved"
              :v-bind="'meal-'+reservation.item.id"
              @click.prevent="confirmCancel(reservation)">
            </ion-checkbox>
          </div>
        </div>
      </ion-item>
    </div>
  </ion-list>

</template>

<script>
import moment from 'moment'
import { IonList, IonListHeader, IonItem, IonLabel, IonCheckbox, alertController, toastController } from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import {doProcessReservation} from '@/utils'

export default defineComponent({
  name: 'FuturPaymentList',
  components: {
    IonList, IonListHeader, IonItem, IonLabel, IonCheckbox
  },
  created: function () {
    this.moment = moment;
  },
  data() {
    return {
      student: {
        'id': null,
        'first_name': '',
        'last_name': ''
      },
      weeks: [],
      isFromControls: false,
      deleteConfirmed: 0,
    }
  },
  mounted() {
    this.separateWeek()
  },
  computed:{
    nextReservations(){
      return this.$store.getters['students/currentWallet'].next_reservations
    },
  },
  methods: {
    separateWeek(){
      this.weeks = []
      this.nextReservations.forEach((payment)=>{
        payment.week = moment(payment.item.start_at).isoWeek()
        payment.year = moment(payment.item.start_at).year()
        payment.isReserved = true
        if(!this.weeks.find((week)=>week.week === payment.week)){
          const weekObj = {week: payment.week, year: payment.year}
          this.weeks.push(weekObj)
        }
      })
      this.weeks.sort((a,b)=>a.week - b.week)
    },
    async confirmCancel(canceledReservation) {
      const alertMsg = {
        message: "Voulez vous vraiment annuler la réservation du " + moment(canceledReservation.item.start_at).format("DD/MM/YYYY") + " ? ",
        buttons: [
          {
            text: "Retour",
            handler: ()=>{
              canceledReservation.isReserved = true
            }
          },
          {
            text: "Continuer",
            cssClass: "btn-danger",
            handler: ()=> {
              this.cancelReservation(canceledReservation)
            }
          }
        ]
      }
      const alertItem = await alertController.create(alertMsg);
      return alertItem.present();
    },
    cancelReservation(canceledReservation){
      doProcessReservation(this, canceledReservation.item).then(()=>{
        canceledReservation.item.isCancel = true
        this.$store.dispatch('students/syncWallet', {student_uuid: this.$route.params.studentUuid, force: true}).then(()=>this.separateWeek())
        setTimeout(async ()=>{
          this.separateWeek();
          const toast = await toastController
            .create({
              message: 'Repas décommandé avec succès.',
              duration: 2000
            })
          return toast.present();
        },300)
      }).catch(async alertMsg => {
        alertMsg['buttons'] = alertMsg['buttons'].map(btn => ({
          text: btn[0],
          handler: () => {
            if (btn[1] === 'confirmReservationProcess') {
              this.confirmReservationProcess(canceledReservation);
            } else {
              this.cancelReservationProcess(canceledReservation);
            }
          },
          cssClass: btn[2] || '',
        }));
        const alertItem = await alertController.create(alertMsg);
        return alertItem.present();
      })

    },
    cancelReservationProcess(canceledReservation) {
      canceledReservation.isReserved = true
      this.isFromControls = true
    },
    confirmReservationProcess(canceledReservation) {
      this.deleteConfirmed = 1;
      this.cancelReservation(canceledReservation);
    },
    sortDate(a,b){
      a = new Date(a.item.start_at);
      b = new Date(b.item.start_at);
      return a.getTime() - b.getTime()
    }
  },
  setup () {
    return {
      copyOutline
    }
  },
});
</script>

<style scoped>

  ion-list-header{
    font-size: 14px;
    font-weight: 700;
    --color: var(--ion-color-white);
    align-items: center;
  }

  .text-week{
    font-size: 14px;
    display: block;
  }

  .btn-cancel{
    margin-left: 1rem;
    position: absolute;
    top: 0.3rem;
    right: 0rem;
    --box-shadow: none;
    --ion-color-base: none;
  }

  .price{
    margin-right: 3.5rem;
    font-weight: 600;
    font-size: 14px;
  }

  .cancelAnimation{
    --ion-color-base: var(--ion-color-light);
    transition: all .3s ease-out;
    transform: translateX(100%);
  }

  .transaction{
    font-size: 0.8rem;
  }

  .transaction-item{
    width: 100%;
    overflow: hidden;
    padding: 0 1rem;
  }
  .transaction-comment{
    text-transform: capitalize;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 10;
    font-weight: 600;
  }
  .transaction-title{
    display: block;
    text-transform: initial;
    font-weight: 500;
    margin-top: 0.5rem;
  }

  @media (min-width: 600px){
    .cancelAnimation{
      transform: none;
      opacity: 0;
    }
    ion-list-header{
      --color: var(--ion-color-white-contrast);
    }
  }

</style>
