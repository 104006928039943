<template>
  <ion-page>
    <page-header
      :title="$store.getters['students/currentStudent'].first_name + ' ' + $store.getters['students/currentStudent'].last_name + '/' + $store.getters['students/currentModule'].name"
    ></page-header>
    <ion-content>
      <div v-for="(itemType, ITkey) in $store.getters['students/currentModule']['item_types']" :key="ITkey">
        <ItemTypeCard :itemType="itemType"></ItemTypeCard>
        <ion-list v-for="(item, ItKey) in itemType['items']" :key="ItKey">
          <ion-item
              v-for="(item, ItKey) in itemType['items']" :key="ItKey"
              @click="goToItem(item.uuid)"
          >
            <ion-label>{{item.name}}</ion-label>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>
    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>
  </ion-page>
</template>

<script>
import {IonPage, IonContent, IonFooter, IonList, IonItem, IonLabel} from "@ionic/vue";
import PageFooter from "@/views/components/PageFooter";
import PageHeader from "@/views/components/PageHeader";
import ItemTypeCard from "@/views/Modules/Default/parts/ItemTypeCard";
export default {
  name: "TypeModuleWrapper",
  components: {
    ItemTypeCard,
    IonPage,
    IonContent,
    IonFooter,
    PageFooter,
    PageHeader,
    IonList, IonItem, IonLabel
  },
  mounted() {
    this.$store.dispatch('students/syncModuleItems', {
      student_uuid: this.$route.params.studentUuid,
      module_uuid: this.$route.params.moduleUuid,
      item_type_uuid: this.$route.params.typeUuid
    });
  },
  methods: {
    goToItem(itemUuid) {
      this.$router.push({
        name: 'ItemModuleWrapper',
        params: {
          studentUuid: this.$route.params.studentUuid,
          moduleUuid: this.$route.params.moduleUuid,
          typeUuid: this.$route.params.typeUuid,
          itemUuid: itemUuid
        }
      });
    }
  }
}
</script>

<style scoped>

</style>