<template>
  <ion-item lines="none" color="white" id="edit-student-address-street">
    <ion-label position="floating">Rue</ion-label>
    <ion-input v-model="student.address_street" required type="text" id='adress-street'></ion-input>
  </ion-item>
  <Ion-list class="row">
    <ion-item class="col" lines="none" color="white" id="edit-student-address-number" slot="start">
      <ion-label position="floating">Numéro</ion-label>
      <ion-input v-model="student.address_number" required type="number" id='adress-number'></ion-input>
    </ion-item>
    <ion-item class="col" lines="none" color="white" id="edit-student-address-letter" slot="end" >
      <ion-label position="floating">Lettre</ion-label>
      <ion-input v-model="student.address_letter" type="text" id='adress-number'></ion-input>
    </ion-item>
  </ion-list>
  <ion-item lines="none" color="white" id="edit-student-address-box">
    <ion-label position="floating">Boite</ion-label>
    <ion-input v-model="student.address_box" required type="text" id='adress-box'></ion-input>
  </ion-item>
  <ion-item lines="none" color="white" id="edit-student-address-zip">
    <ion-label position="floating">Code postal</ion-label>
    <ion-input v-model="student.address_zip" required type="text" id='adress-zip'></ion-input>
  </ion-item>
  <ion-item lines="none" color="white" id="edit-student-address-city">
    <ion-label position="floating">Ville</ion-label>
    <ion-input v-model="student.address_city" required type="text" id='adress-city'></ion-input>
  </ion-item>
  <ion-item lines="none" color="white" id="edit-student-address-country">
    <ion-label position="floating">Pays</ion-label>
    <ion-input v-model="student.address_country" required type="text" id='adress-country'></ion-input>
  </ion-item>
</template>

<script>
import { defineComponent, computed } from 'vue'
import {
  IonItem,
  IonLabel,
  IonInput,
  IonList
} from '@ionic/vue';

export default defineComponent({
  name:"PartAddress",
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonList
  },
  props:["studentProps"],
  setup(props, { emit }) { 
    //Permet d'update le props studentProps via les inputs
    const student = computed({ 
      get: () => props.studentProps, 
      set: (value) => emit('update:studentProps', value) 
    }) 
    return { 
      student
    } 
  },
})
</script>
<style>

  .row{
    display: flex;
    justify-content: space-between;
  }
  .item-input.col{
    margin: 0;
    width: 50%;
  }

</style>