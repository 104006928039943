<template>
  <ion-page>
    <ion-header>
      <page-header>
        <ion-title class="header-link" v-if="!loading" >
          <router-link 
            enterkeyhint="go" 
            to="/home" 
            replace
          >
            <span v-if="isLegalTutor">Mes enfants</span>
            <span v-else-if="isTeacher">Mes classes</span>
          </router-link> / 
          <router-link 
            enterkeyhint="go" 
            :to="`/student/${student.uuid}`"
            replace
          >{{student.first_name}} {{student.last_name}} </router-link> 
          / Éditer
        </ion-title>
      </page-header>
    </ion-header>
    <ion-content>
      <div v-if="loading" class="container">
        <ion-item>
          Loading...<ion-spinner></ion-spinner>
        </ion-item>
      </div>
      <div v-else class="container" >
        <page-student-form :student="student" @refreshDatas="refreshDatas"
              :disabled="true">
        </page-student-form>
      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>
  </ion-page>
</template>

<script>
import {IonPage, IonTitle, IonContent, IonFooter, IonHeader, IonItem, IonSpinner} from '@ionic/vue';
import {defineComponent} from 'vue';
import PageStudentForm from "@/views/components/students/EditStudentForm/PageStudentForm";
import PageHeader from "@/views/components/PageHeader";
import PageFooter from "@/views/components/PageFooter";

export default defineComponent({
  name: 'EditStudent',
  components: {
    IonPage, IonContent, IonFooter, IonHeader, IonTitle,
    IonItem, IonSpinner,
    PageStudentForm, PageFooter, PageHeader
    },
  data() {
    return {
      classroom: {},
      loading: true
    }
  },
  computed:{
    student() {
      return this.$store.getters['students/currentStudent']
    },
    isLegalTutor(){
      return this.$store.getters['home/isLegalTutor'](this.$route.params.studentUuid)
    },
    isTeacher(){
      return this.$store.getters['home/isTeacher'](this.student.classroom.uuid)
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData(){
      this.$store.dispatch('students/setCurrentStudent', {student_uuid: this.$route.params.studentUuid})
      await this.$store.dispatch('students/syncCompleteStudent', {student_uuid: this.$route.params.studentUuid}).then(() => {
        this.loading = false
        return
      })
    },
    refreshDatas() {
      this.loadData.then(()=>{
        this.$emit('refreshDatas');
      })
    },
  },
  setup() {
    return {}
  },
});
</script>

<style scoped>

</style>
