<template>
  <ion-page>
    
    <ion-header :translucent="true">
      <page-header>
        <ion-title class="header-link" id="add-classroom-ariane">
          <router-link enterkeyhint="go" to="/home" replace id="add-classroom-home-link">Mes écoles</router-link> / 
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}`" replace id="add-classroom-school-link">{{schoolName}}</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}/classrooms`" replace id="add-classroom-school-classrooms-link">Classes</router-link> /
          Nouvelle classe
        </ion-title>
      </page-header>
    </ion-header>

    <ion-content :fullscreen="true">

      <div class="container" >

        <ion-modal
            :is-open="teacherModalIsOpenRef"
        >
            <ion-page>
              <ion-header>
                <ion-toolbar color="primary" >
                  <ion-title>
                    Sélectionnez le professeur
                  </ion-title>
                  <ion-buttons slot="end">
                    <ion-button @click.prevent="closeTeacherModal" id="close-select-teacher">Fermer</ion-button>
                  </ion-buttons>
                </ion-toolbar>
              </ion-header>
              <ion-content :fullscreen="true">

                <ion-searchbar color="light" v-model="searchTeacherInput"></ion-searchbar>
                
                <ion-list v-if="teachers" >
                  <ion-item class="itemWithHover" color="white" v-for="(teacher, kTT) in teachers.sort((a,b)=>sortingName(a,b,['user.last_name', 'user.first_name']))" :key="kTT" @click.prevent="addTeacher(teacher)" :v-bind="'select-teacher-'+teacher.id">
                    <ion-label>
                      <h2>{{teacher.user.first_name}} {{teacher.user.last_name}}</h2>
                      <h3>{{teacher.user.email}}</h3>
                    </ion-label>
                  </ion-item>
                </ion-list>
              </ion-content>
            </ion-page>
        </ion-modal>

        <ion-card color="white" >
          <ion-card-header>
            <ion-card-title id="add-classroom-title">{{ schoolName }}</ion-card-title>
            <ion-card-subtitle id="add-classroom-subtitle">Ajout d'une classe</ion-card-subtitle>
          </ion-card-header>
        </ion-card>
        <form class="form" @keyup.enter.prevent="createClassroom" @submit.prevent="createClassroom">
          <ion-list lines="none" class="ion-no-margin ion-no-padding">
            <ion-item lines="none" id="add-classroom-name">
              <ion-label position="floating">Nom <ion-text color="danger">*</ion-text></ion-label>
              <ion-input required type="text" v-model="classroom.name"></ion-input>
            </ion-item>
          </ion-list>

          <ion-list class="list-prof" lines="none" id="add-classroom-teachers">
            <ion-list-header class="header-prof" >
              Professeurs
            </ion-list-header>
            <ion-item
              v-for="(teacher, key) in classroom.teachers.sort((a,b)=>sortingName(a,b,['user.last_name','user.first_name']))" 
              :key="key" :value="teacher.uuid"
            >
              <ion-label>- {{teacher.user.email}}</ion-label>
              <ion-button @click.prevent="deleteTeacher(teacher)" class="btn-cancel" color="white" fill="solid" size="small" >
                <ion-icon src="assets/icon/trash-solid.svg" ></ion-icon>
              </ion-button>
            </ion-item>
          </ion-list>
          <ion-button slot="end" class="btn-addTeacher" @click.prevent="openTeacherModal" size="small" fill="solid" id="select-teacher-button">
            Ajouter un professeur <ion-icon :icon="add"></ion-icon>
          </ion-button>

          <ion-button class="btn-addClassroom" @click.prevent="createClassroom" shape="round" expand="block" id="add-classroom-button">Ajouter la classe</ion-button>
        </form>
      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import { toastController, IonContent, IonFooter, IonPage, IonInput, IonModal,
 IonButton, IonList, IonItem, IonLabel, IonText, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonSearchbar,
 IonIcon, IonListHeader, IonHeader, IonToolbar, IonButtons, IonTitle, alertController  } from '@ionic/vue';
import { copyOutline, add } from 'ionicons/icons';
import { defineComponent } from 'vue';
import {getAPI} from "@/axios-api";
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {sortingName} from "@/utils"

export default defineComponent({
  name: "AddClassroom",
  components: {
    IonPage,
    IonButton, IonList, IonItem, IonLabel, IonText, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle,
    IonContent, IonInput, IonModal, PageHeader, PageFooter, IonFooter, IonSearchbar,
    IonIcon, IonListHeader, IonHeader, IonToolbar, IonButtons, IonTitle, 
  },
  data() {
    return  {
      teacherModalIsOpenRef: false,
      schoolUuid: null,
      classroom: {
        name: "",
        teachers: []
      },
      searchTeacherInput: "",
    }
  },
  mounted() {
    this.schoolUuid = this.$route.params.schoolUuid
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid)
    this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid})
    this.$store.dispatch('school/loadTeachers')
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    teachers(){
      return this.$store.getters["school/getSchoolTeachers"](this.searchTeacherInput)
    }
  },
  methods: {
    closeTeacherModal() {
      this.teacherModalIsOpenRef = false
    },
    addTeacher(teacher) {
      this.classroom.teachers.push(teacher)
      this.closeTeacherModal()
    },
    deleteTeacher(deletedTeacher){
      this.classroom.teachers =  this.classroom.teachers.filter(teacher => teacher.id !== deletedTeacher.id)
    },
    openTeacherModal() {
      this.teacherModalIsOpenRef = true
    },
    async createClassroom() {

      if(!this.classroom.name){
        const alertItem = await alertController
          .create({
            message: 'Le champ Nom ne peut pas être vide.',
            buttons: ['OK']
          })
        return alertItem.present();
      }

      const data = {
        name: this.classroom.name,
        school: this.schoolUuid,
        teacher: this.classroom.teachers.map((teacher)=>teacher.id)
      }
      getAPI.post('/schools/' + this.schoolUuid + '/classrooms/create', data).then(async (response) => {
        this.$store.dispatch('school/addClassroom', response.data.data)
        this.classroom.name = "";
        this.classroom.teachers = [];
        await this.$router.back()
        const toast = await toastController
          .create({
            message: 'Classe créée avec succès.',
            duration: 2000
          })
        return toast.present();
      }).catch(async (error) => {
        const alertItem = await alertController
          .create({
            message: error,
            buttons: ['OK']
          })
        return alertItem.present();
      })
    }
  },
  setup () {
    return {
      copyOutline, add, sortingName
    }
  },
});
</script>

<style scoped>
  .btn-addClassroom{
    margin: 2rem auto;
  }
  .btn-addTeacher{
    width: 280px;
    margin: 0 auto;
  }

  .header-prof{
    max-width: var(--item-max-width);
    padding: 0;
    margin: 0 auto;
    color: var(--ion-color-white-contrast);
    font-size: 16px;
  }
  .list-prof ion-item {
    --min-height: 0px;
  }
  .btn-cancel{
    width: 1.5rem;
    --ion-color-base: var(--ion-color-white);
    --ion-color-contrast: var(--ion-color-white-contrast);
  }
  .itemWithHover:hover, .itemWithHover:focus{
    --ion-color-base: var(--ion-color-light);
    cursor: pointer;
  }
</style>