<template>
  <ion-page>

    <ion-header>
      <page-header :title="`Mes enfants / ${student.first_name} ${student.last_name} / Paiement`" >
        <ion-title class="header-link"  >
          <router-link 
            enterkeyhint="go" 
            to="/home" 
            replace
          >Mes enfants</router-link> / 
          <router-link 
            enterkeyhint="go" 
            :to="`/student/${student.uuid}`" 
            replace
          >{{student.first_name}} {{student.last_name}} </router-link> 
          / Paiement</ion-title>
      </page-header>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="container" >
        <form class="form" @submit.prevent="makePayment" v-if="!paymentCreated">
          <ion-list>
            <ion-item lines="none">
              <h2 class="ion-text-center title--medium" >Faire un virement pour {{student.first_name}} {{student.last_name}}</h2>
            </ion-item>

            <ion-item  lines="none" >
              <ion-label>Montant (&euro;) <ion-text color="danger">*</ion-text> :</ion-label>
              <ion-input v-model="amount" required type="number" step="0.1" placeholder="15.0">
              </ion-input>
            </ion-item>

            <ion-item  v-if="errorMessage" lines="none" >
              <ion-label class="errorMessage" > {{errorMessage}} </ion-label>
            </ion-item>
            <ion-button :disabled="loading" shape="round" expand="full" color="primary" @click.prevent="makePayment" id="make-payment">Ajouter au solde</ion-button>

          </ion-list>
        </form>
        <div v-else >
          <ion-card class="form-payment" >
            <p style="text-align: center;" v-if="paymentStatus === 'success'">Paiement en cours de traitement. Si celui-ci est validé, il s'affichera dans la liste de vos paiements.</p>
            <p style="text-align: center;" v-if="paymentStatus === 'error'">Votre paiement a été annulé ou rencontre une erreur.</p>
            <ion-button shape="round" expand="full" color="primary" @click.prevent="goPaymentList">Revenir à la liste de mes paiements</ion-button>
          </ion-card>
        </div>
      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>

</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonInput,
  IonButton,
  IonCard,
  IonFooter,
  IonLabel,
  IonText,
  IonItem,
  IonList,
  IonTitle,
  alertController
} from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import {getAPI} from "@/axios-api";
import {loadStripe} from '@stripe/stripe-js';
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from '@/views/components/PageFooter.vue';

export default defineComponent({
  name: 'MakePayment',
  components: {
    IonPage,
    IonContent, IonHeader, IonInput,
    IonButton, IonCard, IonFooter, IonLabel, IonText, IonItem,
    PageHeader, PageFooter, IonList, IonTitle
  },
  mounted() {
    getAPI.get('/students/' + this.$route.params.studentUuid).then((response) => {
      this.student = response.data.data
    })
  },
  data() {
    return {
      loading: false,
      paymentCreated: false,
      student: {},
      amount: null,
      paymentStatus: 'success',
      payment: {
        amount: 0.0,
        legalTutor: null,
      },
      errorMessage: ""
    }
  },
  methods: {
    goPaymentList() {
      this.loading= false
      this.paymentCreated= false
      this.amount = 0.0
      this.paymentStatus = 'success'
      this.$router.replace('/student/' + this.$route.params.studentUuid)
    },
    setPaymentInError() {
      this.paymentStatus = 'error'
    },
    makePayment() {
      if(this.amount <= 0.5 || !this.amount ){
        this.errorMessage = "Le montant doit être supérieur à 0.5 €"
        return
      }else if(this.amount > 200){
        this.errorMessage = "Le montant doit être inférieur à 200 €"
        return
      }

      const formatter = new Intl.NumberFormat('en',  {
        minimumFractionDigits: 2
      });

      const formattedAmount = formatter.format(this.amount)
      this.loading = true

      getAPI.post('/payments/students/' + this.$route.params.studentUuid + '/generate', {
        'amount': formattedAmount
      }).then(async (response) => {
        this.$store.dispatch('students/unsetWallet', {student_uuid: this.student.uuid})   
        this.paymentCreated = true
        const stripe = await loadStripe(response.data.data.payment_key);
        return stripe.redirectToCheckout({
          sessionId: response.data.data.id
        });
      }).catch(async ()=>{
        const alertItem = await alertController
          .create({
            header: 'Une erreur est survenue',
            message: 'Veuillez réessayer plus tard',
            buttons: ['OK']
          })
        this.loading = false
        return alertItem.present();
      })
    }
  },
  setup () {

    const options = {
      cssClass: 'custom-options'
    }

    return {
      options, copyOutline
    }
  },
});

</script>

<style scoped>

  .form-payment{
    box-shadow: none;
    height: calc(100vh - 7rem);
    padding: 1rem;
    margin-top: 0;

    background: #fff;
    width: 100%;
    margin: 0;
    border-radius: 0;

  }

  ion-item{
    --ion-item-background: none;
    --ion-item-border-color: rgba(0,0,0,1);
    --padding-start: 0;
    --inner-padding-end: 0;
    color: var(--ion-color-white-contrast)
  }

  ion-input{
    border-bottom : 1px solid #fff;
    box-sizing: border-box;
    --padding-start: 0.5rem;
    --background: var(--ion-color-light);
    --color: var(--ion-color-white-contrast);
    margin-left: 0.5rem;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  }

  ion-button{
    width: 100%;
    margin: 0;
    --box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  }

  .errorMessage{
    color: var(--ion-color-danger)
  }


  @media (min-width: 600px) {
    .form-payment{

      --background: var(--ion-color-white);
      --color: var(--ion-color-white-contrast);

      width: 500px;
      height: inherit;
      position: absolute;
      top: 50%;
      left:50%;
      transform: translateX(-50%) translateY(-50%);
      margin-inline: 0;
      padding: 1rem 1rem 2rem 1rem;
      overflow: visible;
      border-radius: 10px;

      --item-max-width: 350px;
    }

    ion-item{
      --ion-item-color: var(--ion-color-white-contrast);
      max-width: var(--item-max-width);
      margin: 0 auto;
      padding-bottom: 1rem;
    }

    ion-input{
      border-bottom: 1px solid var(--ion-color-white-contrast);
    }

    ion-button{
      --ion-color-base: var(--ion-background-color);
      --ion-color-contrast: var(--ion-color-white);
      max-width: var(--item-max-width);
      margin: 0 auto;
      
    }
  }

</style>
