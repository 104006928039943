<template>
  <ion-page>

    <page-header :user="user" :noBackButton="true" :homePage="true" ></page-header>

    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="reLoadUserDatas($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div v-if="loading" class="container">
        <ion-list>
          <ion-item>
            Loading...<ion-spinner></ion-spinner>
          </ion-item>
        </ion-list>

      </div>
      <div v-else class="container">
        <ion-card v-if="user" color="light">
          <ion-card-header>
            <ion-card-title v-if="user.first_name+user.last_name !== ''" id='current-user'>{{ $store.getters['home/currentUser'].first_name }} {{ $store.getters['home/currentUser'].last_name }}</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p id='current-email'>{{ $store.getters['home/currentUser'].email }} </p>
          </ion-card-content>
          <ion-button @click.prevent='goToUserEdit' class="btn-modifyProfil" shape="round" size="small" color="white">
            <ion-icon src="/assets/icon/pen-solid.svg"></ion-icon>
          </ion-button>
        </ion-card>
        <ion-list v-if="greetersSchools.length > 0">
          <ion-list-header>
            <ion-label class="title--medium" color="white" id='attendances'>
              Présences
            </ion-label>
          </ion-list-header>
          <ion-item v-for="(school, gKey) in greetersSchools" :key="gKey"
                    :router-link="'/attendance/' + school.uuid"
                    v-bind:id="'attendance'+school.id">
            {{ school.name }}
          </ion-item>
        </ion-list>

        <ion-list v-if="nurseryModule.length > 0">
          <ion-list-header>
            <ion-label class="title--medium" color="white" id='attendances'>
              Garderies
            </ion-label>
          </ion-list-header>
          <ion-item v-for="(moduleSub, gKey) in nurseryModule" :key="gKey"
                    :router-link="'/nursery/' + moduleSub.school.uuid"
                    v-bind:id="'nursery'+moduleSub.school.id">
            {{ moduleSub.school.name }}
          </ion-item>
        </ion-list>

        <ion-list v-if="students.length > 0">
          <ion-list-header>
            <ion-label class="title--medium" color="white" id='children'>
              Mes enfants
            </ion-label>
          </ion-list-header>
          <ion-item
              v-for="(student, sKey) in students"
              :key="sKey" @click="goToStudent(student)"
              v-bind:id="'student'+student.id">
            <ion-label>
              <h2 v-bind:id="'student'+student.id+'-name'">{{ student.first_name }} {{ student.last_name }}</h2>
              <p v-if="student.classroom.name" v-bind:id="'student'+student.id+'-classroom'">{{ student.classroom.name }} - {{ student.classroom.school.name }}</p>
            </ion-label>
            <ion-label class="ion-text-right label-balance">
              <p>Solde disponible</p>
              <span v-bind:id="'student'+student.id+'-balance'" v-if="$store.getters['students/studentWallet']({student_uuid: student.uuid})">
                {{ Math.round((($store.getters['students/studentWallet']({student_uuid: student.uuid}).total_balance + $store.getters['students/studentWallet']({student_uuid: student.uuid}).next_amount) + Number.EPSILON) * 100) / 100 }} &euro;
              </span>
              <ion-spinner v-else name="lines-sharp-small"></ion-spinner>
            </ion-label>
          </ion-item>
        </ion-list>

        <ion-list class="classroom" v-if="classrooms.length > 0">
          <ion-list-header>
            <ion-label class="title--medium" color="white" id='classrooms'>
              Mes classes
            </ion-label>
          </ion-list-header>
          <ion-item
              v-for="(classroom, crKey) in classrooms.sort((a,b)=>sortingName(a,b,['school.name', 'name']))"
              :key="crKey"
              :router-link="'/school/' + classroom.school.uuid +'/classroom/' + classroom.uuid ">

            <ion-icon class="ion-icon" src="./assets/icon/school-solid.svg"></ion-icon>
            <ion-label>

              <h2 v-bind:id="'classroom'+classroom.id">{{ classroom.school.name }} <br> {{ classroom.name }}</h2>

            </ion-label>
          </ion-item>
        </ion-list>


        <ion-list v-if="schools.length > 0">
          <ion-list-header>
            <ion-label class="title--medium" color="white" id='schools'>
               {{$t('home.my_schools')}}
            </ion-label>
          </ion-list-header>
          <ion-item v-for="(school, sckey) in schools" :key="sckey"
                    :router-link="'/school/' + school.uuid">
            <ion-label>
              <h2 v-bind:id="'school' + school.uuid">{{ school.name }}</h2>
              <p></p>
            </ion-label>
          </ion-item>
        </ion-list>

        <ion-list v-if="townships.length > 0">
          <ion-list-header>
            <ion-label class="title--medium" color="white" id='townships'>
              Mes communes
            </ion-label>
          </ion-list-header>
          <ion-item v-for="(township, id) in townships.sort((a,b)=>sortingName(a,b,['name']))" :key="id"
                    :router-link="'/townships/' + township.uuid">
            <ion-label>
              <h2 v-bind:id="'township'+township.id">{{ township.name }}</h2>
              <p></p>
            </ion-label>
          </ion-item>
        </ion-list>

        <div class="btn-bottom" >
          <ion-button hidden v-if="classrooms.length > 0 || greetersSchools.length > 0 " :router-link="'/meal/attendances'" expand="block" id='print-attendances'>
            Imprimer les présences
          </ion-button>
          <ion-button v-if="schools.length && directorSchools.length > 0" :router-link="'/payment/recap'" expand="block" id='payments-recap'>
            Récapitulatif des soldes
          </ion-button>
          <ion-button v-if="classrooms.length > 0 || schools.length && directorSchools.length > 0 || greetersSchools.length > 0" @click.prevent="openRecapModal" router-direction="forward" expand="block"  id='requests-recap'>
            Recevoir les commandes prévisionnelles
          </ion-button>
        </div>

      </div>
    </ion-content>
  </ion-page>
</template>

<i18n>
  {
    "fr": {
      "attendances": "Presences",
      "my_children": "Mes enfants",
      "my_classes": "Mes classes",
      "my_schools": "Mes écoles",
      "my_townships": "Mes communes",
      "summary_of_balances": "Récapitulatif des soldes",
      "receive_the_balance_summary": "Recevoir le récapitulatif des soldes",
    }
  }
</i18n>

<script>
import {
  IonContent,
  IonPage,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonCard,
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  modalController,
  IonSpinner,
} from '@ionic/vue';
import {peopleCircleOutline, warningOutline, logOutOutline, createOutline, pencilOutline} from 'ionicons/icons';
import {defineComponent} from 'vue';
import {getAPI} from "@/axios-api";
import PageHeader from "@/views/components/PageHeader.vue";
import {sortingName} from "@/utils"
import RecapRequestModal from "@/views/components/teacher/RecapRequestModal";

export default defineComponent({
  name: 'HomePage',
  components: {
    IonContent,
    IonPage,
    IonList, IonListHeader, IonItem, IonLabel, IonSpinner,
    IonRefresher, IonRefresherContent, PageHeader, IonIcon,
    IonCard, IonButton, IonCardHeader, IonCardTitle, IonCardContent,
  },
  data() {
    return {
      user: null,
      isOpenEditModal: false,
      editType: '',
      objId: false,
      loading: true,
      recapModal: null,
      userDatas: {
        'greeters_schools': [],
        'legal_tutors': [],
        'classrooms': [],
        'students': [],
        'schools': [],
        'townships': [],
        'directorSchools': [],
      }
    }
  },
  computed:{
    schools(){
      return this.$store.getters["home/getSchools"]
    },
    classrooms(){
      return this.$store.getters["home/getUserClassrooms"]
    },
    greetersSchools(){
      return this.$store.getters["home/getUserGreeterSchools"]
    },
    legalTutors(){
      return this.$store.getters["home/getUserLegalTutors"]
    },
    students(){
      return this.$store.getters["home/getUserStudents"]
    },
    townships(){
      return this.$store.getters["home/getUserTownships"]
    },
    nurseryModule(){
      return this.$store.getters["home/getUserNurseryModule"]
    },
    directorSchools(){
      return this.$store.getters["home/getUserDirectorSchools"]
    },
  },
  methods: {
    goToStudent(student) {
      this.$store.dispatch('students/setCurrentStudent', { student_uuid: student.uuid }).then(() => {
        this.$router.push('/student/' + student.uuid);
      });
    },
    async openRecapModal() {
      const schools = []
      this.classrooms.forEach((classroom) => {
        const exist = schools.filter(_school => _school.uuid === classroom.school.uuid)
        if (exist.length === 0) {
          schools.push(classroom.school)
        }
      })
      Object.values(this.schools).forEach((school) => {
        const exist = schools.filter(_school => _school.uuid == school.uuid)
        if (exist.length === 0) {
            schools.push(school)
        }
      })
      if (this.greetersSchools.length > 0) {
        this.greetersSchools.forEach((school) => {
          const exist = schools.filter(_school => _school.uuid == school.uuid)
          if (exist.length === 0) {
            schools.push(school)
          }
        })
      }
      this.recapModal = await modalController.create({
        component: RecapRequestModal,
        componentProps: {
          schools: schools
        },
      });
      return this.recapModal.present();
    },
    loadUserDatas() {
      this.$store.dispatch('home/loadHome').then(() => {
        this.loading = false
      }).catch((error) => {
        console.log(error)
      })
    },
    reLoadUserDatas(event) {
      this.$store.dispatch('home/loadHome').then(() => {
        event.target.complete();
      })
    },
    disconnect() {
      localStorage.setItem('auth-token', null)
      localStorage.setItem('user', null)
      delete getAPI.defaults.headers.common ['Authorization']
      this.$router.push('/login')
    },
    loadUser() {
      let user = JSON.parse(localStorage.getItem('user'))
      getAPI.get('api/user/').then((response) => {
        const data = response.data.data
        localStorage.setItem('user', JSON.stringify(data))
        user = data
      }).catch((error) => {
        if (error.response.status > 499) {
          alert('Une erreur serveur est survenue. Veuillez réessayer plus tard.')
        }
      })
      this.user = user
    },
    goToUserEdit() {
      this.$router.push('/user/' + this.user.userprofile.uuid)
    },
    openEditModal(type, obj) {
      this.isOpenEditModal = true;
      this.editType = type;
      this.objId = obj;
    },
    closeEditModal() {
      this.isOpenEditModal = false;
    },
    refreshDatas() {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.closeEditModal();
      this.loadUserDatas();
    }
  },
  ionViewDidEnter() {
    if (!this.$store.getters['home/isHomeDataLoaded']) {
      this.refreshDatas();
    }else{
      this.loading = false
    }
  },
  activated() {
    this.reLoadUserDatas()
  },
  setup() {
    return {
      peopleCircleOutline, warningOutline, logOutOutline, sortingName, createOutline, pencilOutline
    }
  },
});
</script>

<style scoped>

ion-toolbar {
  --background: var(--ion-color-secondary)
}

.ion-icon {
  padding-right: 1rem;
}

ion-item {
  --ion-item-background: var(--ion-color-white);
  --ion-item-color: var(--ion-color-white-contrast);
  transition: all .3s ease-out;
}

.label-balance {
  max-width: 11ch;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}

.label-balance p {
  font-size: 11px;
}

.btn-modifyProfil {
  --box-shadow: none;
  --ion-color-base: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.btn-bottom{
  padding: 1rem;
}

ion-card-title {
  font-size: 21px;
}

@media (min-width: 600px) {
  ion-label {
    --ion-color-base: var(--ion-color-white-contrast);
  }

  ion-item:hover, ion-item:focus {

    --ion-item-background: var(--ion-color-light);
    cursor: pointer;
  }

}

</style>
