import { RouteRecordRaw } from 'vue-router';
import TransactionsPage from "@/views/pages/student/TransactionsPage.vue"
import FuturPage from "@/views/pages/student/FuturPage.vue"
import IndexModuleWrapper from "@/views/Modules/Default/Student/IndexModuleWrapper.vue"
import TypeModuleWrapper from "@/views/Modules/Default/Student/TypeModuleWrapper.vue"
import ItemModuleWrapper from "@/views/Modules/Default/Student/ItemModuleWrapper.vue"
import MakePayment from "@/views/pages/student/MakePayment.vue";
import PaymentPage from '@/views/pages/student/PaymentPage.vue'
import EditStudent from "@/views/pages/student/EditStudent.vue";
import StudentHome from "@/views/pages/student/StudentHome.vue"

const routesStudent: Array<RouteRecordRaw> = [
    {
        path: '/student/:studentUuid/transactions',
        name: 'StudentTransactions',
        component: TransactionsPage
    },
    {
        path: '/student/:studentUuid/futur',
        name: 'StudentFutur',
        component: FuturPage
    },
    {
        path: '/student/:studentUuid/make_payment',
        name: 'MakePayment',
        component: MakePayment
    },
    {
        path: '/student/:studentUuid/edit',
        name: 'EditStudent',
        component: EditStudent
    },
    {
        path: '/student/:studentUuid',
        name: 'StudentHome',
        component: StudentHome,
    },
    {
        path: '/student/:studentUuid/module/:moduleUuid',
        name: 'ViewModuleWrapper',
        component: IndexModuleWrapper,
    },
    {
        path: '/student/:studentUuid/module/:moduleUuid/types/:typeUuid',
        name: 'TypeModuleWrapper',
        component: TypeModuleWrapper
    },
    {
        path: '/student/:studentUuid/module/:moduleUuid/types/:typeUuid/items/:itemUuid',
        name: 'ItemModuleWrapper',
        component: ItemModuleWrapper,
    },
    {
        path: '/student/:studentUuid/payments',
        name: 'PaymentPage',
        component: PaymentPage
    },
]

export default routesStudent