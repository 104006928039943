<template>
  <ion-menu side="end" menu-id="menu" content-id="menu" >
    <ion-content>
      <ion-list lines="none">
        <ion-item>
          <ion-button slot="end" @click.prevent="closeMenu" fill="clear" color="white">
            <ion-icon src="assets/icon/times-solid.svg"></ion-icon>
          </ion-button>
        </ion-item>
        <ion-item color="white" >
          <ion-button @click.prevent="confirmDisconnect" fill="clear" id='disconnect'>
            <ion-icon slot="start" :icon="powerSharp"></ion-icon> Déconnexion
          </ion-button>
        </ion-item>
        <ion-item color="white" >
          <ion-button @click.prevent="goToUserEdit" fill="clear">
            <ion-icon slot="start" src="assets/icon/pen-solid.svg"></ion-icon> Éditer mes informations
          </ion-button>
        </ion-item>
        <ion-item color="white" >
          <a href="https://c4k.nubeo.be/guide-d-utilisation" target="_blank" class="link-help" >
            <ion-icon slot="start" src="assets/icon/circle-question-solid.svg"></ion-icon> Guide aide
          </a>
        </ion-item>
      </ion-list>
      <span class="info-version">C4K v2.1.2<span v-if="showDev">&nbsp;{{showDev}}</span></span>
      <br>
      <span class="info-version-date">2024-01-03 10:57:36</span>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="menu"></ion-router-outlet>
</template>

<script>

import {defineComponent} from 'vue';
import {getAPI} from "@/axios-api";
import {
  IonContent, IonButton, IonMenu, menuController, IonList, IonItem, IonRouterOutlet, IonIcon, alertController
} from '@ionic/vue';
import {powerSharp } from 'ionicons/icons';

export default defineComponent({
  name: 'PartMainMenu',
  components: {
    IonContent, IonMenu, IonList, IonItem, IonRouterOutlet, IonIcon, IonButton
  },
  data() {
    return  {
      user: JSON.parse(localStorage.getItem('user')),
    }
  },
  methods: {
    openMenu(){
      menuController.enable(true, 'menu');
      menuController.open('menu');
    },
    closeMenu(){
      menuController.close('menu');
    },
    async confirmDisconnect(){
      const alertMsg = {
          message: `Voulez-vous vraiment vous déconnecter ?`,
          buttons: [
            {
              text: "Retour",
            },
            {
              text: "Déconnexion",
              cssClass: "btn-danger",
              handler: ()=> {
                this.disconnect()
              }
            }
          ]
        }
        const alertItem = await alertController.create(alertMsg);
        return alertItem.present();
    },
    disconnect() {
      localStorage.setItem('auth-token', null)
      localStorage.setItem('user', null)
      delete getAPI.defaults.headers.common ['Authorization']
      this.closeMenu()
      this.$router.push('/login')
    },
    goToUserEdit() {
      this.closeMenu()
      this.$router.push('/user/' + this.user.userprofile.uuid)
    },
  },
  computed: {
    showDev() {
      return process.env.NODE_ENV.slice(0, 3).toUpperCase()
    }
  },
  setup () {
    return {
      powerSharp
    }
  },
});
</script>

<style scoped>

  ion-menu{
    position: fixed;
  }
  .info-version {
    position: absolute;
    padding: 4px 8px 8px 4px;
    bottom: 10px;
    right: 0;
    color: #45454b;
    font-size: 0.7em;
  }
  .info-version-date {
    position: absolute;
    padding: 4px 8px 8px 4px;
    bottom: 0;
    right: 0;
    color: #45454b;
    font-size: 0.5em;
  }

  .link-help{
    display: flex;
    align-items: center;

  }
  .link-help ion-icon{
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

</style>
