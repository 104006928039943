<template>
  <ion-app>
    <part-main-menu></part-main-menu>
    <!-- <ion-router-outlet>
    </ion-router-outlet> -->
  </ion-app>
</template>

<script lang="ts">
import { IonApp } from '@ionic/vue';
import { defineComponent } from 'vue';
import PartMainMenu from '@/views/components/PartMainMenu.vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    // IonRouterOutlet,
    PartMainMenu
  },
});
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&family=Open+Sans:wght@300;400;600;700&display=swap');
</style>