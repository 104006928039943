<template>
  <ion-page>

    <ion-header>
      <page-header>
        <ion-title class="header-link" >
          <router-link enterkeyhint="go" to="/home" replace>Mes classes</router-link> / 
          <router-link v-if="student" enterkeyhint="go" :to="`/school/${student.classroom.school.uuid}/classroom/${student.classroom.uuid}`" replace>{{student.classroom.school.name}} {{student.classroom.name}}</router-link> / 
          <router-link v-if="student" enterkeyhint="go" :to="`/classroom/student/${student.uuid}`" replace>{{student.first_name}} {{student.last_name}}</router-link> /
          Paiement
        </ion-title>
      </page-header>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="container">
        <form class="form" @submit.prevent="receivePaymentCheck">
          <ion-list>
            <ion-item lines="none">
              <h2 class="ion-text-center title--medium" >Recevoir un paiement liquide</h2>
            </ion-item>

            <ion-item  lines="none" >
              <ion-label>Montant (&euro;) <ion-text color="danger">*</ion-text> :</ion-label>
              <ion-input v-model="payment.amount" required type="number" step="0.1" placeholder="15.0">
              </ion-input>
            </ion-item>

            <ion-item lines="none" v-if="student" >
              <ion-label position="stacked">Sélectionnez le tuteur <ion-text color="danger">*</ion-text></ion-label>

              <ion-select class="hiddenForDesktop" placeholder="Choisissez un tuteur" interface="action-sheet" :interface-options="options" cancelText="" v-model="legalTutor">
                <ion-select-option  v-for="(legal_tutor, key) in student.legal_tutors" :key="key" :value="legal_tutor">
                  <span v-bind:id="'mobile-legal-tutor-'+legal_tutor.user.id" v-if="legal_tutor.user.first_name !== '' || legal_tutor.user.last_name !== ''">{{ legal_tutor.user.first_name }} {{ legal_tutor.user.last_name }}</span>
                  <span v-bind:id="'mobile-legal-tutor-'+legal_tutor.user.id" v-else-if="legal_tutor.user.email !== ''">{{ legal_tutor.user.email }}</span>
                  <span v-bind:id="'mobile-legal-tutor-'+legal_tutor.user.id" v-else>{{ legal_tutor.user.username }}</span>
                </ion-select-option>
              </ion-select>

              <ion-select class="hiddenForMobile hiddenForMobile-flex" placeholder="Choisissez un tuteur" interface="popover" :interface-options="options" cancelText="" v-model="legalTutor">
                <ion-select-option  v-for="(legal_tutor, key) in student.legal_tutors" :key="key" :value="legal_tutor">
                  <span v-bind:id="'legal-tutor-'+legal_tutor.user.id" v-if="legal_tutor.user.first_name !== '' || legal_tutor.user.last_name !== ''">{{ legal_tutor.user.first_name }} {{ legal_tutor.user.last_name }}</span>
                  <span v-bind:id="'legal-tutor-'+legal_tutor.user.id" v-else-if="legal_tutor.user.email !== ''">{{ legal_tutor.user.email }}</span>
                  <span v-bind:id="'legal-tutor-'+legal_tutor.user.id" v-else>{{ legal_tutor.user.username }}</span>
                </ion-select-option>
              </ion-select>

            </ion-item>

            <ion-button shape="round" expand="full" color="primary" @click.prevent="receivePaymentCheck" id="receive-payment">Ajouter au solde</ion-button>

          </ion-list>
        </form>
      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>

</template>

<script>
import { IonContent, IonHeader, IonPage, IonInput, IonSelect, IonSelectOption, IonFooter, IonList, IonButton, IonLabel, IonText, IonItem, alertController, IonTitle,} from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import {getAPI} from "@/axios-api";
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from '@/views/components/PageFooter.vue';

export default defineComponent({
  name: 'ReceivePayment',
  components: {
    IonPage,
    IonContent, IonHeader, IonInput, IonSelect, IonSelectOption,
    IonFooter, IonButton, IonLabel, IonText, IonItem,
    PageHeader, PageFooter, IonList, IonTitle,
  },
  mounted() {
    getAPI.get('/students/' + this.$route.params.studentUuid).then((response) => {
      this.student = response.data.data
    })
  },
  data() {
    return {
      student: null,
      amount: null,
      legalTutor: null,
      payment: {
        amount: null,
        legalTutor: null,
      }
    }
  },
  methods: {
    async receivePaymentCheck() {
      if(!this.legalTutor) {
        const alertItem = await alertController
          .create({
            message: 'Vous devez choisir un tuteur.',
            buttons: ['OK']
          })
        return alertItem.present();
      } else if (this.payment.amount <= 0 ) {
        const alertItem = await alertController
          .create({
            message: 'Le montant ne peut pas être inférieur ou égal à 0',
            buttons: ['OK']
          })
        return alertItem.present();
      } else if (this.payment.amount > 200) {
        const alertItem = await alertController
          .create({
            message: 'Le montant ne peut pas être supérieur à 200€',
            buttons: ['OK']
          })
        return alertItem.present();
      } else {
      const alertMsg = {
          message: ` Voulez-vous bien ajouter ${this.payment.amount} € reçu en liquide par le parent ${this.legalTutor.user.first_name} ${ this.legalTutor.user.last_name} ?`,
          buttons: [
            {
              text: "Annuler",
            },
            {
              text: "Confirmer",
              cssClass: "btn-danger",
              handler: ()=> {
                this.receivePayment()
              }
            }
          ]
        }
        const alertItem = await alertController.create(alertMsg);
        return alertItem.present();
      }
    },
    receivePayment(){
      this.payment.legalTutor = this.legalTutor.id
      getAPI.post('/payments/students/' + this.$route.params.studentUuid + '/receive', this.payment).then(() => {
        this.payment.amount = 0.0
        this.payment.legalTutor = null
        this.$router.go(-1)
        this.$store.dispatch('students/syncWallet', {student_uuid: this.student.uuid, force: true})
      }).catch(async ()=>{
        const alertItem = await alertController
          .create({
            header: 'Une erreur est survenue',
            message: 'Veuillez réessayer plus tard',
            buttons: ['OK']
          })
        return alertItem.present();
      })
    }
  },
  setup () {

    const options = {
      cssClass: 'custom-options'
    }

    return {
      options, copyOutline
    }
  },
});

</script>

<style scoped>

  .form-payment{
    box-shadow: none;
    height: calc(100vh - 7rem);
    padding: 1rem;
    margin-top: 0;

    background: #fff;
    width: 100%;
    margin: 0;
    border-radius: 0;

  }

  ion-item{
    --ion-item-background: none;
    --ion-item-border-color: rgba(0,0,0,1);
    --padding-start: 0;
    --inner-padding-end: 0;
    --padding-bottom: 2rem;
    color: var(--ion-color-white-contrast)
  }

  ion-input{
    border-bottom : 1px solid #fff;
    box-sizing: border-box;
    --padding-start: 0.5rem;
    --background: var(--ion-color-light);
    --color: var(--ion-color-white-contrast);
    margin-left: 0.5rem;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  }

  ion-button{
    width: 100%;
    margin: 0;
    --box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  }

  @media (min-width: 600px) {
    .form-payment{

      --background: var(--ion-color-white);
      --color: var(--ion-color-white-contrast);

      width: 500px;
      height: inherit;
      position: absolute;
      top: 50%;
      left:50%;
      transform: translateX(-50%) translateY(-50%);
      margin-inline: 0;
      padding: 1rem 1rem 2rem 1rem;
      overflow: visible;
      border-radius: 10px;

      --item-max-width: 350px;
    }

    ion-item{
      --ion-item-color: var(--ion-color-white-contrast);
      max-width: var(--item-max-width);
      margin: 0 auto;
      padding-bottom: 1rem;
    }

    ion-input{
      border-bottom: 1px solid var(--ion-color-white-contrast);
    }

    ion-button{
      --ion-color-base: var(--ion-background-color);
      --ion-color-contrast: var(--ion-color-white);
      max-width: var(--item-max-width);
      margin: 0 auto;

    }
  }

</style>
