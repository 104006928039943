<template>
  <ion-page>
    <ion-header translucent>
      <page-header>
        <ion-title class="header-link" id="school-students-ariane">
          <router-link enterkeyhint="go" to="/home" replace id="school-students-home-link">Mes écoles</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${$route.params.schoolUuid}`" replace id="school-students-school-link">
              {{ $store.getters['school/getSchoolNameByUuid']({school_uuid: $route.params.schoolUuid}) || schoolName }}
          </router-link> /
          Module {{module.name}}
        </ion-title>
      </page-header>
    </ion-header>

    <ion-content :fullscreen="true" >
      <div class="container" >
        <ion-card>
          <ion-card-header color="white">
            <ion-card-title>
              {{module.name}}
            </ion-card-title>
          </ion-card-header>
        </ion-card>
        <section style="padding: 0 10px">
          <ion-button
              v-if="$store.getters['home/isManager']($route.params.schoolUuid)"
              :router-link="'/school/' + $route.params.schoolUuid + '/module/' + $route.params.moduleUuid + '/types'"
              router-direction="forward"
              expand="block"
              id="edit-datas">
            Ajouter/modifier des données
          </ion-button>
          <ion-button
              v-if="$store.getters['home/isDirector']($route.params.schoolUuid)"
              :router-link="'/school/' + $route.params.schoolUuid + '/module/' + $route.params.moduleUuid + '/managers'"
              router-direction="forward"
              expand="block"
              id="edit-manager">
            Managers
          </ion-button>
        </section>
      </div>
    </ion-content>
    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import {
  IonPage, IonContent, IonHeader,
  IonFooter, IonButton, IonTitle,
  IonCard, IonCardTitle, IonCardHeader
} from '@ionic/vue';

import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";

export default {
  name: "AdminModuleIndex",
  components: {
    IonCard, IonCardTitle, IonCardHeader,
    IonButton,
    IonContent,
    IonPage,
    PageHeader,
    PageFooter,
    IonHeader, IonFooter, IonTitle,
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    modules(){
      return this.$store.getters["school/getSchoolModules"]
    },
    module(){
      if (this.$route.params.moduleUuid in this.modules){
        return this.modules[this.$route.params.moduleUuid]
      }
      return {}

    }
  },
  mounted(){
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid).then(()=>{
      this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid}).then(()=>{
        this.$store.dispatch('school/navigateToModule', this.$route.params.moduleUuid).then(()=>{
          this.$store.dispatch('school/loadModules', {schoolUuid: this.$route.params.schoolUuid})
        })
      })
    })
  }
}
</script>

<style scoped>

</style>