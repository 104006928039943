<template>
  <div style="margin-top: 3px;">
    <ion-button
        v-for="(tag_uuid, ktag) in tags"
        :key="ktag"
        :size="size"
        :color="color"
        :id="tag_uuid"
        :fill="selected[tag_uuid] ? 'solid' : 'outline'"
        @click.prevent="switchTag(tag_uuid)"
    >
      {{$store.getters['tag/getTag'](tag_uuid).name}}

      <!-- first char 'i' = PNG and '/' = jpg -->
      <ion-icon
          v-if="$store.getters['tag/getTag'](tag_uuid).b64_icon && $store.getters['tag/getTag'](tag_uuid).b64_icon.charAt(0) != 'i' && $store.getters['tag/getTag'](tag_uuid).b64_icon.charAt(0) != '/'"
          :src="'data:image/png;base64,' + $store.getters['tag/getTag'](tag_uuid).b64_icon">
      </ion-icon>

      <ion-img
          v-else
          class='image'
          :src="'data:image/png;base64,' + $store.getters['tag/getTag'](tag_uuid).b64_icon"
          style="height: 16px;margin-left: 3px;"
      >
      </ion-img>
    </ion-button>
  </div>
</template>

<script>
import {IonButton, IonIcon, IonImg} from "@ionic/vue";

export default {
  name: "TagsInput",
  components: {IonButton, IonIcon, IonImg},
  props: {
    tags: {
      type: Object,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: "Ajouter un tag"
    },
    color: {
      type: String,
      required: false,
      default: "primary"
    },
    size: {
      type: String,
      required: false,
      default: "default"
    }
  },
  data () {
    return {
      selected: {},
    }
  },
  methods: {
    switchTag(tag_uuid) {
      if (this.selected[tag_uuid]) {
        delete this.selected[tag_uuid]
      } else {
        this.selected[tag_uuid] = this.$store.getters['tag/getTag'](tag_uuid);
      }
      this.$emit('input', this.selected);
    }
  }
}
</script>

<style scoped>

</style>