<template>
  <ion-page>
    <ion-header :translucent="true">
      <page-header>
        <ion-title class="header-link" v-if="!loading">
          <router-link enterkeyhint="go" to="/home" replace>Mes écoles</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}`" replace>{{schoolName}}</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}/greeters`" replace>Accueillants</router-link> /
          {{userGreeter.first_name + " " + userGreeter.last_name}}
        </ion-title>
      </page-header>
    </ion-header>

    <ion-content :fullscreen="true">

      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div v-if="loading">
        <p style="text-align: center">
          Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
        </p>
      </div>
      <div class="container" v-else >

        <ion-card color="light" > 
          <ion-card-header>
            <ion-card-title>{{userGreeter.first_name + " " + userGreeter.last_name}}</ion-card-title> 
          </ion-card-header>
          <ion-card-content>
            <p>{{userGreeter.username}}</p>
          </ion-card-content>
          <ion-button @click.prevent='goToGreeterEdit()' class="btn-modifyProfil" shape="round"  size="small" color="light" >
            <ion-icon src="/assets/icon/pen-solid.svg" ></ion-icon>
          </ion-button>
        </ion-card>
      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import {
  IonPage, IonContent, IonFooter, IonCard, IonCardTitle, IonCardHeader,
  IonHeader, IonTitle, IonButton, IonIcon, IonRefresherContent, IonRefresher, IonSpinner,
  IonCardContent
} from '@ionic/vue';
import {defineComponent} from 'vue';
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {sortingName} from '@/utils';

export default defineComponent({
  name: "GreeterPage",
  components: {
    IonContent,
    IonPage, IonButton,
    IonFooter, IonHeader,IonTitle,
    PageHeader, PageFooter, IonCard, IonCardTitle,
    IonCardHeader, IonIcon, IonRefresherContent, IonRefresher, 
    IonSpinner, IonCardContent
  },
  data() {
    return {
      schoolUuid: null,
      search: ""
    }
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    userGreeter(){
      return this.$store.getters["greeter/getGreeterUser"]
    },
    loading(){
      if (this.userGreeter && this.schoolName){
        return false
      }
      return true
    }
  },
  mounted(){
    this.schoolUuid = this.$route.params.schoolUuid
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid)
    this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid})
    this.$store.dispatch('greeter/navigateToGreeter', this.$route.params.greeterUuid)
    this.$store.dispatch('greeter/loadUserGreeter')
  },
  methods: {
    goToGreeterEdit(){
      this.$router.push('/school/'+ this.schoolUuid + '/greeters/' + this.$route.params.greeterUuid + '/edit' )
    },
    doRefresh(event){
      this.$store.dispatch('greeter/reloadGreeter').then(()=>{
        event.target.complete()
      })
    }
  },
  setup() {
    return {
      sortingName
    }
  },
});
</script>

<style scoped>

  .btn-delete{
    --box-shadow: none;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
  .btn-modifyProfil{
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    --box-shadow: 0;
  }
  ion-list-header{
      color: var(--ion-color-white)
  }
  @media (min-width:600px){
    ion-list-header{
      color: var(--ion-color-white-contrast)
    }
  }
 

</style>