import {getAPI} from "@/axios-api";

const state = () => ({
    currentStudentUuid: null,
    defaultStudent: {
        uuid: null,
        first_name: null,
        last_name: null,
        national_number: null,
        bank_account_number: null,
        address_street: null,
        address_number: null,
        address_box: null,
        address_zip: null,
        address_city: null,
        address_country: null,
        wallet: {
            transactions: []
        },
        classroom: {
            name: null,
            uuid: null,
            school: {
                uuid: null,
                name: null
            }
        },
        legal_tutors: {},
        modules: {},
        meals: {
            weeks: {}
        }
    },
    students: {},
})

const mutations = {
    setCurrentStudent(state: any, student_uuid: string) {
        state.currentStudentUuid = student_uuid
    },
    unsetCurrentStudent(state: any) {
        state.currentStudentUuid = null
    },
    setWallet (state: any, {student_uuid, data} : any) {
        state.students[student_uuid]['wallet'] = data
    },
    unsetWallet (state: any, {student_uuid} : any) {
        if (state.students[student_uuid]){
            state.students[student_uuid]['wallet'] = null
        }
    },
    setClassroom (state: any, {student_uuid, data} : any) {
        state.students[student_uuid].classroom = data
    },
    setLegalTutors (state: any, {student_uuid, data} : any) {
        state.students[student_uuid].legal_tutors = {}
        for (let i = 0; i < data.length; i++) {
            state.students[student_uuid].legal_tutors[data[i].uuid] = data[i]
        }
    },
    setModules (state: any, {student_uuid, data} : any) {
        state.students[student_uuid].modules = {}
        for (let i = 0; i < data.length; i++) {
            state.students[student_uuid].modules[data[i].module.uuid] = data[i].module
            state.students[student_uuid].modules[data[i].module.uuid].settings = data[i].settings
        }
    },
    resetStudent (state: any, {student_uuid}: any) {
        state.students[student_uuid] = null
    },
    setStudent (state: any, {data} : any) {
        state.students[data.uuid] = data
        state.students[data.uuid].meals = {weeks: {}}
    },
    setCurrentModule (state: any, {student_uuid, module_uuid} : any) {
        state.students[student_uuid].current_module = module_uuid
    },
    setModuleArticleTypes (state: any, {student_uuid, module_uuid, data} : any) {
        state.students[student_uuid].modules[module_uuid]['item_types'] = {}
        for (let i = 0; i < data.length; i++) {
            state.students[student_uuid].modules[module_uuid]['item_types'][data[i].uuid] = data[i]
            state.students[student_uuid].modules[module_uuid]['item_types'][data[i].uuid]['items'] = {}
        }
    },
    setModuleItemTypeItems (state: any, {student_uuid, module_uuid, item_type_uuid, data} : any) {
        if (state.students[student_uuid]?.modules[module_uuid] && 
            state.students[student_uuid].modules[module_uuid]['item_types'] && 
            state.students[student_uuid].modules[module_uuid]['item_types'][item_type_uuid]['items']){
            for (let i = 0; i < data.length; i++) {
                state.students[student_uuid].modules[module_uuid]['item_types'][item_type_uuid]['items'][data[i].uuid] = data[i]
            }
        }
    },
    setStudentTransactions(state:any, data:any){
        state.students[state.currentStudentUuid].wallet.transactions = data
    },
    removeStudentTransactions(state:any){
        state.students[state.currentStudentUuid].wallet.transactions = null
    },

    // Module items

    setItemsByWeek(state: any, { data, currentYear, currentWeek, student_uuid, module_uuid }: any) {
        if (!student_uuid) {
            student_uuid = state.currentStudentUuid;
        }
        const itemWeek = { year: currentYear, week: currentWeek, items: data };
        if (!state.students[student_uuid]?.modules?.[module_uuid]?.items) {
            if (!state.students[student_uuid].modules) {
                state.students[student_uuid].modules = {};
            }
            if (!state.students[student_uuid].modules[module_uuid]) {
                state.students[student_uuid].modules[module_uuid] = { "items": {} };
            }
        }
        // Copier le contenu existant de items dans un nouvel objet
        const existingItems = { ...state.students[student_uuid].modules[module_uuid].items };
        // Ajouter le nouvel élément à l'objet existant
        existingItems[itemWeek.week + '-' + itemWeek.year] = itemWeek;
        // Mettre à jour la propriété items avec le nouvel objet
        state.students[student_uuid].modules[module_uuid].items = existingItems;
    },
    removeAllItemsFromStudent(state:any){
        state.students[state.currentStudentUuid].modules = null
    },

    // Réservations
    setStudentReservations(state:any, reservations:any) {
        const student = state.students[state.currentStudentUuid]
        student.wallet = {
            ...student.wallet,
            reservations: [...(student?.wallet?.reservations || []), ...reservations],
        };
    },
    setStudentReservationsLoadedMonth(state:any, month:any) {
        const student = state.students[state.currentStudentUuid]
        student.wallet = {
            ...student.wallet,
            reservationsLoadedMonths: [...(student?.wallet?.reservationsLoadedMonths || []), month],
        };
        
    }
}

const getters = {
    getStudent: (state: any) => (student_uuid: string) => {
        if (state.students[student_uuid]) {
            return state.students[student_uuid]
        }
        return null
    },
    classroom: (state: any) => (student_uuid: string) => {
        if (state.students[student_uuid]) {
            return state.students[student_uuid].classroom
        }
        return null
    },
    currentStudent (state: any) {
        if (state.currentStudentUuid && state.students[state.currentStudentUuid]) {
            return state.students[state.currentStudentUuid]
        }
        return null
    },
    currentWallet (state: any) {
        if (state.currentStudentUuid === null || !state.students[state.currentStudentUuid]) {
            return null
        }
        return state.students[state.currentStudentUuid].wallet
    },
    currentClassroom (state: any) {
        if (state.currentStudentUuid === null || !state.students[state.currentStudentUuid]) {
            return null
        }
        return state.students[state.currentStudentUuid].classroom
    },
    currentSchool (state: any) {
        if (state.currentStudentUuid === null || !state.students[state.currentStudentUuid]) {
            return null
        }
        return state.students[state.currentStudentUuid].classroom.school
    },
    currentModules (state: any) {
        return state.students[state.currentStudentUuid].modules
    },
    currentModule (state: any) {
        if (
            state.currentStudentUuid === null || 
            !state.students[state.currentStudentUuid] || 
            !state.students[state.currentStudentUuid].modules || 
            !state.students[state.currentStudentUuid].current_module
        ) {
            return null
        }
        return state.students[state.currentStudentUuid].modules[state.students[state.currentStudentUuid].current_module]
    },
    student (state: any, {student_uuid} : any) {
        if (student_uuid === null && !state.students[student_uuid]) {
            return null
        }
        return state.students[student_uuid]
    },
    studentWallet: (state: any ) => ({student_uuid}: any) => {
        if (state.students[student_uuid] === undefined) {
            return null
        }
        if (state.students[student_uuid].wallet === undefined) {
            return null
        }
        return state.students[student_uuid].wallet
    },
    studentClassroom (state: any, {student_uuid}: any) {
        if (state.students[student_uuid] === undefined) {
            return null
        }
        if (state.students[student_uuid].wallet === undefined) {
            return null
        }
        return state.students[student_uuid].wallet
    },
    studentSchool (state: any, {student_uuid}: any) {
        if (state.students[student_uuid] === undefined) {
            return null
        }
        if (state.students[student_uuid].wallet === undefined) {
            return null
        }
        return state.students[student_uuid].wallet
    },
    students: (state: any) => {
        if (state.students === undefined) {
            return {}
        }
        const students = Object.keys(state.students).map((key: any) => {
            return state.students[key]
        })
        students.sort((a: any, b: any) => {
            return a.first_name.localeCompare(b.first_name)
        })
        return students
    },

    // Module items 

    studentWeekItems: (state:any) => ({year,weekNumber,module_uuid}:any) => {
        if(state.students[state.currentStudentUuid]?.modules?.[module_uuid]?.items){
            const itemWeek = state.students[state.currentStudentUuid].modules[module_uuid].items[weekNumber + '-' + year]
            return itemWeek ? itemWeek.items : []
        }
        return []
    },
    studentTransactions (state:any){
        const student = state.students[state.currentStudentUuid]
        if(student && student.wallet){
            return student.wallet.transactions
        }
    },
    studentReservationsDays: (state:any) => (moduleUuid:string) => {
        // Return la date de toute les réservation du student par module
        return state.students[state.currentStudentUuid]?.wallet?.reservations?.filter(
            (reservation:any)=> reservation.item.item_type.module.uuid == moduleUuid
        ).map((reservation:any) => new Date(reservation.item.start_at)) || []
    }
}

const actions = {
    resetStudent ({commit}: any, {student_uuid}: any) {
        commit('resetStudent', student_uuid)
    },
    setCurrentModule ({commit}: any, {student_uuid, module_uuid}: any) {
        commit('setCurrentModule', {student_uuid, module_uuid})
    },
    setCurrentStudent ({commit, state}: any, {student_uuid}: any) {
        if (student_uuid != state.currentStudentUuid){
            return new Promise((resolve) => {
                commit('setCurrentStudent', student_uuid)
                resolve(student_uuid)
            })
        }
    },
    syncCompleteStudent ({dispatch}: any, {student_uuid}: any) {
        return dispatch('syncStudent', {student_uuid}).then(() => {
            dispatch('syncWallet', {student_uuid})
            dispatch('syncClassroom', {student_uuid})
            dispatch('syncLegalTutors', {student_uuid})
            dispatch('syncModules', {student_uuid})
        })
    },
    syncStudent({ commit, state }: any, {student_uuid}: any) {
        if(!state.students[student_uuid]?.national_number){
            return new Promise((resolve, reject) => {
                getAPI.get('/students/' + student_uuid).then(response => {
                    commit('setStudent', {data: response.data.data})
                    commit('setLegalTutors', {student_uuid, data: response.data.data.legal_tutors})
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    syncWallet({ commit, state }: any, {student_uuid, force = false}: any){
        if(!state.students[student_uuid].wallet || force){
            return new Promise((resolve, reject) => {
                getAPI.get('/payments/students/' + student_uuid).then(response => {
                    commit('setWallet', {student_uuid, data: response.data.data})
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    unsetWallet({ commit }:any, {student_uuid}:any){
        commit('unsetWallet', {student_uuid})
    },
    syncTransactions({commit, state, dispatch}: any,offset:number){
        return new Promise((resolve, reject)=>{
            if(!state.students[state.currentStudentUuid] || 
                !state.students[state.currentStudentUuid].wallet || 
                !state.students[state.currentStudentUuid].wallet.transactions ||
                Object.keys(state.students[state.currentStudentUuid].wallet.transactions || {}).length < offset*10
            ){
                dispatch("syncCompleteStudent", {student_uuid:state.currentStudentUuid}).then(()=>{
                    getAPI.get('/payments/students/' + state.currentStudentUuid + '/transactions?offset='+offset).then((response)=>{
                        const data = response.data.data.transactions
                        commit('setStudentTransactions', data)
                        resolve(data)
                    }).catch(error => {
                        reject(error)
                    })
                })
            }
        })
    },
    reloadTransactions({commit, dispatch}:any){
        commit('removeStudentTransactions')
        dispatch('syncTransactions', 1)
    },
    syncClassroom({ commit, state }: any, {student_uuid}: any){
        if(!state.students[student_uuid].classroom){
            return new Promise((resolve, reject) => {
                getAPI.get('/students/' + student_uuid + '/classroom').then(response => {
                    commit('setClassroom', {student_uuid, data: response.data.data})
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    syncLegalTutors({ commit, state }: any, {student_uuid}: any){
        if(!state.students[student_uuid].legal_tutors){
            return new Promise((resolve, reject) => {
                getAPI.get('/students/' + student_uuid + '/legal_tutors').then(response => {
                    commit('setLegalTutors', {student_uuid, data: response.data.data})
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    syncModules({ commit }: any, {student_uuid}: any){
        return new Promise((resolve, reject) => {
            getAPI.get('/students/' + student_uuid + '/modules').then(response => {
                commit('setModules', {student_uuid, data: response.data.data})
                response.data.data.forEach((module: any) => {
                    commit('module/setModule', module.module, {root: true})
                })
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    syncModuleItemTypes({ commit }: any, {student_uuid, module_uuid}: any){
        return new Promise((resolve, reject) => {
            getAPI.get('/students/' + student_uuid + '/modules/' + module_uuid + '/item_types').then(response => {
                commit('setModuleArticleTypes', {student_uuid, module_uuid, data: response.data.data})
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    syncModuleItems({ commit }: any, {student_uuid, module_uuid, item_type_uuid}: any){
        return new Promise((resolve, reject) => {
            getAPI.get('/students/' + student_uuid + '/modules/' + module_uuid + '/item_types/' + item_type_uuid).then(response => {
                commit('setModuleItemTypeItems', {student_uuid, module_uuid, item_type_uuid, data: response.data.data})
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    buyItem({ commit }: any, {student_uuid, item_uuid}: any){
        return new Promise((resolve, reject) => {
            getAPI.post('/students/' + student_uuid + '/items/' + item_uuid + '/buy').then(response => {
                commit('setStudent', {data: response.data.data})
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    async loadStudentItemsWeek({commit, state, rootGetters}:any, {year,weekNumber, force = false, student_uuid = null, module_uuid}:any){
        const student = student_uuid === null ? state.students[state.currentStudentUuid] : state.students[student_uuid]
        const itemWeek = student?.modules?.[module_uuid]?.items?.[weekNumber + '-' + year]
        let school = null
        if (student.classroom.school) {
            school = rootGetters['school/getSchool']({school_uuid: student.classroom.school.uuid})
        }
        if (!school) {
            school = student.classroom.school
        }
        if((student && !itemWeek) || force){
            const module_slug = rootGetters['module/getModule'](module_uuid).slug_name
            await getAPI.get('/modules/' + module_slug + '/school/' + school.uuid + '/student/' + student.uuid + '/items', {
                params: {
                  year: year,
                  week: weekNumber
                }
              }
          ).then((response) => {
            const data = response.data.data
            commit("setItemsByWeek",{
                data,
                currentYear: year,
                currentWeek: weekNumber,
                student_uuid: student.uuid,
                module_uuid: module_uuid
            })
          })
        }
    },
    reloadStudentitems({commit}:any){
        commit('removeAllItemsFromStudent')
    },
    refundWallet({commit}:any, {student_uuid}:any){
        return new Promise((resolve, reject) => {
            getAPI.post('/students/' + student_uuid + '/refund_wallet').then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    async loadStudentReservations({commit, state}:any, {moduleUuid, params}:any) {
        // Load reservations data by month, year and module
        const student = state.students[state.currentStudentUuid]
        const monthExist = student?.wallet?.reservationsLoadedMonths?.find((month:any) => month.year === params.year && month.month === params.month && month.moduleUuid == moduleUuid)
        if (!monthExist) {
            await getAPI.get('/students/' + state.currentStudentUuid + '/module/' + moduleUuid + '/reservations',{params: {year: params.year, month: params.month}}).then((response) => {
                commit('setStudentReservations', response.data.data)
                commit('setStudentReservationsLoadedMonth', {month: params.month, year: params.year, moduleUuid: moduleUuid})
            }).catch(error => {
                console.log(error)
            })
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
