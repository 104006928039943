<template>
  <ion-page>
    <ion-header>
      <page-header>
        <ion-title class="header-link" >
          <router-link enterkeyhint="go" to="/home" replace id="school-students-home-link">Mes écoles</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${$route.params.schoolUuid}`" replace id="school-students-school-link">
            {{ $store.getters['school/getSchoolNameByUuid']({school_uuid: $route.params.schoolUuid}) }}
          </router-link> /
          <router-link enterkeyhint="go" :to="`/school/${$route.params.schoolUuid}/module/${$route.params.moduleUuid}`" replace id="school-students-module-link">
            {{module && module.name}}
          </router-link> /
        </ion-title>
      </page-header>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="container">
        <item-type-card :uuid="$route.params.typeUuid" :show-buttons="false"></item-type-card>
        <ion-list>
          <ion-item color="light" lines="none">
            <ion-label>
              <h2>Prix par classes</h2>
            </ion-label>
          </ion-item>
          <ion-item color="light" v-for="(classroom, index) in $store.getters['school/getSchoolClassrooms']({schoolUuid: $route.params.schoolUuid})" :key="index">
            <ion-label position="stacked">
              {{classroom.name}}
            </ion-label>
            <ion-input
                :clear-input="true"
                type="number"
                :placeholder="classroom.meal_price"
                :value="$store.getters['itemTypeClassroomPrice/getClassroomItemTypePrice']({
                  item_type_uuid: $route.params.typeUuid,
                  classroom_uuid: classroom.uuid
                })"
                @change="$store.commit('itemTypeClassroomPrice/setClassroomPriceForItemType', {
                  item_type_uuid: $route.params.typeUuid,
                  classroom_uuid: classroom.uuid,
                  value: $event.target.value
                })"
            ></ion-input>
          </ion-item>
        </ion-list>
        <ion-button expand="block" color="primary" @click.prevent="saveClassroomPrices">Enregistrer</ion-button>
      </div>
    </ion-content>
    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonFooter, IonTitle, IonLabel, IonInput, IonItem, IonHeader, IonList } from "@ionic/vue";

import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import ItemTypeCard from "@/views/components/itemTypes/ItemTypeCard.vue";
export default {
  name: "TypePricesPage",
  components: {
    ItemTypeCard,
    PageHeader,
    PageFooter,
    IonPage,
    IonContent,
    IonFooter,
    IonTitle,
    IonLabel,
    IonInput, IonItem, IonHeader, IonList
  },
  mounted() {
    this.$store.dispatch("school/loadClassrooms").then(() => {
      this.$store.dispatch("itemTypeClassroomPrice/getClassroomPriceForItemType", {
        item_type_uuid: this.$route.params.typeUuid,
        school_uuid: this.$route.params.schoolUuid,
      }).then(() => {
        console.log("Classroom prices loaded")
      })
      console.log("Classrooms loaded")
    })
  },
  computed: {
    module() {
      return this.$store.getters["module/getModule"](
        this.$route.params.moduleUuid
      );
    },
  },
  methods: {
    saveClassroomPrices() {
      this.$store.getters['school/getSchoolClassrooms']({schoolUuid: this.$route.params.schoolUuid}).forEach(classroom => {
        this.$store.dispatch("itemTypeClassroomPrice/saveClassroomPriceForItemType", {
          item_type_uuid: this.$route.params.typeUuid,
          classroom_uuid: classroom.uuid,
        }).then(() => {
          console.log("Classroom prices saved")
        })
      })
    }
  }

}
</script>

<style scoped>

</style>