<template>
  <ion-page>
    <ion-content>
      <form class="login" @keyup.enter.prevent="getToken()"  @submit.prevent="getToken()" >

          <ion-card-header class="title header" >
            <h1><ion-img class="logo-top" src="assets/images/icon-transparent.png"></ion-img> C4K</h1>
          </ion-card-header>

          <ion-item lines="none">
            <h2 class="ion-text-center" >Se connecter</h2>
          </ion-item>

          <ion-item lines="none" >
            <ion-label color="white" class="customlabel" position="floating">Adresse email</ion-label>
            <ion-input v-model="form.username" id="username" required></ion-input>
          </ion-item>

          <ion-item lines="none" >
            <ion-label color="white" class="customlabel" position="floating">Mot de passe</ion-label>
            <ion-input type="password" v-model="form.password" id="password" required></ion-input>
            <router-link class="link-forgetPassword" enterkeyhint="go" to="/reset_password" >Mot de passe oublié?</router-link>
          </ion-item>

          <ion-button ref="loginBtn" type="submit" class="connect-btn" shape="round" expand="full" color="white" enterkeyhint="go" tabindex="0" id="connect-btn">
            Se connecter
          </ion-button>

          <ion-icon class="logo-bottom logo-bottom-insideCard" src="assets/icon/logo-nubeo.svg" ></ion-icon>
      </form>

      <ion-icon class="logo-bottom logo-bottom-outsideCard" src="assets/icon/logo-nubeo.svg" ></ion-icon>

    </ion-content>

  </ion-page>
</template>

<script>
import {IonPage, IonItem, menuController, IonCardHeader, IonLabel, IonButton, IonInput, IonContent, IonIcon, IonImg, alertController} from '@ionic/vue';
import {logIn} from 'ionicons/icons';
import {defineComponent} from 'vue';
import {getAPI, getLogin} from "@/axios-api";

export default defineComponent({
  name: "LoginPage",
  components: {
    IonPage,
    IonItem, IonCardHeader, IonLabel, IonButton, IonInput,
    IonContent, IonIcon, IonImg
  },
  ionViewWillEnter() {
    menuController.enable(false, 'menu');
  },
  setup() {
    return {
      logIn
    }
  },
  mounted() {
    this.loadUser();
    setTimeout(() => {
      document.getElementById('connect-btn').focus()
    }, 500)
  },
  methods: {
    async loadUser() {
      getAPI.get('api/user/', this.form).then((response) => {
        menuController.enable(true, 'menu');
        this.$router.push('/home')
        localStorage.setItem('user', JSON.stringify(response.data.data))
      }).catch(async (error) => {
        if (typeof error.response !== "undefined" && error.response.status > 499) {
          const alertItem = await alertController
              .create({
                message: 'Une erreur serveur est survenue. Veuillez réessayer plus tard.',
                buttons: ['OK']
              })
          return alertItem.present();
        }
      })
    },
    async getToken() {
      if(!this.form.username || !this.form.password){
        this.errorMessage = "Adresse email ou mot de passe invalide !";
          const alertItem = await alertController
          .create({
            header: 'Une erreur est survenue',
            message: this.errorMessage,
            buttons: ['OK']
          })
        return alertItem.present();
      }

      getLogin.post('api-token-auth/', this.form).then((response) => {
        if (typeof response.data.token !== 'undefined' && response.data.token !== null) {
          getAPI.defaults.headers.common['Authorization'] = 'Token ' + response.data.token;
          localStorage.setItem('auth-token', response.data.token)
          getAPI.get('api/user/').then((response) => {
            localStorage.setItem('user', JSON.stringify(response.data.data))
          }).catch(async (error) => {
            if (typeof error.response !== "undefined" && error.response.status > 499) {
              const alertItem = await alertController
                .create({
                  message: 'Une erreur serveur est survenue. Veuillez réessayer plus tard.',
                  buttons: ['OK']
                })
              return alertItem.present();
            }
          }).finally(() => {
            location.replace('/');
          })
        }
      }).catch(async (error) => {
        if (!navigator.onLine) {
          const alertItem = await alertController
              .create({
                message: 'Vous êtes actuellement hors connexion. Veuillez réessayer plus tard.',
                buttons: ['OK']
              })
          return alertItem.present();
        }
        if (typeof error.response !== "undefined") {
          if (error.response.status > 499) {
            const alertItem = await alertController
                .create({
                  message: 'Une erreur serveur est survenue. Veuillez réessayer plus tard.',
                  buttons: ['OK']
                })
            return alertItem.present();
          }
          if (399 < error.response.status < 500) {
            let message = ""
            Object.keys(error.response.data).forEach((item) => {
              message += error.response.data[item] + '\n'
            })
            this.errorMessage = message
            const alertItem = await alertController
                .create({
                  message: this.errorMessage,
                  buttons: ['OK']
                })
            return alertItem.present();
          }
        } else {
            const alertItem = await alertController
                .create({
                  message: 'L\'application n\'est pas accessible pour l\'instant. Veuillez réessayer plus tard.',
                  buttons: ['OK']
                })
            return alertItem.present();
        }
      })
    },
    _doOnKeyUp(event) {
      if (event.keyCode === 13 && event.target.id !== 'connect-btn') {
        const formLogin = document.querySelector('.login');
        event.preventDefault();
        formLogin.submit();
      }
    }
  },
  data() {
    return {
      form: {
        username: null,
        password: null
      },
      errorMessage: ""
    }
  }
});
</script>

<style scoped>

  h1{
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    margin-top: 2rem;
    color: #fff;
  }
  .header h1{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .logo-top{
    max-height: 60px;
    max-width: 60px;
  }

  .login{
    box-shadow: none;
    min-height: 100vh;
    margin: 0 auto;
    padding: 0 1rem;
    max-width: 500px;
    width: 100%;
  }

  .ion-text-center{
    width: 100%;
  }

  .link-forgetPassword{
    color: var(--ion-color-light);
    margin-top: 1rem;
    text-align: right;
    width: 100%;
  }
  .link-forgetPassword:hover, .link-forgetPassword:focus{
    text-decoration: underline;
  }

  .logo-bottom{
    position: relative;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 100px;
    height: 100px;
  }

  .logo-bottom-outsideCard{
    display: none;
  }

  ion-item{
    --ion-item-background: none;
    --ion-item-border-color: rgba(0,0,0,1);
    --padding-start: 0;
    --inner-padding-end: 0;
    --padding-bottom: 5px;
  }
  .customlabel{
    margin-bottom: 1rem;
    color: var(--ion-color-white)
  }

  ion-input{
    border-bottom : 1px solid #fff;
    box-sizing: border-box;
    --padding-start: 0.5rem;
    margin-top: 0.5rem;
    caret-color: var(--ion-color-white-contrast);
  }

  ion-button.connect-btn{
    width: 100%;
    margin: 2rem auto;
    --box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  }

  .errorMessage{
    color: var(--ion-color-danger-tint);
  }

  @media (min-width: 600px) {
    .login{
      background: var(--ion-color-white);
      --color: var(--ion-color-white-contrast);

      width: 500px;
      min-height: inherit;
      max-height: 500px;
      position: absolute;
      top: 50%;
      left:50%;
      transform: translateX(-50%) translateY(-50%);
      margin-inline: 0;
      overflow: visible;
      border-radius: 10px;

      --item-max-width: 350px;
    }

    .customlabel{
      color: var(--ion-color-white-contrast)
    }

    ion-item{
      --ion-item-color: var(--ion-color-white-contrast);
      max-width: var(--item-max-width);
      margin: 0 auto;
    }

    ion-input{
      border-bottom: 1px solid var(--ion-color-white-contrast);
    }

    ion-button{
      --ion-color-base: var(--ion-background-color);
      --ion-color-contrast: var(--ion-color-white);
      max-width: var(--item-max-width);
    }

    ion-button.connect-btn{
      width: 100%;
      --box-shadow: 0 3px 6px rgba(0,0,0,0.16);
      margin: 1rem auto;
    }

    .link-forgetPassword{
      color: var(--ion-color-secondary);
    }
    
    .title h1{
      color: var(--ion-color-white-contrast);
      text-align: center;
    }

    .errorMessage{
      color: var(--ion-color-danger);
    }

    @media (max-height: 600px){
      .login{
        position: relative;
        top:0;
        left:0;
        margin: 0 auto;
        transform: initial;
        min-height: 100vh;
        max-height: initial;
        border-radius: 0;
      }
    }
    @media (min-height: 600px){
      .header{
        position: absolute;
        top: -7rem;
        left: 0;     
      }
      .header h1{
        color: var(--ion-color-white);
      }

      .logo-bottom{
        right: initial;
        left: 50%;
      }

      ion-button.connect-btn{
        margin: 2rem auto;
      }

      .logo-bottom-insideCard{
        display: none;
      }

      .logo-bottom-outsideCard{
        position: absolute;
        display: block;
      }
    }

  }
  @media (max-height: 460px){
    .header{
      margin: 0;
      position: relative;
    }
  }


</style>
