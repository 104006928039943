<template>
    <ion-page>
      <ion-header>
        <page-header>
            <ion-title class="header-link" v-if="itemType && student">
                <router-link enterkeyhint="go" to="/home" replace id="add-classroom-home-link">Mes garderies</router-link> / 
                <router-link enterkeyhint="go" :to="`/nursery/${this.$route.params.schoolUuid}`" replace id="add-classroom-home-link">{{ schoolName }}</router-link> / 
                <router-link enterkeyhint="go" :to="`/nursery/${this.$route.params.schoolUuid}/types/${itemType.uuid}`" replace id="add-classroom-home-link">{{ itemType.name }}</router-link> / 
                {{ student.first_name }} {{ student.last_name }}
            </ion-title>
        </page-header>
      </ion-header>
      <ion-content :fullscreen="true">
        <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
            <ion-refresher-content></ion-refresher-content>
        </ion-refresher>
        <div class="container">
            <ion-card color="light" v-if="itemType && student" >
                <ion-card-header>
                    <ion-card-subtitle>{{itemType.module.name}}</ion-card-subtitle>
                    <ion-card-title>
                        {{itemType.name}}
                    </ion-card-title>
                    <ion-card-title>
                        {{ student.first_name }} {{ student.last_name }}
                    </ion-card-title>
                </ion-card-header>
            </ion-card>
            <form class="form" @submit.prevent="submitForm" >
                <ion-list lines="none" class="ion-no-margin ion-no-padding">
                    <ion-item lines="none">
                        <ion-label position="floating">Date <ion-text color="danger">*</ion-text></ion-label>
                        <ion-datetime presentation="date" size="cover" display-format="DD MMM YYYY" :picker-options="customPickerOptions" required v-model="check.date" :max="today"></ion-datetime>
                    </ion-item>
                    <ion-item lines="none">
                        <ion-label position="floating">Heure <ion-text color="danger">*</ion-text></ion-label>
                        <ion-input type="time" size="cover" presentation="time" :picker-options="customPickerOptions" required v-model="check.time"></ion-input>
                    </ion-item>
                    <ion-button type="submit" shape="round" expand="block" :disabled="loading">Valider <ion-spinner name="lines-sharp-small" v-if="loading"></ion-spinner></ion-button>
                </ion-list>
            </form>
        </div>
      </ion-content>
      <ion-footer>
        <page-footer></page-footer>
      </ion-footer>
    </ion-page>
  </template>
  
  <script>
  import {IonPage, IonContent, IonFooter, IonHeader, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonRefresher, IonRefresherContent,
    IonList, IonItem, IonLabel, IonDatetime, IonButton, IonInput, toastController } from '@ionic/vue';
  import PageHeader from "@/views/components/PageHeader";
  import PageFooter from "@/views/components/PageFooter";
  import {defineComponent} from 'vue';
  import {NURSERY_MODULE_SLUG, basicAlertMsg} from "@/utils"
  import moment from "moment"
  import { getAPI } from "@/axios-api";
  
  export default defineComponent({
    name: "NurseryFormCheck",
    components: {
      IonPage, IonContent, IonFooter, IonHeader, IonRefresher, IonRefresherContent,
      IonList, IonItem, IonLabel, IonDatetime, IonButton, IonInput,
      IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle,
      PageHeader, PageFooter
    },
    data() {
        return  {
            check: {
                date: null,
                time: null,
            },
            today: moment().format('YYYY-MM-DD'),
            loading: false
        }
    },
    setup() {
      const customPickerOptions = {
        cssClass: 'custom-options'
      }
      return {
        NURSERY_MODULE_SLUG, customPickerOptions, basicAlertMsg
      }
    },
    methods: {
        doRefresh(event = null){
            this.$store.dispatch('school/reloadModuleItemTypes').then(()=>{
                this.$store.dispatch('school/reloadSchoolStudents').then(()=>{
                    if (event !== null) {
                        event.detail.complete();
                    }
                })
            }) 
        },
        submitForm() {
            // Au submit du formulaire, on fait une requête api avec la date enregistrer pour créer une réservation pour cet élève
            this.loading = true
            if (this.check.date > this.today){
                this.basicAlertMsg("Vous ne pouvez pas valider une future garderie.");
                this.loading = false
                return
            }
            const date = { 'check_date': moment(`${this.check.date} ${this.check.time}`).format() };
            getAPI.post('/nursery/type/' + this.itemType.uuid + '/student/' + this.student.uuid + '/' + this.checkType, date)
            .then(async () => {
                this.message = 'Garderie enregistrée avec succès.';
                this.$router.push(`/nursery/${this.$route.params.schoolUuid}/types/${this.itemType.uuid}`);
                const toast = await toastController.create({
                    message: this.message,
                    duration: 2000
                });
                this.loading = false
                return toast.present();
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 404) {
                        this.message = 'Pas de garderie disponible.';
                    } else if(error.response.status === 422) {
                        this.message = 'Une garderie a déjà été enregistrée pour cet élève à cette heure-ci.';
                    } else {
                        this.message = 'Une erreur s\'est produite.';
                    }
                    this.loading = false
                    this.basicAlertMsg(this.message);
                }
            });
        }
    },
    computed: {
        itemType() {
            return this.$store.getters["itemType/getItemType"](this.$route.params.typeUuid);
        },
        student() {
            return this.$store.getters['school/currentStudent']
        },
        schoolName() {
            return this.$store.getters["school/getSchoolName"];
        },
        checkType() {
            if (this.$route.path.includes('check-out')){
                return 'check-out'
            }
            return "check-in"
        },
    },
    mounted(){
        this.check.date = moment().format('YYYY-MM-DD')
        this.check.time = moment().format('HH:mm')
        this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid).then(()=>{
            this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid}).then(()=>{
                this.$store.dispatch('school/loadModules', {schoolUuid: this.$route.params.schoolUuid}).then(()=>{
                    const module_uuid = this.$store.getters["module/getModuleBySlug"](this.NURSERY_MODULE_SLUG).uuid
                    this.$store.dispatch('school/navigateToModule', module_uuid).then(()=>{
                        this.$store.dispatch('school/loadModuleItemTypes')
                        this.$store.dispatch('school/loadStudents').then(()=>{
                            this.$store.dispatch('school/navigateToStudent', this.$route.params.studentUuid)
                        })
                    })
                })
            })
        })
    }
  })
  </script>
  
  <style scoped>
  
  </style>