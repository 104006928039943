<template>
  <ion-page>
    <page-header>
       <ion-title class="header-link" >
        <router-link enterkeyhint="go" to="/home" replace>Mes classes</router-link> / 
        Présences
      </ion-title>
    </page-header>
    <ion-content :fullscreen="true">
      <div class="container" >
        <ion-card color="light" >
          <ion-card-header>
            <ion-card-title>Imprimer les présences</ion-card-title>
            <ion-card-subtitle>Choisissez les critères pour l'impression</ion-card-subtitle>
          </ion-card-header>
        </ion-card>
        <form class="form" onSubmit={this.processForm}>
          <ion-list lines="none" class="ion-no-margin ion-no-padding">
            <ion-item class="hiddenForDesktop" >
              <ion-label position="stacked">Date <ion-text color="danger">*</ion-text></ion-label>
              <ion-datetime :picker-options="customPickerOptions"  min="1990-02" max="2100" display-format="DD MMM YYYY" required v-model="recapWizard.date"></ion-datetime>
            </ion-item>
            <DatePicker :attributes="calendarAttrs" is-expanded class="hiddenForMobile" :masks="masks" v-model="recapWizard.date" mode="date">
              <template v-slot="{ inputValue, inputEvents }">
                <ion-label position="stacked">Date <ion-text color="danger">*</ion-text></ion-label>
                <ion-input
                  :value="inputValue"
                  v-on="inputEvents"
                  readonly
                ></ion-input>
              </template>
            </DatePicker >
            <ion-item lines="none" >
              <ion-label position="stacked">École
                <ion-text color="danger">*</ion-text>
              </ion-label>
              <ion-select multiple v-model="recapWizard.schools" interface="alert">
                <ion-select-option v-for="(school, key) in schools" :key="key" :value="school.uuid">
                  {{ school.name }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-list>
          <ion-button shape="round" expand="block" @click.prevent="doGenerateRecap">Imprimer</ion-button>
        </form>
      </div>
    </ion-content>
    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonContent, IonPage, IonList, IonItem, alertController, IonFooter,
  IonLabel, IonText, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, 
  IonButton, IonDatetime, IonSelect, IonSelectOption, IonTitle, IonInput
} from '@ionic/vue';
import {copyOutline} from 'ionicons/icons';
import {defineComponent} from 'vue';
import {getAPI} from "@/axios-api";
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {DatePicker } from 'v-calendar';
import moment from 'moment'

export default defineComponent({
  name: "AttendanceRecap",
  components: {
    IonPage,
    IonContent, IonList, IonItem, PageHeader, IonFooter, PageFooter,
    IonLabel, IonText, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, 
    IonButton, IonDatetime, IonSelect, IonSelectOption, DatePicker, IonTitle, IonInput
  },
  data() {
    return {
      schools: null,
      recapWizard: {},
      masks: {
        input: 'DD MMM YYYY',
      },
      calendarAttrs: [
        {
          key: 'today',
          bar: {
            color: 'gray',
          },
          dates: new Date(),
        },
      ],
    }
  },
  mounted() {
    getAPI.get('meals/attendance/recap/data').then((response) => {
      const data = response.data.data
      this.schools = data.schools;
      this.recapWizard = {
        date: data.date,
        schools: this.schools.map((school) => school.id),
      }
    })
  },
  methods: {
    doGenerateRecap() {
      getAPI.post('meals/attendance/recap/generate', this.recapWizard).then(async (response) => {
        let alertMsg = {}
        const data = response.data.data
        if(data.result === 'empty') {
          alertMsg = {
            header: 'Pas de données à imprimer :',
            message: "Il n'y a pas de données à imprimer pour cette date.",
            buttons: ['OK'],
          }
        }
        if (data.result === 'ok') {
          alertMsg = {
            header: 'Récapitulatif des présences envoyé :',
            message: 'Le récapitulatif des présences du ' + moment(this.recapWizard.date).format("dddd DD MMM YYYY") + ' vous est envoyé par email. Merci.',
            buttons: ['OK'],
          }
          this.$router.back();
        }
        const alertItem = await alertController.create(alertMsg);
        return alertItem.present();
      }).catch(async() => {
        const alertItem = await alertController
          .create({
            header: 'Une erreur est survenue',
            message: 'Veuillez réessayer plus tard',
            buttons: ['OK']
          })
        return alertItem.present();
      });
    }
  },
  setup() {
    const customPickerOptions = {
      cssClass: 'custom-options'
    }
    return {
      copyOutline, customPickerOptions
    }
  },
})
</script>

<style scoped>
  ion-button{
    margin: 1rem auto;
  }
  .form ion-list{
    display: initial;
  }

  .form ion-input{
    box-shadow: none;
  }
</style>
