<template>
  <ion-page>
    <ion-header :translucent="true">
      <page-header>
        <ion-title class="header-link" id="school-greeters-ariane">
          <router-link enterkeyhint="go" to="/home" replace id="school-greeters-home-link">Mes écoles</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}`" replace id="school-greeters-school-link">{{schoolName}}</router-link> /
          Accueillants
        </ion-title>
      </page-header>
    </ion-header>

    <ion-content :fullscreen="true">

      <ion-refresher slot="fixed" @ionRefresh="refreshData($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <ion-fab vertical="bottom" horizontal="end" slot="fixed" id="add-greeter">
        <ion-fab-button @click.prevent="goAddGreeter">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <div v-if="loading">
          <p style="text-align: center">
            Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
          </p>
        </div>
      <div class="container" v-else >
        <ion-searchbar color="light" v-model="search" id="school-greeters-searchbar"></ion-searchbar>
        <ion-list class="listPadding" >
          <ion-item 
            color="white" 
            v-for="(greeter, kGreeter) in greeters.sort((a,b)=>sortingName(a,b,['user.last_name', 'user.first_name']))" 
            :key="kGreeter" 
            :v-bind="'greeter-'+greeter.id"
            :router-link="'/school/' + $route.params.schoolUuid + '/greeters/' + greeter.uuid"
            >
            <ion-label>
              <h2>{{ greeter.user.first_name }} {{ greeter.user.last_name }}</h2>
              <h3>{{ greeter.user.email }}</h3>
            </ion-label>
            <ion-button class="btn-delete" @click.prevent="confirmDelete(greeter)" color="white" fill="solid" size="small">
              <ion-icon src="assets/icon/trash-solid.svg"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import {
  IonPage, IonContent, IonIcon, IonSearchbar, alertController, IonFooter, IonList, IonSpinner,
  IonItem, IonButton, IonLabel, IonFab, IonFabButton, IonHeader, IonTitle, IonRefresher, IonRefresherContent,
} from '@ionic/vue';
import {defineComponent} from 'vue';
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {add, trash} from "ionicons/icons";
import {doDeleteElement, sortingName} from '@/utils';

export default defineComponent({
  name: "SchoolGreeters",
  components: {
    IonContent,
    IonPage,
    IonIcon, IonSearchbar,IonFooter, IonList, IonTitle,
    IonItem, IonButton, IonLabel, IonFab, IonFabButton, IonHeader,
    PageHeader, PageFooter, IonRefresher, IonRefresherContent, IonSpinner
  },
  data() {
    return {
      schoolUuid: null,
      search: ''
    }
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    greeters(){
      return this.$store.getters["school/getSchoolGreeters"](this.search)
    },
    loading(){
      if (this.greeters && this.schoolName){
        return false
      }
      return true
    }
  },
  methods: {
    refreshData(event){
      this.$store.dispatch('school/reloadSchoolGreeters').then(()=>{
        event.target.complete()
      }) 
    },
    goAddGreeter() {
      this.$router.push('/school/' + this.schoolUuid + '/add_greeter')
    },
    goToGreeterEdit(greeter){
      this.$router.push('/school/'+ this.schoolUuid + '/greeters/' + greeter.uuid + '/edit' )
    },
    async confirmDelete(greeter) {
      const alertMsg = {
        message: "Voulez vous vraiment supprimer la classe " + greeter.user.first_name + " " + greeter.user.last_name  + " ? ",
        buttons: [
          {
            text: "Retour",
            role: "cancel"
          },
          {
            text: "Continuer",
            cssClass: "btn-danger",
            handler: ()=> {
              this.deleteGreeter('schools/greeter', greeter)
            }
          }
        ]
      }
      const alertItem = await alertController.create(alertMsg);
      return alertItem.present();
    },
    deleteGreeter(model, object) {
      doDeleteElement(model, object, object.user.first_name + ' ' + object.user.last_name, true).then(() => {
        this.$store.dispatch('school/removeGreeter',object.uuid)
      }).catch(async () => {
        const alertItem = await alertController
            .create({
              header: 'Erreur lors de la suppression :',
              message: 'Cet objet ne peut pas être supprimé !!',
              buttons: ['OK']
            })
        return alertItem.present();
      })
    }
  },
  setup() {
    return {
      add, trash, sortingName
    }
  },
  ionViewWillEnter() {
    this.schoolUuid = this.$route.params.schoolUuid
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid)
    this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid})
    this.$store.dispatch('school/loadGreeters')
  },
});
</script>

<style scoped>
  ion-item:hover, ion-item:focus{
    --ion-color-base: var(--ion-color-light);
    cursor: pointer;
  }
  .btn-delete{
    --box-shadow: none;
  }

</style>
