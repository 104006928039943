<template>
  <ion-page>

    <ion-header>
      <page-header>
        <ion-title class="header-link" id="school-ariane">
          <router-link enterkeyhint="go" to="/home" replace>Mes écoles</router-link> / 
          {{schoolName}}
        </ion-title>
      </page-header>
    </ion-header>

    <ion-content :fullscreen="true">

      <div class="container" >

        <ion-card>
          <ion-card-header color="white" >
            <ion-card-title class="title--medium" id="school-name">{{schoolName}}</ion-card-title>
          </ion-card-header>
        </ion-card>

        <section style="padding: 0 10px">
          <div v-if="$store.getters['home/isDirector']($route.params.schoolUuid)">
            <h3>L'école</h3>
            <ion-button :router-link="'/school/' + $route.params.schoolUuid + '/students'" router-direction="forward" expand="block" id="students">Élèves</ion-button>
            <ion-button :router-link="'/school/' + $route.params.schoolUuid + '/tutors'" router-direction="forward" expand="block" id="legal-tutors">Tuteurs légaux</ion-button>
            <ion-button :router-link="'/school/' + $route.params.schoolUuid + '/teachers'" router-direction="forward" expand="block" id="teachers">Professeurs</ion-button>
            <ion-button :router-link="'/school/' + $route.params.schoolUuid + '/classrooms'" router-direction="forward" expand="block" id="classrooms">Classes</ion-button>
            <ion-button :router-link="'/school/' + $route.params.schoolUuid + '/greeters'" router-direction="forward" expand="block" id="greeters">Accueillant(e)s</ion-button>
          </div>
          <div v-if="Object.keys(modules).length > 0">
            <h3>Modules</h3>
            <ion-button
                v-for="(module, kModule) in Object.values(modules).filter((module)=>module.slug_name != 'nursery-module')"
                :key="kModule"
                @click="goToModule(module)"
                :router-link="'/school/' + $route.params.schoolUuid + '/module/' + module.uuid"
                router-direction="forward"
                expand="block"
                id="students">
              {{module.name}}
            </ion-button>
          </div>
          <div v-if="$store.getters['home/isDirector']($route.params.schoolUuid)">
            <ion-item class="btn-import" lines="none" >
              <input ref="inputFile" class="input-file" id="file" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
            </ion-item>
            <ion-item hidden>
              <ion-label>Pré-vérifier les données</ion-label>
              <ion-checkbox :modelValue="verifyImport" @update:modelValue="verifyImport = $event" color="primary" slot="start"></ion-checkbox>
            </ion-item>
            <ion-button @click.prevent="controlImport" expand="block" id="import-data">Importer les données<ion-icon slot="start" src="assets/icon/file-arrow-up-solid.svg" ></ion-icon></ion-button>
            <ion-button @click.prevent="exportData" expand="block" id="export-data">Télécharger les données<ion-icon slot="start" src="assets/icon/file-download-solid.svg" ></ion-icon></ion-button>
          </div>
        </section>

      </div>

    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import { IonContent, IonCheckbox, IonHeader, IonFooter, IonPage, IonButton, IonCard, IonCardHeader, IonCardTitle, IonTitle, alertController, IonItem, IonIcon, IonLabel } from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import XLSX from "xlsx"
import {getAPI} from "@/axios-api";

export default defineComponent({
  name: 'SchoolPage',
  components: {
    IonPage, IonCheckbox,
    IonContent, IonButton, IonCard, IonCardHeader, IonCardTitle, IonLabel,
    PageHeader, IonHeader, PageFooter, IonFooter, IonTitle, IonItem, IonIcon
  },
  data() {
    return  {
      school: null
    }
  },
  ionViewWillEnter (){
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid)
    this.$store.dispatch('school/loadModules', {schoolUuid: this.$route.params.schoolUuid})
  },
  setup () {
    return {
      copyOutline,
      verifyImport: false
    }
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"] || this.$store.getters["school/getSchoolNameByUuid"](this.$route.params.schoolUuid)
    },
    modules(){
      return this.$store.getters["school/getSchoolModules"]
    }
  },
  methods:{
    goToModule(module){
      this.$store.dispatch('school/navigateToModule', module.uuid).then(() => {
        this.$router.push({
          name: 'AdminModuleIndex',
          params: {
            schoolUuid: this.$route.params.schoolUuid,
            moduleUuid: module.uuid
          }
        })
      })
    },
    async erreurhandler(msg){
      const alertMsg = {
        header: 'Une erreur est survenue',
        message: msg,
        buttons: ['OK'],
      }
      const alertItem = await alertController.create(alertMsg);
      return alertItem.present();
    },
    async controlImport(){
      //https://medium.com/swlh/how-to-convert-excel-data-into-json-object-using-javascript-1c4e0d3e97ee
      const selectedFile = this.$refs.inputFile.files[0]
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, {type: 'binary'});
        let filePassed = true
        if (this.verifyImport) {
          workbook.SheetNames.forEach((sheetName) => {

            const results = {[sheetName] : XLSX.utils.sheet_to_json(workbook.Sheets[sheetName])}
            const keyName = Object.keys(results)

            let headers = []

            if(keyName[0] === "Classe"){
              headers = ["Nom classe","Prix repas"]
            }else if(keyName[0] === "Elèves"){
              headers = ["Nom","Prénom","NISS","Classe"]
            }else if(keyName[0] === "Prof" || keyName[0] === "Accueillant" ){
              headers = ["Email"]
            }

            for (let j = 0; j < results[keyName[0]].length; j++) {
              const row = results[keyName[0]][j];

              const col = Object.keys(row)

              for (let i = 0; i < headers.length; i++) {
                if(typeof col[i] !== "undefined" && col[i].replace(" ", "").toLowerCase() !== headers[i].replace(" ", "").toLowerCase()){
                  this.erreurhandler(`Erreur page ${keyName[0]} ligne ${j+1}: la colonne "${headers[i]}" ne peut pas être vide `)
                  filePassed = false
                  return;
                }
                if(typeof col[i] !== "undefined" && col[i].replace(" ", "").toLowerCase() === "niss"){
                  const nationalNumberPattern = new RegExp(/([0-9]{6}-[0-9]{3}-[0-9]{2})/g)
                  if(!nationalNumberPattern.test(row.NISS)){
                    this.erreurhandler(`Erreur page Éléves ligne ${j+1}: Le code NISS devrait être au format: xxxxxx-xxx-xx `)
                    filePassed = false
                    return;
                  }
                }
              }
            }
          })
        }
        if(filePassed || !this.verifyImport){
          const formData = new FormData();
          formData.append('schoolUuid', this.$route.params.schoolUuid);
          formData.append('xlsx', selectedFile);
          getAPI.post('/schools/imports', formData, {
            headers: {'Content-Type': 'multipart/form-data'}
          }).then(async () => {
            const alertMsg = {
              message: 'Les données sont en cours d\'importation. Vous recevrez un email lorsque celles-ci seront importées.',
              buttons: ['OK'],
            }
            this.$refs.inputFile.value = null
            const alertItem = await alertController.create(alertMsg);
            return alertItem.present();
          }).catch(async () => {
            this.erreurhandler(`Erreur serveur, veuillez réessayer plus tard`)
          })
        }
      };

      reader.onerror = () => {
        this.erreurhandler(`Impossible de lire le fichier, vérifiez que le format est bien en .xlsx`)
      };
      reader.readAsBinaryString(selectedFile);
    },
    exportData(){
      const formData = new FormData();
      formData.append('schoolUuid', this.$route.params.schoolUuid);
      getAPI.post('/schools/exports', formData).then(async () => {
        const alertMsg = {
          message: 'Les données sont en cours d\'exportation. Vous recevrez un email lorsque celles-ci seront exportées.',
          buttons: ['OK'],
        }
        const alertItem = await alertController.create(alertMsg);
        return alertItem.present();
      }).catch(async () => {
        this.erreurhandler(`Erreur serveur, veuillez réessayer plus tard`)
      })
    }
  }
});
</script>

<style scoped>

  ion-card{
    box-shadow: none;
  }

  .container{
    padding: 1rem 0;
  }

  .btn-import{
    margin-top: 3rem;
  }

  @media (min-width:600px) {
    ion-card-header{
      padding: 1rem 0;
    }

    .title--medium{
      font-size: 24px;
      text-align: center;
    }
  }

</style>
