<template>
  <ion-page>
    <page-header></page-header>
    <ion-content :fullscreen="true">
      <div class="container" >
        <ion-card color="light" >
          <ion-card-header>
            <ion-card-title> Récapitulatif des soldes</ion-card-title>
            <ion-card-subtitle>Choisissez les critères pour le récapitulatif</ion-card-subtitle>
          </ion-card-header>
        </ion-card>
        <form class="form" onSubmit={this.processForm}>
          <ion-list lines="none" class="ion-no-margin ion-no-padding">
            <ion-item class="hiddenForDesktop" >
              <ion-label position="floating">Date <ion-text color="danger">*</ion-text></ion-label>
              <ion-datetime :picker-options="customPickerOptions"  min="1990-02" max="2100" display-format="DD MMM YYYY" required v-model="recapWizard.date"></ion-datetime>
            </ion-item>
            <DatePicker :attributes="calendarAttrs" is-expanded class="hiddenForMobile" :masks="masks" v-model="recapWizard.date" mode="date">
              <template v-slot="{ inputValue, inputEvents }">
                <ion-label position="floating">Date <ion-text color="danger">*</ion-text></ion-label>
                <ion-input
                  :value="inputValue"
                  v-on="inputEvents"
                  readonly
                ></ion-input>
              </template>
            </DatePicker >
            <ion-item lines="none" >
              <ion-label position="stacked">École
                <ion-text color="danger">*</ion-text>
              </ion-label>
              <ion-select multiple v-model="recapWizard.schools" interface="alert" @ionChange="onchangeSchools()">
                <ion-select-option v-for="(school, key) in schools" :key="key" :value="school.uuid">
                  {{ school.name }}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item lines="none" >
              <ion-label position="stacked">Classe
                <ion-text color="danger">*</ion-text>
              </ion-label>
              <ion-select multiple v-model="recapWizard.classrooms" interface="alert">
                <ion-select-option v-for="(classroom, key) in filteredClassrooms" :key="key" :value="classroom.id">
                  {{ classroom.name }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-list>
            <ion-button shape="round" expand="block" @click.prevent="doGenerateRecap">Imprimer</ion-button>
        </form>
      </div>
    </ion-content>
    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonContent, IonPage, IonList, IonItem, alertController, IonFooter, IonSelectOption, IonInput,
  IonLabel, IonText, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonButton, IonDatetime, IonSelect,
} from '@ionic/vue';
import {copyOutline} from 'ionicons/icons';
import {defineComponent} from 'vue';
import {getAPI} from "@/axios-api";
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {DatePicker } from 'v-calendar';


export default defineComponent({
  name: "PaymentRecap",
  components: {
    IonPage,
    IonContent, IonList, IonItem, PageHeader, IonFooter, PageFooter, IonSelectOption,
    IonLabel, IonText, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle,
    IonButton, IonDatetime, IonSelect, DatePicker, IonInput
  },
  data() {
    return {
      schools: null,
      classrooms: null,
      filteredClassrooms: null,
      recapWizard: {},
      masks: {
        input: 'DD MMM YYYY',
      },
      calendarAttrs: [
        {
          key: 'today',
          bar: {
            color: 'gray',
          },
          dates: new Date(),
        },
      ],
    }
  },
  mounted() {
    getAPI.get('payments/recap/data').then((response) => {
      const data = response.data.data
      this.classrooms = data.classrooms;
      this.schools = data.schools;
      this.recapWizard = {
        date: data.date,
        schools: this.schools.map((school) => school.uuid),
        classrooms: this.classrooms.map((classroom) => classroom.id),
      }
    })
  },
  methods: {
    onchangeSchools() {
      this.recapWizard.classrooms = [];
      this.filteredClassrooms = [];
      this.classrooms.forEach((classroom) => {
        if (this.recapWizard.schools.includes(classroom.school.uuid)) {
          this.filteredClassrooms.push(classroom);
          this.recapWizard.classrooms.push(classroom.id);
        }
      })
    },
    doGenerateRecap() {
      this.recapWizard.currentHours = new Date().toLocaleTimeString();
      getAPI.post('payments/recap/generate', this.recapWizard).then(async () => {
        this.$router.back();
        const alertItem = await alertController.create({
          header: 'Récapitulatif des soldes envoyé',
          message: 'Le récapitulatif des soldes vous est envoyé par email. Merci.',
          buttons: ['OK'],
        });
        return alertItem.present();
      }).catch(async() => {
        const alertItem = await alertController.create({
          message: 'Une erreur s\'est produite, veuillez réessayer plus tard.',
          buttons: ['OK'],
        });
        return alertItem.present();
      });
    }
  },
  setup() {
    const customPickerOptions = {
      cssClass: 'custom-options'
    }
    return {
      copyOutline, customPickerOptions
    }
  },
})
</script>

<style scoped>
  ion-button{
    margin: 1rem auto;
  }
  .form ion-list{
    display: initial;
  }

  .form ion-input{
    box-shadow: none;
  }
</style>
