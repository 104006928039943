<template>
  <ion-modal ref="addTutorModal" :is-open="isOpenAddTutor" @onIonModalDidDismiss="isOpenAddTutor = false">
    <ion-page>
      <ion-header>
        <ion-toolbar color="primary" >
          <ion-title>
            Sélectionnez un tuteur
          </ion-title>
          <ion-buttons slot="end">
            <ion-button @click.prevent="closeModal" id="close-select-legal-tutor">Fermer</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content :fullscreen="true">

        <ion-searchbar color="light" v-model="searchTutorInput"></ion-searchbar>

        <ion-list>
          <ion-item
            class="itemWithHover"
            color="white"
            v-for="(tutor, kTT) in legalTutors.sort((a,b)=>sortingName(a,b,['user.last_name', 'user.first_name']))"
            :key="kTT"
            :v-bind="'select-legal-tutor-'+tutor.uuid"
            @click.prevent="doAddTutor(tutor)"
          >
            <ion-label>
              <h2>{{tutor.user.first_name}} {{tutor.user.last_name}}</h2>
              <h3>{{tutor.user.email}}</h3>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-page>
  </ion-modal>

  <ion-button color="primary" slot="start" class="btn-addTutor" @click.prevent="openTutorModal" size="small" fill="solid" id="select-legal-tutor-button">
    Ajouter un tuteur <ion-icon :icon="add"></ion-icon>
  </ion-button>

</template>

<script>
import { defineComponent } from 'vue'
import {sortingName} from '@/utils';
import {
  IonModal,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonSearchbar,
  IonList,
  IonItem,
  IonLabel,
  IonIcon
} from '@ionic/vue';
 '@ionic/vue';
 import {add} from 'ionicons/icons';

export default defineComponent({
  name:"ModalAddTutor",
  props:["currentStudent"],
  emits:['addTutor'],
  components:{
    IonModal,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonSearchbar,
    IonList,
    IonItem,
    IonLabel,
    IonIcon
  },
  data(){
    return {
      student: this.currentStudent,
      isOpenAddTutor: false,
      searchTutorInput: ""
    }
  },
  setup(){
    return {
      sortingName, add
    }
  },
  computed:{
    legalTutors(){
      let tutors = this.$store.getters['school/getSchoolLegalTutors'](this.searchTutorInput)
      const selectedTutors = Object.keys(this.currentStudent.legal_tutors)?.map(tutor => tutor.uuid)
      tutors = tutors.filter(tutor => !selectedTutors.includes(tutor.uuid))
      return tutors
    }
  },
  methods:{
    openTutorModal() {
      this.isOpenAddTutor = true
    },
    closeModal() {
      this.isOpenAddTutor = false
    },
    doAddTutor(tutor){
      this.isOpenAddTutor = false
      this.$emit('addTutor',tutor)
    }
  }
})
</script>

<style scoped>
  .btn-addTutor{
    margin-bottom: 1rem;
  }
  .btn-tuteur ion-icon{
    margin-left: 1rem;
  }
  .itemWithHover:hover, .itemWithHover:focus{
    --ion-color-base: var(--ion-color-light);
    cursor: pointer;
  }
</style>
