<template>
  <ion-page>
    <page-header>
      <ion-title class="header-link" v-if="student">
        <router-link enterkeyhint="go" to="/home" replace>
          <span v-if="isLegalTutor">Mes enfants</span>
          <span v-else-if="isTeacher">Mes classes</span>
        </router-link> / 
        <router-link enterkeyhint="go" :to="`/student/${student.uuid}`" replace>
          {{student.first_name}} {{student.last_name}}
        </router-link> / Transactions
      </ion-title>
    </page-header>
    <ion-content>
      <div v-if="!student">
        <p style="text-align: center">
          Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
        </p>
      </div>
      <div v-else class="container" >
        <student-header></student-header>
        <transactions-list v-if="student && student.wallet"></transactions-list>
      </div>
    </ion-content>
    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>
  </ion-page>
</template>

<script>
import {IonPage, IonContent, IonFooter, IonSpinner, IonTitle} from "@ionic/vue";
import PageHeader from "@/views/components/PageHeader";
import pageFooter from "@/views/components/PageFooter";
import TransactionsList from "@/views/components/students/TransactionsList";
import {mapActions, mapGetters} from 'vuex'
import StudentHeader from "@/views/components/students/StudentHeader";
export default {
  name: "TransactionsPage",
  components: {
    TransactionsList,
    PageHeader,
    IonPage,
    pageFooter,
    IonContent,
    IonFooter,
    StudentHeader,
    IonSpinner, IonTitle
  },
  mounted(){
    this.setCurrentStudent({student_uuid: this.$route.params.studentUuid}).then(() => {
      this.syncCompleteStudent({student_uuid:  this.$route.params.studentUuid})
    });
  },
  methods: {
    ...mapActions('students', [
      'syncCompleteStudent',
      'setCurrentStudent',
      'setCurrentModule'
    ]),
  },
  computed:{
    ...mapGetters('students', {
      student:'currentStudent',
      module:'currentModule',
    }),
    isLegalTutor(){
      return this.$store.getters['home/isLegalTutor'](this.$route.params.studentUuid)
    },
    isTeacher(){
      return this.$store.getters['home/isTeacher'](this.student.classroom.uuid)
    }
  }
}
</script>

<style scoped>

</style>