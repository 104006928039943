import {getAPI} from "@/axios-api";

const state = () => ({
    greeters: {},
    currentGreeterUuid: null
})

const mutations = {
    setCurrentGreeterUuid(state: any, greeterUuid: string){
        state.currentGreeterUuid = greeterUuid
    },
    setGreeterUser(state:any, {data, currentSchoolId}:any){
        state.greeters[state.currentGreeterUuid] = {greeter : {user : data}}
        const isOrderManager = data.orderManager.filter((manager:any) => manager.school == currentSchoolId)
        if(isOrderManager.length){
            state.greeters[state.currentGreeterUuid].greeter.user.checkOrderManager = true
        }
    },
    removeGreeters(state:any){
        delete state.greeters[state.currentGreeterUuid]
    },
    updateGreeterUser(state: any, data: any){
        state.greeters[state.currentGreeterUuid].greeter.user = data
    },
}

const getters = {
    getGreeter (state:any){
        return state.greeters[state.currentGreeterUuid]
    },
    getGreeterUser (state:any){
        const greeter = state.greeters[state.currentGreeterUuid]
        return greeter && greeter.greeter.user
    }
}

const actions = {
    navigateToGreeter({ commit,state }: any, greeterUuid: string){
        if(state.currentGreeterUuid != greeterUuid){
            commit("setCurrentGreeterUuid", greeterUuid)
        }
    },
    async loadUserGreeter({ commit, state, rootState }: any){
        // Check if the current greeter uuid is already loaded in state.greeters
        if(!state.greeters[state.currentGreeterUuid]){
            await getAPI.get('api/user/greeter/' + state.currentGreeterUuid).then((response: any) => {
                const schoolId = rootState.school.schools[rootState.school.currentSchoolUuid].id
                commit("setGreeterUser", {data:response.data.data,currentSchoolId:schoolId})
            })
        }
    },
    async reloadGreeter({commit, dispatch}:any){
        commit('removeGreeters')
        await dispatch('loadUserGreeter')
    },
    updateGreeterUser({commit}:any, data:any){
        commit('updateGreeterUser',data)
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}