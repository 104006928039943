<template>
  <ion-page>
    <page-header>
      <ion-title v-if="township" class="header-link" >
        <router-link enterkeyhint="go" to="/home" replace>Mes communes</router-link> / 
        {{township.name}}
      </ion-title>
    </page-header>

    <ion-content :fullscreen="true" v-if="township">
      <div class="container" >

        <ion-card color="white" >
          <ion-card-header>
            <ion-card-title>{{ township.name }}</ion-card-title>
          </ion-card-header>
        </ion-card>

        <section style="padding: 0 10px">
          <h3>Menus des écoles</h3>
          <ion-button :router-link="'/townships/' + townshipUuid + '/add_menus'" router-direction="forward" expand="block" id="add-menu">Ajouter des menus</ion-button>
          <ion-button :router-link="'/townships/' + townshipUuid + '/manage_menus'" router-direction="forward" expand="block" id="manage-menu">Gérer les menus</ion-button>
          <ion-button hidden :router-link="'/townships/' + townshipUuid + '/reservation_recap'" router-direction="forward" expand="block">Télécharger les réservations</ion-button>
        </section>

      </div>

    </ion-content>

     <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton, IonFooter, IonCard, IonCardHeader, IonCardTitle, IonTitle } from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";

export default defineComponent({
  name: "ViewTownship",
  components: {
    PageHeader, PageFooter,
    IonPage, IonFooter, IonContent, IonButton, IonCard, IonCardHeader, IonCardTitle, IonTitle
  },
  data(){
    return{
      townshipUuid: null
    }
  },
  computed:{
    township(){
      return this.$store.getters["township/getTownship"]
    }
  },
  mounted() {
    this.townshipUuid = this.$route.params.townshipUuid
    this.$store.dispatch('township/navigateToTownship', this.townshipUuid)
    this.$store.dispatch('township/loadTownship')
  },
  setup () {
    return {
      copyOutline,
    }
  },
});
</script>

<style scoped>

</style>
