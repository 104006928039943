<template>
  <ion-page>
    <ion-header :translucent="true">
      <page-header>
        <ion-title class="header-link" id="school-teachers-ariane">
          <router-link enterkeyhint="go" to="/home" replace id="school-teachers-home-link">Mes écoles</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}`" replace id="school-teachers-school-link">{{schoolName}}</router-link> /
          Professeurs
        </ion-title>
      </page-header>
    </ion-header>
    <ion-content :fullscreen="true">

      <ion-refresher slot="fixed" @ionRefresh="refreshData($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <ion-fab vertical="bottom" horizontal="end" slot="fixed" id="add-teacher">
        <ion-fab-button @click.prevent="goAddTeacher">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>

      <div class="container" >
        <ion-searchbar color="light" v-model="search" id="school-teachers-searchbar"></ion-searchbar>
        <div v-if="loading">
          <p style="text-align: center">
            Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
          </p>
        </div>
        <ion-list v-else class="listPadding" >
          <ion-item 
            color="white" 
            v-for="(teacher, kStudent) in teachers.sort((a,b)=>sortingName(a,b,['user.last_name','user.first_name']))" 
            :key="kStudent" 
            :v-bind="'teacher-'+teacher.id"
            :router-link="'/school/' + $route.params.schoolUuid + '/teachers/' + teacher.uuid"
          >
            <ion-label>
              <h2>{{ teacher.user.first_name }} {{ teacher.user.last_name }}</h2>
              <h3>{{ teacher.user.email }}</h3>
            </ion-label>
            <ion-button class="btn-delete" @click.prevent="confirmDelete(teacher)" color="white" fill="solid" size="small">
              <ion-icon src="assets/icon/trash-solid.svg"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import {IonPage, IonContent, IonIcon, IonSearchbar, alertController, IonRefresher, IonRefresherContent,
IonFooter, IonList, IonItem, IonButton, IonLabel, IonFab, IonFabButton, IonHeader, IonTitle, IonSpinner } from '@ionic/vue';
import {defineComponent} from 'vue';
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {add, trash} from "ionicons/icons";
import {doDeleteElement, sortingName} from '@/utils';

export default defineComponent({
  name: "SchoolTeachers",
  components: {
    IonContent,
    IonPage, IonRefresher, IonRefresherContent,
    IonIcon, IonSearchbar, IonFooter, IonList, IonItem,
    IonButton, IonLabel, IonFab, IonFabButton, IonHeader,
    PageHeader, PageFooter, IonTitle, IonSpinner
  },
  data() {
    return {
      schoolUuid: null,
      search: '',
    }
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    teachers(){
      return this.$store.getters["school/getSchoolTeachers"](this.search)
    },
    loading(){
      if (this.teachers && this.schoolName){
        return false
      }
      return true
    }
  },
  methods: {
    refreshData(event){
      this.$store.dispatch('school/reloadSchoolTeachers').then(()=>{
        event.target.complete()
      })  
    },
    goAddTeacher() {
      this.$router.push('/school/' + this.schoolUuid + '/add_teacher')
    },
    goToTeacherEdit(teacher){
      this.$router.push('/school/'+ this.schoolUuid + '/teachers/' + teacher.uuid + '/edit' )
    },
    async confirmDelete(teacher) {
      const alertMsg = {
        message: "Voulez vous vraiment supprimer le professeur " + teacher.user.first_name + " " + teacher.user.last_name  + " ? ",
        buttons: [
          {
            text: "Retour",
            role: "cancel"
          },
          {
            text: "Continuer",
            cssClass: "btn-danger",
            handler: ()=> {
              this.deleteTeacher('schools/teacher', teacher)
            }
          }
        ]
      }
      const alertItem = await alertController.create(alertMsg);
      return alertItem.present();
    },
    deleteTeacher(model, object) {
      doDeleteElement(model, object, object.user.first_name + ' ' + object.user.last_name, true).then(() => {
        this.$store.dispatch('school/removeTeacher',object.uuid)
        this.$router.back();
      }).catch(async () => {
        const alertItem = await alertController
            .create({
              header: 'Erreur lors de la suppression :',
              message: 'Cet objet ne peut pas être supprimé !!',
              buttons: ['OK']
            })
        return alertItem.present();
      })
    },
  },
  setup() {
    return {
      add, trash, sortingName
    }
  },
  ionViewWillEnter() {
    this.schoolUuid = this.$route.params.schoolUuid
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid)
    this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid})
    this.$store.dispatch('school/loadTeachers')
  },
});
</script>

<style scoped>
  ion-item:hover, ion-item:focus{
    --ion-color-base: var(--ion-color-light);
    cursor: pointer;
  }
  .btn-delete{
    --box-shadow: none;
  }
  .btn-modifyProfil {
    --box-shadow: none;
  }

</style>
