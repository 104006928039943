<template>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title>Sélectionnez l'école</ion-title>
      <ion-buttons slot="end">
        <ion-button @click.prevent="closeModal()">Fermer</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content fullscreen>
    <ion-list>
      <ion-item v-for="(school, kSchool) in schools" :key="kSchool" @click.prevent="doGenerateRecap(school.uuid)">
        <ion-label>{{ school.name }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import {alertController, modalController, IonLabel, IonTitle, IonButton, IonButtons, IonToolbar, IonHeader, IonItem, IonList, IonContent,} from "@ionic/vue";
import {defineComponent} from 'vue';
import {getAPI} from "@/axios-api";

export default defineComponent({
  name: "RecapRequestModal",
  components: {IonLabel, IonTitle, IonButton, IonButtons, IonToolbar, IonHeader, IonItem, IonList, IonContent,},
  props: {
    schools: { type: Array },
    userId: { type: Number },
  },
  data() {
    return {
      currentModal: null,
    }
  },
  methods: {
    doGenerateRecap(schoolUuid) {
      getAPI.post('townships/schools/' + schoolUuid + '/reservations/generate').then(async () => {
        let alertMsg = {}
        alertMsg = {
          header: 'Un mail vous a été envoyé :',
          message: 'Le récapitulatif des réservations vous a été envoyé par email. Merci.',
          buttons: ['OK'],
        }
        const alertItem = await alertController.create(alertMsg);
        this.closeModal()
        return alertItem.present();
      }).catch(async (error) => {
        console.log(error)
        if (error.response.status === 404) {
          const alertMsg = {
            message: 'Aucune réservation n\'a été effectuée pour cette école ou la configuration de l\'école est incomplète.',
            buttons: ['OK'],
          }
          const alertItem = await alertController.create(alertMsg);
          this.closeModal()
          return alertItem.present();
        }else {
          const alertMsg = {
            message: 'Une erreur s\'est produite, veuillez réessayer plus tard.',
            buttons: ['OK'],
          }
          const alertItem = await alertController.create(alertMsg);
          this.closeModal()
          return alertItem.present();
        }
      });
    }
  },
  setup() {
    const closeModal = () => {
      modalController.dismiss();
    };
    return { closeModal };
  },
});
</script>

<style scoped>

ion-toolbar {
  --background: var(--ion-color-secondary)
}

ion-item {
  --ion-item-background: var(--ion-color-white);
  --ion-item-color: var(--ion-color-white-contrast);
  transition: all .3s ease-out;
}

ion-card-title {
  font-size: 21px;
}

@media (min-width: 600px) {

  ion-item:hover, ion-item:focus {

    --ion-item-background: var(--ion-color-light);
    cursor: pointer;
  }

}

</style>