<template>
  <ion-page>
    <ion-header>
      <page-header></page-header>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="container">
        <ion-card color="light">
          <ion-card-header>
            <ion-card-title>
              Nouvel item
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <item-form
                :tags="$store.getters['itemType/getItemTypeTags'](this.$route.params.typeUuid)"
            ></item-form>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>
  </ion-page>
</template>

<script>
import {IonPage, IonContent, IonFooter, IonHeader, IonCardTitle, IonCard, IonCardHeader, IonCardContent, } from '@ionic/vue';
import PageHeader from "@/views/components/PageHeader";
import PageFooter from "@/views/components/PageFooter";
import ItemForm from "@/views/components/items/ItemForm.vue";

export default {
  name: "CreateItemPage",
  components: {
    ItemForm,
    IonPage, IonContent, IonFooter, IonHeader, IonCardTitle, IonCard, IonCardHeader, IonCardContent,
    PageHeader, PageFooter
  },
  mounted() {
    this.$store.dispatch(
      'tag/fetchTagsFromItemTypeAndSchool',
      {
        item_type_uuid: this.$route.params.typeUuid,
        module_uuid: this.$route.params.moduleUuid,
        school_uuid: this.$route.params.schoolUuid
      })
  },
}
</script>

<style scoped>

</style>