<template>
  <ion-page>
    <ion-header :translucent="true">
      <page-header>
        <ion-title class="header-link" v-if="tutor">
          <router-link enterkeyhint="go" to="/home" replace>Mes écoles</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}`" replace>{{schoolName}}</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}/tutors`" replace>Tuteurs</router-link> /
          {{tutor.user.first_name + " " + tutor.user.last_name}}
        </ion-title>
      </page-header>
    </ion-header>

    <ion-content :fullscreen="true">

      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div v-if="!tutor">
        <p style="text-align: center">
          Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
        </p>
      </div>

      <div class="container" v-else >

        <ion-card color="light" > 
          <ion-card-header>
            <ion-card-title>{{tutor.user.first_name + " " + tutor.user.last_name}}</ion-card-title> 
          </ion-card-header>
          <ion-card-content>
            <p>{{tutor.user.username}}</p>
          </ion-card-content>
          <ion-button @click.prevent='goToTutorEdit(student)' class="btn-modifyProfil" shape="round"  size="small" color="light" >
            <ion-icon src="/assets/icon/pen-solid.svg" ></ion-icon>
          </ion-button>
        </ion-card>

        <ion-list class="listPadding" >
          <ion-list-header>
            <h2>Élèves :</h2>
          </ion-list-header>
          <ion-item 
            color="white" 
            v-for="(student, kStudent) in students.sort((a,b)=>sortingName(a,b,['last_name', 'first_name']))" 
            :key="kStudent" 
            :v-bind="'student-'+student.id">
            <ion-label>
              <h2>{{ student.first_name }} {{ student.last_name }}</h2>
            </ion-label>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import {
  IonPage, IonContent, IonList, IonFooter, IonCard, IonCardTitle, IonCardHeader,
  IonItem, IonLabel, IonHeader, IonTitle, IonListHeader, IonButton, IonIcon,
  IonRefresher, IonRefresherContent
} from '@ionic/vue';
import {defineComponent} from 'vue';
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {sortingName} from '@/utils';

export default defineComponent({
  name: "LegalTutorPage",
  components: {
    IonContent,
    IonPage, IonButton,
    IonFooter, IonHeader, IonTitle, IonLabel, IonListHeader,
    PageHeader, PageFooter, IonList, IonItem, IonCard, IonCardTitle,
    IonCardHeader, IonIcon, IonRefresher, IonRefresherContent
  },
  data() {
    return {
      schoolUuid: null,
    }
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    tutor(){
      return this.$store.getters["legalTutor/getLegalTutor"]
    },
    students(){
      return this.$store.getters["legalTutor/getLegalTutorStudents"]
    }
  },
  methods: {
    goToTutorEdit(){
        this.$router.push('/school/'+ this.schoolUuid + '/tutors/' + this.tutor.uuid + '/edit' )
    },
    doRefresh(event) {
      this.$store.dispatch('legalTutor/reloadLegalTutor').then(()=>{
        event.target.complete()
      })
      
    },
  },
  setup() {
    return {
      sortingName
    }
  },
  ionViewWillEnter() {
    this.schoolUuid = this.$route.params.schoolUuid
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid)
    this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid})
    this.$store.dispatch('legalTutor/navigateToLegalTutor', this.$route.params.tutorUuid)
    this.$store.dispatch('legalTutor/loadLegalTutor')
  },
});
</script>

<style scoped>

  .btn-delete{
    --box-shadow: none;
  }
  .btn-modifyProfil{
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    --box-shadow: 0;
  }
  ion-list-header{
      color: var(--ion-color-white)
  }
  @media (min-width:600px){
    ion-list-header{
      color: var(--ion-color-white-contrast)
    }
  }
 

</style>
