<template>
  <ion-card :color="color" v-if="itemType" >
    <ion-card-header>
      <ion-card-subtitle>{{itemType.module.name}}</ion-card-subtitle>
      <ion-card-title>
        {{itemType.name}}
      </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <div v-if="showButtons">
        <ion-button
            color="primary"
            size="small"
            fill="solid"
            @click.prevent="$router.push('/school/' + $route.params.schoolUuid + '/module/' + $route.params.moduleUuid + '/types/' + uuid + '/edit')"
        >
          <ion-icon
              style="font-size: 13px;"
              slot="start"
              src="assets/icon/pen-solid.svg"
          ></ion-icon>
          &nbsp;Editer
        </ion-button>
        <ion-button
            color="primary"
            size="small"
            fill="solid"
            @click.prevent="$router.push('/school/' + $route.params.schoolUuid + '/module/' + $route.params.moduleUuid + '/types/' + $route.params.typeUuid +  '/prices')"
        >
          <strong>&euro;</strong>
          &nbsp;&nbsp;Prix par classes
        </ion-button>
        <ion-button
            color="danger"
            size="small"
            fill="solid"
            disabled
        >
          <ion-icon
              style="font-size: 13px;"
              slot="start"
              :icon="trashOutline"
          ></ion-icon>
          &nbsp;Supprimer
        </ion-button>
        <ion-button
            hidden
            color="primary"
            size="small"
            fill="solid"
            @click.prevent="$router.push('/school/' + $route.params.schoolUuid + '/module/' + $route.params.moduleUuid + '/types/' + uuid + '/prices')"
        >
          <ion-icon
              style="font-size: 13px;"
              slot="start"
              :icon="logoEuro"
          ></ion-icon>
          &nbsp;Prix par classes
        </ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonIcon, IonButton, IonCardContent, } from '@ionic/vue';
import {logoEuro, trashOutline} from "ionicons/icons";

export default {
  components: { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonIcon, IonButton, IonCardContent, },
  name: "ItemTypeCard",
  props: {
    uuid: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "light"
    },
    showButtons: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    itemType() {
      return this.$store.getters["itemType/getItemType"](this.uuid);
    }
  },
  setup() {
    return {
      logoEuro, trashOutline
    }
  }
}
</script>

<style scoped>

</style>