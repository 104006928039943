<template>
  <ion-page>
    <ion-header v-if="currentStudent">
      <page-header>
        <ion-title class="header-link" >
          <router-link 
            enterkeyhint="go" 
            to="/home" 
            replace
          >
          <span v-if="isLegalTutor">Mes enfants</span>
          <span v-else-if="isTeacher">Mes classes</span>
        </router-link> / 
          {{currentStudent.first_name}} {{currentStudent.last_name}}
        </ion-title>
      </page-header>
    </ion-header>

    <ion-content v-if="currentStudent">
      <ion-refresher slot="fixed" @ionRefresh="refreshModules">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <div class="container" >
        <student-header
            v-if="currentStudent"
            :currentStudent="currentStudent"
            :currentClassroom="currentClassroom"
            :currentWallet="currentWallet"
        ></student-header>
        <student-modules
            v-if="currentStudent"
            :modules="currentStudent.modules"
        ></student-modules>
      </div>
    </ion-content>
    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFooter,
} from '@ionic/vue'
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from '@/views/components/PageFooter.vue';
import StudentHeader from "@/views/components/students/StudentHeader";
import {mapActions, mapGetters} from 'vuex'
import StudentModules from "@/views/components/students/StudentModules";

export default defineComponent({
  name: 'StudentHome',
  components: {
    StudentModules,
    StudentHeader, IonPage, IonContent, IonHeader,
    PageHeader, PageFooter, IonTitle, IonRefresher,
    IonRefresherContent, IonFooter
  },
  mounted() {
    this.setCurrentStudent({student_uuid: this.$route.params.studentUuid}).then(() => {
      this.syncCompleteStudent({student_uuid:  this.$route.params.studentUuid})
    });
  },
  methods: {
    ...mapActions('students', [
      'syncModules',
      'syncWallet',
      'syncCompleteStudent',
      'setCurrentStudent'
    ]),
    refreshModules(event) {
      this.syncModules({student_uuid:  this.$route.params.studentUuid}).finally(() => {
        this.syncWallet({student_uuid:  this.$route.params.studentUuid, force: true}).finally(() => {
          event.target.complete();
        })
      })
    }
  },
  data(){
    return{
      student: ""
    }
  },
  computed: {
    ...mapGetters('students', [
      'currentWallet',
      'currentStudent',
      'currentClassroom',
      'currentStudent'
    ]),
    isLegalTutor(){
      return this.$store.getters['home/isLegalTutor'](this.$route.params.studentUuid)
    },
    isTeacher(){
      return this.$store.getters['home/isTeacher'](this.currentClassroom.uuid)
    }
  },
})
</script>