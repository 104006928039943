import { RouteRecordRaw } from 'vue-router';

import StudentHome from "@/views/pages/student/StudentHome.vue"
import ReceivePayment from "@/views/pages/student/ReceivePayment.vue";


const routesTeacher: Array<RouteRecordRaw> = [
    {
        path: '/classroom/student/:studentUuid',
        name: 'ClassroomStudent',
        component: StudentHome
    },
    {
        path: '/classroom/student/:studentUuid/receive_payment',
        name: 'ReceivePayment',
        component: ReceivePayment
    },
]

export default routesTeacher