<template>
    <ion-page>
      <page-header>
        <ion-title class="header-link" v-if="currentStudent">
          <router-link enterkeyhint="go" to="/home" replace>
            <span v-if="isLegalTutor">Mes enfants</span>
            <span v-else-if="isTeacher">Mes classes</span>
          </router-link> / 
          <router-link enterkeyhint="go" :to="`/student/${currentStudent.uuid}`" replace>
            {{currentStudent.first_name}} {{currentStudent.last_name}}
          </router-link> / Futur
        </ion-title>
      </page-header>
      <ion-content>
        <div v-if="!currentStudent">
          <p style="text-align: center">
            Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
          </p>
        </div>
        <div v-else class="container" >
          <student-header 
            v-if="currentStudent"
            :currentStudent="currentStudent" 
            :currentClassroom="currentClassroom" 
            :currentWallet="currentWallet"></student-header>
          <futur-payment-list v-if="currentStudent && currentWallet" :isTeacher="false"></futur-payment-list>
        </div>
      </ion-content>
      <ion-footer>
        <page-footer></page-footer>
      </ion-footer>
    </ion-page>
  </template>
  
  <script>
  import {IonPage, IonContent, IonFooter, IonSpinner, IonTitle} from "@ionic/vue";
  import PageHeader from "@/views/components/PageHeader";
  import pageFooter from "@/views/components/PageFooter";
  import FuturPaymentList from "@/views/components/students/FuturPaymentList"
  import {mapActions, mapGetters} from 'vuex'
  import StudentHeader from "@/views/components/students/StudentHeader";
  export default {
    name: "FuturPage",
    components: {
      FuturPaymentList,
      PageHeader,
      IonPage,
      pageFooter,
      IonContent,
      IonFooter,
      StudentHeader,
      IonSpinner, IonTitle
    },
    mounted(){
      this.setCurrentStudent({student_uuid: this.$route.params.studentUuid}).then(() => {
        this.syncCompleteStudent({student_uuid:  this.$route.params.studentUuid})
      });
    },
    methods: {
      ...mapActions('students', [
        'syncCompleteStudent',
        'setCurrentStudent',
        'setCurrentModule'
      ]),
    },
    computed:{
      ...mapGetters('students', [
        'currentModule',
        'currentWallet',
        'currentStudent',
        'currentClassroom',
      ]),
      isLegalTutor(){
        return this.$store.getters['home/isLegalTutor'](this.$route.params.studentUuid)
      },
      isTeacher(){
        return this.$store.getters['home/isTeacher'](this.currentClassroom.uuid)
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>