import {getAPI} from "@/axios-api";

const state = () => ({
    teachers: {},
    currentTeacherUuid: null
})

const mutations = {
    setCurrentTeacherUuid(state: any, teacherUuid: string){
        state.currentTeacherUuid = teacherUuid
    },
    setTeacherUser(state:any, {data}:any){
        state.teachers[state.currentTeacherUuid] = data
        const isOrderManager = data.orderManager
        if(isOrderManager.length){
            state.teachers[state.currentTeacherUuid].teacher.user.checkOrderManager = true
        }
    },
    removeTeachers(state:any){
        delete state.teachers[state.currentTeacherUuid]
    },
    updateTeacherUser(state: any, data: any){
        state.teachers[state.currentTeacherUuid].teacher.user = data
    },
}

const getters = {
    getTeacher (state:any){
        return state.teachers[state.currentTeacherUuid]
    },
    getTeacherClassrooms (state:any){
        const teacher = state.teachers[state.currentTeacherUuid]
        return teacher && teacher.classrooms
    },
    getTeacherUser (state:any){
        const teacher = state.teachers[state.currentTeacherUuid]
        return teacher && teacher.teacher.user
    }
}

const actions = {
    navigateToTeacher({ commit,state }: any, teacherUuid: string){
        if(state.currentTeacherUuid != teacherUuid){
            commit("setCurrentTeacherUuid", teacherUuid)
        }
    },
    async loadUserTeacher({ commit, state, rootState }: any){
        // Check if the current teacher uuid is already loaded in state.teachers
        if(!state.teachers[state.currentTeacherUuid]){
            await getAPI.get('schools/teacher/' + state.currentTeacherUuid).then((response: any) => {
                const schoolId = rootState.school.schools[rootState.school.currentSchoolUuid].id
                commit("setTeacherUser", {data:response.data.data,currentSchoolId:schoolId})
            })
        }
    },
    async reloadTeacher({commit, dispatch}:any){
        commit('removeTeachers')
        await dispatch('loadUserTeacher')
    },
    updateTeacherUser({commit}:any, data:any){
        commit('updateTeacherUser',data)
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}