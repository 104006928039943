<template>
  <ion-card color="light">
    <img v-if="itemType.b64_image" :src="'data:image/png;base64,' + itemType.b64_image" />
    <ion-card-header>
      <ion-card-title id="ItemTypeCard-ion-card-title">{{itemType.name}}</ion-card-title>
    </ion-card-header>
  </ion-card>
</template>

<script>
import {IonCard, IonCardHeader, IonCardTitle} from "@ionic/vue";

export default {
  name: "ItemTypeCard",
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
  },
  props: {
    itemType: {
      type: Object,
      required: true
    }
  },
}
</script>

<style scoped>
#ItemTypeCard-ion-card-title {
  font-size: 1.1em;
}
</style>