<template>
  <ion-item :disabled="disabled" lines="none" color="white" id="edit-student-first-name">
    <ion-label position="floating">Prénom
      <ion-text color="danger">*</ion-text>
    </ion-label>
    <ion-input v-model="student.first_name" required type="text"></ion-input>
  </ion-item>
  <ion-item :disabled="disabled" lines="none" color="white" id="edit-student-last-name">
    <ion-label position="floating">Nom
      <ion-text color="danger">*</ion-text>
    </ion-label>
    <ion-input v-model="student.last_name" required type="text"></ion-input>
  </ion-item>
  <ion-item :disabled="disabled" lines="none" color="white" id="edit-student-national-number">
    <ion-label position="floating">Numéro national
      <ion-text color="danger">*</ion-text>
    </ion-label>
    <ion-input maxlength="13" v-model="student.national_number" required
                type="text"></ion-input>
    <input v-mask="'######-###-##'" masked="true" v-model="student.national_number" required type="text"
            style="display: none"/>
  </ion-item>
  <ion-item :disabled="disabled" lines="none" color="white" id="edit-student-classroom">
    <ion-label position="floating">Classe
      <ion-text color="danger">*</ion-text>
    </ion-label>
    <ion-select v-if="classrooms.length" class="hiddenForDesktop" v-model="student.classroom"
                interface="action-sheet" :interface-options="options" @ionChange="alertClassChange()">
      <ion-select-option v-for="(classroom, key) in classrooms.sort((a,b)=>sortingName(a,b,['name']))"
                          :key="key" :value="classroom">{{ classroom.name }}
      </ion-select-option>
    </ion-select>
    <ion-select v-if="classrooms.length" class="hiddenForMobile hiddenForMobile-flex"
                v-model="student.classroom" interface="alert" :interface-options="options">
      <ion-select-option v-for="(classroom, key) in classrooms.sort((a,b)=>sortingName(a,b,['name']))"
                          :key="key" :value="classroom">{{ classroom.name }}
      </ion-select-option>
    </ion-select>
    <ion-input v-if="!classrooms.length && student.classroom"
                v-model="student.classroom.name" required type="text"></ion-input>
  </ion-item>
  <ion-item v-if="!disabled" :disabled="disabled" lines="none" class="ion-align-items-end" color="white" id="edit-student-cpas">
    <ion-label position="stacked">CPAS</ion-label>
    <ion-checkbox slot="end" v-model="student.cpas" :checked="student.cpas"></ion-checkbox>
  </ion-item>
</template>

<script>
import { defineComponent, computed } from 'vue'
import {
  IonItem,
  IonText,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
} from '@ionic/vue';
import {mask} from 'vue-the-mask';
import {sortingName, basicAlertMsg} from '@/utils';

export default defineComponent({
  name: "PartPersonalInfo",
  components:{
    IonItem,
    IonText,
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonCheckbox,
  },
  props:["studentProps", "disabled", "addStudent"],

  computed:{
    classrooms(){
      return this.$store.getters['school/getSchoolClassrooms']({schoolUuid: this.$route.params.schoolUuid})
    }
  },
  setup(props, { emit }) { 
    //Permet d'update le props studentProps via les inputs
    const student = computed({ 
      get: () => props.studentProps, 
      set: (value) => emit('update:studentProps', value) 
    }) 

    const options = {
      cssClass: "custom-options"
    }

    return { 
      student, options, sortingName, basicAlertMsg
    } 
  },
  directives: {mask},
  methods:{
    alertClassChange() {
      if(!this.addStudent){
        const header = 'Suppression des réservations futures'
        const msg = "Attention, les réservations des futurs repas à cette classe seront supprimées."
        this.basicAlertMsg(msg, header)
      } 
    },
  }
})
</script>
