<template>
  <ion-page>
    <ion-header translucent>
      <page-header>
        <ion-title class="header-link" id="school-students-ariane">
          <router-link enterkeyhint="go" to="/home" replace id="school-students-home-link">Mes écoles</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}`" replace id="school-students-school-link">{{schoolName}}</router-link> /
          Élèves
        </ion-title>
      </page-header>
    </ion-header>
    
    <ion-content :fullscreen="true">

      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <ion-fab vertical="bottom" horizontal="end" slot="fixed" id="add-student">
        <ion-fab-button @click.prevent="goAddStudent">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>

      <div class="container" >
        
        <ion-searchbar color="light" v-model="search" id="school-students-searchbar"></ion-searchbar>

        <ion-list v-if="classrooms.length > 0">
          <div v-for="(classroom, kClassroom) in classrooms" :key="kClassroom" :v-bind="'classroom-list-'+classroom.classroom.id">
            <ion-list-header :v-bind="'classroom-'+classroom.classroom.id">
              {{ classroom.classroom.name }}
            </ion-list-header>
            <ion-item color="white" v-for="(student, kStudent) in classroom.students.sort((a,b)=>sortingName(a,b,['first_name','last_name']))" :key="kStudent"
                      :router-link="'/school/' + $route.params.schoolUuid + '/students/' + student.uuid"
                      :v-bind="'student-'+student.id">
              <ion-label>
                {{ student.first_name }} {{ student.last_name }}
              </ion-label>
              <ion-button class="btn-delete" @click.prevent="confirmDelete(student)" color="white" fill="solid" size="small">
                <ion-icon src="assets/icon/trash-solid.svg"></ion-icon>
              </ion-button>
            </ion-item>
          </div>
        </ion-list>
        <ion-list v-else>
          <ion-item color="white" class="no-classroom">
            <ion-label>Aucune classe</ion-label>
          </ion-item>
        </ion-list>

      </div>

    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import {
  IonPage, IonContent, IonIcon, IonItem, IonSearchbar, alertController, IonHeader, 
  IonFooter, IonList, IonListHeader, IonButton, IonLabel, IonFab, IonFabButton, IonTitle,
  IonRefresher, IonRefresherContent
} from '@ionic/vue';
import {defineComponent} from 'vue';
import PageHeader from "@/views/components/PageHeader.vue";
import {getAPI} from "@/axios-api";
import {add, trash, chevronDownOutline} from "ionicons/icons";
import {doDeleteElement} from '@/utils';
import PageFooter from "@/views/components/PageFooter.vue";
import {sortingName} from "@/utils"


export default defineComponent({
  name: "SchoolStudents",
  components: {
    IonContent,
    IonPage,
    IonHeader, IonFooter, IonList, IonListHeader, IonButton, IonLabel, IonFab, IonFabButton,
    IonIcon, IonItem, IonSearchbar, IonTitle, IonRefresher, IonRefresherContent,
    PageHeader, PageFooter
  },
  data() {
    return {
      search: '',
      schoolUuid: null
    }
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    classrooms(){
      return this.$store.getters["school/getSchoolStudents"](this.search)
    }
  },
  methods: {
    doRefresh(event){
      this.$store.dispatch('school/reloadSchoolStudents').then(()=>{
        event.target.complete()
      })  
    },
    goAddStudent() {
      this.$router.push('/school/' + this.schoolUuid + '/add_student')
    },
    goToStudentEdit(studentUuid){
      this.$router.push('/school/'+ this.schoolUuid  +'/students/' + studentUuid + '/edit' )
    },
    confirmDelete(student) {
      getAPI.get('/schools/' + this.$route.params.schoolUuid + '/students/' + student.uuid).then(async (response) => {
        student.totalBalance = response.data.data.total_balance
        const alertMsg = {
          message: `Voulez vous vraiment supprimer l'élève ${student.first_name} ${student.last_name} ?
            Ceci va décommander automatiquement tous les repas futurs commandés.
            A titre informatif, il reste ${student.totalBalance} € sur le compte de cet enfant.
          `,
          buttons: [
            {
              text: "Retour",
              role: "cancel"
            },
            {
              text: "Continuer",
              cssClass: "btn-danger",
              handler: ()=> {
                this.deleteStudent('students', student)
              }
            }
          ]
        }
        const alertItem = await alertController.create(alertMsg);
        return alertItem.present();
      })

    },
    deleteStudent(model, object) {
      doDeleteElement(model, object, object.first_name + ' ' + object.last_name, true).then(() => {
        this.$store.dispatch('school/removeStudent',object.uuid)
      }).catch(async () => {
        const alertItem = await alertController
            .create({
              header: 'Erreur lors de la suppression :',
              message: 'Cet objet ne peut pas être supprimé !!',
              buttons: ['OK']
            })
        return alertItem.present();
      })
    }
  },
  setup() {
    return {
      add, trash, chevronDownOutline, sortingName
    }
  },
  ionViewWillEnter() {
    this.schoolUuid = this.$route.params.schoolUuid
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid)
    this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid})
    this.$store.dispatch('school/loadStudents')
  },
});
</script>

<style scoped>

  ion-list-header{
    font-weight: 600;
    font-size: 18px;
    color: var(--ion-color-white-contrast);
  }

  ion-list{
    background: var(--ion-color-white);
  }

  ion-item:hover, ion-item:focus{
    --ion-color-base: var(--ion-color-light);
    cursor: pointer;
  }

  .btn-delete{
    --box-shadow: none;
    --ion-color-base: none;
  }

</style>
