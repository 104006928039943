<template>
  <ion-page>

    <ion-header :translucent="true">
      <page-header>
        <ion-title class="header-link">
          <router-link enterkeyhint="go" to="/home" replace id="school-students-home-link">Mes écoles</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${$route.params.schoolUuid}`" replace id="school-students-school-link">
            {{ $store.getters['school/getSchoolNameByUuid']({school_uuid: $route.params.schoolUuid}) }}
          </router-link> /
          <router-link 
            enterkeyhint="go" 
            :to="`/school/${$route.params.schoolUuid}/module/${$route.params.moduleUuid}/types`" 
            replace 
          >Données {{module && module.name}}</router-link> /
          Éditer 
        </ion-title>
      </page-header>
    </ion-header>

    <ion-content>

      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>


      <div class="container">
      
        <div v-if="!type">
          <p style="text-align: center">
            Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
          </p>
        </div>
        <item-type-form
            :module-uuid="$route.params.moduleUuid"
            :school-uuid="$route.params.schoolUuid"
            :uuid="$route.params.typeUuid"
        ></item-type-form>
    </div>

    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>


<script>

import {
  IonPage, IonContent, IonHeader, IonFooter, IonTitle
} from '@ionic/vue';
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import ItemTypeForm from "@/views/components/itemTypes/ItemTypeForm.vue";
export default {
  name: "AdminEditType",

  components: {
    ItemTypeForm,
    IonContent, IonPage, PageHeader, PageFooter,
    IonHeader, IonFooter, IonTitle,
  },

  data() {
    return {
      type: {},
      fieldMap: {
        'name': 'name',
      },
    }
  },

  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    modules(){
      return this.$store.getters["school/getSchoolModules"]
    },
    module(){
      return this.$store.getters["school/getSchoolModules"][this.$route.params.moduleUuid]
    },
    itemType(){
      return this.$store.getters["school/getItemType"]
    }
  },
  mounted(){
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid).then(()=>{
      this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid}).then(()=>{
        this.$store.dispatch('school/navigateToModule', this.$route.params.moduleUuid).then(()=>{
          this.$store.dispatch('school/loadModules', {schoolUuid: this.$route.params.schoolUuid}).then(()=>{
            this.$store.dispatch('school/navigateToItemType',this.$route.params.typeUuid).then(()=>{
              this.$store.dispatch('school/loadModuleItemTypes').then(()=>{
                this.type = {...this.itemType}
              })
            })
          })
        })
      })
    })
  },
  methods: {
    doRefresh(event){
      this.$store.dispatch('school/reloadModuleItemTypes').then(()=>{
        event.target.complete()
        this.type = {...this.itemType}
      }) 
    },
  }
}
</script>

<style scoped>

</style>