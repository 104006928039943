<template>
  <form class="form" @submit.prevent="saveType()" >
    <ion-item color="white">
      <ion-label position="stacked">Nom de la catégorie de produit</ion-label>
      <ion-input type="text" placeholder="Catégorie" v-model="form.name"></ion-input>
    </ion-item>
    <ion-item color="white">
      <ion-label position="stacked">Prix par défaut</ion-label>
      <ion-input type="number" placeholder="3,4" step="0.01" v-model="form.price"></ion-input>
    </ion-item>
    <section style="padding: 10px">
      <ion-button shape="round" expand="block" type="submit">
        <span v-if="!uuid">Créer la catégorie</span>
        <span v-else>Enregistrer les modifications</span>
      </ion-button>
    </section>
  </form>
</template>

<script>
import { alertController, IonItem, IonLabel, IonInput, IonButton, toastController} from '@ionic/vue';

export default {
  name: "ItemTypeForm",
  components: {
    IonItem, IonLabel, IonInput, IonButton,
  },
  props: {
    schoolUuid: {
      type: String,
      required: true
    },
    moduleUuid: {
      type: String,
      required: true
    },
    uuid: {
      type: String,
      required: false
    }
  },
  emits:['closeModal'],
  methods: {
    addType() {
      this.$store.dispatch("itemType/createItemType", {
        schoolUuid: this.schoolUuid,
        moduleUuid: this.moduleUuid,
        itemType: this.form
      }).then(async () => {
        this.form = {
          name: "",
          price: null
        }
        this.$emit('closeModal')
        const toast = await toastController
            .create({
              message: "Type créé avec succès",
              duration: 2000
            })
        return toast.present();
        
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          alertController.create({
            header: 'Erreur',
            message: error.response.data.message,
            buttons: ['OK']
          }).then(alert => alert.present());
        } else if (error.response && error.response.status === 403) {
          alertController.create({
            header: 'Erreur',
            message: "Vous n'avez pas les droits pour créer un type",
            buttons: ['OK']
          }).then(alert => alert.present());
        } else {
          alertController.create({
            header: 'Erreur',
            message: "Erreur lors de la création du type",
            buttons: ['OK']
          }).then(alert => alert.present());
        }
      });
    },
    updateType() {
      this.$store.dispatch("itemType/updateItemType", {
        schoolUuid: this.schoolUuid,
        moduleUuid: this.moduleUuid,
        uuid: this.uuid,
        itemType: this.form
      }).then(async() => {
        await this.$router.back()
        const toast = await toastController
          .create({
            message: "Erreur lors de la création du type",
            duration: 2000
          })
        return toast.present();
      });
    },
    saveType() {
      if (this.uuid) {
        this.updateType();
      } else {
        this.addType();
      }
    }
  },
  computed: {
    form() {
      return {
        uuid: this.itemType ? this.itemType.uuid : null,
        name: this.itemType ? this.itemType.name : "",
        price: this.itemType ? this.itemType.price : null,
      };
    },
    itemType() {
      return this.$store.getters["itemType/getItemType"](this.uuid);
    }
  }
}
</script>

<style scoped>

</style>