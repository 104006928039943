<template>
  <div class="container-calendar" >
    <calendar
      is-expanded
      :attributes="attributes"
      @update:pages="pageChange"
      :is-dark="false"
    />
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import { Calendar} from 'v-calendar';

export default defineComponent({
  name: 'PartCalendar',
  components: {
    Calendar
  },
  data() {
    return {
      reservations: null,
    };
  },

  computed: {
    attributes() {
      const selectedDays = this.days.map(date => ({   
        highlight: 'green',
        dates: date,
      }));

      selectedDays.push({
        bar: {
          color: 'gray',
          style: {
            maxWidth: '35px',
            margin: '0 auto',
            height: '3px'
          }
        },
        dates: new Date()
      })
      return selectedDays
    },
    days(){
      // Return tout les jours ou il y a une reservation charger dans le store
      return this.$store.getters['students/studentReservationsDays'](this.$route.params.moduleUuid)
    },
  },
  methods: {
    pageChange(page){
      // Au changement de mois, on load les reservations dans le store
      this.$store.dispatch('students/loadStudentReservations',{moduleUuid: this.$route.params.moduleUuid, params:page[0]})
    }
  }
});
</script>

<style scoped type="scss">
  .container-calendar :deep(.vc-container) {
    margin: 0 auto 5rem;
    color: #000;
    border: none;
    border-radius: 0;
    width: 100%;
  }

</style>
