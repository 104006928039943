import {getAPI} from "@/axios-api";
import moment from 'moment';

const state = () => ({
    //  townships:{
    //      townshipUuid : {
    //          townshipData,
    //          schools: {
    //              schoolUuid: {
    //                  schoolData,
    //                  classrooms: {classroomUuid:{}}
    //                  weeks:[{year, week, meals:{mealUuid:{mealdata}}}]
    //              }
    //          } 
    //      }
    //  }
    townships: {},
    currentTownshipUuid: null,
    currentSchooluuid: null,
    currentWeek:null,
    currentYear:null,
    menuToEdit:null,
    menuTags: []
})

const mutations = {
    setItemTypeList(state: any, {townshipUuid, data}: any) {
        state.townships[townshipUuid].itemTypes = data
    },
    setTownship(state: any, data:any){
        state.townships[data.uuid] = data
        state.townships[data.uuid].itemTypes = []
    },
    setCurrentTownshipUuid(state: any, townshipUuid:string){
        state.currentTownshipUuid = townshipUuid
    },
    removeAllTownships(state:any){
        state.townships = {}
    },

    setTownshipSchool(state: any,data: any){
        state.townships[state.currentTownshipUuid].schools = {}
        data.forEach((school:any) => {
            if(school.name){
                state.townships[state.currentTownshipUuid].schools[school.uuid] = school
            }else{
                state.townships[state.currentTownshipUuid].schools[school.school.uuid] = school.school
                state.townships[state.currentTownshipUuid].schools[school.school.uuid].classrooms = {}
                school.classrooms.forEach((classroom:any)=>{
                    state.townships[state.currentTownshipUuid].schools[school.school.uuid].classrooms[classroom.uuid] = classroom
                })
            }
        });
    },
    setCurrentSchoolUuid(state: any, schoolUuid:string){
        state.currentSchooluuid = schoolUuid
    },

    setWeekAndYear(state:any, {year,week}:any){
        state.currentWeek = week
        state.currentYear = year
    },
    setMealsByWeekAndClassroom(state:any, {data, school, currentYear, currentWeek}:any){
        /*
            Lorsqu'on load les repas, on les loads par semaines et par classes.
            Une semaine = une année, un numéro de semaine et tout les repas de cette semaine.
            Chaque repas a l'uuid de sa classe correspondante
        */

        school.classrooms = {}
        const mealWeek = {year:currentYear, week:currentWeek, meals:{}}
        if (school){
            if (school.weeks){
                school.weeks.push(mealWeek)
            }else{
                school.weeks = [mealWeek]
            }
        }
        data.forEach((classroom:any)=>{
            if(!school.classrooms[classroom.classroom.uuid]){
                school.classrooms[classroom.classroom.uuid] = classroom.classroom
            }
            classroom.meals.forEach((meal:any)=>{
                meal.classroom_uuid = classroom.classroom.uuid
                school.weeks.find((week:any)=>
                    week.year == currentYear && 
                    week.week == currentWeek
                ).meals[meal.uuid] = meal
            })
        })
    },
    removeMeal(state:any, mealUuid: string){
        const township = state.townships[state.currentTownshipUuid]
        const school = township.schools[state.currentSchooluuid]
        const mealWeek = school.weeks.find((meals:any)=>meals.year == state.currentYear && meals.week == state.currentWeek)
        delete mealWeek.meals[mealUuid]
    },
    setMenuToEdit(state: any, meal: any){
        state.menuToEdit = meal
    },
    updateMenuToEdit(state: any, menu: any){
        state.menuToEdit.menu = menu
    },
    removeMenuToEdit(state: any){
        delete state.menuToEdit
    },
    resetOneWeek(state: any, {schoolUuid, mealWeek}:any){
        const school = state.townships[state.currentTownshipUuid].schools[schoolUuid]
        school.weeks = school.weeks.filter((week:any)=>week != mealWeek)
    },
    setMenuTags(state:any, tags: any){
        state.menuTags = tags.tags
    },
    selectSchool(state:any, {schoolUuid, townshipUuid, selected}: any){
        state.townships[townshipUuid].schools[schoolUuid].selected = selected
    },
    selectClassroom(state:any, {classroomUuid, schoolUuid, townshipUuid, selected}: any){
        state.townships[townshipUuid].schools[schoolUuid].classrooms[classroomUuid].selected = selected
    }
}

const getters = {
    getItemTypes(state: any){
        if (state.townships[state.currentTownshipUuid] === undefined || state.townships[state.currentTownshipUuid].itemTypes === undefined){
            return []
        }
        return state.townships[state.currentTownshipUuid].itemTypes
    },
    getSelectableItemTypes(state: any) {
        // Si les données de l'école ou du type de repas sont manquantes, retourne un tableau vide
        if (state.currentTownshipUuid === undefined || 
            state.townships[state.currentTownshipUuid]?.itemTypes === undefined ||
            state.townships[state.currentTownshipUuid]?.schools === undefined) {
          return [];
        }
        
        // Récupère les écoles sélectionnées
        const selectedSchoolObjects = Object.values(state.townships[state.currentTownshipUuid].schools).filter(
            ({ selected, classrooms }:any) => selected || Object.values(classrooms).some(({ selected }:any) => selected)
        );
        // Filtre les types de repas qui sont disponibles dans toutes les écoles sélectionnées
        return Object.values(state.townships[state.currentTownshipUuid].itemTypes).filter(
            (itemType:any) =>
            selectedSchoolObjects.every(
                (selectedSchool: any) =>
                itemType.schools.find(( school:any ) =>selectedSchool.uuid === school.uuid)
            ) && itemType.module.slug_name == 'meals-module'
        );
    },
    getSelectedSchools: (state:any) => {
        const township = state.townships[state.currentTownshipUuid]
        return Object.values(township.schools).filter((school:any)=>school.selected)
    },
    getTownship(state: any){
        return state.townships[state.currentTownshipUuid]
    },
    getTownshipSchools(state:any) {
        const township = state.townships[state.currentTownshipUuid]
        const classroomsSchool = township && township.schools && Object.keys(township.schools).map((key:string)=> township.schools[key]);
        return township && classroomsSchool
    },
    getSchoolWeekMeals(state: any) {
        const township = state.townships[state.currentTownshipUuid]
        const school = township && township.schools && township.schools[state.currentSchooluuid]
        if (school && school.weeks){
            // On va chercher la semaine qui correspond dans school.weeks.
            const mealWeek = school.weeks.find((meals:any)=>meals.year == state.currentYear && meals.week == state.currentWeek)
            if(mealWeek){
                // Transform l'objet week.meals en array avec tout les meals
                let mealsArray = Object.keys(mealWeek.meals).map((key:string)=> mealWeek.meals[key]);

                // Tri par date
                mealsArray = mealsArray.sort((a:any,b:any) => {
                    a = new Date(a.date);
                    b = new Date(b.date);
                    return a.getTime() - b.getTime()
                })

                // Créer un array qui contient pour chaque élément une classe et les repas de cette classe 
                const classroomMealsArray: Array<any> = []
                if(school.classrooms){
                    const classroomsArray = Object.keys(school.classrooms).map((key:string)=> school.classrooms[key]);
                    classroomsArray.forEach((classroom:any)=>{
                        classroomMealsArray.push({
                            'classroom':classroom,
                            'meals':mealsArray.filter((meal:any)=>meal.classroom_uuid == classroom.uuid)
                        })
                        
                    })
                }
                return classroomMealsArray
            }
        }     
    },
    getCurrentSchool(state: any){
        const township = state.townships[state.currentTownshipUuid]
        if(township){
            return township.schools && township.schools[state.currentSchooluuid]
        }
    },
    getMenuToEdit(state: any){
        return state.menuToEdit
    },
    getMenuTags(state: any){
        return state.menuTags
    }
}

const actions = {

    loadItemTypeList({commit}: any, {townshipUuid}: any){
        return new Promise((resolve, reject)=>{
            getAPI.get('/townships/' + townshipUuid + '/item_types')
            .then((response: any)=>{
                commit('setItemTypeList', {townshipUuid, data: response.data.data})
                resolve(response.data)
            })
            .catch((error: any)=>{
                reject(error)
            })
        })
    },

    navigateToTownship({ commit,state }: any, townshipUuid: string){
        if(state.currentTownshipUuid != townshipUuid){
            commit("setCurrentTownshipUuid", townshipUuid)
        }
    },
    async loadTownship({ commit, state }: any){
        if (!state.townships[state.currentTownshipUuid]){
            await getAPI.get('/townships/' + state.currentTownshipUuid).then((response) => {
                commit("setTownship", response.data.data)
            })
        }
    },
    reloadTownship({dispatch, commit}:any){
        commit('removeAllTownships')
        dispatch('loadTownship')
    },
    
    navigateToSchool({ commit,state }: any, schoolUuid: string){
        if(state.currentSchoolUuid != schoolUuid){
            commit("setCurrentSchoolUuid", schoolUuid)
        }
    },
    async loadTownshipSchools({ dispatch, commit, state }: any){
        await dispatch('loadTownship').then(async()=>{
            if (!state.townships[state.currentTownshipUuid].schools){
                await getAPI.get('/townships/' + state.currentTownshipUuid + '/schools').then((response) => {
                    commit("setTownshipSchool", response.data.data)
                })
            }  
        })
    },
    changeWeek({commit,dispatch}:any, {year,week}:any){
        commit('setWeekAndYear', {year,week})
        dispatch('loadSchoolWeekMeals')
    },
    async loadSchoolWeekMeals({ dispatch, commit, state }: any){
        await dispatch('loadTownshipSchools').then(async()=>{
            const township = state.townships[state.currentTownshipUuid]
            const school = township.schools[state.currentSchooluuid]
            const mealWeek = school.weeks && school.weeks.find((week:any)=>week.year == state.currentYear && week.week == state.currentWeek)
            if (!mealWeek){
                await getAPI.get('/townships/' + state.currentTownshipUuid + '/schools/' + state.currentSchooluuid + '/meals', {
                    params: {
                        year: state.currentYear,
                        week: state.currentWeek,
                    }
                }
                ).then((response) => {
                    const data = response.data.data
                    commit("setMealsByWeekAndClassroom",{
                        data,
                        school: state.townships[state.currentTownshipUuid].schools && state.townships[state.currentTownshipUuid].schools[state.currentSchooluuid],
                        currentYear: state.currentYear,
                        currentWeek: state.currentWeek
                    })
                })
            }
        })
    },
    removeMeal({commit}:any, mealUuid: any){
        commit('removeMeal',mealUuid)
    },
    setMenuToEdit({commit}: any, meal: any){
        commit('setMenuToEdit', meal)
    },
    updateMenu({state, commit, dispatch}:any, menu:any){
        // Update the menu and reload the current week so we don't have to refresh 
        if(state.menuToEdit){
            commit('updateMenuToEdit',menu)
            const township = state.townships[state.currentTownshipUuid]
            const school = township?.schools?.[state.currentSchooluuid]
            if (school?.weeks){
                const mealWeek = school.weeks.find((meals:any)=>meals.year == state.currentYear && meals.week == state.currentWeek)
                commit("resetOneWeek", {schoolUuid: state.currentSchooluuid,mealWeek})
                dispatch('loadSchoolWeekMeals')
            }
        }
    },
    async loadMenuToEdit({state,commit, dispatch}:any, menuUuid:string){
        await dispatch('loadTownshipSchools').then(async()=>{
            await getAPI.get('meals/schools/' + state.currentSchooluuid +'/caterers/menu/' + menuUuid).then((response) => {
                const data = response.data.data
                commit('setMenuToEdit',{menu:data})
            })
        })
    },

    reloadMenuToEdit({dispatch, commit}:any, menuUuid:string){
        commit('removeMenuToEdit')
        dispatch('loadMenuToEdit', menuUuid)
    },
    addMealsToTownship({state, commit}:any, {meals, school}:any){
        // Quand on ajoute des repas manuellement, on reset la semaine ou ils ont été ajoutés 
        const date = moment(meals.date)
        const township = state.townships[state.currentTownshipUuid]
        const selectedSchool = township.schools && township.schools[school.uuid]
        const mealWeek = selectedSchool.weeks && selectedSchool.weeks.find((week:any)=>week.year == date.year() && week.week == date.week())
        if (mealWeek){
            commit("resetOneWeek", {schoolUuid: school.uuid,mealWeek})
        }
    },
    async loadMenuTagList({commit}:any){
        getAPI.get('/tags/module/meals-module').then((response)=>{
            commit('setMenuTags', {tags : response.data.data})
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}

