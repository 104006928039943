<template>
  <ion-header>
    <ion-toolbar class="header-toolbar" color="secondary">

      <ion-label v-if="VUE_APP_ENVIRONMENT !=='production'" slot="start" class="test-label" >
        {{ VUE_APP_ENVIRONMENT }}
      </ion-label>

      <ion-buttons v-if="!noBackButton" class="hiddenForMobile" slot="start">
        <ion-back-button class="btn-back" text=""  defaultHref="/"></ion-back-button>
      </ion-buttons>

      <ion-button v-if="!noHomeLink" @click.prevent="goHome" color="secondary" class="btn-home" slot="start" >
        <ion-icon src="assets/icon/home-solid.svg"></ion-icon>
      </ion-button>

      <slot>
        <ion-title v-if="user && typeof title === 'undefined'">
          <span v-if="user.first_name+user.last_name !== ''"  id='user'>{{user.first_name}} {{user.last_name}}</span>
          <span v-else  id='user'>{{user.username}}</span>
        </ion-title>
      </slot>

      <a v-if="homePage" href="https://c4k.nubeo.be/guide-d-utilisation" target="_blank" class="link-help" slot="end" >
        <ion-icon color="white" src="assets/icon/circle-question-solid.svg"></ion-icon>
      </a>

      <ion-menu-button v-if="!homePage" slot="end"  fill="clear" color="white">
        <ion-icon src="assets/icon/cog-solid.svg"></ion-icon>
      </ion-menu-button>

      <ion-button v-if="homePage" id='disconnect-btn' slot="end" @click.prevent="confirmDisconnect" fill="clear" color="white">
        <ion-icon :icon="powerSharp"></ion-icon>
      </ion-button>

    </ion-toolbar>
  </ion-header>

  <!-- <part-main-menu ref="mainMenu" ></part-main-menu> -->

</template>

<script>
import {
  IonTitle, IonToolbar, IonIcon, IonBackButton, IonButtons, 
  IonButton, IonMenuButton, IonHeader, alertController, IonLabel
} from '@ionic/vue';
import {copyOutline, powerSharp } from 'ionicons/icons';
import {defineComponent } from 'vue';
import {getAPI} from "@/axios-api";

export default defineComponent({
  name: "PageHeader",
  components: {
    IonTitle, IonToolbar,  IonIcon, IonHeader, IonLabel,
    IonBackButton, IonButtons, IonButton, IonMenuButton
  },
  props:['title', 'noBackButton', 'noHomeLink', 'homePage'],
  data() {
    return  {
      VUE_APP_ENVIRONMENT: process.env.VUE_APP_ENVIRONMENT
    }
  },
  methods: {
    async confirmDisconnect(){
      const alertMsg = {
          message: `Voulez-vous vraiment vous déconnecter ?`,
          buttons: [
            {
              text: "Retour",
            },
            {
              text: "Déconnexion",
              cssClass: "btn-danger",
              handler: ()=> {
                this.disconnect()
              }
            }
          ]
        }
        const alertItem = await alertController.create(alertMsg);
        return alertItem.present();
    },

    disconnect() {
      localStorage.setItem('auth-token', null)
      localStorage.setItem('user', null)
      this.$store.commit('unloadHome')
      delete getAPI.defaults.headers.common ['Authorization']
      this.$router.push('/login')
    },
    goHome() {
      this.$router.replace('/home')
    },
  },
  computed: {
    user() {
      return this.$store.getters.currentUser
    },
  },
  setup () {
    return {
      copyOutline, powerSharp
    }
  },
});
</script>

<style scoped>
  .link-help ion-icon{
    width: 20px;
    height: 20px;
    margin-top: 5px;
  }
  .title-main{
    font-size: 16px;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    direction: rtl;
  }
  .icon-cog{
    margin-left: 0.5rem;
    width: 1rem;
  }

  .btn-home{
    --box-shadow: 0;
  }
  .test-label{
    font-weight: bold;
    font-size: 0.7rem;
    color: #fff;
    background: rgba(231, 59, 0, 1);
    padding: 0 20px;
    position: absolute;
    top: 8px;
    left: 45%;
    z-index: 0;
    opacity: 0.5;
  }

</style>
