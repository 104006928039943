import {getAPI} from "@/axios-api";
import moment from "moment"

const state = () => ({
    reservations:{},
})
const mutations = {
    setMealReservations(state: any, reservations: any){
        // Set state reservations sorted by date
        reservations.forEach((reservation:any)=>{
            const date = moment(reservation.item.start_at).format("YYYY-MM-DD")
            if (!state.reservations[date]){
                state.reservations[date] = []
            }
            state.reservations[date].push(reservation)
        })
    },
}
const getters = {
    getMealReservations: (state: any) => (date:any) => {
        // Return the reservations for one day
        return state.reservations[date]
    },
}
const actions = {
    async fetchMealReservationsByDay({ commit, state }: any, {schoolUuid, date}: any){
        // Fetch reservation for module meal for one day
        if(!state.reservations[date]){
            await getAPI.get('modules/meals-module/school/' + schoolUuid + '/reservations',{
                params: {
                  date: date,
                }
              }).then((response) => {
                commit('setMealReservations', response.data.data)
              })
        }
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}