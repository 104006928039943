<template>
    <ion-page>
      <ion-header>
        <page-header>
            <ion-title class="header-link" v-if="itemType && student">
                <router-link enterkeyhint="go" to="/home" replace id="add-classroom-home-link">Mes garderies</router-link> / 
                <router-link enterkeyhint="go" :to="`/nursery/${this.$route.params.schoolUuid}`" replace id="add-classroom-home-link">{{ schoolName }}</router-link> / 
                <router-link enterkeyhint="go" :to="`/nursery/${this.$route.params.schoolUuid}/types/${itemType.uuid}`" replace id="add-classroom-home-link">{{ itemType.name }}</router-link> / 
                {{ student.first_name }} {{ student.last_name }}
            </ion-title>
        </page-header>
      </ion-header>
      <ion-content :fullscreen="true">
        <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
            <ion-refresher-content></ion-refresher-content>
        </ion-refresher>
        <div class="container">
            <ion-card color="light" v-if="itemType && student" >
                <ion-card-header>
                    <ion-card-subtitle>{{itemType.module.name}}</ion-card-subtitle>
                    <ion-card-title>
                        {{itemType.name}}
                    </ion-card-title>
                    <ion-card-title>
                        {{ student.first_name }} {{ student.last_name }}
                    </ion-card-title>
                </ion-card-header>
            </ion-card>
            <ion-button expand="block"
            :router-link="`/nursery/${$route.params.schoolUuid}/types/${$route.params.typeUuid}/student/${$route.params.studentUuid}/check-in`" router-direction="forward">
                Entrée
            </ion-button>
            <ion-button expand="block"
            :router-link="`/nursery/${$route.params.schoolUuid}/types/${$route.params.typeUuid}/student/${$route.params.studentUuid}/check-out`" router-direction="forward">
                Sortie
            </ion-button>
        </div>
      </ion-content>
      <ion-footer>
        <page-footer></page-footer>
      </ion-footer>
    </ion-page>
  </template>
  
  <script>
  import {IonPage, IonContent, IonFooter, IonHeader, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonButton, IonRefresher, IonRefresherContent } from '@ionic/vue';
  import PageHeader from "@/views/components/PageHeader";
  import PageFooter from "@/views/components/PageFooter";
  import {defineComponent} from 'vue';
  import {NURSERY_MODULE_SLUG} from "@/utils"
  
  export default defineComponent({
    name: "NurseryInOutChoice",
    components: {
      IonPage, IonContent, IonFooter, IonHeader, IonRefresher, IonRefresherContent,
      IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonButton,
      PageHeader, PageFooter
    },
    setup() {
      return {
        NURSERY_MODULE_SLUG
      }
    },
    methods: {
        doRefresh(event = null){
            this.$store.dispatch('school/reloadModuleItemTypes').then(()=>{
                this.$store.dispatch('school/reloadSchoolStudents').then(()=>{
                    if (event !== null) {
                        event.detail.complete();
                    }
                })
            }) 
        },
    },
    computed: {
        itemType() {
            return this.$store.getters["itemType/getItemType"](this.$route.params.typeUuid);
        },
        student() {
            return this.$store.getters['school/currentStudent']
        },
        schoolName() {
            return this.$store.getters["school/getSchoolName"];
        }
    },
    mounted(){
        this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid).then(()=>{
            this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid}).then(()=>{
                this.$store.dispatch('school/loadModules', {schoolUuid: this.$route.params.schoolUuid}).then(()=>{
                    const module_uuid = this.$store.getters["module/getModuleBySlug"](this.NURSERY_MODULE_SLUG).uuid
                    this.$store.dispatch('school/navigateToModule', module_uuid).then(()=>{
                        this.$store.dispatch('school/loadModuleItemTypes')
                        this.$store.dispatch('school/loadStudents').then(()=>{
                            this.$store.dispatch('school/navigateToStudent', this.$route.params.studentUuid)
                        })
                    })
                })
            })
        })
    }
  })
  </script>
  
  <style scoped>
  
  </style>