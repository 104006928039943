<template>
  <ion-page>
    <page-header
      :title="$store.getters['students/currentStudent'].first_name + ' ' + $store.getters['students/currentStudent'].last_name + '/' + $store.getters['students/currentModule'].name"
    ></page-header>
    <ion-content v-if="item">
      <ion-card color="light">
        <img v-if="item.b64_image" :src="'data:image/png;base64,' + item.b64_image" />
        <ion-card-header>
          <ion-card-title>{{item.name}}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          {{item.description}}
          <br>

          <br>
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-button expand="block" @click.prevent="removeQuantity">-</ion-button>
              </ion-col>
              <ion-col style="text-align: center; font-size: 1.3em;">
                <strong>{{quantity}}</strong>
              </ion-col>
              <ion-col>
                <ion-button expand="block" @click.prevent="addQuantity">+</ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button expand="block" @click="buyItem">Réserver {{quantity}} {{item.description}} pour {{(item.price / 100) * quantity}} &euro;</ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>
    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonFooter,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent, IonButton, alertController
} from "@ionic/vue";
import PageFooter from "@/views/components/PageFooter";
import PageHeader from "@/views/components/PageHeader";

export default {
  name: "ItemModuleWrapper",
  components: {
    IonPage,
    IonContent,
    IonFooter,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent, IonButton,
    PageFooter,
    PageHeader,
  },
  data() {
    return {
      quantity: 1
    };
  },
  methods: {
    addQuantity() {
      this.quantity++;
    },
    removeQuantity() {
      if (this.quantity - 1 > 0) {
        this.quantity--;
      }
    },
    buyItem() {
      this.$store.dispatch('students/buyItem', {
        student_uuid: this.$route.params.studentUuid,
        item_uuid: this.$route.params.itemUuid,
        quantity: this.quantity
      }).then(async () => {
        const alertItem = await alertController
            .create({
              message: 'Votre réservation est bien prise en compte. Celle-ci s\'affichera dans vos transactions dans quelques minutes.',
              buttons: ['OK']
            })
        return alertItem.present();

      }).catch(async (error) => {
        const alertItem = await alertController
            .create({
              header: 'Erreur',
              message: error.response.data.data,
              buttons: ['OK']
            })
        return alertItem.present();
      }).finally(() => {
        console.log("finally of buy");
      });
    }
  },
  computed: {
    item: function() {
      return this.$store.getters['students/currentModule']['item_types'][this.$route.params.typeUuid]['items'][this.$route.params.itemUuid];
    }
  }
}
</script>

<style scoped>

</style>