<template>
  <ion-page>

    <ion-header>
      <page-header>
        <ion-title class="header-link" id="school-classrooms-ariane">
          <router-link enterkeyhint="go" to="/home" replace id="school-classrooms-home-link">Mes écoles</router-link> / 
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}`" replace id="school-classrooms-school-link">{{schoolName}}</router-link> /
          Classes
        </ion-title>
      </page-header>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-fab vertical="bottom" horizontal="end" slot="fixed" id="add-classroom">
        <ion-fab-button @click.prevent="goAddClassroom">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <div v-if="!classrooms">
        <p style="text-align: center">
          Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
        </p>
      </div>
      <div class="container"  v-else>
        <ion-card> 
          <ion-card-header color="light" >
            <ion-card-title>{{ schoolName }}</ion-card-title> 
          </ion-card-header>
        </ion-card>
        <ion-list class="listPadding" >
          <ion-item color="white" v-for="(classroom, clKey) in classrooms.sort((a,b)=>sortingName(a,b,['name']))" :key="clKey" :v-bind="'classroom-'+classroom.id"
                    :router-link="'/school/' + schoolUuid +'/classrooms/' + classroom.uuid">
            <ion-label>
              <h2>{{ classroom.name }}</h2>
            </ion-label>
            <ion-button @click.prevent='goToClassroomEdit(classroom)' class="btn-modifyProfil" shape="round"  size="small" color="white" >
              <ion-icon src="/assets/icon/pen-solid.svg" ></ion-icon>
            </ion-button>
            <ion-button class="btn-delete" @click.prevent="confirmDelete(classroom)" color="white" fill="solid" size="small">
              <ion-icon src="assets/icon/trash-solid.svg"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>

     <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonList, IonItem, IonIcon, IonHeader, IonFooter, IonButton, IonLabel, IonRefresher, IonFab, IonFabButton,
  alertController, IonCard, IonCardHeader, IonRefresherContent, IonCardTitle, IonTitle
} from '@ionic/vue';
import {defineComponent} from 'vue';
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {add, copyOutline, trash} from "ionicons/icons";
import {doDeleteElement, sortingName} from '@/utils';


export default defineComponent({
  name: "SchoolClassrooms",
  components: {
    IonPage,
    IonContent, IonHeader, IonFooter, IonButton, IonLabel, IonRefresher, IonFab, IonFabButton, IonTitle,
    PageHeader, PageFooter, IonList, IonItem, IonIcon, IonCard, IonCardHeader, IonRefresherContent, IonCardTitle
  },
  data() {
    return {
      schoolUuid: null
    }
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    classrooms(){
      return this.$store.getters["school/getSchoolClassrooms"]({query: this.search, schoolUuid: this.$route.params.schoolUuid})
    }
  },
  mounted(){
    this.schoolUuid = this.$route.params.schoolUuid
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid)
    this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid})
    this.$store.dispatch('school/loadClassrooms')
  },
  methods: {
    doRefresh(event){
      this.$store.dispatch('school/reloadSchoolClassrooms').then(()=>{
        event.target.complete()
      }) 
    },
    goAddClassroom() {
      this.$router.push('/school/' + this.schoolUuid + '/add_classroom')
    },
    goToClassroomEdit(classroom){
      this.$router.push('/school/'+ this.schoolUuid + '/classrooms/' + classroom.uuid + '/edit' )
    },
    async confirmDelete(classroom) {
      const alertMsg = {
        message: "Voulez vous vraiment supprimer la classe " + classroom.name + " ? ",
        buttons: [
          {
            text: "Retour",
            role: "cancel"
          },
          {
            text: "Continuer",
            cssClass: "btn-danger",
            handler: ()=> {
              this.deleteClassroom('schools/classroom', classroom)
            }
          }
        ]
      }
      const alertItem = await alertController.create(alertMsg);
      return alertItem.present();
    },
    deleteClassroom(model, object) {
      doDeleteElement(model, object, object.name, true).then(() => {
        this.$store.dispatch('school/removeClassroom',object.uuid)
      }).catch(async () => {
        const alertItem = await alertController
            .create({
              header: 'Erreur lors de la suppression :',
              message: 'Cet objet ne peut pas être supprimé !!',
              buttons: ['OK']
            })
        return alertItem.present();
      })
    }
  },
  setup() {
    return {
      copyOutline, add, trash, doDeleteElement, sortingName
    }
  },
});
</script>

<style scoped>
  ion-item:hover, ion-item:focus{
    --ion-color-base: var(--ion-color-light);
    cursor: pointer;
  }
  .btn-delete{
    --box-shadow: none;
    --ion-color-base: none;
  }
  .btn-modifyProfil {
    --box-shadow: none;
    --ion-color-base: none;
  }
</style>
