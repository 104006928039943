import {getAPI} from "@/axios-api";

const state = () => ({
    classrooms:{},
    currentClassroomUuid:null
})
const mutations = {
    setCurrentClassroomUuid(state: any, classroomUuid: string){
        state.currentClassroomUuid = classroomUuid
    },
    setCurrentClassroom(state: any, data: any){
        state.classrooms[state.currentClassroomUuid] = data
    },
    setClassroom(state: any, data: any){
        state.classrooms[data.uuid] = data
    },
    removeClassroom(state: any){
        delete state.classrooms[state.currentClassroomUuid]
    },
    updateClassroom(state: any, data: any){
        state.classrooms[state.currentClassroomUuid] = data
    },
    addTeacher(state:any, newTeacher:any){
        state.classrooms[state.currentClassroomUuid].teachers.push(newTeacher)
    },
    removeTeacher(state:any, teacherUuid:string){
        const classroom = state.classrooms[state.currentClassroomUuid]
        classroom.teachers = classroom.teachers.filter((teacher:any)=>teacher.uuid != teacherUuid)
    },
    setClassroomWeekMeals(state:any, {week,year,data}:any){
        const classroom = state.classrooms[state.currentClassroomUuid]
        if (!classroom.weeks){
            classroom.weeks = []
        }
        const weekData = {week:week, year:year, meals:data}
        classroom.weeks.push(weekData)
    }
}
const getters = {
    getClassroom(state: any){
        return state.classrooms[state.currentClassroomUuid]
    },
    getClassroomStudents: (state: any) => (query:string) => {
        const classroom = state.classrooms[state.currentClassroomUuid]
        if(query){
            //lowercase + retire les accents
            query= query.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '')
            return classroom.students && classroom.students.filter((student:any)=>
                student.last_name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '').includes(query) || 
                student.first_name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '').includes(query)
            )
        }
        return classroom && classroom.students
    },
    getClassroomWeekMeals: (state:any) => ({year,week}:any) => {
        const classroom = state.classrooms[state.currentClassroomUuid]
        const mealWeeks = classroom.weeks && classroom.weeks.find((weekData:any)=>weekData.year == year && weekData.week == week)
        return mealWeeks && Object.values(mealWeeks.meals).filter((day:any) => day.meals.length > 0);
    }
}
const actions = {
    navigateToClassroom({ commit, state }: any, classroomUuid: any){
        if(state.currentClassroomUuid != classroomUuid){
            commit("setCurrentClassroomUuid", classroomUuid)
        }
    },
    async loadClassroom({ commit, state, rootState }: any){
        if(!state.classrooms[state.currentClassroomUuid]?.students){
            await getAPI.get('/schools/' + rootState.school.currentSchoolUuid + '/classrooms/' + state.currentClassroomUuid).then((response: any) => {
                commit("setCurrentClassroom", response.data.data)
            })
        }
    },
    updateClassroom({ commit, state }:any, newClassroom:any){
        if(state.classrooms[state.currentClassroomUuid]){
            commit("updateClassroom", newClassroom)
        }else{
            commit("setCurrentClassroom", newClassroom)
        }
    },
    refreshClassroom({ commit, dispatch }: any){
        commit("removeClassroom")
        return dispatch('loadClassroom')
    },
    addTeacher({commit}:any, newTeacher:any){
        commit('addTeacher', newTeacher)
    },
    removeTeacherFromClassroom({commit}:any, teacherUuid:string){
        commit('removeTeacher', teacherUuid)
    },
    async loadClassroomWeekMeals({ dispatch, commit, state, rootState }: any, {week,year}:any){
        await dispatch('loadClassroom').then(async()=>{
            const classroom = state.classrooms[state.currentClassroomUuid]
            const mealWeek = classroom.weeks && classroom.weeks.some((week:any)=>week.year == year && week.week == week)
            if (!mealWeek){
                await getAPI.get('/meals/schools/' + rootState.school.currentSchoolUuid + '/classrooms/' + state.currentClassroomUuid + '/meals', {
                    params: {
                      year: year,
                      week: week
                    }
                  }).then((response) => {
                    commit('setClassroomWeekMeals', {week:week,year:year,data:response.data.data})
                  })
            }
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}