const state = () => ({
    managers: {}
})

const mutations = {
    setManager(state: any, data: any) {
        state.managers[data.uuid] = data
    }
}

const getters = {

}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
}