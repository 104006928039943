<template>
  <ion-page>
    <page-header :noHomeLink="true"></page-header>

    <ion-content :fullscreen="true">
      <div class="container" >
          <form class="form" @submit.prevent="resetPassword">
              <ion-header>
                <h2>Mot de passe oublié</h2>
              </ion-header>
              <ion-item lines="none" >
                  <ion-label position="floating" >Adresse email</ion-label>
                  <ion-input required v-model="email" type="email" ></ion-input>
              </ion-item>

              <ion-button type="submit" shape="round" id="reset-password">Réinitialiser mon mot de passe</ion-button>
          </form>
      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonFooter,
  toastController
} from '@ionic/vue';
import { defineComponent } from 'vue';
import {getAPI} from "@/axios-api";
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";

export default defineComponent({
  name: "ResetPassword",
  components: {
    IonPage, IonContent, PageHeader, PageFooter, IonFooter, IonHeader, IonItem, IonLabel, IonInput, IonButton
  },
  data() {
    return  {
        email: null
    }
  },
  methods: {
      resetPassword() {
        getAPI.put('/api/user/reset-password', {email: this.email}).then(async () => {
          const toast = await toastController
              .create({
                message: 'Si vous êtes encodé dans l\'école de votre enfant, un email pour réinitialiser votre mot de passe vous a été envoyé. Veuillez suivre les instructions de celui-ci.',
                duration: 5000
              })
          this.$router.back();
          return toast.present();
        })
      }
  }
});
</script>

<style scoped>
    ion-header::after{
        height: 0;
    }
    ion-header{
        color: var(--ion-color-white-contrast);
        margin-bottom: 2rem;
    }
    .form ion-item{
        margin-bottom: 2rem;
    }
</style>