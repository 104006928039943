import {getAPI} from "@/axios-api";
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { getPlatforms } from '@ionic/vue';
import { detect} from "detect-browser";

const state = () => ({
    homeDataLoaded: false,
    homeDatas: {
        classrooms: [],
        greetersSchools: [],
        legalTutors: [],
        schools: [],
        students: [],
        townships: [],
        directed_schools: [],
        managers: {},
        nurseryModule: []
    },
    currentUser: {
        email: "",
        firstName: "",
        id: null,
        lastName: "",
        username: "",
    }
})

const mutations = {
    setHomeDataLoaded(state: any) {
        state.homeDataLoaded = true
    },
    resetStudent (state: any) {
        state.students = []
    },
    setSchool(state: any, data: any) {
        if (!state.homeDatas.schools.includes(data.uuid)){
            state.homeDatas.schools.push(data.uuid)
        }
    },
    setDirectedSchool(state: any, data: any) {
        state.homeDatas.directed_schools.push(data.uuid)
    },
    setHome (state: any, data: any) {
        state.homeDatas.classrooms = data.classrooms.map((classroom:any)=>classroom.uuid)
        state.homeDatas.greetersSchools = data.greeters_schools.map((school:any)=>school.uuid)
        state.homeDatas.legalTutors = data.legal_tutors.map((tutor:any)=>tutor.uuid)
        state.homeDatas.students = data.students.map((student:any)=>student.uuid)
        state.homeDatas.townships = data.townships.map((township:any)=>township.uuid)
        state.homeDatas.nurseryModule = data.nursery_schools.map((school:any)=>school.uuid)
        state.currentUser = data.user
    },
    unloadHome (state: any) {
        state.homeDatas = {
            classrooms: [],
            greetersSchools: [],
            legalTutors: [],
            schools: [],
            students: [],
            townships: [],
            managers: {},
        }
    },
    setManager(state: any, data: any) {
        state.homeDatas.managers[data.uuid] = data
    }
}

const getters = {
    isHomeDataLoaded (state: any) {
        return state.homeDataLoaded
    },
    userDatas (state: any) {
        return state.homeDatas
    },
    currentUser (state: any) {
        return state.currentUser
    },
    isDirector: (state: any) => (currentSchoolUuid: string) => {
        let is_director = false
        state.homeDatas.directed_schools.forEach((studentUuid: any) => {
            is_director = (studentUuid === currentSchoolUuid) ? true : is_director;
        })
        return is_director
    },
    isTeacher: (state: any) => (currentClassroomUuid: string) => {
        let is_teacher = false
        state.homeDatas.classrooms.forEach((classroomUuid:any) => {
          if(classroomUuid === currentClassroomUuid){
            is_teacher = true
          }
        })
        return is_teacher
    },
    isLegalTutor: (state:any) => (currentStudentUuid: string) => {
        let is_legal_tutor = false
        state.homeDatas.students.forEach((studentUuid: any) => {
            is_legal_tutor = (studentUuid === currentStudentUuid) ? true : is_legal_tutor;
        })
        return is_legal_tutor
    },
    isManager: (state:any) => (currentSchoolUuid: string) => {
        let is_manager = false
        Object.keys(state.homeDatas.managers).forEach((school_uuid: any) => {
            is_manager = (school_uuid === currentSchoolUuid) ? true : is_manager;
        })
        return is_manager
    },
    getUserDirectorSchools: (state: any, getters:any, rootState:any) => {
        return state.homeDatas.directed_schools.map((uuid:string)=>rootState.school.schools[uuid])
    },
    getSchools: (state: any, getters:any, rootState:any) => {
        return state.homeDatas.schools.map((uuid:string)=>rootState.school.schools[uuid])
    },
    getUserClassrooms: (state:any, getters:any, rootState:any) => {
        return state.homeDatas.classrooms.map((uuid:string)=>rootState.classroom.classrooms[uuid])
    },
    getUserGreeterSchools: (state:any, getters:any, rootState:any) => {
        return state.homeDatas.greetersSchools.map((uuid:string)=>rootState.school.schools[uuid])
    },
    getUserLegalTutors: (state:any, getters:any, rootState:any) => {
        return state.homeDatas.legalTutors.map((uuid:string)=>rootState.legalTutor.legalTutors[uuid])
    },
    getUserStudents: (state:any, getters:any, rootState:any) => {
        return state.homeDatas.students.map((uuid:string)=>rootState.students.students[uuid])
    },
    getUserTownships: (state:any, getters:any, rootState:any) => {
        return state.homeDatas.townships.map((uuid:string)=>rootState.township.townships[uuid])
    },
    getUserNurseryModule: (state:any, getters:any, rootState:any) => {
        return state.homeDatas.nurseryModule.map((uuid:string)=>rootState.moduleSubscription.modulesSubscriptions[uuid])
    }
}

const actions = {
    loadHome({ commit, dispatch }: any){
        return new Promise((resolve, reject) => {
            let device = ''
            const platforms = getPlatforms()
            if (platforms.includes('desktop')) {
                const browser = detect();
                device = window.btoa(JSON.stringify({
                    cordova: null,
                    isVirtual: null,
                    manufacturer: (browser !== null ? browser.name : null),
                    model: (browser !== null ? browser.type : null),
                    platform: (browser !== null ? browser.os : null),
                    serial: platforms.join(','),
                    uuid: null,
                    version: (browser !== null ? browser.version : null),
                }))
            } else {
                device = window.btoa(JSON.stringify(new Device()))
            }

            getAPI.get('/api/home/', {
                params: {
                    context: device
                }
            }).then((response: any) => {
                commit('setHome', response.data.data)
                Object.keys(response.data.data.managers).forEach((key: any) => {
                    commit('manager/setManager', response.data.data.managers[key], {root: true})
                    commit('setSchool', response.data.data.managers[key].school)
                    commit('setManager', response.data.data.managers[key].school)
                    commit('school/setSchool', response.data.data.managers[key].school, {root: true})
                })
                response.data.data.classrooms.forEach((classroom: any) => {
                    commit('classroom/setClassroom', classroom, {root: true})
                    commit('school/setSchool', classroom.school, {root: true})
                })
                response.data.data.legal_tutors.forEach((legalTutor: any) => {
                    commit('legalTutor/setLegalTutor', legalTutor, {root: true})
                })
                response.data.data.students.forEach((student: any) => {
                    commit('students/setStudent', {data: student}, {root: true})
                })
                response.data.data.townships.forEach((township: any) => {
                    commit('township/setTownship', township, {root: true})
                })
                response.data.data.schools.forEach((school: any) => {
                    commit('school/setSchool', school, {root: true})
                    commit('setSchool', school)
                    commit('setDirectedSchool', school)
                })
                response.data.data.greeters_schools.forEach((school: any) => {
                    commit('school/setSchool', school, {root: true})
                })
                response.data.data.students.forEach((student: any) => {
                    dispatch('students/syncCompleteStudent', {student_uuid: student.uuid}, {root: true})
                })
                response.data.data.nursery_schools.forEach((moduleSubscription: any) => {
                    commit('moduleSubscription/setModuleSubscription', moduleSubscription, {root: true})
                })
                commit('setHomeDataLoaded')
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
