<template>
  <ion-grid>
    <ion-row>
      <ion-col size="6" v-for="(module, key) in modules" :key="key">
        <ion-button @click="goToModule(module)" class="btn-img" color="light" expand="block" >
          <div>
            <ion-img :src="'data:image/png;base64,' + module.b64_image" alt="Bouton repas"></ion-img>
            <ion-label class="btn-img__label" color="dark">{{module.name}}</ion-label>
          </div>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonImg,
  IonLabel
} from '@ionic/vue';
export default {
  name: "StudentModules",
  components: {
    IonButton,
    IonImg,
    IonLabel,
    IonCol,
    IonRow,
    IonGrid
  },
  props: {
    modules: {
      type: Object,
      required: true
    }
  },
  methods: {
    goToModule(module) {
      this.$store.dispatch('students/setCurrentModule',
          {
            student_uuid: this.$route.params.studentUuid,
            module_uuid: module.uuid
          }
      ).then(() => {
        console.log('Current module set')
      });
      if (module.settings.module && module.settings.module.path) {
        this.$router.push(module.settings.module.path.replace('${student_uuid}', this.$route.params.studentUuid));
      } else {
        this.$router.push('/student/' + this.$route.params.studentUuid + '/module/' + module.uuid);
      }
    }
  }
}
</script>

<style scoped>

ion-grid{
  max-width: 564px;
}
ion-col{
  --ion-grid-column-padding: 16px;
}
.btn-img{
  position: relative;
  border-radius: 5px;
  max-width: 250px;
  margin: 0 auto;
  height: 100%;
  --padding-top: 100%;
}
.btn-img div{
  position: absolute;
  bottom: 16px;
}
.btn-img ion-img{
  width: 80%;
  margin: 0 auto 8px;
}
.btn-img__label{
  font-weight: bold;
}

@media (max-width: 450px){
  ion-col{
    --ion-grid-column-padding: 8px;
  }
}
@media (max-width: 400px){
  .btn-img{
    width: 100%;
  }
}
@media (max-width: 360px){
  .btn-img__label{
    font-size: 0.7rem;
  }
}
</style>