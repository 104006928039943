import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { IonicVue } from '@ionic/vue';
import store from "./store/index"
import { createI18n } from 'vue-i18n'
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/typo.css';
import './theme/global.css';
import './theme/form.css';

/* CSS for other package */
import 'v-calendar/dist/style.css';

const i18n = createI18n({
  locale: 'fr',
  messages: {
    "fr": {
      "home": {
        "attendances": "Presences",
        "my_children": "Mes enfants",
        "my_classes": "Mes classes",
        "my_schools": "Mes écoles",
        "my_townships": "Mes communes",
        "summary_of_balances": "Récapitulatif des soldes",
        "receive_the_balance_summary": "Recevoir le récapitulatif des soldes",
      }
    }
  }
})

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store);

app.use(i18n);

router.isReady().then(() => {
  app.mount('#app');
});