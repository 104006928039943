import { RouteRecordRaw } from 'vue-router';
import ViewTownship from "@/views/pages/township/ViewTownship.vue"
import ManageMenus from "@/views/pages/township/ManageMenus.vue"
import ImportMenus from "@/views/pages/township/ImportMenus.vue"
import EditMenu from "@/views/pages/township/EditMenu.vue"
import ViewReservationRecap from "@/views/pages/township/ViewReservationRecap.vue"

const routesTownship: Array<RouteRecordRaw> = [
    {
        path: '/townships/:townshipUuid',
        name: 'ViewTownship',
        component: ViewTownship,
    },
    {
        path: '/townships/:townshipUuid/manage_menus',
        name: 'ManageMenusTownship',
        component: ManageMenus,
    },
    {
        path: '/townships/:townshipUuid/add_menus',
        name: 'ImportMenusTownship',
        component: ImportMenus,
    },
    {
        path: '/townships/:townshipUuid/school/:schoolUuid/menu/:menuUuid/edit_menus',
        name: 'EditMenuTownship',
        component: EditMenu
    },
    {
        path: '/townships/:townshipUuid/reservation_recap',
        name: 'ViewReservationRecap',
        component: ViewReservationRecap,
    },
]

export default routesTownship