<template>
  <ion-page>
    <page-header></page-header>
    <ion-content :fullscreen="true" v-if="student.id !== null">
      <div class="container">
        <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
          <ion-refresher-content></ion-refresher-content>
        </ion-refresher>
        <ion-card color="light">
          <ion-card-header>
            <ion-card-title>{{student.first_name}} {{student.last_name}}</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <h1><em>Solde de l'élève:</em>&nbsp;
              <strong v-if="payments.total_balance >= 0" style="color: green">{{payments.total_balance}}&nbsp;&euro;</strong>
              <strong v-else style="color: red">{{payments.total_balance}}&nbsp;&euro;</strong>
            </h1>
          </ion-card-content>
        </ion-card>
        <ion-card>
          <ion-item lines="none">
            <ion-label>Solde à ajouter:</ion-label>
            <ion-input v-model="amount" type="number" step="0.1" placeholder="15.0">
            </ion-input>
          </ion-item>

          <ion-button @click.prevent="makePayment">Ajouter au solde de mon enfant</ion-button>
        </ion-card>
        <div class="alert" v-if="showReloadAlert">
          Recharger la page pour voir vos nouveaux paiements
        </div>
        <ion-list v-if="false">
          <ion-list-header>
            <ion-label>
              Mes virements
            </ion-label>
          </ion-list-header>
          <ion-item v-for="(payment, pKey) in payments.payments" :key="pKey">
            <ion-label>
              <h2>{{payment.amount}}&nbsp;&euro;
                <span v-if="payment.source === 2">
                <ion-badge style="font-size: 0.6em" v-if="parseInt(payment.status) === 0" color="dark">Inconnu</ion-badge>
                <ion-badge style="font-size: 0.6em" v-else-if="parseInt(payment.status) === 100" color="warning">En attente</ion-badge>
                <ion-badge style="font-size: 0.6em" v-else-if="parseInt(payment.status) === 200" color="primary">En cours</ion-badge>
                <ion-badge style="font-size: 0.6em" v-else-if="parseInt(payment.status) === 300" color="success">Succès</ion-badge>
                <ion-badge style="font-size: 0.6em" v-else-if="parseInt(payment.status) === 400" color="success">Refusé</ion-badge>
                <ion-badge style="font-size: 0.6em" v-else color="dark">Inconnu</ion-badge>
              </span>
              </h2>
              <h3>{{moment(payment.payment_date).format("DD MMM YYYY")}}</h3>
              <p v-if="payment.source === 0">
                En liquide, à {{payment.receiver.last_name}} {{payment.receiver.first_name}} ({{payment.receiver.username}}),
                <span v-if="payment.legal_tutor">
                de {{payment.legal_tutor.user.last_name}} {{payment.legal_tutor.user.first_name}} ({{payment.legal_tutor.user.username}})
              </span>
              </p>
              <p v-if="payment.source === 2">
                Paiement en ligne,
                <span v-if="payment.legal_tutor">
                de {{payment.legal_tutor.user.last_name}} {{payment.legal_tutor.user.first_name}} ({{payment.legal_tutor.user.username}})
              </span>
              </p>
              <p v-if="payment.source === 1">Depuis le compte BE01 1234 5678 9012</p>
            </ion-label>
          </ion-item>
          <ion-list-header>
            <ion-label>
              Mes repas futurs
            </ion-label>
          </ion-list-header>
          <ion-item v-for="(reservation, rKey) in payments.next_reservations" :key="rKey">
            <ion-label>
              <h2>{{reservation.meal.price}}&nbsp;&euro;</h2>
              <h3>{{moment(reservation.meal.date).format("DD MMM YYYY") }}: {{reservation.meal.menu.description}}</h3>
            </ion-label>
          </ion-item>
          <ion-list-header>
            <ion-label>
              Mes repas précédents
            </ion-label>
          </ion-list-header>
          <ion-item v-for="(reservation, rKey) in payments.passed_reservations" :key="rKey">
            <ion-label>
              <h2>{{reservation.meal.price}}&nbsp;&euro;</h2>
              <h3>{{moment(reservation.meal.date).format("DD MMM YYYY") }}: {{reservation.meal.menu.description}}</h3>
            </ion-label>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>
  </ion-page>

</template>

<script>
import moment from 'moment'
import { IonContent, IonInput, IonPage, IonList, IonListHeader, IonItem, IonLabel,
  IonCard, IonCardHeader, IonButton, IonCardTitle, IonCardContent, IonBadge, IonRefresher, IonRefresherContent,
} from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import PageHeader from "@/views/components/PageHeader.vue";
import {getAPI} from "@/axios-api";
import { InAppBrowser } from '@ionic-native/in-app-browser';

export default defineComponent({
  name: 'PaymentPage',
  components: {
    IonPage, IonList, IonListHeader, IonItem, IonLabel, IonRefresher, IonRefresherContent,
      IonCard, IonCardHeader, IonButton, IonCardTitle, IonCardContent, IonBadge,
    IonContent, IonInput, PageHeader
  },
  created: function () {
    this.moment = moment;
  },
  data() {
    return {
      payments: [],
      showReloadAlert: false,
      amount: null,
      student: {
        'id': null,
        'first_name': '',
        'last_name': ''
      }
    }
  },
  mounted() {
    this.loadStudent()
    this.loadPayments()
  },
  methods: {
    doRefresh(event) {
      this.loadPayments().then(() => {
        event.target.complete();
        this.showReloadAlert = false
      });
    },
    loadStudent() {
      getAPI.get('students/' + this.$route.params.studentUuid).then((response) => {
        this.student = response.data.data
      })
    },
    loadPayments() {
      return getAPI.get('/payments/students/' + this.$route.params.studentUuid).then((response) => {
        this.payments = response.data.data
      })
    },
    makePayment() {
      const formatter = new Intl.NumberFormat('en',  {
        minimumFractionDigits: 2
      });
      const formattedAmount = formatter.format(this.amount)
      getAPI.post('/payments/students/' + this.$route.params.studentUuid + '/generate', {
        'amount': formattedAmount
      }).then((response) => {
        const browser = InAppBrowser.create(response.data.data.url)
        this.showReloadAlert = true
        browser.on('exit', () => {
          this.loadPayments()
        })
        browser.close();
      })
    }
  },
  computed: {
    walletAmount() {
      let amount = 0.0
      this.payments.forEach((item) => {
        amount = amount + item.amount
      })
      return amount
    }
  },
  setup () {
    return {
      copyOutline
    }
  },
});
</script>

<style scoped>
.alert {
  padding: 8px;
  margin: 12px;
  border-radius: 8px;
  font-size: 0.8em;
  border: #ec9d5a solid 1px;
  background: #f8d1b8;
}
</style>
