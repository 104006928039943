<template>
  <ion-page>
    <ion-header translucent>
      <page-header>
        <ion-title class="header-link" id="school-students-ariane">
          <router-link enterkeyhint="go" to="/home" replace id="school-students-home-link">Mes écoles</router-link> /
          <router-link :to="`/school/${$route.params.schoolUuid}`" replace id="school-students-school-link">
            {{ $store.getters['school/getSchoolNameByUuid']({school_uuid: $route.params.schoolUuid}) || schoolName }}
          </router-link> /
          <router-link :to="`/school/${$route.params.schoolUuid}/module/${module.uuid}`" router-direction="forward">
            Module {{ module.name }} </router-link> / Managers
        </ion-title>
      </page-header>
    </ion-header>

    <ion-content :fullscreen="true">

      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="openAddTypeModal">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>

      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>


      <div class="container">
      
      <div v-if="!module && !managers">
        <p style="text-align: center">
          Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
        </p>
      </div>
      <ion-card v-if="module">
        <ion-card-header color="light">
          <ion-card-title>
            {{module.name}}
          </ion-card-title>
          <em>Managers</em>
        </ion-card-header>
      </ion-card>

      <section>
        <ion-item color="white">
          <ion-label>
            <ion-label class="ion-text-wrap" >Les managers peuvent gérer les données du module.</ion-label>
          </ion-label>
        </ion-item>
        <ion-list v-if="managers && managers.length">
          <ion-item color="white" v-for="(manager, kmanager) in managers.sort((a,b)=>sortingName(a,b,['user.last_name','user.first_name']))" :key="kmanager">
            <ion-label>
              <h2>{{ manager.user.first_name }} {{ manager.user.last_name }}</h2>
              <h3>{{ manager.user.email }}</h3>
            </ion-label>
            <ion-button class="btn-delete" @click.prevent="deleteManager(manager)" color="white" fill="solid" size="small">
              <ion-icon src="assets/icon/trash-solid.svg"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-list>
      </section>

      <ion-modal ref="modal" trigger="open-modal" :is-open="isOpen" @willDismiss="onWillDismiss">
        <ion-header>
          <ion-toolbar>
            <ion-title>Ajouter un manager</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="cancel">Fermer</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <form class="form" @keyup.enter.prevent="createManager" @submit.prevent="createManager">
            <ion-list lines="none" class="ion-no-margin ion-no-padding">
              <ion-item lines="none" id="add-manager-email">
                <ion-label position="floating">Email <ion-text color="danger">*</ion-text></ion-label>
                <ion-input required type="email" v-model="manager.email"></ion-input>
              </ion-item>
              <ion-item lines="none" id="add-manager-first-name">
                <ion-label position="floating">Prénom</ion-label>
                <ion-input type="text" v-model="manager.first_name"></ion-input>
              </ion-item>
              <ion-item lines="none" id="add-manager-last-name">
                <ion-label position="floating">Nom</ion-label>
                <ion-input type="text" v-model="manager.last_name"></ion-input>
              </ion-item>
              <ion-item>
                <ion-checkbox slot="end" v-model="manager.reporter_only" color="primary"></ion-checkbox>
                <ion-label>Rapporteur uniquement</ion-label>
              </ion-item>
            </ion-list>
            <section style="padding: 10px">
              <ion-button shape="round" expand="block" @click.prevent="createManager">Créer et ajouter le manager</ion-button>
            </section>
          </form>
        </ion-content>
      </ion-modal>
      </div>
    </ion-content>
    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage, IonContent, IonIcon, IonItem, IonHeader,
  IonFooter, IonList, IonButton, IonLabel, IonFab, IonFabButton, IonTitle,
  IonCard, IonCardTitle, IonCardHeader, IonSpinner, IonText,
  IonModal, IonToolbar, IonButtons, IonInput, alertController, IonRefresher, IonRefresherContent
} from '@ionic/vue';

import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import { add } from 'ionicons/icons';
import {sortingName, doDeleteElement} from '@/utils';
export default {
  name: "AdminListManagers",
  components: {
    IonCard, IonCardTitle, IonCardHeader,
    IonList, IonItem, IonLabel,
    IonContent,
    IonPage, IonButton,
    PageHeader,
    PageFooter, IonFab, IonFabButton, IonIcon,
    IonHeader, IonFooter, IonTitle, IonSpinner, IonText,
    IonModal, IonToolbar, IonButtons, IonInput, IonRefresher, IonRefresherContent
  },
  data: () => ({
    isOpen: false,
    manager: {
      email: '',
      first_name: '',
      last_name: ''
    },
  }),
  setup() {
    return {
      add, sortingName
    }
  },
  mounted() {
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid).then(()=>{
      this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid}).then(()=>{
        this.$store.dispatch('school/navigateToModule', this.$route.params.moduleUuid).then(()=>{
          this.$store.dispatch('school/loadModules', {schoolUuid: this.$route.params.schoolUuid}).then(()=>{
            this.$store.dispatch('school/loadModuleManagers');
          })
        })
      }) 
    })
    
  },
  methods: {
    doRefresh(event){
      this.$store.dispatch('school/reloadModuleManagers').then(()=>{
        event.target.complete()
      }) 
    },
    openAddTypeModal() {
      this.isOpen = true;
    },
    onWillDismiss() {
      this.isOpen = false;
    },
    cancel() {
      this.isOpen = false;
    },
    createManager() {
      this.$store.dispatch('school/addManagerOnModule', {
        moduleUuid: this.$route.params.moduleUuid,
        manager: this.manager
      }).then(()=>{
        this.doRefresh();
      })
      this.isOpen = false;
    },
    deleteManager(object) {
      doDeleteElement(`schools/${this.$route.params.schoolUuid}/modules/${this.$route.params.moduleUuid}/managers`, object, object.name, true).then(() => {
        this.$store.dispatch('school/removeManagerFromModule',object.uuid)
      }).catch(async () => {
        const alertItem = await alertController
            .create({
              header: 'Erreur lors de la suppression :',
              message: 'Cet objet ne peut pas être supprimé !!',
              buttons: ['OK']
            })
        return alertItem.present();
      })
    }
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    modules(){
      return this.$store.getters["school/getSchoolModules"]
    },
    module(){
      return this.modules[this.$route.params.moduleUuid]
    },
    managers(){
      return this.$store.getters["school/getSchoolModuleManager"]
    }
  },
}
</script>

<style scoped>

.btn-delete{
  --box-shadow: none;
  --ion-color-base: none;
}

</style>