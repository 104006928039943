<template>
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="reLoadUserDatas($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div class="container">
        <form class="form" onSubmit={this.processForm} v-if="user">
            <ion-list lines="none">
              <ion-list-header color="white" v-bind:id="'edit-'+userType+'-header'">
                  Modifier les informations de {{user.first_name}} {{user.last_name}}
              </ion-list-header>
              <ion-item lines="none" v-bind:id="'edit-'+userType+'-email'">
                  <ion-label position="floating">Email
                  <ion-text color="danger">*</ion-text>
                  </ion-label>
                  <ion-input required type="email" v-model="user.email"></ion-input>
              </ion-item>
              <ion-item lines="none" v-bind:id="'edit-'+userType+'-first-name'">
                  <ion-label position="floating">Prénom</ion-label>
                  <ion-input type="text" v-model="user.first_name"></ion-input>
              </ion-item>

              <ion-item lines="none" v-bind:id="'edit-'+userType+'-last-name'">
                  <ion-label position="floating">Nom</ion-label>
                  <ion-input type="text" v-model="user.last_name"></ion-input>
              </ion-item>

            </ion-list>
            <ion-button @click.prevent="saveUser" shape="round" v-bind:id="'edit-'+userType+'-button'">
            Enregistrer
            </ion-button>
        </form>
      </div>
    </ion-content>

    <ion-footer>
        <page-footer></page-footer>
    </ion-footer>

</template>

<script>
import {
  IonContent, IonRefresher, IonRefresherContent, IonButton, IonList, IonItem,
  IonFooter, IonInput, IonLabel, IonText, IonListHeader
} from '@ionic/vue';
import {defineComponent} from 'vue';
import PageFooter from '@/views/components/PageFooter.vue';

export default defineComponent({
  name: 'PartFormEdit',
  components: {
    IonContent,
    IonRefresher, IonRefresherContent, PageFooter, IonButton, IonList, IonItem,
    IonFooter, IonInput, IonLabel, IonText, IonListHeader
  },
  data() {
    return {
      user: this.userProps,
    }
  },
  props:['userProps', 'userType'],
  methods:{
    saveUser(){
      this.$emit('saveUser')
    },
    reLoadUserDatas(event){
      this.$emit('reLoadUserDatas', event)
    }
  },
});
</script>

<style scoped>

</style>
