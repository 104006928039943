import {getAPI} from "@/axios-api";

const state = () => ({
    items:{},
    new_item:{
        uuid: null,
        price: null,
        sell_end_at: null,
        student_maximum_quantity: null,
        sell_start_at: null,
        description: null,
        start_at: null,
        name: null,
        maximum_quantity: null,
        image: null,
        end_at: null,
    },
})

const mutations = {
    setItem(state: any, data: any){
        state.items[data.uuid] = data
         state.items[data.uuid].tags = {}
         state.items[data.uuid].available_tags = {}
    },
    removeItem(state: any, uuid: string){
        delete state.items[uuid]
    },
    updateItem(state: any, data: any){
        state.items[data.uuid] = data
    },
    addTagToItem(state: any, {data, item_uuid}: any){
        state.items[item_uuid].tags[data.uuid] = data.uuid
    },
    setAvailableTag(state: any, {data, item_uuid}: any){
        state.items[item_uuid].available_tags[data.uuid] = data.uuid
    }
}

const getters = {
    getItem: (state: any, dispatch: any) => (uuid: string) => {
        if (state.items[uuid]){
            return state.items[uuid]
        }
        dispatch('fetchItem', uuid).then(() => {
            return state.items[uuid]
        }).catch((error: any) => {
            console.log(error)
        })
        return state.items[uuid]
    },
    getNewItem: (state: any) => {
        return state.new_item
    },
    getItems: (state: any) => (query: string) => {
        if(query){
            //lowercase + retire les accents
            query= query.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '')
            return Object.values(state.items).filter((item:any)=>
                item.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '').includes(query)
            )
        }
        return Object.values(state.items)
    },
    getItemTags: (state: any) => (uuid: string) => {
        if (state.items[uuid] != undefined){
            return state.items[uuid].tags
        }
        return {}
    },
    getAvailableItemTags: (state: any) => (uuid: string) => {
        if (state.items[uuid] != undefined){
            return state.items[uuid].available_tags
        }
        return {}
    }
}

const actions = {
    navigateToItem({ commit, state }: any, itemUuid: any){
        if(state.currentItemUuid != itemUuid){
            commit('setCurrentItemUuid', itemUuid)
        }
    },
    async fetchItem({ commit, dispatch }: any, itemUuid: any){
        const response = await getAPI.get(`/modules/items/${itemUuid}`)
        commit('setItem', response.data)
        await dispatch('fetchItemTags', itemUuid)
        if ("undefined" != typeof response.data){
            return response.data
        }
        return null
    },
    async createItem({ commit }: any, item: any){
        const response = await getAPI.post('/modules/items', item)
        commit('setItem', response.data)
    },
    async updateItem({ commit }: any, item: any){
        const response = await getAPI.patch(`/modules/items/${item.uuid}`, item)
        commit('updateItem', response.data)
    },
    async deleteItem({ commit }: any, itemUuid: any){
        await getAPI.delete(`/modules/items/${itemUuid}`)
        commit('removeItem', itemUuid)
    },
    async fetchItemTags({ commit }: any, itemUuid: any) {
        const response = await getAPI.get(`/modules/items/${itemUuid}/tags`)
        response.data.data.forEach((tag: any) => {
            commit('tag/setTag', tag, { root: true })
            commit('item/addTagToItem', {data: tag, item_uuid: itemUuid}, { root: true })
        })
    },
    async getAvailableTags({ commit, dispatch }: any, itemUuid: any) {
        const response = await getAPI.get(`/modules/items/${itemUuid}/tags/available`)
        response.data.data.forEach((tag: any) => {
            dispatch('tag/fetchTag', tag.uuid, { root: true })
            commit('item/setAvailableTag', {data: tag, item_uuid: itemUuid}, { root: true })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
