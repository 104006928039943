<template>
    <div>
        <ion-toolbar color="white">
            <ion-button expand="block" size="large" @click.prevent="openScanner">
                <ion-icon slot="start" :icon="qrCodeOutline" /> Scanner qrcode
            </ion-button>
            <ion-searchbar color="light" v-model="search" id="school-students-searchbar"></ion-searchbar>
        </ion-toolbar>

        <div class="scanner__container" v-if="$route.hash.substring(1)=='scanner'">
            <qrcode-stream :key="_uid" @decode="onDecode" />
            <p>{{ qrcodeLink }}</p>
        </div>

        <div v-if="classrooms.length > 0">
            <ion-list v-for="(classroom, kClassroom) in classrooms" :key="kClassroom" :v-bind="'classroom-list-'+classroom.classroom.id">
                <ion-list-header :v-bind="'classroom-'+classroom.classroom.id">
                    <ion-label>{{ classroom.classroom.name }}</ion-label>
                </ion-list-header>
                <ion-item color="white" v-for="(student, kStudent) in classroom.students.sort((a,b)=>sortingName(a,b,['first_name','last_name']))" :key="kStudent"
                        :router-link="link && link + student.uuid"
                        :v-bind="'student-'+student.id">
                    <ion-label>
                        {{ student.first_name }} {{ student.last_name }}
                    </ion-label>
                </ion-item>
            </ion-list>
        </div>
        <ion-list v-else>
            <ion-item color="white" class="no-classroom">
                <ion-label>Aucune classe</ion-label>
            </ion-item>
        </ion-list>
    </div>
  </template>
  
  <script>
  import {IonList, IonListHeader, IonItem, IonLabel, IonSearchbar, IonButton, IonIcon} from '@ionic/vue';
  import {defineComponent} from 'vue';
  import {sortingName} from "@/utils"
  import {qrCodeOutline } from 'ionicons/icons';
  import { QrcodeStream } from 'vue3-qrcode-reader'
  import {basicAlertMsg} from '@/utils'
  
  export default defineComponent({
    name: "StudentList",
    components: {
      IonSearchbar, IonList, IonListHeader, IonItem, IonLabel, IonButton, IonIcon, QrcodeStream
    },
    data() {
        return {
            search: '',
        }
    },
    props:['link'],
    setup() {
      return {
        sortingName, qrCodeOutline, basicAlertMsg
      }
    },
    computed: {
        classrooms(){
            return this.$store.getters["school/getSchoolStudents"](this.search)
        }
    },
    methods: {
        openScanner(){
            this.$router.push({hash: '#scanner'})
        },
        onDecode(result){
            if(this.$store.getters['students/getStudent'](result)){
                this.$router.push(this.link + result)
            }else{
                this.$router.push({hash: ''})
                this.basicAlertMsg("L'élève n'a pas été trouvé")

            }
        }
    }
  })
  </script>
  
  <style scoped>
    ion-list-header{
        color: var(--ion-color-white-contrast);
    }

    ion-list{
        background: var(--ion-color-white);
    }

    ion-item:hover, ion-item:focus{
        --ion-color-base: var(--ion-color-light);
        cursor: pointer;
    }

    .scanner__container{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;

        &::before{
            content:"";
            position: absolute;
            top:0;
            left:0;
            width:100vw;
            height: 100vh;
            background: #000;

        }
    }
  </style>