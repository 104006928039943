<template>
  <ion-page>
    <ion-header :translucent="true">
      <page-header>
        <ion-title class="header-link" id="school-legal-tutors-ariane">
          <router-link enterkeyhint="go" to="/home" replace id="school-legal-tutors-home-link">Mes écoles</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}`" replace id="school-legal-tutors-school-link">{{schoolName}}</router-link> /
          Tuteurs
        </ion-title>
      </page-header>
    </ion-header>

    <ion-content :fullscreen="true">

      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <ion-fab vertical="bottom" horizontal="end" slot="fixed" id="add-legal-tutor">
        <ion-fab-button @click.prevent="goAddTutor">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>

      <div class="container" v-if="tutors" >

        <ion-searchbar color="light" v-model="search" id="school-legal-tutors-searchbar"></ion-searchbar>

        <ion-list class="listPadding" >
          <ion-item 
            color="white" 
            v-for="(tutor, kStudent) in tutors.sort((a,b)=>sortingName(a,b,['user.last_name', 'user.first_name', 'user.username']))" 
            :key="kStudent" 
            :v-bind="'legal-tutor-'+tutor.id"
            :router-link="'/school/' + $route.params.schoolUuid + '/tutors/' + tutor.uuid"
            >
            <ion-label>
              <h2>{{ tutor.user.first_name }} {{ tutor.user.last_name }}</h2>
              <h3>{{ tutor.user.email }}</h3>
            </ion-label>
            <ion-button class="btn-delete" @click.prevent="confirmDelete(tutor)" shape="round" color="white" size="small">
              <ion-icon src="assets/icon/trash-solid.svg"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import {
  IonPage, IonContent, IonIcon, IonSearchbar, alertController, IonList, IonFooter,
  IonItem, IonButton, IonLabel, IonFab, IonFabButton, IonHeader, IonTitle, IonRefresher, IonRefresherContent,
} from '@ionic/vue';
import {defineComponent} from 'vue';
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {add, trash} from "ionicons/icons";
import {doDeleteElement, sortingName} from '@/utils';

export default defineComponent({
  name: "SchoolTutors",
  components: {
    IonContent,
    IonPage,
    IonIcon, IonSearchbar, IonList, IonFooter, IonItem, IonButton,
    IonLabel, IonFab, IonFabButton, IonHeader, IonTitle,
    PageHeader, PageFooter, IonRefresher, IonRefresherContent,
  },
  data() {
    return {
      schoolUuid: null,
      search: ""
    }
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    tutors(){
      return this.$store.getters["school/getSchoolLegalTutors"](this.search)
    }
  },
  methods: {
    goAddTutor() {
      this.$router.push('/school/' + this.schoolUuid + '/add_legal_tutor')
    },
    async confirmDelete(tutor) {
      const alertMsg = {
        message: "Voulez vous vraiment supprimer le tuteur " + tutor.user.first_name + " " + tutor.user.last_name  + " ? ",
        buttons: [
          {
            text: "Retour",
            role: "cancel"
          },
          {
            text: "Continuer",
            cssClass: "btn-danger",
            handler: ()=> {
              this.deleteTutor('schools/tutor', tutor)
            }
          }
        ]
      }
      const alertItem = await alertController.create(alertMsg);
      return alertItem.present();
    },
    deleteTutor(model, object) {
      doDeleteElement(model, object, object.user.first_name + ' ' + object.user.last_name, true).then(() => {
        this.$store.dispatch('school/removeLegalTutor',object.uuid)
      }).catch(async () => {
        const alertItem = await alertController
            .create({
              header: 'Erreur lors de la suppression :',
              message: 'Cet objet ne peut pas être supprimé !!',
              buttons: ['OK']
            })
        return alertItem.present();
      })
    },
    doRefresh(event) {
      this.$store.dispatch('school/reloadSchoolLegalTutors').then(()=>{
        event.target.complete()
      }) 
    },
  },
  setup() {
    return {
      add, trash, doDeleteElement, sortingName
    }
  },
  ionViewWillEnter() {
    this.schoolUuid = this.$route.params.schoolUuid
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid)
    this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid})
    this.$store.dispatch('school/loadLegalTutors')
  },
});
</script>

<style scoped>

  ion-item:hover, ion-item:focus{
    --ion-color-base: var(--ion-color-light);
    cursor: pointer;
  }

  .btn-delete{
    --box-shadow: none;
  }
  .btn-modifyProfil {
    --box-shadow: none;
  }

</style>
