<template>
  <ion-page>
    <page-header>
      <ion-title v-if="classroom" class="header-link" >
        <router-link enterkeyhint="go" to="/home" replace>Mes écoles</router-link> / 
        <router-link enterkeyhint="go" :to="`/school/${schoolUuid}`" replace>{{schoolName}}</router-link> /
        <router-link enterkeyhint="go" :to="`/school/${schoolUuid}/classrooms`" replace>Classes</router-link> /
        {{classroom.name}}
      </ion-title>
    </page-header>
    <ion-content :fullscreen="true">

      <ion-refresher slot="fixed" @ionRefresh="refreshData($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <div v-if="!classroom || !students">
        <p style="text-align: center">
          Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
        </p>
      </div>
      <div class="container" v-if="classroom && students" >
        <ion-modal
            :is-open="teacherModalIsOpenRef"
        >
            <ion-page>
              <ion-header>
                <ion-toolbar color="primary" >
                  <ion-title>
                    Sélectionnez le professeur
                  </ion-title>
                  <ion-buttons slot="end">
                    <ion-button @click.prevent="closeTeacherModal" >Fermer</ion-button>
                  </ion-buttons>
                </ion-toolbar>
              </ion-header>
              <ion-content :fullscreen="true">

                <ion-searchbar color="light" v-model="searchTeacherInput" @ionChange="loadTeachers"></ion-searchbar>
                <ion-list v-if="schoolTeachers">
                  <ion-item 
                    class="itemWithHover" 
                    color="white" 
                    v-for="(teacher, kTT) in schoolTeachers.sort(
                      (a,b)=>sortingName(a,b,['user.last_name', 'user.first_name'])
                      ).filter((teacher)=>!classroom.teachers.some(classTeacher => teacher.uuid == classTeacher.uuid))" 
                    :key="kTT" 
                    @click.prevent="addTeacher(teacher)"
                  >
                    <ion-label>
                      <h2>{{teacher.user.first_name}} {{teacher.user.last_name}}</h2>
                      <h3>{{teacher.user.email}}</h3>
                    </ion-label>
                  </ion-item>
                </ion-list>
              </ion-content>
            </ion-page>
        </ion-modal>
        <ion-card color="white" >
          <ion-card-header>
            <ion-card-title>{{classroom.name}}</ion-card-title>
            <em>{{classroom.school.name}}</em>
            <ion-button @click.prevent='goToClassroomEdit(student)' class="btn-modifyClassroom" shape="round"  size="small" color="light" >
              <ion-icon src="/assets/icon/pen-solid.svg" ></ion-icon>
            </ion-button>
          </ion-card-header>
        </ion-card>

          <ion-segment mode="md" scrollable="true" color="primary" @ionChange="segmentChanged($event)" :value="module">
            <ion-segment-button value="students">Élèves</ion-segment-button>
            <ion-segment-button value="menu">Repas</ion-segment-button>
            <ion-segment-button value="teachers">Professeurs</ion-segment-button>
          </ion-segment>

        <div v-if="module === 'teachers'">
          <ion-list>
            <ion-item-sliding v-for="(teacher, sKey) in classroom.teachers.sort((a,b)=>sortingName(a,b,['user.last_name', 'user.first_name']))" :key="sKey" :ref="'sliding-'+ teacher.uuid">
              <ion-item-options side="start">
                <ion-item-option color="danger" @click="removeTeacher(teacher, $event)">Supprimer</ion-item-option>
              </ion-item-options>
              <ion-item color="white" >
                <ion-label>
                  <h2>{{teacher.user.first_name}} {{teacher.user.last_name}}</h2>
                  <h3>{{teacher.user.username}}</h3>
                </ion-label>
                <ion-button @click.prevent="confirmRemove(teacher)" class="btn-cancel" title="Retirer professeur" color="white" fill="solid" size="small">
                  <ion-icon src="assets/icon/trash-solid.svg"></ion-icon>
                </ion-button>
              </ion-item>
            </ion-item-sliding>
          </ion-list>

          <ion-grid>
            <ion-row>
              <ion-col>
              </ion-col>
              <ion-col>
                <ion-button @click.prevent="openTeacherModal" expand="block" fill="solid">
                  Ajouter
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        
        <div v-if="module === 'students'">

          <ion-searchbar color="light" v-model="search"></ion-searchbar>

          <ion-list>
            <ion-list-header>
              <ion-label>
                Elèves
              </ion-label>
            </ion-list-header>
            <ion-item color="white" class="itemWithHover" v-for="(student, sKey) in students.sort((a,b)=>sortingName(a,b,['first_name', 'last_name']))" :key="sKey" :router-link="'/classroom/student/' + student.uuid">
              <ion-label>
                <h2>{{student.first_name}} {{student.last_name}}</h2>
              </ion-label>
            </ion-item>
          </ion-list>
        </div>
        <div v-else-if="module === 'menu'">
          <ion-toolbar color="light" >
            <ion-buttons slot="start">
              <ion-button @click.prevent="goPreviousWeek">
                <ion-icon :icon="chevronBackOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
            <ion-buttons slot="end">
              <ion-button @click.prevent="goNextWeek">
                <ion-icon :icon="chevronForwardOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
            <ion-title 
              v-if="mealWeek" 
              class="ion-text-center hiddenForMobile"
            >
              Semaine {{ mealWeek }} <br/><span>
                {{moment().year(year).week(mealWeek).weekday(0).format("DD/MM/YYYY")}} - {{moment().year(year).week(mealWeek).weekday(4).format("DD/MM/YYYY")}}
              </span> 
            </ion-title>
            <ion-title 
              v-if="mealWeek" 
              class="ion-text-center hiddenForDesktop text-small"
            >
              Semaine {{ mealWeek }} <br/><span>
                {{moment().year(year).week(mealWeek).weekday(0).format("DD/MM/YYYY")}} - {{moment().year(year).week(mealWeek).weekday(4).format("DD/MM/YYYY")}}
              </span> 
            </ion-title>
          </ion-toolbar>
          
          <div v-if="!days">
            <p style="text-align: center">
              Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
            </p>
          </div>
          <ion-item v-else-if="!days.length" class="text-small">
            <ion-label class="ion-text-wrap" >Il n'y a pas de repas disponible cette semaine.</ion-label>
          </ion-item>
          <ion-list class="listPadding" v-else>

            <ion-item-group v-for="(day, dkey) in days" :key="dkey">
              <ion-item-divider class="ion-text-capitalize" color="white">
                {{ moment(day['day']).format("dddd DD/MM/YYYY") }}
              </ion-item-divider>
              <ion-item lines="none" color="white" v-for="(meal, mKey) in day['meals']" :class="'editable-'+meal.is_editable+' meal-'+meal.uuid" :key="mKey">
                <div>
                  <ion-badge :style="tag.color && '--background:' + tag.color + ';'" v-for="(tag, ktag) in meal.tags" :key="ktag" >
                    {{tag.name}}
                    <!-- ion-icon n'accepte que les svg, first char 'i' = PNG et '/' = jpg -->
                    <ion-icon 
                      v-if="tag.b64_icon && tag.b64_icon.charAt(0) != 'i' && tag.b64_icon.charAt(0) != '/'" 
                      :src="'data:image/png;base64,' + tag.b64_icon"></ion-icon>
                    
                    <ion-img 
                      v-else
                      class='image'
                      :src="'data:image/png;base64,' + tag.b64_icon" ></ion-img>
                  </ion-badge>
                  <span v-if="meal.description !== null" class="meal-desc" v-html="meal.description == null ? '' :  meal.description.replace(/(?:\r\n|\r|\n)/g, '<br />')">
                  </span>
                </div>
              </ion-item>
            </ion-item-group>

          </ion-list>
        </div>

      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>


<script>
import moment from 'moment';
import { IonContent, IonFooter, IonPage, IonToolbar, IonItem, IonCard, IonCardHeader,
  IonCardTitle, IonSegment, IonSegmentButton, IonModal,
  IonItemOptions, IonItemOption, IonItemSliding, IonItemGroup, 
  IonItemDivider, IonIcon, IonListHeader, IonGrid, IonRow, IonCol, IonList, IonLabel, IonHeader, IonButtons, IonButton, IonBadge,  
  IonTitle, IonSearchbar, alertController, IonRefresher, IonRefresherContent,
} from '@ionic/vue';
import { copyOutline, chevronBackOutline, chevronForwardOutline, add } from 'ionicons/icons';
import { defineComponent } from 'vue';
import {getAPI} from "@/axios-api";
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {sortingName} from "@/utils"


export default defineComponent({
  name: 'SchoolClassroom',
  components: {
    IonPage, IonToolbar,
    IonContent, IonItem, IonCard, IonCardHeader, IonCardTitle, IonSegment, IonSegmentButton,
    IonModal, IonRefresher, IonRefresherContent,
    IonItemOptions, IonItemOption, IonItemSliding, IonItemGroup, IonItemDivider,
    IonIcon, IonListHeader, IonGrid, IonRow, IonCol, IonList, IonLabel, IonHeader, IonBadge,
    IonButtons, IonButton, IonTitle, IonSearchbar,
    PageHeader, PageFooter, IonFooter
  },
  data() {
    return {
      module: 'students',
      teacherModalIsOpenRef: false,
      mealWeek: 0,
      year: null,
      search: "",
      searchTeacherInput: "",
      schoolUuid: null,
    }
  },
  setup () {
    return {
      copyOutline, chevronBackOutline, chevronForwardOutline, add, sortingName
    }
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    classroom(){
      return this.$store.getters["classroom/getClassroom"]
    },
    students(){
      return this.$store.getters["classroom/getClassroomStudents"](this.search)
    },
    schoolTeachers(){
      return this.$store.getters["school/getSchoolTeachers"](this.searchTeacherInput)
    },
    days(){
      return this.$store.getters['classroom/getClassroomWeekMeals']({week:this.mealWeek,year:this.year})
    }
  },
  ionViewWillEnter() {
    this.schoolUuid = this.$route.params.schoolUuid
    this.$store.dispatch('school/navigateToSchool', this.schoolUuid)
    this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid})
    this.mealWeek = this.moment(new Date()).isoWeek();
    this.year = this.moment(new Date()).year();
    this.loadClassroom()
    this.loadWeekMeals()
  },
  created: function () {
    this.moment = moment;
  },
  methods: {
    refreshData(event){
      this.$store.dispatch('classroom/refreshClassroom').then(()=>{
        this.$store.dispatch('school/reloadSchoolTeachers').then(()=>{
          this.loadWeekMeals().then(()=>{
            event.target.complete()
          })
        })  
      })
    },
    formatDate(oldDate){
      return new Date(Date.parse(oldDate)).toLocaleDateString('fr-FR')
    },
    goToClassroomEdit(){
      this.$router.push('/school/'+ this.schoolUuid + '/classrooms/' +this.$route.params.classroomUuid + '/edit' )
    },
    closeTeacherModal() {
      this.teacherModalIsOpenRef = false
    },
    addTeacher(teacher) {
      getAPI.post('/schools/' + this.schoolUuid + '/classrooms/' + this.$route.params.classroomUuid + '/teachers/' + teacher.uuid + '/add').then(() => {
        this.closeTeacherModal()
        this.$store.dispatch('classroom/addTeacher', teacher)
        this.loadClassroom()
      });
    },
    loadTeachers() {
      this.$store.dispatch('school/loadTeachers')
    },
    openTeacherModal() {
      this.loadTeachers()
      this.teacherModalIsOpenRef = true
    },
    loadClassroom() {
      this.$store.dispatch('classroom/navigateToClassroom', this.$route.params.classroomUuid)
      this.$store.dispatch('classroom/loadClassroom')
    },
    loadWeekMeals() {
      return this.$store.dispatch('classroom/loadClassroomWeekMeals', {year:this.year,week:this.mealWeek})
    },
    async confirmRemove(teacher) {
      const alertMsg = {
        message: "Voulez vous vraiment retirer " + teacher.user.first_name + " " + teacher.user.last_name  + "de la classe ? ",
        buttons: [
          {
            text: "Retour",
            role: "cancel"
          },
          {
            text: "Continuer",
            cssClass: "btn-danger",
            handler: ()=> {
              this.removeTeacher(teacher)
            }
          }
        ]
      }
      const alertItem = await alertController.create(alertMsg);
      return alertItem.present();
    },
    removeTeacher(teacher) {
      getAPI.delete('/schools/' + this.schoolUuid + '/classrooms/' + this.$route.params.classroomUuid + '/teachers/' + teacher.uuid + '/delete').then(() => {
        this.$store.dispatch("classroom/removeTeacherFromClassroom", teacher.uuid)
        this.loadClassroom()
      });
    },
    goNextWeek() {
      this.mealWeek = this.mealWeek + 1;
      if (this.mealWeek >= 53) {
        this.mealWeek = this.mealWeek - 52
        this.year = this.year + 1
      }
      this.loadWeekMeals()
    },
    goPreviousWeek() {
      this.mealWeek = this.mealWeek - 1;
      if (this.mealWeek <= 0) {
        this.mealWeek = this.mealWeek + 52
        this.year = this.year - 1
      }
      this.loadWeekMeals()
    },
    segmentChanged(ev) {
      this.module = ev.detail.value
    },
  }
});
</script>

<style scoped>

  .itemWithHover:hover, .itemWithHover:focus{
    --ion-color-base: var(--ion-color-light);
    cursor: pointer;
  }

  .btn-modifyClassroom{
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    --box-shadow: 0;
  }

  ion-list-header{
    font-weight: 600;
    font-size: 18px;
    color: var(--ion-color-white-contrast);
  }

  ion-badge ion-icon, ion-badge ion-img{
    max-width: 13px;
    max-height: 13px;
    margin-left: 0.3rem;
    position: relative;
    top: 2px;
    display: inline-block;
  }

  .meal-desc {
    display: block;
    font-size: 0.8rem;
    line-height: 1.8;
    margin-bottom: 1rem;
  }

  .text-small{
    font-size: 14px;
  }

  ion-list {
    background: var(--ion-color-white);
  }

  ion-item-divider {
    font-weight: 600;
    font-size: 11px;
    padding: 0.5rem 20px;
    border: none;
  }

  ion-item-group {
    border-bottom: 1px solid rgba(var(--ion-color-white-contrast-rgb), 0.2)
  }

  ion-badge ion-icon{
    margin-left: 0.3rem;
    position: relative;
    top: 2px;
  }

  ion-segment{
    background: var(--ion-color-primary);
    border-radius: 0;
  }

  ion-segment-button{
    --ion-background-color: none;
    color: rgba(255,255,255,0.7);
    --indicator-box-shadow: none;
    border-radius: 0;
    font-size: 14px;
    padding: 0.5rem 0;
    font-weight: 700;
    text-transform: uppercase;
    --ion-color-base: #fff;

  }
  ion-segment-button::part(indicator){
    color: #fff;
    border-bottom: 3px solid #fff;
  }

  @media (min-width: 600px){
    ion-segment{
      background: none;
      
    }
    ion-segment-button{
      --ion-color-base: var(--ion-color-primary);
      color: var(--ion-color-white-contrast);
    }
  }

</style>
