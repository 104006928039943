import { RouteRecordRaw } from 'vue-router';

import AttendanceManagement from "@/views/pages/greeter/AttendanceManagement.vue";
import AttendanceRecap from '@/views/pages/greeter/AttendanceRecap.vue'

const routesAttendance: Array<RouteRecordRaw> = [
    {
        path: '/meal/attendances',
        name: 'CreateAttendanceRecap',
        component: AttendanceRecap,
    },
    {
        path: '/attendance/:schoolUuid',
        name: 'AttendanceManagement',
        component: AttendanceManagement
    },
]

export default routesAttendance