import {getAPI} from "@/axios-api";
import moment from 'moment'
import {alertController} from '@ionic/vue'


export const doDeleteElement = (model, object, objName, noConfirmMessage) => {
  return new Promise((resolve, reject) => {
    if(!noConfirmMessage){
      const deleteConfirm = confirm('Voulez-vous vraiment supprimer "' + objName + '" ?');
      if (deleteConfirm) {
        const endpoint = model + '/' + object.uuid + '/delete';
        getAPI.delete(endpoint)
          .then(() => resolve(true))
          .catch(async (e) => reject(e))
      }
    }else{
      const endpoint = model + '/' + object.uuid + '/delete';
        getAPI.delete(endpoint)
          .then(() => resolve(true))
          .catch(async (e) => reject(e))
    }
  });
}

export const doProcessReservation = (self, meal, e) => {
  return new Promise((resolve, reject) => {
    const currentDate = moment().format('YYYY-MM-DD h:mm:ss');
    if (e && e.detail.checked) {
      getAPI.post('/meals/students/' + self.$route.params.studentUuid + '/meals/' + meal.uuid + '/reservations/create?current_date=' + currentDate).then(() => {
        resolve(true);
      }).catch((err) => { 
        const response = err.response
        const data = response.data.data
        if (data && (response.data.status === 'fail' || response.data.status === 'error')) {
          const alertMsg = {
            header: 'Réservation impossible',
            backdropDismiss: false,
            buttons: [['Ok', 'cancelReservationProcess', false]]
          };
          if (data === 'insufficient_balance') {
            alertMsg['message'] = 'Le total de votre commande dépasse votre solde actuel.\nVeuillez alimenter le compte repas de votre enfant pour pouvoir passer plus de commandes.'
            reject(alertMsg);
          } else if (data.last_reservation_date) {
            alertMsg['message'] = 'La réservation pour cette date aurait dû être faite avant le ' + data.last_reservation_date + '.';
            reject(alertMsg);
          } else {
            reject(alertMsg);
          }
        }
      })
    } else {
      getAPI.delete('/meals/students/' + self.$route.params.studentUuid + '/meals/' + meal.uuid + '/reservations/delete?is_confirmed=' + self.deleteConfirmed + '&current_date=' + currentDate).then(() => {
        resolve(true);
      }).catch((err) => {
        const response = err.response
        const data = response.data.data
        if (response.data.status === 'fail' || response.data.status === 'error') {
          const alertMsg = {header: 'Annulation impossible', backdropDismiss: false};
          if (data.last_reservation_cancel) {
            alertMsg['message'] = 'L\'annulation de la commande aurait dû être faite avant le ' + data.last_reservation_cancel + '.';
            alertMsg['buttons'] = [['Ok', 'cancelReservationProcess']];
            reject(alertMsg);
          } else if (data === 'to_confirm') {
            alertMsg['header'] = "Voulez-vous vraiment annuler la réservation ?";
            alertMsg['message'] = 'Vous ne pourrez plus modifier votre choix (commander à nouveau) pour cette semaine.';
            alertMsg['buttons'] = [['Annuler', 'cancelReservationProcess', 'secondary'], ['Continuer', 'confirmReservationProcess', false]];
            reject(alertMsg);
          } else {
            alertMsg['message'] = 'Une erreur inconnue est survenue lors de l\'annulation de la commande: ';
            alertMsg['buttons'] = [['Ok', 'cancelReservationProcess']];
            reject(alertMsg);
          }
        }
      })
    }
  });
}

export const sortingName = (a,b,path) => {

  // path est un array qui permet de stocker une ou plusieurs clés d'objet (ex: ["student.last_name"]);
  // la premiére clés sera comparer en priorité, si a et b sont égaux, on passe à la deuxiéme (ex: ["student.last_name", "student.first_name"] )
  // Par exemple:
  //      const arrayOfObject = [{student:{lastName: "Smith", firstName: "Will"}},  {student:{lastName: "Willis", firstName: "Bruce"}}]
  //      On veut trier par lastName PUIS firstName donc le call de la fonction sera: arrayOfObject.sort((a,b)=>sortingName(a,b,['student.lastName', 'student.firstName']))

  for (let i = 0; i < path.length; i++) {
    let aCompared = a
    let bCompared = b
    path[i].split('.').forEach((p)=>{
       aCompared = aCompared[p];
       bCompared = bCompared[p];
    });

    if(aCompared !== bCompared ){
      const orderBool = aCompared.toLowerCase().localeCompare(bCompared.toLowerCase())
      return orderBool
    }
  }
  return 0
}

export const basicAlertMsg = async (msg, header) => {
  const alertItem = await alertController.create({
    header: header || "Une erreur est survenue",
    message: msg,
    backdropDismiss: false,
    buttons: ['OK']
  });
  return alertItem.present();
}

export const MEALS_MODULE_SLUG = 'meals-module'
export const SHOP_MODULE_SLUG = 'shop-module'
export const NURSERY_MODULE_SLUG = 'nursery-module'
export const SCHOOL_ACTIVITIES_MODULE_SLUG = 'school-activities-module'