<template>
  <ion-page>
    <ion-header translucent>
      <page-header>
        <ion-title class="header-link" id="school-students-ariane">
          <router-link enterkeyhint="go" to="/home" replace id="school-students-home-link">Mes écoles</router-link> /
          <router-link :to="`/school/${$route.params.schoolUuid}`" replace id="school-students-school-link">
            {{ $store.getters['school/getSchoolNameByUuid']({school_uuid: $route.params.schoolUuid}) || schoolName }}
          </router-link> /
          <router-link :to="`/school/${$route.params.schoolUuid}/module/${module.uuid}`" router-direction="forward">
            Module {{ module.name }} </router-link> / Ajouter/Modifier des données
        </ion-title>
      </page-header>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="openAddTypeModal = true">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>



      <div class="container">
      
        <div v-if="!module">
          <p style="text-align: center">
            Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
          </p>
        </div>
        <ion-card>
          <ion-card-header color="light" >
            <ion-card-title>
              {{module && module.name}}
            </ion-card-title>
            <em>Types</em>
          </ion-card-header>
        </ion-card>

        <ion-list v-if="item_types.length">
          <ion-item color="white" v-for="(item_type, kit) in item_types.sort((a,b)=>sortingName(a,b,['name']))" :key="kit"
            :router-link="`/school/${$route.params.schoolUuid}/module/${$route.params.moduleUuid}/types/${item_type.uuid}`" 
          >
            <ion-label>
              <h2>{{item_type && item_type.name}}</h2>
            </ion-label>
          </ion-item>
        </ion-list>

        
        <ion-modal ref="openAddTypeModal" :is-open="openAddTypeModal" @willDismiss="onWillDismiss($event)">
          <ion-header>
            <ion-toolbar>
              <ion-title>Ajouter une catégorie</ion-title>
              <ion-buttons slot="end">
                <ion-button @click="openAddTypeModal=false">Fermer</ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>
          <ion-content class="ion-padding">
            <item-type-form
                :schoolUuid="$route.params.schoolUuid"
                :moduleUuid="$route.params.moduleUuid"
                @closeModal="onWillDismiss"
            ></item-type-form>
          </ion-content>
        </ion-modal>
      </div>
    </ion-content>
    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage, IonContent, IonIcon, IonItem, IonHeader,
  IonFooter, IonList, IonButton, IonLabel, IonFab, IonFabButton, IonTitle,
  IonRefresher, IonRefresherContent, IonCard, IonCardTitle, IonCardHeader,
  IonModal, IonToolbar, IonButtons, IonSpinner
} from '@ionic/vue';
import { add } from 'ionicons/icons';
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {sortingName} from '@/utils';
import ItemTypeForm from "@/views/components/itemTypes/ItemTypeForm.vue";

export default {
  name: "AdminListType",
  components: {
    ItemTypeForm,
    IonCard, IonCardTitle, IonCardHeader, IonFabButton,
    IonContent, IonFab,IonIcon, IonSpinner,
    IonPage,
    PageHeader,
    PageFooter,
    IonHeader, IonFooter, IonTitle, IonList, IonRefresher, IonRefresherContent,
    IonModal, IonToolbar, IonButtons, IonButton, IonItem, IonLabel
  },
  setup() {
    return {
      add, sortingName
    }
  },
  data() {
    return {
      openAddTypeModal: false,
      form: {
        name: null,
        price: null
      }
    }
  },
  methods: {
    onWillDismiss() {
      this.openAddTypeModal = false;
    },
    doRefresh(event = null){
      this.$store.dispatch('school/reloadModuleItemTypes').then(()=>{
        if (event !== null) {
          event.detail.complete();
        }
      }) 
    },
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    modules(){
      return this.$store.getters["school/getSchoolModules"]
    },
    module(){
      return this.modules[this.$route.params.moduleUuid]
    },
    item_types(){
      return this.$store.getters["school/getModuleItemTypes"]
    }
  },
  mounted(){
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid).then(()=>{
      this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid}).then(()=>{
        this.$store.dispatch('school/navigateToModule', this.$route.params.moduleUuid).then(()=>{
          this.$store.dispatch('school/loadModules', {schoolUuid: this.$route.params.schoolUuid}).then(()=>{
            this.$store.dispatch('school/loadModuleItemTypes')
          })
        })
      })
    })
  }
}
</script>

<style scoped>
  ion-item:hover, ion-item:focus{
    --ion-color-base: var(--ion-color-light);
    cursor: pointer;
  }
  .btn-delete{
    --box-shadow: none;
    --ion-color-base: none;
  }
</style>