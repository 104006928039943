<template>
  <ion-page>
    <ion-header>
      <page-header>
          <ion-title class="header-link">
            <router-link enterkeyhint="go" to="/home" replace id="school-students-home-link">Mes écoles</router-link> /
            <router-link enterkeyhint="go" :to="`/school/${$route.params.schoolUuid}`" replace id="school-students-school-link">
            {{ $store.getters['school/getSchoolNameByUuid']($route.params.schoolUuid) }}
            </router-link> /
            <router-link :to="`/school/${$route.params.schoolUuid}/module/${module.uuid}`" router-direction="forward">
              Module {{ module.name }}
            </router-link> /
            <router-link :to="`/school/${$route.params.schoolUuid}/module/${module.uuid}/types`">
              Ajouter/Modifier des données
            </router-link> /
              Enregistrer les modifications
        </ion-title>
    </page-header>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="container">
        <ItemTypeForm :schoolUuid="$route.params.schoolUuid" :uuid="$route.params.typeUuid" :moduleUuid="module.uuid" />
        <ion-refresher slot="fixed" @ionRefresh="doRefresh">
          <ion-refresher-content></ion-refresher-content>
        </ion-refresher>
        </div>
    </ion-content>
    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
} from '@ionic/vue';
import {add, chevronBack, chevronForward,addCircleOutline } from 'ionicons/icons';
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {mapActions, mapGetters} from "vuex";
import ItemTypeForm from "@/views/components/itemTypes/ItemTypeForm";
import moment from "moment";

export default {
  name: "ItemTypesFormPage",
  components: {
    ItemTypeForm,
    IonContent,
    IonHeader,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    PageHeader,
    IonFooter,
    PageFooter,
    IonTitle,
  },
  data() {
    return {
      openAddItemModal: false,
      week: moment().week(),
      year: moment().year(),
      loading: false
    };
  },
  setup() {
    return {
      add, chevronBack, chevronForward, addCircleOutline
    };
  },
  mounted() {
    this.doRefresh()
  },
  methods: {
    ...mapActions("itemType", ["fetchItemType", "fetchItemTypeItems"]),
    previousWeek() {
      this.loading = true;
      this.week = this.week - 1;
      if (this.week === 0) {
        this.week = 52;
        this.year = this.year - 1;
      }
      this.doRefresh();
    },
    nextWeek() {
      this.loading = true;
      this.week = this.week + 1;
      if (this.week > 52) {
        this.week = 1;
        this.year = this.year + 1;
      }
      this.doRefresh();
    },
    onWillDismiss() {
      this.openAddItemModal = false;
    },
    days() {
      return moment().week(this.week);
    },
    addItem() {
      this.$store.dispatch('school/addItemType', this.item);
      this.openAddItemModal = false;
    },
    openAddItem() {
      this.openAddItemModal = true;
    },
    doRefresh(event) {
      this.loading = true;
      const start_datetime = moment().week(this.week).year(this.year).startOf('week').format('YYYY-MM-DD HH:mm:ss');
      const end_datetime = moment().week(this.week).year(this.year).endOf('week').format('YYYY-MM-DD HH:mm:ss');
      this.$store.dispatch("itemType/fetchItemTypeItems", {
        itemTypeUuid: this.$route.params.typeUuid,
        from_datetime: start_datetime,
        to_datetime: end_datetime,
      }).then(() => {
        this.loading = false;
        if (event) {
          event.target.complete();
        }
      }).finally(() => {
        this.loading = false;
        if (event) {
          event.target.complete();
        }
      });
    },
    itemsForADay(day) {
      const datas = Object.keys(this.items(this.$route.params.typeUuid))
      return datas.filter((item) => {
        const storeItem = this.$store.getters['item/getItem'](item)
        if (moment(storeItem.start_at).format('DMMYYYY') === day.format('DMMYYYY')) {
          return true
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      items: 'itemType/getItemTypeItems',
    }),
    type() {
      return this.$store.getters['itemType/getItemType'](this.$route.params.typeUuid);
    },
    module() {
      return this.$store.getters['module/getModule'](this.$route.params.moduleUuid);
    },
    item() {
      return this.$store.getters['school/item'];
    },
    listDays() {
      const days = [];
      for (let i = 0; i < 7; i++) {
        days.push(moment().week(this.week).year(this.year).startOf('week').add(i, 'days'));
      }
      return days;
    },
  },
}
</script>

<style scoped>

</style>