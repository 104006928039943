import axios from 'axios'
import router from "@/router";
const API_DOMAIN = process.env.VUE_APP_API_URL
import {alertController} from '@ionic/vue';
const getLogin = axios.create({
    baseURL: API_DOMAIN,
    timeout: 2000
})

getLogin.defaults.headers.common['X-Client-Version'] = process.env.VUE_APP_VERSION;
getLogin.defaults.headers.common['X-Client-Name'] = process.env.VUE_APP_CLIENT_NAME;

const getAPI = axios.create({
    baseURL: API_DOMAIN,
    timeout: 60000
})
getAPI.defaults.headers.common['Authorization'] = 'Token ' + localStorage.getItem('auth-token');
getAPI.defaults.headers.common['X-Client-Version'] = process.env.VUE_APP_VERSION;
getAPI.defaults.headers.common['X-Client-Name'] = process.env.VUE_APP_CLIENT_NAME;
getAPI.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, async (error) => {
    if (!navigator.onLine) {
        const alertItem = await alertController
            .create({
                message: 'Vous êtes actuellement hors connexion. Veuillez réessayer plus tard.',
                buttons: ['OK']
            })
        await alertItem.present();
        return error;
    }
    if (typeof error.response !== "undefined") {
        if (error.response.status === 401 && router.currentRoute.value.fullPath !== "/reset_password") {
            router.push('/login')
        }
        if (error.response.status > 499 && router.currentRoute.value.fullPath !== "/reset_password") {
          const alertItem = await alertController
            .create({
              message: 'Une erreur serveur est survenue. Veuillez réessayer plus tard.',
              buttons: ['OK']
            })
          await alertItem.present();
          return error
        }
    } else {
        const alertItem = await alertController
            .create({
                message: 'Une erreur serveur est survenue. Veuillez réessayer plus tard.',
                buttons: ['OK']
            })
        await alertItem.present();
        return error;
    }
    return Promise.reject(error);
});


export { getLogin, getAPI }
