import {getAPI} from "@/axios-api";
import {alertController} from "@ionic/vue";

const state = () => ({
    schools:{},
    currentSchoolUuid: null,
    currentStudentUuid: null,
    currentModuleUuid: null,
    currentItemTypeUuid: null,
})

const mutations = {
    setSchoolItemTypes(state: any, {schoolUuid, moduleUuid, itemType}: any) {
        itemType.forEach((itemType: any) => {
            if (!state.schools[schoolUuid]?.modules[moduleUuid]?.item_types[itemType.uuid]) {
                state.schools[schoolUuid].modules[moduleUuid].item_types[itemType.uuid] = itemType
            } else {
                state.schools[schoolUuid].modules[moduleUuid].item_types[itemType.uuid].name = itemType.name
                state.schools[schoolUuid].modules[moduleUuid].item_types[itemType.uuid].price = itemType.price
            }

        })
    },

    setCurrentSchool(state: any, data: any){
        state.schools[state.currentSchoolUuid] = data
    },
    setSchool(state: any, data: any){
        state.schools[data.uuid] = data
    },

    setCurrentSchoolUuid(state: any, schoolUuid: string){
        state.currentSchoolUuid = schoolUuid
    },

    // ======= Teachers =======

    setSchoolTeachers(state: any, data: any){
        state.schools[state.currentSchoolUuid].teachers = {}
        data.forEach((teacher: any) => {
            state.schools[state.currentSchoolUuid].teachers[teacher.uuid] = teacher
        });
    },
    addTeacher(state: any, data: any){
        const school = state.schools[state.currentSchoolUuid]
        if (school.teachers){
            school.teachers[data.uuid] = data
        }
    },
    removeSchoolAllTeachers(state: any){
        state.schools[state.currentSchoolUuid].teachers = null
    },
    updateTeacherUser(state: any, {data,currentTeacherUuid}: any){
        const school = state.schools[state.currentSchoolUuid]
        if(school.teachers){
            school.teachers[currentTeacherUuid].user = data
        }
    },
    removeTeacher(state: any, teacherUuid: any){
        const school = state.schools[state.currentSchoolUuid]
        delete school.teachers[teacherUuid]
    },

    // ======= Greeters =======

    setSchoolGreeters(state: any, data: any){
        state.schools[state.currentSchoolUuid].greeters = {}
        data.forEach((greeter: any) => {
            state.schools[state.currentSchoolUuid].greeters[greeter.uuid] = greeter
        });
    },
    addGreeter(state: any, data: any){
        const school = state.schools[state.currentSchoolUuid]
        if (school.greeters){
            school.greeters[data.uuid] = data
        }
    },
    removeSchoolAllGreeters(state: any){
        state.schools[state.currentSchoolUuid].greeters = null
    },
    updateGreeterUser(state: any, {data, currentGreeterUuid}: any){
        const school = state.schools[state.currentSchoolUuid]
        if(school.greeters){
            school.greeters[currentGreeterUuid].user = data
        }
    },
    removeGreeter(state: any, greeterUuid: any){
        const school = state.schools[state.currentSchoolUuid]
        delete school.greeters[greeterUuid]
    },

    // ======= Legal_tutors =======

    setSchoolLegalTutors(state: any, data: any){
        state.schools[state.currentSchoolUuid].legal_tutors = {}
        data.forEach((legal_tutor: any) => {
            state.schools[state.currentSchoolUuid].legal_tutors[legal_tutor.uuid] = legal_tutor
        });
    },
    addLegalTutor(state: any, data: any){
        const school = state.schools[state.currentSchoolUuid]
        if (school.legal_tutors){
            school.legal_tutors[data.uuid] = data
        }
    },
    removeSchoolAllLegalTutors(state: any){
        state.schools[state.currentSchoolUuid].legal_tutors = null
    },
    updateLegalTutorUser(state: any, {data,currentLegalTutorUuid}: any){
        const school = state.schools[state.currentSchoolUuid]
        if(school.legal_tutors){
            school.legal_tutors[currentLegalTutorUuid].user = data
        }
    },
    removeLegalTutor(state: any, legalTutorUuid: any){
        const school = state.schools[state.currentSchoolUuid]
        delete school.legal_tutors[legalTutorUuid]
    },

    // ======= Classrooms =======

    setSchoolClassrooms(state: any, data: any){
        state.schools[state.currentSchoolUuid].classrooms = {}
        data.forEach((classroom: any) => {
            state.schools[state.currentSchoolUuid].classrooms[classroom.uuid] = classroom
        });
    },
    addClassroom(state: any, data: any){
        const school = state.schools[state.currentSchoolUuid]
        if (school.classrooms){
            school.classrooms[data.uuid] = data
        }
    },
    removeSchoolAllClassrooms(state: any){
        state.schools[state.currentSchoolUuid].classrooms = null
    },
    updateClassroom(state: any, {data, classroomUuid}:any){
        const school = state.schools[state.currentSchoolUuid]
        if(school.classrooms){
            school.classrooms[classroomUuid] = data
        }
    },
    removeClassroom(state: any, classroomUuid: any){
        const school = state.schools[state.currentSchoolUuid]
        delete school.classrooms[classroomUuid]
    },

    // ======= Students =======

    setSchoolStudents(state: any, data: any){
        // Set students AND classroom
        state.schools[state.currentSchoolUuid].classrooms = {}
        state.schools[state.currentSchoolUuid].students = {}
        data.forEach((classroom: any) => {
            state.schools[state.currentSchoolUuid].classrooms[classroom.classroom.uuid] = classroom.classroom
            classroom.students.forEach((student:any)=>{
                state.schools[state.currentSchoolUuid].students[student.uuid] = student
                state.schools[state.currentSchoolUuid].students[student.uuid].classroom_uuid = classroom.classroom.uuid
            })
        });
    },
    addStudent(state: any, data: any){
        const school = state.schools[state.currentSchoolUuid]
        if (school.students){
            data.classroom_uuid = data.classroom.uuid
            data.modules = data.modules === undefined ? {} : data.modules
            data.wallet = data.wallet === undefined ? {} : data.wallet
            school.students[data.uuid] = data
        }
    },
    removeSchoolAllStudents(state: any){
        state.schools[state.currentSchoolUuid].students = null
    },
    setCurrentStudentUuid(state: any, studentUuid: string){
        state.currentStudentUuid = studentUuid
    },
    updateStudent(state: any, data: any){
        const school = state.schools[state.currentSchoolUuid]
        if(school.students){
            school.students[state.currentStudentUuid] = data
        }
    },
    removeStudent(state: any, studentUuid: any){
        const school = state.schools[state.currentSchoolUuid]
        delete school.students[studentUuid]
    },
    setCurrentStudentWallet(state: any, wallet: any){
        const school = state.schools[state.currentSchoolUuid]
        if(school.students && school.students[state.currentStudentUuid] !== undefined){
            school.students[state.currentStudentUuid].wallet = wallet
        }
    },
    setCurrentStudentModules(state: any, modules: any){
        const school = state.schools[state.currentSchoolUuid]
        if(school.students && school.students[state.currentStudentUuid] !== undefined) {
            for (let i = 0; i < modules.length; i++) {
                if (school.students[state.currentStudentUuid].modules === undefined) {
                    school.students[state.currentStudentUuid].modules = {}
                }
                school.students[state.currentStudentUuid].modules[modules[i].module.uuid] = modules[i].module
                school.students[state.currentStudentUuid].modules[modules[i].module.uuid].settings = modules[i].settings
            }
        } else {
            console.log('School or student not found')
        }
    },

    // ======= Modules =======
    setSchoolModules(state: any, data: any){
        console.log('setSchoolModules', data)
        if (state.schools[state.currentSchoolUuid] === undefined){
            state.schools[state.currentSchoolUuid] = {}
        }
        state.schools[state.currentSchoolUuid].modules = {}
        data.forEach((moduleSubscription: any) => {
            state.schools[state.currentSchoolUuid].modules[moduleSubscription.module.uuid] = moduleSubscription.module
            state.schools[state.currentSchoolUuid].modules[moduleSubscription.module.uuid].types = {}
        });
    },

    setCurrentModuleUuid(state: any, moduleUuid: string){
        state.currentModuleUuid = moduleUuid
    },

    addTypeOnModule(state: any, data: any){
        const school = state.schools[state.currentSchoolUuid]
        if (school.modules){
            school.modules[state.currentModuleUuid].types[data.uuid] = data
            school.modules[state.currentModuleUuid].types[data.uuid].items = {}
        }
    },

    addManagerOnModule(state: any, data: any){
        const school = state.schools[state.currentSchoolUuid]
        if (school.modules){
            school.modules[state.currentModuleUuid].managers[data.uuid] = data
        }
    },
    setManagersOnModule(state: any, data: any){
        const school = state.schools[state.currentSchoolUuid]
        if (school.modules){
            school.modules[state.currentModuleUuid].managers = {}
            data.forEach((manager: any) => {
                school.modules[state.currentModuleUuid].managers[manager.uuid] = manager
            });
        }
    },
    removeManagerFromModule(state: any, managerUuid:string){
        const school = state.schools[state.currentSchoolUuid]
        if (school.modules){
            const module = school.modules[state.currentModuleUuid]
            if(module && module.managers){
                delete module.managers[managerUuid]
            }
        }
    },
    removeAllManagersFromModule(state:any){
        const school = state.schools[state.currentSchoolUuid]
        if (school.modules){
            const module = school.modules[state.currentModuleUuid]
            if(module && module.managers){
                module.managers = null
            }
        }
    },

    // ======= ItemType =======

    setItemTypesOnModule(state:any, data:any){
        const school = state.schools[state.currentSchoolUuid]
        if (school.modules){
            school.modules[state.currentModuleUuid].item_types = {}
            data.forEach((item_type: any) => {
                school.modules[state.currentModuleUuid].item_types[item_type.uuid] = item_type
            });
        }
    },
    removeAllItemTypesFromModule(state:any){
        const school = state.schools[state.currentSchoolUuid]
        if (school.modules){
            const module = school.modules[state.currentModuleUuid]
            if(module && module.item_types){
                module.item_types = null
            }
        }
    },
    setCurrentItemTypeUuid(state: any, itemTypeUuid: string){
        state.currentItemTypeUuid = itemTypeUuid
    },
    updateItemType(state: any, data:any){
        const school = state.schools[state.currentSchoolUuid]
        if(school && school.modules){
            const module = school.modules[state.currentModuleUuid]
            if(module && module.item_types){
                module.item_types[state.currentItemTypeUuid] = data
            }
        }
    }
}
const getters = {
    getSchoolNameByUuid: (state: any) => (school_uuid:any = null) => {
        const school = state.schools[school_uuid ? school_uuid : state.currentSchoolUuid]
        return school && school.name? school.name : null
    },
    getSchoolName(state: any) {
        const school = state.schools[state.currentSchoolUuid]
        return school ? school.name : null
    },
    getSchool(state: any) {
        return ({school_uuid = null}: any) => {
            return state.schools[school_uuid ? school_uuid : state.currentSchoolUuid]
        }
    },
    getSchoolTeachers: (state: any) => (query: string) => {
        const school = state.schools[state.currentSchoolUuid]
        if (school && school.teachers) {
            const teachersArray = Object.keys(school.teachers).map((key: string) => school.teachers[key]);
            if (query) {
                return teachersArray && teachersArray.filter((teacher: any) =>
                    teacher.user.last_name.includes(query) ||
                    teacher.user.first_name.includes(query) ||
                    teacher.user.username.includes(query) ||
                    teacher.user.email.includes(query)
                )
            }
            return teachersArray
        }
        return null
    },
    getSchoolGreeters: (state: any) => (query: string) => {
        const school = state.schools[state.currentSchoolUuid]
        if (school && school.greeters) {
            const greetersArray = Object.keys(school.greeters).map((key: string) => school.greeters[key]);
            if (query) {
                return greetersArray && greetersArray.filter((greeter: any) =>
                    greeter.user.last_name.includes(query) ||
                    greeter.user.first_name.includes(query) ||
                    greeter.user.username.includes(query) ||
                    greeter.user.email.includes(query)
                )
            }
            return greetersArray
        }
        return []
    },
    getSchoolLegalTutors: (state: any) => (query: string) => {
        const school = state.schools[state.currentSchoolUuid]
        if (school && school.legal_tutors) {
            const legalTutorsArray = Object.keys(school.legal_tutors).map((key: string) => school.legal_tutors[key]);
            if (query) {
                return legalTutorsArray && legalTutorsArray.filter((legal_tutor: any) =>
                    legal_tutor.user.last_name.includes(query) ||
                    legal_tutor.user.first_name.includes(query) ||
                    legal_tutor.user.username.includes(query) ||
                    legal_tutor.user.email.includes(query)
                )
            }
            return legalTutorsArray
        }
        return []
    },

    getSchoolClassrooms: (state: any) => ({query, schoolUuid }: any) => {
        let school: any
        if (schoolUuid != null) {
            school = state.schools[schoolUuid]
        } else {
            school = state.schools[state.currentSchoolUuid]
        }
        if (school && school.classrooms) {
            const classroomsArray = Object.keys(school.classrooms).map((key: string) => school.classrooms[key]);
            if (query) {
                return classroomsArray && classroomsArray.filter((classroom: any) =>
                    classroom.name.includes(query)
                )
            }
            return classroomsArray
        }
        return []
    },

    getSchoolStudents: (state: any) => (query: string) => {
        // Return students sorted by classrooms
        query = query.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '')
        const school = state.schools[state.currentSchoolUuid]
        if (school && school.students) {
            let studentsArray = Object.keys(school.students).map((key: string) => school.students[key]);
            if (query) {
                studentsArray = studentsArray.filter((student: any) =>
                    student.last_name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '').includes(query) ||
                    student.first_name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '').includes(query)
                )
            }
            const classroomStudentsArray: Array<any> = []
            if (school.classrooms) {
                const classroomsArray = Object.keys(school.classrooms).map((key: string) => school.classrooms[key]);
                classroomsArray.forEach((classroom: any) => {
                    classroomStudentsArray.push({
                        'classroom': classroom,
                        'students': studentsArray.filter((student: any) => student.classroom_uuid == classroom.uuid)
                    })

                })
            }
            return classroomStudentsArray
        }
        return []
    },

    getSchoolModules: (state: any) => {
        const school = state.schools[state.currentSchoolUuid]
        if (school && school.modules) {
            return school.modules
        }
        return []
    },

    getSchoolModuleManager: (state: any) => {
        const school = state.schools[state.currentSchoolUuid]
        if (school && school.modules) {
            const module = school.modules[state.currentModuleUuid]
            if (module && module.managers) {
                const managersArray = Object.keys(module.managers).map((key: string) => module.managers[key]);
                return managersArray
            }
        }
        return []
    },
    getModuleItemTypes: (state: any) => {
        const school = state.schools[state.currentSchoolUuid]
        if (school && school.modules) {
            const module = school.modules[state.currentModuleUuid]
            if (module && module.item_types) {
                const itemTypesArray = Object.keys(module.item_types).map((key: string) => module.item_types[key]);
                return itemTypesArray
            }
        }
        return []
    },
    getItemType: (state: any) => {
        const school = state.schools[state.currentSchoolUuid]
        if (school && school.modules) {
            const module = school.modules[state.currentModuleUuid]
            if (module && module.item_types) {
                return module.item_types[state.currentItemTypeUuid]
            }
        }
    },
    currentStudent: (state: any) => {
        const school = state.schools[state.currentSchoolUuid]
        if (school && school.students) {
            return school.students[state.currentStudentUuid]
        }
        return null
    },
    currentStudentWallet: (state: any) => {
        const school = state.schools[state.currentSchoolUuid]
        if (!school || !school.students) {
            return null
        }
        const student = school.students[state.currentStudentUuid]
        if (!student || !student.wallet) {
            return null
        }
        return student.wallet
    },
    currentStudentClassroom: (state: any) => {
        const school = state.schools[state.currentSchoolUuid]
        if (!school || !school.students) {
            return null
        }
        const student = school.students[state.currentStudentUuid]
        if (!student || !student.classroom.uuid) {
            return null
        }
        return school.classrooms[student.classroom.uuid]
    },
    currentStudentModules: (state: any) => {
        const school = state.schools[state.currentSchoolUuid]
        if (!school || !school.students) {
            return null
        }
        const student = school.students[state.currentStudentUuid]
        if (!student || !student.modules) {
            return null
        }
        return student.modules
    }
}


const actions = {

    async loadItemTypeList({commit}: any, {schoolUuid, moduleUuid}: any) {
        await getAPI.get('/schools/' + schoolUuid + '/modules/' + moduleUuid +'/item_types').then((response: any) => {
            commit("setSchoolItemTypes", {schoolUuid, moduleUuid, itemType: response.data.data})
            response.data.data.forEach((itemType: any) => {
                commit("itemType/setItemType", itemType, {root: true})
            })
        })
    },

    navigateToSchool({ commit,state }: any, schoolUuid: string){
        if(state.currentSchoolUuid != schoolUuid){
            commit("setCurrentSchoolUuid", schoolUuid)
        }
    },

    async loadSchool({ commit, state }: any, {schoolUuid = null}: any){
        let _schoolUuid: any
        if (schoolUuid !== null) {
            _schoolUuid = schoolUuid
        } else {
            _schoolUuid = state.currentSchoolUuid
        }
        // Check if the current school uuid is already loaded in state.schools
        if(!state.schools[_schoolUuid]){
            await getAPI.get('/schools/' + _schoolUuid).then((response: any) => {
                commit("setCurrentSchool", response.data.data)
            })
        }
    },

    // ======= Teachers =======
    
    async loadTeachers({dispatch, commit, state}:any){
        await dispatch('loadSchool', {schoolUuid: state.currentSchoolUuid}).then(()=>{
            if(!state.schools[state.currentSchoolUuid].teachers){
                getAPI.get('/schools/' + state.currentSchoolUuid + '/teachers').then((response) => {
                    commit('setSchoolTeachers', response.data.data)
                })
            }
        })
    },
    reloadSchoolTeachers({dispatch, commit}:any){
        commit('removeSchoolAllTeachers')
        dispatch('loadTeachers')
    },
    addTeacher({commit}:any, data: any){
        commit('addTeacher',data)
    },
    updateTeacherUser({commit, rootState}:any, data: any){
        commit('updateTeacherUser',{data,currentTeacherUuid:rootState.teacher.currentTeacherUuid})
    },
    removeTeacher({commit}:any, teacherUuid: any){
        commit('removeTeacher',teacherUuid)
    },

    // ======= Greeters =======

    async loadGreeters({dispatch, commit, state}:any){
        await dispatch('loadSchool', {schoolUuid: state.currentSchoolUuid}).then(()=>{
            if(!state.schools[state.currentSchoolUuid].greeters){
                getAPI.get('/schools/' + state.currentSchoolUuid + '/greeters').then((response) => {
                    commit('setSchoolGreeters', response.data.data)
                })
            }
        })
    },
    reloadSchoolGreeters({dispatch, commit}:any){
        commit('removeSchoolAllGreeters')
        dispatch('loadGreeters')
    },
    addGreeter({commit}:any, data: any){
        commit('addGreeter',data)
    },
    updateGreeterUser({commit, rootState}:any, data: any){
        commit('updateGreeterUser',{data, currentGreeterUuid: rootState.greeter.currentGreeterUuid})
    },
    removeGreeter({commit}:any, greeterUuid: any){
        commit('removeGreeter',greeterUuid)
    },

    // ======= Legal_tutors =======

    async loadLegalTutors({dispatch, commit, state}:any){
        await dispatch('loadSchool', {schoolUuid: state.currentSchoolUuid}).then(()=>{
            if(!state.schools[state.currentSchoolUuid].legal_tutors){
                return getAPI.get('/schools/' + state.currentSchoolUuid + '/legal_tutors').then((response) => {
                    commit('setSchoolLegalTutors', response.data.data)
                })
            }
        })
    },
    reloadSchoolLegalTutors({dispatch, commit}:any){
        commit('removeSchoolAllLegalTutors')
        dispatch('loadLegalTutors')
    },
    addLegalTutor({commit}:any, data: any){
        commit('addLegalTutor',data)
    },
    updateLegalTutorUser({commit, rootState}:any, data: any){
        const currentLegalTutorUuid = rootState.legalTutor.currentLegalTutorUuid
        commit('updateLegalTutorUser',{data,currentLegalTutorUuid})
    },
    removeLegalTutor({commit}:any, legalTutorUuid: any){
        commit('removeLegalTutor',legalTutorUuid)
    },

    // ======= Classrooms =======

    async loadClassrooms({dispatch, commit, state}:any){
        await dispatch('loadSchool', {schoolUuid: state.currentSchoolUuid}).then(()=>{
            if(!state.schools[state.currentSchoolUuid].classrooms){
                return getAPI.get('/schools/' + state.currentSchoolUuid + '/classrooms').then((response) => {
                    commit('setSchoolClassrooms', response.data.data)
                })
            }
        })
    },
    reloadSchoolClassrooms({dispatch, commit}:any){
        commit('removeSchoolAllClassrooms')
        dispatch('loadClassrooms')
    },
    addClassroom({commit}:any, data: any){
        commit('addClassroom',data)
    },
    updateClassroom({commit, rootState}:any, data: any){
        commit('updateClassroom',{data:data, classroomUuid:rootState.classroom.currentClassroomUuid})
    },
    removeClassroom({commit}:any, classroomUuid: any){
        commit('removeClassroom',classroomUuid)
    },

    // ======= Students =======

    async loadStudents({dispatch, commit, state}:any){
        await dispatch('loadSchool', {schoolUuid: state.currentSchoolUuid}).then(async ()=>{
            const school = state.schools[state.currentSchoolUuid]
            if(!school.classrooms || !school.students){
                await getAPI.get('/schools/' + state.currentSchoolUuid + '/students').then((response) => {
                    commit('setSchoolStudents', response.data.data)
                    response.data.data.forEach((classroom: any) => {
                        classroom.students.forEach((student: any) => {
                            student.classroom = classroom.classroom
                            student.classroom.school = school
                            commit('students/setStudent', {data: student}, { root: true })
                        })
                    })
                })
            }
        })
    },
    reloadSchoolStudents({dispatch, commit}:any){
        commit('removeSchoolAllStudents')
        dispatch('loadStudents')
    },
    loadStudent({commit, state}:any, studentUuid: string){
        return new Promise((resolve) => {
            const school = state.schools[state.currentSchoolUuid]
            if (school.students) {
                getAPI.get('/students/' + studentUuid).then((response) => {
                    commit('addStudent', response.data.data)
                    resolve(response)
                })
            }else{
                resolve(null)
            }
        })
    },
    loadStudentWallet({commit, state}:any, studentUuid: string){
        return new Promise((resolve) => {
        const school = state.schools[state.currentSchoolUuid]
            if(school.students){
                getAPI.get('/payments/students/' + studentUuid).then((response) => {
                    commit('setCurrentStudentWallet', response.data.data)
                    resolve(response)
                })
            }
            resolve(null)
        })
    },
    loadStudentModules({commit, state}:any, studentUuid: string){
        return new Promise((resolve, reject) => {
            const school = state.schools[state.currentSchoolUuid]
            if(school.students){
                getAPI.get('/students/' + studentUuid + '/modules').then((response) => {
                    commit('setCurrentStudentModules', response.data.data)
                    resolve(response)
                })
            } else {
                reject('No current school')
            }
        })
    },
    addStudent({commit}:any, data: any){
        commit('addStudent',data)
    },
    navigateToStudent({ commit, state }: any, studentUuid: string){
        if(state.currentStudentUuid != studentUuid){
            commit("setCurrentStudentUuid", studentUuid)
        }
    },
    reloadStudent({dispatch}:any, studentUuid: string){
        return new Promise((resolve) => {
            dispatch('loadStudent', studentUuid).then(()=>{
                dispatch('loadStudentWallet', studentUuid).then(()=>{
                    dispatch('loadStudentModules', studentUuid).then((result: any)=> {
                        resolve(result)
                    })
                })
            })
        })
    },
    updateStudent({commit}:any, {data}: any){
        commit('updateStudent',data)
    },
    removeStudent({commit}:any, studentUuid: any){
        commit('removeStudent',studentUuid)
    },

    // ======= Modules =======

    async loadModules({commit}:any, {schoolUuid}: any){
        await getAPI.get('/schools/' + schoolUuid + '/modules').then((response) => {
            commit('setSchoolModules', response.data.data)
            response.data.data.forEach((module: any) => {
                commit('module/setModule', module.module, {root: true})
            })
        })
    },

    navigateToModule({ commit,state }: any, moduleUuid: string){
        if(state.currentModuleUuid != moduleUuid){
            commit("setCurrentModuleUuid", moduleUuid)
        }
    },
    
    async createTypeOnModule({commit, state}:any, data: any){
        await getAPI.post('/schools/' + state.currentSchoolUuid + '/modules/' + state.currentModuleUuid + '/item_types/add', data).then((response) => {
            commit('addTypeOnModule', response.data.data)
        })
    },

    async addManagerOnModule({commit, state}:any, {manager}: any){
        await getAPI.post('/schools/' + state.currentSchoolUuid + '/modules/' + state.currentModuleUuid + '/managers/add', manager).then((response) => {
            commit('addManagerOnModule', response.data.data)
        })
    },

    async loadModuleManagers({commit, state, dispatch}:any){
        await dispatch('loadModules', {schoolUuid: state.currentSchoolUuid}).then(async ()=>{
            if(!state.schools[state.currentSchoolUuid].modules[state.currentModuleUuid].managers){
                await getAPI.get('/schools/' + state.currentSchoolUuid + '/modules/' + state.currentModuleUuid + '/managers').then((response) => {
                    commit('setManagersOnModule', response.data.data)
                })
            }
        })
    },
    removeManagerFromModule({commit}:any, managerUuid:string){
        commit('removeManagerFromModule', managerUuid)
    },
    reloadModuleManagers({commit, dispatch}:any){
        commit("removeAllManagersFromModule")
        dispatch("loadModuleManagers")
    },

    // ======= ItemType =======

    async loadModuleItemTypes({commit, state, dispatch}:any){
        await dispatch('loadModules', {schoolUuid: state.currentSchoolUuid}).then(async ()=>{
            await getAPI.get('/schools/' + state.currentSchoolUuid + '/modules/' + state.currentModuleUuid + '/item_types').then((response) => {
                commit('setItemTypesOnModule', response.data.data)
                response.data.data.forEach((itemType: any) => {
                    commit('itemType/setItemType', itemType, {root: true})
                })
            }).catch((error) => {
                console.log('ERROR: ', error)
                if (error.response && error.response.status == 403) {
                    alertController.create({
                        header: 'Erreur',
                        message: "Vous ne pouvez pas accéder à ce module",
                        buttons: ['OK']
                    }).then(alert => alert.present());
                }
            });
        }).catch((error: any) => {
            console.log('ERROR: ', error)
            if (error.response && error.response.status == 403) {
                alertController.create({
                    header: 'Erreur',
                    message: "Vous ne pouvez pas accéder à ce module",
                    buttons: ['OK']
                }).then(alert => alert.present());
            }
        });
    },
    async reloadModuleItemTypes({commit, dispatch}:any){
        commit("removeAllItemTypesFromModule")
        await dispatch("loadModuleItemTypes")
    },
    navigateToItemType({commit,state}:any, itemTypeUuid: string){
        if(state.currentItemTypeUuid != itemTypeUuid){
            commit("setCurrentItemTypeUuid", itemTypeUuid)
        }
    },
    updateItemType({commit}:any, data: any){
        commit("updateItemType", data)
    },
    async getFilteredStudents({getters}:any, query: any){
        return new Promise((resolve) => {
            resolve(getters.getSchoolStudents(query))
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
