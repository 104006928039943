<template>
  <ion-page>
    
    <ion-header :translucent="true">
      <page-header>
        <ion-title class="header-link" id="add-legal-tutor-ariane">
          <router-link enterkeyhint="go" to="/home" replace id="add-legal-tutor-home-link">Mes écoles</router-link> / 
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}`" replace id="add-legal-tutor-school-link">{{schoolName}}</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}/tutors`" replace id="add-legal-tutor-school-legal-tutors-link">Tuteurs</router-link> /
          Nouveau tuteur
        </ion-title>
      </page-header>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="container" >
        <ion-card color="white" >
          <ion-card-header>
            <ion-card-title id="add-legal-tutor-title">{{ schoolName }}</ion-card-title>
            <ion-card-subtitle id="add-legal-tutor-subtitle">Ajout d'un tuteur</ion-card-subtitle>
          </ion-card-header>
        </ion-card>
        <form class="form" @submit.prevent="createTutor">
          <ion-list lines="none" class="ion-no-margin ion-no-padding">
            <ion-item lines="none" id="add-legal-tutor-email">
              <ion-label position="floating">Email <ion-text color="danger">*</ion-text></ion-label>
              <EmailAutocomplete @selected="tutor.email = $event" />
            </ion-item>
            <ion-item lines="none" id="add-legal-tutor-first-name">
              <ion-label position="floating">Prénom</ion-label>
              <ion-input required type="text" v-model="tutor.first_name"></ion-input>
            </ion-item>
            <ion-item lines="none" id="add-legal-tutor-last-name">
              <ion-label position="floating">Nom</ion-label>
              <ion-input required type="text" v-model="tutor.last_name"></ion-input>
            </ion-item>
            <ion-item v-if="errorMessage">
              <ion-label color="danger" > {{errorMessage}} </ion-label>
            </ion-item>
          </ion-list>
          <br>
          <section style="padding: 0 10px">
            <ion-button shape="round" expand="block" @click.prevent="createTutor" id="add-legal-tutor-button">Ajouter le tuteur</ion-button>
          </section>
        </form>
      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import { IonContent, IonPage,toastController, IonList, IonItem,
IonLabel, IonText, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonButton, IonHeader, IonFooter, IonTitle, IonInput } from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { getAPI } from "@/axios-api";
import PageFooter from "@/views/components/PageFooter.vue";
import PageHeader from "@/views/components/PageHeader.vue";
import EmailAutocomplete from "@/views/components/PartEmailAutocomplete.vue";

export default defineComponent({
  name: "AddLegalTutor",
  components: {
    IonPage,
    IonContent, IonList, IonItem, PageHeader, PageFooter, EmailAutocomplete, IonHeader, IonInput,
    IonLabel, IonText, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonButton, IonFooter, IonTitle
  },
  data() {
    return  {
      errorMessage: "",
      user: {},
      schoolUuid: null,
      tutor: {
        first_name: "",
        last_name: "",
        email: ""
      }
    }
  },
  methods: {
    validateEmail() {
      const validation = this.tutor.email.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i
      );
      return validation
    },
    createTutor() {
      
      if(this.validateEmail()){
        getAPI.post('schools/' + this.schoolUuid + '/legal_tutors/create', this.tutor).then(async (response) => {
          this.$router.back();
          const data = response.data.data
          if(response.status === 200 && data){
            this.$store.dispatch('school/addLegalTutor', data)
          }
          if (data && data.existed) {
            const toast = await toastController
                .create({
                  message: 'Le tuteur existe déjà et ne sera plus créé.',
                  duration: 2000
                })
            return toast.present();
          }
        }).catch(() => {
          alert('Une erreur s\'est produite, veuillez réessayer plus tard.')
        });
      }else{
        this.errorMessage = "L'adresse e-mail entrée n'est pas valide."
      }
      
    }
  },
  mounted() {
    this.schoolUuid = this.$route.params.schoolUuid
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid)
    this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid})
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    }
  },
  setup () {
    return {
      copyOutline
    }
  },
})
</script>

<style scoped>

</style>
