import {getAPI} from "@/axios-api";

const state = () => ({
    legalTutors: {},
    currentLegalTutorUuid: null
})

const mutations = {
    setCurrentLegalTutorUuid(state: any, legalTutorUuid: string){
        state.currentLegalTutorUuid = legalTutorUuid
    },
    setCurrentLegalTutor(state:any, data:any){
        state.legalTutors[state.currentLegalTutorUuid] = data
    },
    setLegalTutor(state:any, data:any){
        state.legalTutors[data.uuid] = data
    },
    removeLegalTutors(state:any){
        delete state.legalTutors[state.currentLegalTutorUuid]
    },
    updateLegalTutorUser(state: any, data: any){
        state.legalTutors[state.currentLegalTutorUuid].tutor.user = data
    },
}

const getters = {
    getLegalTutor (state: any){
        const legalTutor = state.legalTutors[state.currentLegalTutorUuid]
        return legalTutor && legalTutor.tutor
    },
    getLegalTutorStudents (state: any){
        const legalTutor = state.legalTutors[state.currentLegalTutorUuid]
        return legalTutor && legalTutor.students
    },
    getLegalTutorUser (state:any){
        const legalTutor = state.legalTutors[state.currentLegalTutorUuid]
        return legalTutor && legalTutor.tutor.user
    }
}

const actions = {
    navigateToLegalTutor({ commit,state }: any, legalTutorUuid: string){
        if(state.currentLegalTutorUuid != legalTutorUuid){
            commit("setCurrentLegalTutorUuid", legalTutorUuid)
        }
    },

    async loadLegalTutor({ commit, state }: any){
        // Check if the current legalTutor uuid is already loaded in state.legalTutors
        if(!state.legalTutors[state.currentLegalTutorUuid]){
            await getAPI.get('/schools/legal_tutor/' + state.currentLegalTutorUuid).then((response: any) => {
                commit("setCurrentLegalTutor", response.data.data)
            })
        }
    },
    reloadLegalTutor({commit, dispatch}:any){
        commit('removeLegalTutors')
        dispatch('loadLegalTutor')
    },
    updateLegalTutorUser({commit}:any, data:any){
        commit('updateLegalTutorUser',data)
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}