import {getAPI} from "@/axios-api";

const state = () => ({
    tags:{},
})

const mutations = {
    setTag(state: any, data: any){
        state.tags[data.uuid] = data
        state.tags[data.uuid].items = {}
    }
}

const getters = {
    getTag: (state: any) => (uuid: string) => {
        return state.tags[uuid]
    },
}

const actions = {
    async fetchTag({ commit, state }: any, tagUuid: any){
        if(!state.tags[tagUuid]){
            const response = await getAPI.get(`/tags/${tagUuid}`)
            commit('setTag', response.data.data)
        }
    },
    fetchTagsFromItemTypeAndSchool: ({commit}: any, {item_type_uuid, module_uuid, school_uuid}: any) => {
        return getAPI.get(`/schools/${school_uuid}/modules/${module_uuid}/item_types/${item_type_uuid}/tags`).then((response: any) => {
            response.data.data.forEach((tag: any) => {
                commit('setTag', tag)
                commit(
                    'itemType/addTagToItemType',
                    {tag_uuid: tag.uuid, item_type_uuid: item_type_uuid},
                    { root: true }
                )
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}