<template>
  <ion-list v-if="item">
    <ion-item>
      <ion-label color="dark" position="stacked">Nom</ion-label>
      <ion-input color="secondary" :clear-input="true" v-model="item.name"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label color="dark" position="stacked">Tags</ion-label>
      <tags-input
          :tags="$store.getters['item/getAvailableItemTags'](item.uuid)"
          :color="'primary'"
          :size="'small'"
      ></tags-input>
    </ion-item>
    <ion-item>
      <ion-label color="dark" position="stacked">Description</ion-label>
      <ion-textarea color="secondary" auto-grow :clear-input="true">{{ item.description }}</ion-textarea>
    </ion-item>
    <ion-item>
      <ion-label color="dark" position="stacked">Prix</ion-label>
      <ion-input color="secondary" type="number" :clear-input="true" v-model="item.price"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label color="dark" position="stacked">Date de début</ion-label>
      <ion-input color="secondary" type="datetime-local" :clear-input="true" v-model="item.start_at"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label color="dark" position="stacked">Date de fin</ion-label>
      <ion-input color="secondary" type="datetime-local" :clear-input="true" v-model="item.end_at"></ion-input>
    </ion-item>
    <div v-if="showDetail">
      <ion-item>
        <ion-label color="dark" position="stacked">Quantité disponnible</ion-label>
        <ion-input color="secondary" :clear-input="true" type="number" v-model="item.maximum_quantity"></ion-input>
        <ion-note slot="helper">Quantité maximum réservable</ion-note>
      </ion-item>
      <ion-item>
        <ion-label color="dark" position="stacked">Quantity maximum par étudiant</ion-label>
        <ion-input color="secondary" :clear-input="true" type="number" v-model="item.student_maximum_quantity"></ion-input>
        <ion-note slot="helper">Quantité maximum réservable par étudiant</ion-note>
      </ion-item>
      <ion-item disabled>
        <ion-label color="dark" position="stacked">Image</ion-label>
        <ion-input color="secondary" type="file" :clear-input="true" v-model="item.image"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label color="dark" position="stacked">Date de début de la vente</ion-label>
        <ion-input color="secondary" type="datetime-local" :clear-input="true" v-model="item.sell_start_at"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label color="dark" position="stacked">Date de fin de la vente</ion-label>
        <ion-input color="secondary" type="datetime-local" :clear-input="true" v-model="item.sell_end_at"></ion-input>
      </ion-item>
    </div>
  </ion-list>
</template>

<script>
import { IonItem, IonLabel, IonInput, IonTextarea, IonList, IonNote } from '@ionic/vue'
import TagsInput from "@/views/components/Tags/TagsInput.vue";

export default {
  name: "ItemForm",
  components: {
    TagsInput,
    IonItem,
    IonLabel,
    IonInput,
    IonTextarea,
    IonList,
    IonNote
  },
  data() {
    return {

    };
  },
  props: {
    item_uuid: {
      type: String,
      required: false,
      default: null
    },
    tags: {
      type: Object,
      required: false,
    },
    showDetail: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    item() {
      if (this.item_uuid !== null) {
        return this.$store.getters["item/getItem"](this.item_uuid);
      } else {
        return this.$store.getters["item/getNewItem"];
      }
    },
  },
}
</script>

<style scoped>

</style>