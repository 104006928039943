import { RouteRecordRaw } from 'vue-router';
import NurseryManagement from "@/views/pages/nursery/NurseryManagement.vue";
import NurseryStudentList from "@/views/pages/nursery/NurseryStudentList.vue";
import NurseryInOutChoice from "@/views/pages/nursery/NurseryInOutChoice.vue";
import NurseryFormCheck from "@/views/pages/nursery/NurseryFormCheck.vue";

const routesNursery: Array<RouteRecordRaw> = [
    {
        path: '/nursery/:schoolUuid',
        name: 'NurseryManagement',
        component: NurseryManagement
    },
    {
        path: '/nursery/:schoolUuid/types/:typeUuid',
        name: 'NurseryStudentList',
        component: NurseryStudentList
    },
    {
        path: '/nursery/:schoolUuid/types/:typeUuid/student/:studentUuid',
        name: 'NurseryInOutChoice',
        component: NurseryInOutChoice
    },
    {
        path: '/nursery/:schoolUuid/types/:typeUuid/check-out/student/:studentUuid',
        name: 'NurseryFormCheckOut',
        component: NurseryFormCheck
    },
    {
        path: '/nursery/:schoolUuid/types/:typeUuid/check-in/student/:studentUuid',
        name: 'NurseryFormCheckIn',
        component: NurseryFormCheck
    },
]

export default routesNursery