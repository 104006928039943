import {getAPI} from "@/axios-api";

const state = () => ({
    item_types:{},
})
const mutations = {
    setClassroomPriceForItemType(state: any, {item_type_uuid, classroom_uuid, value}: any){
        console.log("setClassroomPriceForItemType", item_type_uuid, classroom_uuid, value)
        if (state.item_types[item_type_uuid] === undefined){
            state.item_types[item_type_uuid] = {}
        }
        if (state.item_types[item_type_uuid][classroom_uuid] === undefined){
            state.item_types[item_type_uuid][classroom_uuid] = {
                classroom_uuid: classroom_uuid,
                item_type_uuid: null,
                price: value,
                uuid: null,
            }
        }
        if (typeof value === "string"){
            state.item_types[item_type_uuid][classroom_uuid].price = value
        } else {
            state.item_types[item_type_uuid][classroom_uuid] = value
        }
    }
}


const getters = {
    getClassroomItemTypePrice: (state: any) => ({item_type_uuid, classroom_uuid}: any) => {
        console.log("dzdzdzdzdzdz", item_type_uuid, classroom_uuid)
        if (state.item_types[item_type_uuid] === undefined){
            return null
        }
        if (state.item_types[item_type_uuid][classroom_uuid] === undefined){
            return null
        }
        console.log("aaaaaaagetClassroomItemTypePrice", item_type_uuid, classroom_uuid, state.item_types[item_type_uuid][classroom_uuid])
        return state.item_types[item_type_uuid][classroom_uuid].price
    }
}


const actions = {
    async getClassroomPriceForItemType({commit}: any, {item_type_uuid, school_uuid}: any){
        await getAPI.get(`/modules/item_types/${item_type_uuid}/schools/${school_uuid}/prices`).then((response: any) => {
             Object.keys(response.data.data).forEach((key: any) => {
                commit('setClassroomPriceForItemType', {item_type_uuid, classroom_uuid: response.data.data[key].classroom_uuid, value: response.data.data[key]})
             })
        })
    },
    async saveClassroomPriceForItemType({getters}: any, {item_type_uuid, classroom_uuid}: any){
        const price = getters.getClassroomItemTypePrice({item_type_uuid, classroom_uuid})
        if (price === undefined || price === null){
            return {}
        }
        console.log("saveClassroomPriceForItemType", item_type_uuid, classroom_uuid, price)
        await getAPI.post(
            `/modules/item_types/${item_type_uuid}/classrooms/${classroom_uuid}/prices/save`,
            {price: price}
        ).then((response: any) => {
            console.log(response.data)
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}