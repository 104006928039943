<template>
      <ion-toolbar color="secondary">
        <ion-buttons slot="start" >
          <ion-back-button defaultHref="/" text=""  ></ion-back-button>
        </ion-buttons>

<!--        <div v-if='buttonMenu' slot="end" class="switch switch-focus-bg" :class="buttonMenu !== 'menu-calendar' && 'switch-focus-bg-right'"  >
          <ion-button mode="md" slot="start" class="switch-btn" :class="buttonMenu === 'menu-calendar' && 'switch-focus'" color="white" @click.prevent="goToCalendar" >
            <ion-icon src="assets/icon/calendar-solid.svg" ></ion-icon>
          </ion-button>

          <ion-button mode="md" slot="end" class="switch-btn" :class="buttonMenu !== 'menu-calendar' && 'switch-focus'" color="white" @click.prevent="goToMenu" >
            <ion-icon src="assets/icon/utensils-solid.svg" ></ion-icon>
          </ion-button>
        </div>-->
      </ion-toolbar>  
</template>

<script>
import { IonToolbar, IonBackButton, IonButtons, /*IonButton, IonIcon*/} from '@ionic/vue';
import { copyOutline, powerSharp } from 'ionicons/icons';
import { defineComponent } from 'vue';
import {getAPI} from "@/axios-api";

export default defineComponent({
  name: "PageFooter",
  components: {
    IonToolbar,
    IonBackButton,
    IonButtons,
    /*IonButton,
    IonIcon*/
  },
  props:[
    'buttonMenu'
  ],
  data() {
    return  {
      user: {},
    }
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem('user'))
    if (user === null) {
      getAPI.get('api/user/').then((response) => {
        const data = response.data.data
        localStorage.setItem('user', JSON.stringify(data))
        user = data
      }).catch((error) => {
        if (error.response.status > 499) {
          alert('Une erreur serveur est survenue. Veuillez réessayer plus tard.')
        }
      })
    }
    this.user = user
  },
  methods: {
    goToCalendar(){
      this.$emit('goToCalendar', "menu-calendar")
    },
    goToMenu(){
      this.$emit('goToMenu', "menu")
    }
  },
  setup () {
    return {
      copyOutline, powerSharp
    }
  },
});
</script>

<style scoped>

  .switch{
    display: flex;
    box-shadow: 0 3px 6px rgba(0,0,0,0.3);
    border-radius: 20px;
    width: 100px;
    margin-right: 0.5rem;
    background: var(--ion-color-light-shade);
    position: relative;
  }
  .switch-btn{
    --box-shadow: none;
    --ion-color-base: none;
    margin: 0;
    opacity: 0.5;
    border-radius: 20px;
    z-index: 1;
    transition: all .3s ease-out;
  }
  .switch-focus-bg::before{
    content: "";
    transition: all .3s ease-out;
    display: block;
    width: 50px;
    height: 36px;
    background: var(--ion-color-white);
    position: absolute;
    top: 0;
    border-radius: 20px;
    transform: translateX(0%);
  }
  .switch-focus-bg-right::before{
    transform: translateX(100%);
  }
  .switch-focus{
    opacity: 1;
  }

</style>
