<template>
    <ion-toolbar color="white" class="ion-align-items-center" >
        <div class="switch switch-focus-bg" :class="view !== 'calendar' && 'switch-focus-bg-right'"  >
            <ion-button mode="md" slot="start" class="switch-btn" :class="view === 'calendar' && 'switch-focus'"  color="white" @click.prevent="goToCalendar" id="calendar-view">
                <ion-icon src="assets/icon/calendar-solid.svg" ></ion-icon>
            </ion-button>
            <ion-button mode="md" slot="start" class="switch-btn" :class="view !== 'calendar' && 'switch-focus'" color="white" @click.prevent="goToList" >
                <ion-icon src="assets/icon/list-solid.svg" ></ion-icon>
            </ion-button>
        </div>
    </ion-toolbar>

    <part-week-items-list v-if="view=='list'" :is-teacher="false" :student="student" :not_reservable="true"></part-week-items-list>
    <part-calendar v-if="view=='calendar'"></part-calendar>
</template>

<script>

import {IonToolbar, IonButton, IonIcon} from '@ionic/vue';
import {defineComponent} from 'vue';
import PartWeekItemsList from "@/views/Modules/Default/parts/PartWeekItemsList";
import PartCalendar from '@/views/Modules/Default/parts/PartCalendar'
export default defineComponent({
  name: 'PageNursery',
  components:{
    PartWeekItemsList,
    PartCalendar,
    IonToolbar, IonButton, IonIcon
  },
  props: [
    'isTeacher',
    'student'
  ],
  emits: ['updated'],
  data(){
    return{
        view: "list"
    }
  },
  methods:{
    goToCalendar(){
        this.view = 'calendar'
    },
    goToList(){
        this.view = 'list'
    }
  }
})
</script>

<style scoped>

  .switch{
    display: flex;
    box-shadow: 0 3px 6px rgba(0,0,0,0.3);
    border-radius: 20px;
    width: 100px;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
    margin: 1rem 1rem 1rem 0.5rem;
    background: var(--ion-color-light-shade);
    position: relative;
  }
  .switch-btn{
    --box-shadow: none;
    --ion-color-base: none;
    margin: 0;
    opacity: 0.5;
    border-radius: 20px;
    z-index: 1;
    transition: all .3s ease-out;
  }
  .switch-focus-bg::before{
    content: "";
    transition: all .3s ease-out;
    display: block;
    width: 50px;
    height: 36px;
    background: var(--ion-color-white);
    position: absolute;
    top: 0;
    border-radius: 20px;
    transform: translateX(0%);
  }
  .switch-focus-bg-right::before{
    transform: translateX(100%);
  }
  .switch-focus{
    opacity: 1;
  }
</style>
