import {getAPI} from "@/axios-api";

const state = () => ({
    item_types:{},
})

const mutations = {
    setItemType(state: any, data: any){
        if (state.item_types[data.uuid] === undefined){
            state.item_types[data.uuid] = data
            state.item_types[data.uuid].items = {}
            state.item_types[data.uuid].tags = {}
        } else {
            state.item_types[data.uuid].name = data.name
            state.item_types[data.uuid].price = data.price
        }
    },
    addItemToItemType(state: any, {data, item_type_uuid}: any){
        state.item_types[item_type_uuid].items[data.uuid] = data.uuid
    },
    removeItemType(state: any, uuid: string){
        delete state.item_types[uuid]
    },
    updateItemType(state: any, data: any){
        state.item_types[data.data.uuid].name = data.data.name
        state.item_types[data.data.uuid].price = data.data.price
    },
    setItemTypeName(state: any, {uuid, name}: any){
        state.item_types[uuid].name = name
    },
    setItemTypeDescription(state: any, {uuid, description}: any){
        state.item_types[uuid].description = description
    },
    setItemTypeImage(state: any, {uuid, image}: any){
        state.item_types[uuid].image = image
    },
    addTagToItemType(state: any, {item_type_uuid, tag_uuid}: any){
        state.item_types[item_type_uuid].tags[tag_uuid] = tag_uuid
    }

}

const getters = {
    getItemType: (state: any) => (uuid: string) => {
        return state.item_types[uuid]
    },
    getItemTypeTags: (state: any) => (uuid: string) => {
        state.item_types[uuid] ? state.item_types[uuid].tags : {}
    },
    getItemTypeItems: (state: any) => (uuid: string) => {
        if (state.item_types[uuid] != undefined){
            return state.item_types[uuid].items
        }
        return []
    },
    getItemTypeName: (state: any) => (uuid: string) => {
        return state.item_types[uuid].name
    },
    getItemTypeImage: (state: any) => (uuid: string) => {
        return state.item_types[uuid].image
    },
    getItemTypePrice: (state: any) => (uuid: string) => {
        return state.item_types[uuid].price
    },
    getItemTypes: (state: any) => (query: string) => {
        if(query){
            //lowercase + retire les accents
            query= query.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '')
            return Object.values(state.item_types).filter((item_type:any)=>
                item_type.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '').includes(query)
            )
        }
        return Object.values(state.item_types)
    }
}

const actions = {
    async fetchItemType({ commit, state }: any, itemTypeUuid: any){
        if(!state.item_types[itemTypeUuid]){
            const response = await getAPI.get(`/modules/item_types/${itemTypeUuid}`)
            commit('setItemType', response.data)
        }
    },
    createItemType({ commit, dispatch }: any, {schoolUuid, moduleUuid, itemType}: any){
        return new Promise((resolve, reject) => {
            getAPI.post(`/schools/${schoolUuid}/modules/${moduleUuid}/item_types/add`, itemType).then((response: any) => {
                commit('setItemType', response.data)
                dispatch('school/loadItemTypeList', {schoolUuid, moduleUuid}, {root: true})
                resolve(response)
            }).catch((error: any) => {
                reject(error)
            })
        })
    },
    async updateItemType({ commit }: any, {schoolUuid, moduleUuid, uuid, itemType}: any){
        const response = await getAPI.put(`/schools/${schoolUuid}/modules/${moduleUuid}/item_types/${uuid}/update`, itemType)
        commit('updateItemType', response.data)
        commit('school/updateItemType', response.data.data, {root: true})
    },
    async deleteItemType({ commit }: any, itemTypeUuid: any){
        await getAPI.delete(`/modules/item_types/${itemTypeUuid}`)
        commit('removeItemType', itemTypeUuid)
    },
    async fetchItemTypeItems({ commit }: any, {itemTypeUuid, from_datetime, to_datetime}: any){
        const response = await getAPI.get(
            `/modules/item_types/${itemTypeUuid}/items`,
            {
                params: {
                    from_datetime: from_datetime,
                    to_datetime: to_datetime
                }
            }
        )
        response.data.data.forEach((item: any) => {
            commit('item/setItem', item, { root: true })
            commit('addItemToItemType', {data: item, item_type_uuid: itemTypeUuid})
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}