<template>
  <ion-page>

    <ion-header :translucent="true">
      <page-header>
        <ion-title v-if="classroom" class="header-link"  id="edit-classroom-ariane">
          <router-link enterkeyhint="go" to="/home" replace id="edit-classroom-home-link">Mes écoles</router-link> / 
          <router-link enterkeyhint="go" :to="`/school/${classroom.school.uuid}`" replace id="edit-classroom-school-link">{{schoolName}}</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${classroom.school.uuid}/classrooms`" replace id="edit-classroom-school-classrooms-link">Classes</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${classroom.school.uuid}/classrooms/${classroom.uuid}`" replace id="edit-classroom-school-classroom-link">{{classroom.name}}</router-link> /
          Éditer
        </ion-title>
      </page-header>
    </ion-header>

    <ion-content>

    <div class="container" >
        <form class="form" onSubmit={this.processForm}>
            <ion-list v-if="classroomForm" lines="none" >
                <ion-list-header id="edit-classroom-header">
                    Modifier la classe
                </ion-list-header>
                <ion-item lines="none" id="edit-classroom-name">
                    <ion-label position="floating">Nom de la classe</ion-label>
                    <ion-input v-model="classroomForm.name"></ion-input>
                </ion-item>
            </ion-list>
            <ion-button color="primary" expand="full" shape="round" @click.prevent="updateClassroom" id="edit-classroom-button">Enregistrer</ion-button>
        </form>
    </div>

    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import {
  IonPage, IonList, IonListHeader, IonItem, IonLabel, IonInput, IonButton, IonHeader, IonFooter, IonContent, toastController, IonTitle
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {getAPI} from "@/axios-api";
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";

export default defineComponent({
  name: 'EditClassroom',
  components: {
    IonPage, IonList, IonListHeader, IonItem, IonLabel, IonInput, IonButton, 
    PageHeader, PageFooter, IonHeader, IonFooter, IonContent, IonTitle
  },
  data() {
    return {
      classroomForm:{},
      schoolUuid: null,
      fieldMap: {
        'name': 'nom',
      },
    }
  },
  computed:{
    classroom(){
      return this.$store.getters["classroom/getClassroom"]
    },
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
  },
  methods: {
    loadClassroom() {
      this.$store.dispatch('classroom/navigateToClassroom', this.$route.params.classroomUuid)
      this.$store.dispatch('classroom/loadClassroom').then(()=>{
        // Clone le store classroom pour qu'il ne soit pas réactif
        this.classroomForm = {...this.classroom}
      })
    },
    checkRequired(fields) {
      const invalidFields = Object.keys(fields).map((field) => {
        if (!this.classroomForm[field]) {
          return fields[field];
        }
      })
      return invalidFields.filter(n => n);
    },
    updateClassroom() {
      const invalidFieldsExist = this.checkRequired(this.fieldMap);
      if (invalidFieldsExist.length) {
        const msg = invalidFieldsExist.length > 1 ? 'Les champs ' + invalidFieldsExist.join(', ') + ' sont obligatoires.' : 'Le champ ' + invalidFieldsExist.join(', ') + ' est obligatoire.'
        alert(msg);
        return;
      }
      getAPI.put('/schools/' + this.$route.params.schoolUuid + '/classrooms/' + this.$route.params.classroomUuid + '/update', this.classroomForm).then(async () => {
        this.$store.dispatch('school/updateClassroom', this.classroomForm)
        this.$store.dispatch('classroom/updateClassroom', this.classroomForm)
        this.$router.back();
        const toast = await toastController
            .create({
              message: 'Informations modifiées avec succès.',
              duration: 2000
            })
        return toast.present();
      })
    },
  },
  mounted() {
    this.schoolUuid = this.$route.params.schoolUuid
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid)
    this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid})
    this.loadClassroom()
  }
});
</script>

<style scoped>

</style>
