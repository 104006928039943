<template>
  <ion-page>
    <page-header>
      <ion-title v-if="$store.getters.userDatas.townships.length > 0" class="header-link" >
        <router-link enterkeyhint="go" to="/home" replace>Mes communes</router-link> / 
        <router-link v-if="township" enterkeyhint="go" :to="`/townships/${township.uuid}`" replace>{{township.name}}</router-link> /
        Récap
      </ion-title>
      <ion-title class="header-link" v-else>
         <router-link enterkeyhint="go" to="/home" replace>Accueil</router-link> /
         Récap
      </ion-title>
    </page-header>
    <ion-content :fullscreen="true">
      <div class="container" >
        <ion-card color="light" >
          <ion-card-header>
            <ion-card-title> Télécharger les réservations</ion-card-title>
            <ion-card-subtitle>Choisissez les critères pour le téléchargement</ion-card-subtitle>
          </ion-card-header>
        </ion-card>
        <form class="form" onSubmit={this.processForm}>
          <ion-list lines="none" class="ion-no-margin ion-no-padding">
            <ion-item class="hiddenForDesktop" >
              <ion-label position="floating">Date <ion-text color="danger">*</ion-text></ion-label>
              <ion-datetime :picker-options="customPickerOptions"  min="1990-02" max="2100" display-format="DD MMM YYYY" required v-model="recapWizard.date"></ion-datetime>
            </ion-item>
            <DatePicker :attributes="calendarAttrs" is-expanded class="hiddenForMobile" :masks="masks" v-model="recapWizard.date" mode="date">
              <template v-slot="{ inputValue, inputEvents }">
                <ion-label position="floating">Date <ion-text color="danger">*</ion-text></ion-label>
                <ion-input
                  :value="inputValue"
                  v-on="inputEvents"
                  readonly
                ></ion-input>
              </template>
            </DatePicker >
            <ion-item lines="none" >
              <ion-label position="stacked">École
                <ion-text color="danger">*</ion-text>
              </ion-label>
              <ion-select multiple v-model="recapWizard.schools" interface="alert" @ionChange="onchangeSchools()">
                <ion-select-option v-for="(school, key) in schools" :key="key" :value="school.uuid">
                  {{ school.name }}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item lines="none" >
              <ion-label position="stacked">Classe
                <ion-text color="danger">*</ion-text>
              </ion-label>
              <ion-select multiple v-model="recapWizard.classrooms" interface="alert">
                <ion-select-option v-for="(school, key) in filteredClassrooms" :key="key" :value="school.uuid">
                  {{ school.name }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-list>
          <ion-button class="btn-submit" expand="block" @click.prevent="doGenerateRecap">Imprimer</ion-button>
          <span class="warning">Le mail contiendra les 2 rapports : (j+1 à j+3) et le prévisionnel de la semaine prochaine.</span>
        </form>
      </div>
    </ion-content>
    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonContent, IonPage, IonList, IonItem, alertController, IonFooter,
  IonLabel, IonText, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle,
  IonButton, IonDatetime, IonSelect, IonSelectOption, IonTitle, IonInput
} from '@ionic/vue';
import {copyOutline} from 'ionicons/icons';
import {defineComponent} from 'vue';
import {getAPI} from "@/axios-api";
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import moment from 'moment'
import {DatePicker } from 'v-calendar';


export default defineComponent({
  name: "ViewReservationRecap",
  components: {
    IonPage,
    IonContent, IonList, IonItem, PageHeader, IonFooter, PageFooter, 
    IonLabel, IonText, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, 
    IonButton, IonDatetime, IonSelect, IonSelectOption, DatePicker, IonTitle, IonInput
  },
  data() {
    return {
      schools: [],
      classrooms: [],
      township: null,
      filteredClassrooms: null,
      recapWizard: {},
      masks: {
        input: 'DD MMM YYYY',
      },
      calendarAttrs: [
        {
          key: 'today',
          bar: {
            color: 'gray',
          },
          dates: new Date(),
        },
      ],
    }
  },
  mounted() {
    getAPI.get('/townships/' + this.$route.params.townshipUuid).then((response) => {
      this.township = response.data.data
    })
    getAPI.get('townships/' +  this.$route.params.townshipUuid + '/schools').then((response) => {
      this.schools = response.data.data.map((data) => data.school);
      response.data.data.forEach((data) => {
        this.classrooms = this.classrooms.concat(data.classrooms)
      });
      const today = new Date().toISOString()
      this.recapWizard = {
        date: today,
        schools: [],
        classrooms: [],
      }
    })
  },
  methods: {
    onchangeSchools() {
      this.recapWizard.classrooms = [];
      this.filteredClassrooms = [];
      this.classrooms.forEach((classroom) => {
        if (this.recapWizard.schools.includes(classroom.school.id)) {
          this.filteredClassrooms.push(classroom);
          this.recapWizard.classrooms.push(classroom.id);
        }
      })
    },
    doGenerateRecap() {
      getAPI.post('townships/' +  this.$route.params.townshipUuid + '/reservations/generate', this.recapWizard).then(async (response) => {
        let alertMsg = {}
        const data = response.data.data
        if (data.status === 'ok') {
          alertMsg = {
            header: 'Un mail vous a été envoyé :',
            message: 'Le récapitulatif des réservations du ' + moment(this.recapWizard.date).format('DD MMM YYYY') + ' vous a été envoyé par email. Merci.',
            buttons: ['OK'],
          }
          this.$router.back();
          const alertItem = await alertController.create(alertMsg);
          return alertItem.present();
        }
      }).catch(async () => {
          const alertMsg = {
            message: 'Une erreur s\'est produite, veuillez réessayer plus tard.',
            buttons: ['OK'],
          }
          const alertItem = await alertController.create(alertMsg);
          return alertItem.present();
      });
    }
  },
  setup() {
    const customPickerOptions = {
      cssClass: 'custom-options'
    }
    return {
      copyOutline, customPickerOptions
    }
  },
})
</script>

<style scoped>

  .form ion-list{
    display: initial;
  }

  .form ion-input{
    box-shadow: none;
  }

  .btn-submit{
    margin: 1rem 0;
  }

  .warning{
    font-size: 12px;
  }

</style>
