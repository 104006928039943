import { RouteRecordRaw } from 'vue-router';

import AdminModuleIndex from "@/views/Modules/Default/Admin/AdminModuleIndex.vue"
import AdminListType from "@/views/Modules/Default/Admin/AdminListType.vue"
import AdminEditType from "@/views/Modules/Default/Admin/AdminEditType.vue"
import AdminListManagers from "@/views/Modules/Default/Admin/AdminListManagers.vue"
import ItemTypesFormPage from "@/views/pages/school/module/type/ItemTypesFormPage.vue"
import ItemPage from "@/views/pages/school/module/type/ItemPage.vue"
import CreateItemPage from "@/views/pages/school/module/type/CreateItemPage.vue";
import TypePricesPage from "@/views/pages/school/module/type/TypePricesPage.vue";

const routesAdminModule: Array<RouteRecordRaw> = [
    {
        path: '/school/:schoolUuid/module/:moduleUuid',
        name: 'AdminModuleIndex',
        component: AdminModuleIndex
    },
    {
        path: '/school/:schoolUuid/module/:moduleUuid/types',
        name: 'AdminModuleTypeList',
        component: AdminListType
    },
    {
        path: '/school/:schoolUuid/module/:moduleUuid/types/:typeUuid',
        name: 'AdminItemTypeItemList',
        component: ItemTypesFormPage
    },
    {
        path: '/school/:schoolUuid/module/:moduleUuid/types/:typeUuid/edit',
        name: 'AdminModuleEditType',
        component: AdminEditType
    },
    {
        path: '/school/:schoolUuid/module/:moduleUuid/types/:typeUuid/prices',
        name: 'AdminModuleEditTypePrices',
        component: TypePricesPage
    },
    {
        path: '/school/:schoolUuid/module/:moduleUuid/types/:typeUuid/items/new',
        name: 'AdminItemTypeCreateItem',
        component: CreateItemPage
    },
    {
        path: '/school/:schoolUuid/module/:moduleUuid/types/:typeUuid/items/:itemUuid',
        name: 'AdminItemTypeItemEdit',
        component: ItemPage
    },
    {
        path: '/school/:schoolUuid/module/:moduleUuid/managers',
        name: 'AdminModuleManagerList',
        component: AdminListManagers
    }
]

export default routesAdminModule