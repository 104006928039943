<template>
  <ion-page>

    <ion-header :translucent="true">
      <page-header>
        <ion-title v-if="!loading" class="header-link"  id="edit-teacher-ariane">
          <router-link enterkeyhint="go" to="/home" replace id="edit-teacher-home-link">Mes écoles</router-link> / 
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}`" replace id="edit-teacher-school-link">{{schoolName}}</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}/teachers`" replace id="edit-teacher-school-teachers-link">Professeurs</router-link> /
          {{userTeacher.first_name}} {{userTeacher.last_name}} / Éditer
        </ion-title>
      </page-header>
    </ion-header>

    <div v-if="loading">
      <p style="text-align: center">
        Chargement des données...<br /> <ion-spinner name="lines-small"></ion-spinner>
      </p>
    </div>
    <part-form-edit 
      v-if='user' 
      :userProps="user" 
      :userType="'teacher'" 
      @saveUser="saveUser" 
      @reLoadUserDatas="reLoadUserDatas" 
    ></part-form-edit>

  </ion-page>
</template>

<script>
import {
  IonPage, toastController, IonHeader, IonTitle, IonSpinner
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {getAPI} from "@/axios-api";
import PartFormEdit from '@/views/components/PartFormEdit.vue';
import PageHeader from '@/views/components/PageHeader.vue';

export default defineComponent({
  name: 'EditTeacher',
  components: {
    IonPage, PartFormEdit, IonHeader, IonTitle, PageHeader, IonSpinner
  },
  data() {
    return {
      user: null,
      schoolUuid: null,
      fieldMap: {
        'email': 'email',
      },
      baseEmail: null
    }
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    userTeacher(){
      return this.$store.getters["teacher/getTeacherUser"]
    },
    loading(){
      if (this.userTeacher && this.schoolName){
        return false
      }
      return true
    }
  },
  mounted() {
    this.schoolUuid = this.$route.params.schoolUuid
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid)
    this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid}).then(()=>{
      this.$store.dispatch('teacher/navigateToTeacher', this.$route.params.teacherUuid)
      this.$store.dispatch('teacher/loadUserTeacher').then(()=>{
        this.user = {...this.userTeacher}
      })
    })
  },
  methods: {
    checkRequired(fields) {
      const invalidFields = Object.keys(fields).map((field) => {
        if (!this.user[field]) {
          return fields[field];
        }
      })
      return invalidFields.filter(n => n);
    },
    saveUser() {

      if(this.user.checkOrderManager){
        this.user.updateOrderManager = {
          "school": this.schoolUuid,
          "user" : this.user.userprofile.uuid,
          "update" : "add"
        }
      }else{
        this.user.updateOrderManager = {
          "school": this.schoolUuid,
          "user" : this.user.userprofile.uuid,
          "update": "delete"
        }
      }
      const invalidFieldsExist = this.checkRequired(this.fieldMap);
      if (invalidFieldsExist.length) {
        const msg = invalidFieldsExist.length > 1 ? 'Les champs ' + invalidFieldsExist.join(', ') + ' sont obligatoires.' : 'Le champ ' + invalidFieldsExist.join(', ') + ' est obligatoire.'
        alert(msg);
        return;
      } 
      getAPI.post('/api/user/' + this.user.userprofile.uuid + '/update', this.user).then(async () => {
        const baseEmail = this.userTeacher.email
        this.$store.dispatch('school/updateTeacherUser', this.user)
        this.$store.dispatch('teacher/updateTeacherUser', this.user)
        this.$router.back();
        if (baseEmail != this.user.email){
          const toast = await toastController
            .create({
              message: "Un email a été envoyé à l'adresse " + this.user.email + " pour confirmer le changement d'email.",
              duration: 3000
            })
          return toast.present();
        }
        const toast = await toastController
            .create({
              message: 'Informations modifiées avec succès.',
              duration: 2000
            })
        return toast.present();
      })
    },
    reLoadUserDatas(event){
      this.user = null
      this.$store.dispatch('teacher/reloadTeacher').then(()=>{
        this.user = {...this.userTeacher}
        event.target.complete()
      })
    }
  },
});
</script>

<style scoped>

</style>
