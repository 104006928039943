<template>
  <ion-page>

    <ion-header>
      <page-header>
        <ion-title class="header-link" id="classroom-ariane" v-if="classroom">
          <router-link enterkeyhint="go" to="/home" replace id="classroom-home-link">Mes classes</router-link> / 
          {{classroom.school.name}} {{classroom.name}}
        </ion-title>
      </page-header>
    </ion-header>


    <ion-content :fullscreen="true">
      
      <ion-refresher slot="fixed" @ionRefresh="reloadDatas($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div class="container" >
        <ion-segment mode="md" color="primary" @ionChange="segmentChanged($event)" :value="module">
          <ion-segment-button value="students" id="students">Élèves</ion-segment-button>
          <ion-segment-button value="meal_attendance" id="meal_attendance">Présence</ion-segment-button>
        </ion-segment>

        <div v-if="module === 'students'">
          <ion-searchbar color="light" v-model="search" @ionChange="searchStudent"></ion-searchbar>
          <ion-list lines="full" >
            <ion-item color="white" v-for="(student, sKey) in showStudents.sort((a,b)=>sortingName(a,b,['first_name', 'last_name']))" :key="sKey" :router-link="'/classroom/student/' + student.uuid" replace v-bind:id="'student-'+student.id">
              <ion-label class="student-list">
                {{student.first_name}} {{student.last_name}}
              </ion-label>
            </ion-item>
          </ion-list>
        </div>

        <div v-if="module === 'meal_attendance'">
          <ion-list lines="full">
            <ion-toolbar color="light" >
              <ion-buttons slot="start">
                <ion-button @click.prevent="goPreviousDay">
                  <ion-icon :icon="chevronBackOutline"></ion-icon>
                </ion-button>
              </ion-buttons>
              <ion-buttons slot="end">
                <ion-button @click.prevent="goNextDay">
                  <ion-icon :icon="chevronForwardOutline"></ion-icon>
                </ion-button>
              </ion-buttons>
              <ion-title class="ion-text-center" >
                {{date.getDate()}}/{{date.getMonth()+1 }}/{{date.getFullYear()}}
              </ion-title>
            </ion-toolbar>
            <div v-for="(meal, mkey) in meals" :key="mkey">
              <ion-toolbar color="light" v-if="reservations.length > 0">
                <ion-title class="meal_title">
                  {{ meal.item_type }} :<br/>
                  {{ meal.name }}
                </ion-title>
                <p style="margin:0; padding: 8px 15px;"><em style="font-size: 0.7em;" >{{meal.burned_quantity || 0 }} repa(s) commandé(s) | {{reservations.filter(res=>res.meal_uuid == meal.uuid).length || 0}} présences | Différence: {{parseInt(reservations.filter(res=>res.meal_uuid == meal.uuid).length || 0) - parseInt(meal.burned_quantity || 0)}}</em></p>
              </ion-toolbar>
              <ion-item
                color="white" 
                v-for="(reservation, kReserv) in reservations.sort(
                    (a,b)=>sortingName(a,b,['student.first_name', 'student.last_name'])
                  ).filter(res=>res.meal_uuid == meal.uuid)" 
                  :key="kReserv" 
                  v-bind:id="'attendance-student-'+reservation.student.id">
                <ion-label class="student-list">{{reservation.student.first_name}} {{reservation.student.last_name}}</ion-label>
                <ion-checkbox
                    slot="end"
                    :value="reservation.uuid"
                    @ionChange="updateReservation"
                    @update:modelValue="reservation.consumed = $event"
                    :modelValue="reservation.consumed">
                </ion-checkbox>
              </ion-item>
            </div>
          </ion-list>
        </div>

      </div>

    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>


  </ion-page>
</template>


<script>
import {
  IonContent, IonHeader, IonFooter, IonPage, IonTitle, IonToolbar, IonIcon, IonItem, IonSegment, IonSegmentButton,
  toastController, IonCheckbox, IonButton, IonButtons, IonList, IonLabel, IonSearchbar, IonRefresher, IonRefresherContent,
} from '@ionic/vue';
import { copyOutline, createOutline } from 'ionicons/icons';
import { chevronForwardOutline, chevronBackOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import {getAPI} from "@/axios-api";
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import {sortingName} from "@/utils";

export default defineComponent({
  name: 'ClassroomPage',
  components: {
    IonPage, IonRefresher, IonRefresherContent,
    IonContent, IonItem, IonSegment, IonSegmentButton, IonCheckbox, IonIcon, IonTitle, IonToolbar,
    IonButton, IonButtons, IonList, IonLabel, IonSearchbar,
    IonHeader, IonFooter, PageHeader, PageFooter
  },
  mounted() {
    const currentDate = new Date();
    const oneJan = new Date(currentDate.getFullYear(),0,1);
    const numberOfDays = Math.floor((currentDate - oneJan) / (24 * 60 * 60 * 1000));
    const result = Math.ceil(( currentDate.getDay() + 1 + numberOfDays) / 7);
    this.week = result
    this.date = new Date();
    getAPI.get('schools/' + this.$route.params.schoolUuid + '/classrooms/' + this.$route.params.classroomUuid + '?search=').then((response) => {
      this.classroom = response.data.data
      this.showStudents = response.data.data.students
    })
    this.loadAttendances();
  },
  data() {
    return {
      module: 'students',
      reservations: [],
      meals: [],
      week: 1,
      date: null,
      editType: '',
      objId: false,
      classroom: {
        id: null,
        name: null,
        students: [],
        school: {
          id: null,
          name: null,
        }
      },
      showStudents: [],
      search: ''
    }
  },
  methods: {
    loadAttendances() {
      this.reservations = []
      getAPI.get('schools/' + this.$route.params.schoolUuid + '/classrooms/' + this.$route.params.classroomUuid + '/reservations',
          {
            params: {
              'date': this.date.getFullYear() + '-' + (this.date.getMonth() + 1) + '-' + this.date.getDate()
            }
          }
      ).then((response) => {
        this.reservations = response.data.data.reservations
        this.meals = response.data.data.meals
      })
    },
    segmentChanged(ev) {
      this.module = ev.detail.value
    },
    goPreviousDay() {
      this.date.setDate(this.date.getDate() - 1)
      const dte = this.date
      this.date = null
      this.date = dte
      this.loadAttendances();
    },
    goNextDay() {
      this.date.setDate(this.date.getDate() + 1)
      const dte = this.date
      this.date = null
      this.date = dte
      this.loadAttendances();
    },
    updateReservation(event) {
      getAPI.put('schools/' + this.$route.params.schoolUuid + '/classrooms/' + this.$route.params.classroomUuid + '/reservations/' + event.detail.value + '/update',
          {
            'consumed': event.detail.checked
          }
      ).then(async () => {
        const toast = await toastController
            .create({
              message: 'Présence mise à jour.',
              duration: 2000
            })
        return toast.present();
      })
    },
    searchStudent(){
      this.showStudents = this.classroom.students.filter((student)=>{
        //lowercase + retire les accents
        const search = this.search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '')
        const lastName = student.last_name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '')
        const firstName = student.first_name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '')
        if(firstName.includes(search)
        || lastName.includes(search)){
          return student
        }
      })
    },
    reloadDatas(event) {
      getAPI.get('schools/' + this.$route.params.schoolUuid + '/classrooms/' + this.$route.params.classroomUuid).then((response) => {
        const data = response.data.data
        this.classroom = data
        this.showStudents = data.students
        this.loadAttendances();
        event.target.complete()
      })
    }
  },
  setup () {
    return {
      chevronForwardOutline,
      chevronBackOutline,
      copyOutline,
      sortingName,
      createOutline
    }
  },
});
</script>

<style scoped>
  .meal_title{
    position: relative;
    padding: 15px 15px 0 15px;
  }
  ion-segment{
    background: var(--ion-color-primary);
    border-radius: 0;
  }

  ion-segment-button{
    --ion-background-color: none;
    color: rgba(255,255,255,0.7);
    --indicator-box-shadow: none;
    border-radius: 0;
    font-size: 14px;
    padding: 0.5rem 0;
    font-weight: 700;
    text-transform: uppercase;
    --ion-color-base: #fff;

  }
  ion-segment-button::part(indicator){
    color: #fff;
    border-bottom: 3px solid #fff;
  }

  .student-list{
    padding: 0.5rem 0;
  }


  @media (min-width: 600px){
    ion-segment{
      background: none;

    }
    ion-segment-button{
      --ion-color-base: var(--ion-color-primary);
      color: var(--ion-color-white-contrast);
    }


    ion-label{
      --ion-color-base: var( --ion-color-white-contrast);
    }

    ion-item:hover, ion-item:focus{

      --ion-item-background: var(--ion-color-light);
      cursor: pointer;
    }



  }

</style>
