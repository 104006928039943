<template>
  <ion-page>

    <ion-header :translucent="true">
      <page-header>
        <ion-title v-if="schoolName" class="header-link" id="add-student-ariane">
          <router-link enterkeyhint="go" to="/home" replace id="add-student-home-link">Mes écoles</router-link> / 
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}`" replace id="add-student-school-link">{{schoolName}}</router-link> /
          <router-link enterkeyhint="go" :to="`/school/${schoolUuid}/students`" replace id="add-student-school-students-link">Élèves</router-link> /
          Nouvel élève
        </ion-title>
      </page-header>
    </ion-header>

    <ion-content :fullscreen="true">

      <div class="container" >

        <ion-card color="white" >
          <ion-card-header>
            <ion-card-title id="add-student-title">{{ schoolName }}</ion-card-title>
            <ion-card-subtitle id="add-student-subtitle">Ajout d'un étudiant</ion-card-subtitle>
          </ion-card-header>
        </ion-card>
 
        <form class="form" @keyup.enter.prevent="createStudent" @submit.prevent="createStudent" id="add-student-form">
          <ion-list lines="none" class="ion-no-margin ion-no-padding">

            <part-personal-info :studentProps="student" :disabled="false" :addStudent="true" ></part-personal-info>

            <part-tutors-list :currentStudent="student" ref="tutorsList" ></part-tutors-list>

            <part-address :studentProps="student" ></part-address>
          </ion-list>

          <ion-button shape="round" expand="block" @click.prevent="createStudent" id="add-student-button">Ajouter l'étudiant</ion-button>
        </form>
      </div>
    </ion-content>

    <ion-footer>
      <page-footer></page-footer>
    </ion-footer>

  </ion-page>
</template>

<script>
import {
  IonContent, IonPage, IonCard, IonCardTitle, IonCardHeader, IonHeader,
  IonCardSubtitle, toastController, IonFooter, IonTitle, IonList, IonButton,
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {getAPI} from "@/axios-api";
import PageHeader from "@/views/components/PageHeader.vue";
import PageFooter from "@/views/components/PageFooter.vue";
import PartAddress from "@/views/components/students/EditStudentForm/PartAddress.vue"
import PartPersonalInfo from "@/views/components/students/EditStudentForm/PartPersonalInfo.vue"
import PartTutorsList from "@/views/components/students/EditStudentForm/PartTutorsList.vue"
import {basicAlertMsg} from '@/utils';

export default defineComponent({
  name: 'AddStudent',
  components: {
    IonPage, IonHeader, IonContent,
    IonCard, IonCardTitle, IonCardHeader, IonList, IonButton,
    PageHeader, PageFooter, IonFooter, IonCardSubtitle, IonTitle,
    PartAddress, PartPersonalInfo, PartTutorsList
  },
  data() {
    return {
      errorMessage: "",
      searchTutorInput: "",
      isOpenRef: false,
      schoolUuid: null,
      fieldMap: {
        'first_name': 'prénom',
        'last_name': 'nom',
        'national_number': 'numéro national',
        'classroom': 'classe'
      },
      student: {
        'first_name': null,
        'last_name': null,
        'national_number': null,
        'address_street': null,
        'address_number': null,
        'address_box': null,
        'address_zip': null,
        'address_city': null,
        'address_country': null,
        'classroom': {id: null},
        'legal_tutors': [],
        'cpas': false,
      },
    }
  },
  setup() {

    const options = {
      cssClass: 'custom-options'
    }

    return {
      options,
      basicAlertMsg
    }
  },
  computed:{
    schoolName(){
      return this.$store.getters["school/getSchoolName"]
    },
    legalTutors(){
      return this.$store.getters["school/getSchoolLegalTutors"](this.searchTutorInput)
    }
  },
  mounted() {
    this.schoolUuid = this.$route.params.schoolUuid
    this.$store.dispatch('school/navigateToSchool', this.$route.params.schoolUuid)
    this.$store.dispatch('school/loadSchool', {schoolUuid: this.$route.params.schoolUuid})
    this.$store.dispatch('school/loadClassrooms')
    this.$store.dispatch('school/loadLegalTutors')
  },
  methods: {
    checkRequired(fields) {
      const invalidFields = Object.keys(fields).map((field) => {
        if (!this.student[field]) {
          return fields[field];
        }
      })
      return invalidFields.filter(n => n);
    },
    async createStudent() {
      const invalidFieldsExist = this.checkRequired(this.fieldMap);
      if (invalidFieldsExist.length) {
        const msg = invalidFieldsExist.length > 1 ? 'Les champs ' + invalidFieldsExist.join(', ') + ' sont obligatoires.' : 'Le champ ' + invalidFieldsExist.join(', ') + ' est obligatoire.'
        this.basicAlertMsg(msg)
      }

      const nationalNumberPattern = new RegExp(/([0-9]{6}-[0-9]{3}-[0-9]{2})/g)
      if(!nationalNumberPattern.test(this.student.national_number)){
        const msg = "Le champ Numéro national n'est pas correctement complété (000000-000-00)"
        this.basicAlertMsg(msg)
      }

      this.student["legal_tutors"] = []
      const newStudent = {}
      Object.assign(newStudent, this.student)
      newStudent.classroom = this.student.classroom.uuid

      getAPI.post('students/create', newStudent).then(async (res) => {
        this.student = res.data.data
        this.$store.dispatch('school/addStudent', res.data.data)
        this.$refs.tutorsList.doUpdateTutor(this.student.uuid);
        this.student =  {
          'first_name': null,
          'last_name': null,
          'national_number': null,
          'address_street': null,
          'address_number': null,
          'address_box': null,
          'address_zip': null,
          'address_city': null,
          'address_country': null,
          'classroom': {'uuid':null},
          'legal_tutors': [],
          'cpas': false,
        },
        await this.$router.back()
        const toast = await toastController
            .create({
              message: 'Élève créé avec succès.',
              duration: 2000
            })
        return toast.present();
      }).catch(async (error) => {
        const data = error.response.data.data
        let errorMsg = ''
        if (typeof data !== 'undefined') {
          if (typeof data['national_number'] !== 'undefined') {
            errorMsg = data['national_number'][0]
          }
        } else {
          errorMsg = data;
        }
        const errorMessage = errorMsg.includes('national_number_already_exist')
            ? 'Doublon - Un élève possède déjà ce numéro national.'
            : errorMsg
        this.basicAlertMsg(errorMessage)
      })
    }
  },
});
</script>
